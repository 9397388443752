import styled from 'styled-components';

export const teste = styled.div`
  border: 1px solid black;
`;

export const teste2 = styled.div`
  border: 1px solid black;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const TabelaContainer = styled.div`
  display: flex;
  width: 90%;
  border-radius: 12px;
  height: fit-content;
  align-self: center;
  // box-shadow: 0px 0px 6px 2px rgba(12, 12, 14, 0.2);
`;

export const OpcoesContainer = styled.div`
  display: flex;
  width: 90%;
  margin-top: 50px;
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: row;
  outline: 0;
  border: 0px solid green;
  height: fit-content;
  margin-bottom: 10px;
  width: 100%;
  .input-control {
    border: 0px solid green;
    margin-right: 30px;
    width: 100%;

    p {
      font-weight: bold;
      margin-bottom: 5px;
    }
  }

  .input-control-2 {
    border: 0px solid green;

    width: 100%;
    p {
      font-weight: bold;
      margin-bottom: 5px;
    }
  }

  .input-control:nth-of-type(3) {
    margin-right: 0px;
  }
`;

export const BotaoContainer = styled.div`
  display: flex;
  border: 0px solid red;
  justify-content: space-between;
  align-items: center;
  height: fit-content;
  margin-bottom: 0px;

  .btn {
    width: 45%;
    margin-bottom: 0px;
  }
`;

export const ModalContainerDelete = styled.div`
  height: fit-content;
  margin-bottom: 0px;
  height: fit-content;

  h1 {
    margin-bottom: 40px;
  }
  .aviso {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
    font-weight: regular;
    font-size: 18px;

    p {
    }

    svg {
      fill: red;
      font-size: 180px;
      margin-bottom: 20px;
    }
  }
`;
