import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100vw;
  height: 100vh;
  overflow-x: auto;

  // .header {
  //   display: flex;
  //   boder: 1px solid black;
  // }
  // .menuLateral {
  //   display: flex;
  //   boder: 1px solid black;
  //   margin-top: 100px;
  // }
  // .conteudo {
  //   display: flex;
  //   boder: 1px solid black;
  // }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
`;

export const OpcoesContainer = styled.div`
  display: flex;
  margin-top: 40px;
  margin-bottom: 10px;
  margin-left: 50px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  width: 100vw;
`;

export const SecondRowContainer = styled.div`
  display: flex;
  border: 0px solid blue;
  width: 100vw;
  height: fit-content;
`;

export const MenuLateralContainer = styled.div`
  display: flex;
  width: auto;
  height: auto;
`;

export const TabelaContainer = styled.div`
  display: flex;
  width: fit-content;
  border-radius: 12px;
  height: fit-content;
  align-self: center;
  // box-shadow: 0px 0px 6px 2px rgba(12, 12, 14, 0.2);
`;

export const DetalhesContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  margin-left: 70px;
  width: 100%;
  height: fit-content;
  align-self: center;
  border-radius: 8px;
  box-shadow: 0px 0px 6px 2px rgba(12, 12, 14, 0.2);

  h1 {
    align-self: flex-start;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 20px;
    font-size: 22px;
    font-weigth: bold;
  }

  .endereco {
    width: 100%;
    align-self: flex-start;
    h2 {
      font-size: 20px;
      font-weigth: bold;
      margin-top: 15px;
      margin-bottom: 20px;
    }
  }
`;

export const DetalhesHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 50px;
  .btn {
    margin-right: 30px;
    height: 50px;
  }
`;

export const Usuario = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;

  h1 {
    align-self: center;
  }
  svg {
  }
`;

export const DetalhesContent = styled.div`
  display: flex;
  border: 0px solid black;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  max-width: 100%;
  margin-left: 20px;
  margin-bottom: 20px;
  .item {
    display: flex;
    flex-direction: column;
    border: 0px solid black;
    width: fit-content;
    margin-top: 10px;
    margin-right: 50px;

    h2 {
      font-size: 18px;
      font-weight: bold;
    }
  }
`;
export const ModalContainer = styled.div`
  outline: 0;
  border: 0px solid green;
  height: fit-content;
  margin-bottom: 0px;
`;

export const BotaoContainer = styled.div`
  display: flex;
  border: 0px solid red;
  justify-content: space-between;
  align-items: center;
  height: fit-content;
  margin-bottom: 0px;

  .btn {
    width: 45%;
    margin-bottom: 0px;
  }
`;
