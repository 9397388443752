import styled, { css } from 'styled-components';
import MaterialButton, { ButtonProps } from '@material-ui/core/Button';


export const ButtonContainer = styled(MaterialButton)`
  ${(props: ButtonProps) => css`
    &.MuiButton-root {
      opacity: ${props.disabled ? '0.7' : '1'};
      display: flex;
      align-items: center;
      padding: 12px;
      margin: 16px 8px 16px 0;
      background-color: ${props.color};
      border: ${props.border ? '1px solid #533FE7' : ''};

      &:hover {
        background-color: ${props.color};
        opacity: 0.85;
      }

      &:active {
        transform: scale(0.95);
      }

      .MuiButton-label {
        line-height: 1;
      }

      .MuiCircularProgress-colorPrimary {
        color: white !important;
      }
      &.Mui-disabled {
        background-color: ${props.color};
      }
      div {
        display: flex;
        margin: 0px 5px;
      }

      p {
        display: flex;
        align-items: center;
        margin: 0px 5px;
      }

      &.pequeno {
        height: 16px;
        padding: 0;
        font-size: 10px;
      }
      &.medio {
        height: 30px;
        font-size: 12px;
        font-weight: bold !important;
      }
      &.grande {
        height: 40px;
        font-size: 14px;
        font-weight: bold !important;
      }
      &.fullwidth {
        height: 40px;
        width: 100%;
        font-size: 14px;
        font-weight: bold !important;
      }
    }
  `}
`;

export const SpinnerConatainer = styled.div`
  &.hide {
    display: none !important;
  }
`;
