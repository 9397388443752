/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable class-methods-use-this */
import api from './api';
import AppError from '../errors/AppError';
import { getToken } from './auth';

class RankingService {
  private headers: any;

  constructor() {
    // this.token = token
    this.headers = {
      Authorization: `Bearer: eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyVHlwZSI6IkFETUlOIiwiaWF0IjoxNjM5MTYxNzgzLCJleHAiOjE2Mzk3NjY1ODMsInN1YiI6IjA5MmVkMTE2LTRjNWItNDBhMS1iM2E4LTZhNDNhZDU0NjdkNCJ9.pF7RlpwBIqakGCb1Wnhx0db5ZU9PiQ-k_6H-GuP0NIc`,
    };
  }

  // listar o ranking de todas as imobiliarias
  public async getAllUsersRanking(imbobiliaria: string) {
    try {
      const { data } = await api.get(`ranking/${imbobiliaria}`);
      return data;
    } catch (err: any) {
      throw new AppError(err, err.statusCode);
    }
  }

  public async getRankingByEnterprise(emp_id: string) {
    try {
      const { data } = await api.get(`enterprises/ranking/${emp_id}`,
      {headers: {
        Authorization: `Bearer: ${getToken()}`}});
      return data;
    } catch (err: any) {
      throw new AppError(err, err.statusCode);
    }
  }

  // listar o ranking por imobiliaria
  public async getImobiliariaUsersRanking(imbobiliaria: string) {
    try {
      const { data } = await api.get(`ranking/${imbobiliaria}`);
      return data;
    } catch (err: any) {
      throw new AppError(err, err.statusCode);
    }
  }
}

export default new RankingService();
