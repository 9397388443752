import { useState } from 'react';
import Botao from '../Botao/Botao';
import Modal from '../Modal/Modal';
import { BotaoContainer, ModalContainerDelete, ModalTitle } from './styles';

interface UpdateModalProps {
  isModalOpen: boolean;
  onCloseModal: () => void;
  onFileUpload: (file: File | null) => void;
  isButtonEvidence: boolean;
  onHandleClick: () => void;
}

const ModalEvidence = (props: UpdateModalProps): any => {
  const {
    isModalOpen,
    onCloseModal,
    onFileUpload,
    isButtonEvidence,
    onHandleClick,
  } = props;
  const [files, setFiles] = useState<any>();
  const fileUpload = (e: any) => {
    setFiles(e);
    onFileUpload(e);
  };

  return (
    <Modal isOpen={isModalOpen} width={420} onClose={onCloseModal}>
      <>
        <ModalContainerDelete>
          <ModalTitle>Anexar evidência</ModalTitle>
          <input
            type="file"
            onChange={(e: any) => fileUpload(e.target.files)}
          />
          <BotaoContainer>
            <Botao
              disabled={isButtonEvidence}
              tamanho="medio"
              tipo="salvar"
              className="btn"
              onClick={() => {
                onHandleClick();
                onCloseModal();
              }}
            >
              Lançar proposta
            </Botao>
          </BotaoContainer>
        </ModalContainerDelete>
      </>
    </Modal>
  );
};

export default ModalEvidence;
