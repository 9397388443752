// import React, { useState } from "react";
// import { toast } from "react-toastify";
import { useState } from 'react';
import { Link, NavLink, BrowserRouter, useHistory } from 'react-router-dom';
import { IoLogOutOutline } from 'react-icons/io5';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useDispatch } from 'react-redux';
import Botao from '../Botao/Botao';
import Modal from '../Modal/Modal';
import {
  Container,
  Sair,
  UpperBar,
  LowerBar,
  Logo,
  SidePart,
  LogoContainer,
  ModalContainer,
  BotaoContainer,
} from './styles';
import { userLogout } from '../../storage/modules/user/userAction';
// import Button from "../Botao/Botao";


const HeaderProposta = (e: any) => {
  const [menuSair, setModalSair] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const dispatch = useDispatch();

  const history = useHistory();

  function sairMenu() {
    setModalOpen(true);
  }

  function sairAction(logout: boolean) {
    if (logout) {
      dispatch(userLogout())
      history.replace('/')
    }
    setModalOpen(false);
    history.push('/');
  }

  function refreshPage() {
    const teste = 'goback';

    history.push(e.paginaAtual.caminho, teste);
  }

  const SairModal: any = (): any => {
    return (
      <Modal
        isOpen={isModalOpen}
        width={380}
        onClose={() => setModalOpen(false)}
      >
        <>
          <ModalContainer>
            <h1> Você deseja sair mesmo?</h1>
            <BotaoContainer>
              <Botao tipo="salvar" tamanho="medio" className="btn"
              onClick={() => sairAction(true)}>
                Sim
              </Botao>
              <Botao tipo="excluir" tamanho="medio" className="btn"
              onClick={() => setModalOpen(false)} >
                Não
              </Botao>
            </BotaoContainer>
          </ModalContainer>
        </>
      </Modal>
    );
  };

  return (
    <Container>
      <UpperBar>
        <LogoContainer>
          <Logo />
        </LogoContainer>
        <Sair onClick={sairMenu}>
          <h3>
            <strong>Sair</strong>
          </h3>
          <IoLogOutOutline className="icone" />
        </Sair>
      </UpperBar>
      <SairModal />
    </Container>
  );
};

export default HeaderProposta;
