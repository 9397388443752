/* eslint-disable no-param-reassign */
/* eslint-disable prefer-template */

import React, { useEffect, useRef, useState } from 'react';
import MaterialTable from 'material-table';
import { Overlay } from 'react-bootstrap';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
// import { CsvBuilder } from 'filefy';
import XLSX from 'xlsx';
import { BotaoContainer, Container, ModalContainer } from './styles';
import cores from '../../styles/cores';
import Filter from '../../assets/Icons/FilterIcon';
import Modal from '../Modal/Modal';
import Botao from '../Botao/Botao';


interface IGridProps {
  columns: any;
  data: any;
  pagination: any;
  paginationPageSize: any;
  title: any;
  haveActions?: string;
  onRowClick(e: any, rowData: any): any;
  editarClick(event: any, data: any): any;
  excluirClick(event: any, data: any): any;
  updateCellCallbackFunction?(newValue: any,oldValue: any,rowData: any): any;
  isLoading?: boolean;
}

const Table = (props: IGridProps) => {
  const {
    columns,
    data,
    onRowClick,
    updateCellCallbackFunction,
    pagination,
    paginationPageSize,
    title,
    haveActions,
    isLoading
  } = props;

  let { editarClick, excluirClick } = props;

  const legal = useRef(null);

  editarClick = editarClick || undefined;
  excluirClick = excluirClick || undefined;

  useEffect(() => {
    console.log('');
  }, [legal]);



  let myActions;

  if (haveActions === 'none') {
    myActions = undefined;
  }
  if (haveActions === 'edit') {
    myActions = [
      {
        icon: () => {
          return <EditIcon style={{ fontSize: 32 }} className="editar" />;
        },
        tooltip: 'Editar',
        onClick: editarClick,
      },
    ];
  }
  if (haveActions === 'editdelete') {
    myActions = [
      {
        icon: () => {
          return <EditIcon style={{ fontSize: 32 }} className="editar" />;
        },
        tooltip: 'Editar',
        onClick: editarClick,
      },
      // {
      //   icon: () => {
      //     return <DeleteIcon style={{ fontSize: 32 }} className="excluir" />;
      //   },
      //   tooltip: 'Excluir',
      //   onClick: excluirClick,
      // },
    ];
  }

  const modifiedColumns = columns.map((column: { editable: string; }) => {
    if(column.editable && column.editable !== ''){
      return column
    }
    column.editable = 'never'
    return column
})

  return (
    <Container>
      <MaterialTable
        title={title}
        data={data}
        isLoading = {isLoading}
        columns={modifiedColumns}
        cellEditable={{
          onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
            return new Promise((resolve, reject) => {
              if(updateCellCallbackFunction){
                updateCellCallbackFunction(newValue,oldValue,rowData);
              }
              setTimeout(resolve, 1000);
            });
          }
        }}
        options={{
          hideFilterIcons: false,
          pageSizeOptions: [5, 10, 20],
          thirdSortClick: false,
          search: true,
          paging: true,
          filtering: true,
          exportButton: true,
          exportAllData: true,
          headerStyle: {
            fontSize: 16,
            fontWeight: 'bold',
          },
          loadingType: "linear",
          actionsColumnIndex: 99,

          exportDelimiter: ',',
          exportCsv: (columnsExport, dataExport) => {

            const notExportableColumns: Array<string> = [];

            for (let i = 0; i < columnsExport.length; i++) {
              if (columnsExport[i].export === false) {
                notExportableColumns.push(columnsExport[i].field);
              }
            }

            const newData = dataExport.map((row: any) => {
              delete row.tableData;
              for (let i = 0; i < notExportableColumns.length; i++) {
                // eslint-disable-next-line dot-notation
                delete row[notExportableColumns[i]];
              }
              return row;
            });
            
            const workSheet = XLSX.utils.json_to_sheet(newData);
            const workBook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(
              workBook,
              workSheet,
              `${title.trim()}`,
            );
            // Buffer
            const buf = XLSX.write(workBook, {
              bookType: 'xlsx',
              type: 'buffer',
            });
            // Binary string
            XLSX.write(workBook, { bookType: 'xlsx', type: 'binary' });
            // Download
            XLSX.writeFile(workBook, `${title.trim()}.xlsx`);
          },
        }}
        onRowClick={onRowClick}
        actions={myActions}
        localization={{
          body: {
            emptyDataSourceMessage: `${isLoading ? 'Carregando...' : 'Nenhum dado para exibir'}`,
          },
          toolbar: {
            searchTooltip: 'Pesquisar',
            searchPlaceholder: 'Pesquisar',
            exportCSVName: 'Exportar como Excel',
            exportPDFName: 'Exportar como PDF',
          },
          header: {
            actions: 'Ações'
        },
          pagination: {
            labelRowsSelect: 'linhas',
            labelDisplayedRows: '{count} de {from}-{to}',
            firstTooltip: 'Primeira página',
            previousTooltip: 'Página anterior',
            nextTooltip: 'Próxima página',
            lastTooltip: 'Última página',
          },
        }}
      />
    </Container>
  );
};

Table.defaultProps = {
  haveActions: 'editdelete',
  isLoading: false
};

export default Table;