import Reactotron from 'reactotron-react-js';
import { reactotronRedux } from 'reactotron-redux';

// if (__DEV__) {
const tron = Reactotron.configure().use(reactotronRedux()).connect();

tron.clear();

console.tron = tron
// }

export default Reactotron;
