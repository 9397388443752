/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable class-methods-use-this */
import AppError from '../errors/AppError';
import api from './api';
import apiBlip from './apiBlip';
import { getToken } from './auth';

class ImobiliariasService {
  private headers: any;


  constructor() {
    // this.token = token
    this.headers = {
      Authorization: `Bearer: ${getToken()}`
    };
  }

  // listar todos os usuários
  public async listAllImobiliarias() {
    try {
      const { data } = await api.get(`/agencies/list`,
        {
          headers: {
            Authorization: `Bearer: ${getToken()}`
          }
        }
      );
      return data;
    } catch (err: any) {

      throw new AppError(err, err.statusCode);
    }
  }

  public async findImobiliaria(id: any) {
    try {
      const { data } = await api.get(`/agencies/find/${id}`,
        {
          headers: {
            Authorization: `Bearer: ${getToken()}`
          },
        }
      );
      return data;
    } catch (err: any) {

      throw new AppError(err, err.statusCode);
    }
  }

  public async createImobiliaria(params: any) {
    try {
      const { data } = await api.post(`/agencies`,
        { ...params },
        {
          headers: {
            Authorization: `Bearer: ${getToken()}`
          },
        });
      return data;
    } catch (err: any) {

      throw new AppError(err, err.statusCode);
    }
  }

  public async deleteImobiliaria(id: string | number) {
    try {
      const { data } = await api.delete(`/agencies/delete/${id}`,
        {
          headers: {
            Authorization: `Bearer: ${getToken()}`
          },
        });
      return data;
    } catch (err: any) {

      throw new AppError(err, err.statusCode);
    }
  }

  public async updateImobiliaria(params: any) {
    try {
      const { data } = await api.patch(`/agencies/update`,
        { ...params },
        {
          headers: {
            Authorization: `Bearer: ${getToken()}`
          },
        }
      );
      return data;
    } catch (err: any) {

      throw new AppError(err, err.statusCode);
    }
  }

  public async blipUserId(params: any) {
    try {
      const { data } = await apiBlip.get('',
        {
          headers: {
            Authorization: 'Key dGVzdGVzaXRldml6aWRpZ2l0YWw6YlM2bHF3eFdudmZsek9lWENGR0k='
          },
        }
      );
      return data
    } catch (err: any) {
      throw new AppError(err, err.statusCode);
    }
  }

  public async updateImobiliariaEmpreendimento(params: any) {
    try {
      const { data } = await api.patch(`/agencies/enterprises`,
        { ...params },
        {
          headers: {
            Authorization: `Bearer: ${getToken()}`
          },
        }
      );
      return data;
    } catch (err: any) {

      throw new AppError(err, err.statusCode);
    }
  }

  public async alteraDisponibilidade(idAlteracao: string, novoStatus: boolean, tipoAlteracao: string) {
    try {
      const response = await api.post('https://webhooks.bairru.com.br/leads_internas/altera_disponibilidade', {
        id_alteracao: idAlteracao,
        novo_status: novoStatus,
        tipo_alteracao: 'imobiliaria'
      },
        {
          headers: this.headers
        }
      );

      console.log('Requisição bem-sucedida:', response.data);
      return response.data;
    } catch (err: any) {
      console.error('Erro na requisição:', err);
      throw new AppError(err, err.statusCode);
    }
  }

}

export default new ImobiliariasService();