import styled, { keyframes } from 'styled-components';
import cores from '../../styles/cores';


export const Content = styled.div`
  display: flex;
  position:fixed;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  /* border: 1px solid red; */
  // background-image: ${cores.themeGradient_2};

  .snackbar-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 400px;
    .MuiAlert-icon {
    }
    .MuiSvgIcon-fontSizeSmall {
      font-size: 22px;
    }

    .MuiAlert-action {
      font-size: 64px;
    }
    .MuiAlert-message {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      font-size: 16px;
    }
  }
`;
export const MenuLateralContainer = styled.div`
  display: flex;
  width: fit-content;
  height: 100vh;
  width: 14%;
  overflow: hidden;
  /* margin-top: 88px; */
  /* border: 2px solid blue; */
`;

export const ContainerTabela = styled.div`
  width: 86%;
  max-height: 100vh;
  overflow-y: auto;
  padding: 20px;
  align-items:center;
  justify-content: center;
  margin-bottom:60px;
`

export const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 96%;
  /* margin-left: 5%; */
  /* padding: 20px;
  margin-bottom:60px; */
  /* width: 121%; */
`;

export const TabelaContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 30px;
  align-self: center;
  align-items: center;
  margin-left: 5%;
  margin-right: 20%;

  #titulo_tabela {
    font-size: 1.8rem;
    font-weight: 600;
    margin-top:20px;
    margin-bottom: 30px;
    padding-bottom: 6px;
    border-bottom: 2px solid #27461f;
  }

  #titulo_tabela::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px; /* Altura da linha */
    background-color: currentColor; /* Cor da linha igual à cor do texto */
    transform: translateY(50%); /* Move a linha para baixo da metade das letras */
}

  #h2 {
    font-size: 1.8rem;
    font-weight: 600;
    margin-bottom:20px;
  }
  /* padding-bottom:20px; */
  /* display: flex; */
  /* border: 1px solid black; */
  /* width: 85%; */
  /* border-radius: 12px; */
  /* height: 100vh; */
  /* justify-content: center; */
  /* align-items: center; */
  /* padding-top: 50px; */
  /* margin-bottom: 50px; */
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 121%;
  /* margin-bottom:10px; */
  /* #h2 {
    font-size: 1.8rem;
    font-weight: 600;
    margin-right:12px;
  } */
  #labelTotal{
    margin-right:12px
  }
  
  #botaoInserirNF {
    margin-right: 1.5vw;
    color: "#BEC0C2";
    font-size: 1.8rem;
    border-radius: 50%;
    border: none;
    width: 2rem;
    height: 2rem;
    transition: filter 0.2s;
    margin-left:15px;

    &:hover {
      filter: brightness(0.8);
    }

    &:active {
      filter: brightness(1.2);
    }
  }
`