import styled from 'styled-components';

export const teste = styled.div`
  border: 1px solid black;
`;

export const teste2 = styled.div`
  border: 1px solid black;
`;

export const TabelaContainer = styled.div`
  border: 0px dashed black;
  width: 91%;
  border-radius: 12px;
  height: fit-content;
  align-self: center;
  // box-shadow: 0px 0px 6px 2px rgba(12, 12, 14, 0.2);
`;

export const OpcoesContainer = styled.div`
  display: flex;
  margin-top: 40px;
  margin-bottom: 10px;
  margin-left: 50px;
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: row;
  outline: 0;
  border: 0px solid green;
  height: fit-content;
  margin-bottom: 10px;
  width: 100%;
  .input-control {
    border: 0px solid green;
    width: '100%';

    p {
      font-weight: bold;
      margin-bottom: 5px;
    }
  }

  .input-control-2 {
    border: 0px solid green;

    width: 100%;
    p {
      font-weight: bold;
      margin-bottom: 5px;
    }
  }

  .input-control:nth-of-type(3) {
    margin-right: 0px;
  }
`;

export const BotaoContainer = styled.div`
  display: flex;
  border: 0px solid black;
  justify-content: space-between;
  align-items: center;
  height: fit-content;
  margin-top: 0px;

  .btn {
    align-self: center;
    width: 45%;
  }
`;

export const Teste = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 4px;
  color: white;
  border-radius: 32px;
  width: 80px;
  height: fit-content;
  font-size: 10px;
  font-weight: bold;

  &.normal {
    background-color: green;
  }
  &.moderado {
    background-color: rgba(220, 220, 0, 1);
    background-color: orange;
    color: white;
  }
  &.urgente {
    background-color: red;
  }
  &.indefinido{
    background-color: gray;
  }
`;

export const ModalContainerDelete = styled.div`
  height: fit-content;
  margin-bottom: 0px;
  height: fit-content;

  h1 {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0px solid black;
    width: 100%;
    margin-bottom: 20px;
    margin-top: 25px;
  }
  .aviso {
    border: 0px solid red;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
    font-weight: regular;
    font-size: 18px;

    p {
      border: 0px solid red;
      text-align: justify;
      line-height: 20px;
    }

    p:nth-of-type(1) {
      font-weight: bold;
      margin-bottom: 10px;
      text-align: center;
    }
    p:nth-of-type(2) {
      margin-bottom: 10px;
    }

    svg {
      fill: red;
      font-size: 128px;
      margin-bottom: 4px;
    }
  }
`;
