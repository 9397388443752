/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable class-methods-use-this */
import AppError from '../errors/AppError';
import api from './api';
import { getToken } from './auth';

class MarketplaceService {
  private headers: any;

  constructor() {
    this.headers = {
      Authorization: `Bearer: ${getToken()}`   };
  }

  // listar todos os usuários
  public async listAllProducts() {
    try {
      const { data } = await api.get(`/products/list`, {
        headers: {
          Authorization: `Bearer: ${getToken()}`},
      });
      return data;
    } catch (err: any) {
      
      throw new AppError(err, err.statusCode);
    }
  }

  public async findProduct(id: string | number) {
    try {
      const { data } = await api.get(`/products/find/${id}`, {
        headers: this.headers,
      });
      return data;
    } catch (err: any) {
      
      throw new AppError(err, err.statusCode);
    }
  }

  public async createProduct(params: any) {
    try {
      const formData = new FormData();
      formData.append('id', params.id);
      formData.append('nome', params.nome);
      formData.append('descricao', params.descricao);
      formData.append('valor', params.valor);
      formData.append('categoria', params.categoria);
      formData.append('link', params.link);
      formData.append('foto', params.foto);
      
      const { data } = await api.post(
        `/products`,
        formData,{
          headers: {
            ...this.headers
          }
        }
      );

      return data;
    } catch (err: any) {
      
      throw new AppError(err, err.statusCode);
    }
  }

  public async deleteProduct(id: string) {
    try {
      const { data } = await api.delete(`/products/delete/${id}`, {
        headers: this.headers,
      });

      return data;
    } catch (err: any) {
      
      throw new AppError(err, err.statusCode);
    }
  }

  public async updateProduct(params: any) {
    try {
      const formData = new FormData();
      formData.append('id', params.id);
      formData.append('nome', params.nome);
      formData.append('descricao', params.descricao);
      formData.append('valor', params.valor);
      formData.append('categoria', params.categoria);
      formData.append('link', params.link);
      formData.append('foto', params.foto);
      const { data } = await api.patch(
        `/products/update`,
        formData,{
          headers: {
            ...this.headers
          }
        }
      );
      return data;
    } catch (err: any) {
      throw new AppError(err, err.statusCode);
    }
  }
}

export default new MarketplaceService();
