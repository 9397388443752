/* eslint-disable no-restricted-syntax */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-case-declarations */
/* eslint-disable no-loop-func */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-multi-assign */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-param-reassign */
/* eslint-disable no-useless-escape */
import { TextField } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ClearIcon from '@material-ui/icons/Clear';
import DoneIcon from '@material-ui/icons/Done';
import UpdateIcon from '@material-ui/icons/Update';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import Botao from '../../../components/Botao/Botao';
import CancelChoiceModal from '../../../components/CancelChoiceModal';
import HeaderProposta from '../../../components/HeaderProposta';
import InputField from '../../../components/Inputs/InputField';
import ModalCP from '../../../components/ModalCP/ModalCP';
import ModalWaitCP from '../../../components/ModalWaitCP/ModalWaitCP';
import SendModal from '../../../components/SendModal/SendModal';
import Snackbars from '../../../components/Snackbars';
import UpdateModal from '../../../components/UpdateModal/UpdateModal';
import AppError from '../../../errors/AppError';
import { isSignedIn } from '../../../services/auth';
import ImobiliariasService from '../../../services/ImobiliariasService';
import LotesService from '../../../services/LotesService';
import PropostasService from '../../../services/PropostasService';
import { RootState } from '../../../storage/rootReducer';
import cores from '../../../styles/cores';
import { mascaraCNPJ, mascaraCPF } from '../../../utils/mascaras';
import sleep from '../../../utils/sleep';
import DocumentDevolution from '../DocumentsDevolution';
import DocumentDevolutionBoleto from '../DocumentsDevolutionBoleto';
import DocumentDevolutionContrato from '../DocumentsDevolutionContrato';
import PessoaFisica from '../PessoaFisica';
import PessoaJuridica from '../PessoaJuridica';
import {
  BotoesContainer,
  Content,
  DetalhesContainer,
  DetalhesContent,
  DetalhesHeader,
  InputsContainer,
  InputsContent,
} from './styles';

const EditarProposta = () => {
  const history = useHistory();
  if (!isSignedIn()) {
    history.push('/');
  }
  const location = useLocation<any>();
  const { token } = useSelector((state: RootState) => state.userReducer);
  if (!isSignedIn()) {
    history.push('/');
  }
  const [principal, setPrincipal] = useState<any>();
  const [proposalData, setProposalData] = useState<any>();
  const [proposalPhysical, setProposalPhysical] = useState<any>();
  const [tipoPessoa, setTipoPessoa] = useState('physical');
  const [proposalId, setProposalId] = useState('');
  const [proposalStatus, setProposalStatus] = useState('');
  const [estadoCivil, setEstadoCivil] = useState('');
  const [participantes, setParticipantes] = useState<any>(
    location.state.rowData.participantes,
  );
  const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false);
  const [showSendModal, setShowSendModal] = useState<boolean>(false);
  const [showModalCP, setShowModalCP] = useState<boolean>(false);
  const [isDisapproved, setIsDisapproved] = useState<boolean>(false);
  const [cpf, setCpf] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [sexo, setSexo] = useState('');
  const [dataNascimento, setDataNascimento] = useState<any>('');
  const [numFilhos, setNumFilhos] = useState([]);
  const [rendaFamiliar, setRendaFamiliar] = useState();
  const [nome, setNome] = useState();
  const [numEntrada, setNumEntrada] = useState();
  const [numParcelas, setNumParcelas] = useState();
  const [valorParcela, setValorParcela] = useState();
  const [status, setStatus] = useState();
  const [objetivo, setObjetivo] = useState('');
  const [telefone, setTelefone] = useState('');
  const [email, setEmail] = useState('');
  const [valorEntrada, setValorEntrada] = useState();
  const [documento, setDocumento] = useState();
  const [numeroPessoasMorar, setNumeroPessoasMorar] = useState<number>(0);
  const [escolaridade, setEscolaridade] = useState<string>('');
  const [numeroSinal, setNumeroSinal] = useState<any>(0);
  const [numeroParcela, setNumeroParcela] = useState<any>(0);
  const [cnpj, SetCnpj] = useState();
  const [rgSocio, SetRgSocio] = useState();
  const [cpfSocio, SetCpfSocio] = useState();
  const [faturamento, SetFaturamento] = useState();
  const [numEntradaLegal, SetNumEntradaLegal] = useState();
  const [numParcelasLegal, SetNumParcelasLegal] = useState();
  const [valorParcelasLegal, SetValorParcelasLegal] = useState();
  const [valorTotalLegal, SetValorTotalLegal] = useState();
  const [statusLegal, SetStatusLegal] = useState();
  const [objetivoLegal, SetObjetivoLegal] = useState();
  const [telefoneLegal, SetTelefoneLegal] = useState();
  const [emailLegal, SetEmailLegal] = useState();
  const [nomeFantasia, SetNomeFantasia] = useState();
  const [valorEntradaLegal, SetValorEntradaLegal] = useState();
  const [documentos, SetDocumentos] = useState<any>([]);
  const [documentosFisica, setDocumentosFisica] = useState();
  const [mob, setMob] = useState<any>('');
  const [valorReduzidas, setValorReduzidas] = useState(
    location.state.rowData.valor_reduzidas || '',
  );
  const [dataReduzidas, setDataReduzidas] = useState(
    location.state.rowData.data_vencimento_reduzidas
      ?.split('T')[0]
      .split('-')
      .reverse()
      .join('/') || '',
  );
  const [dataSinal, setDataSinal] = useState<string>('');
  const [dataParcela, setDataParcela] = useState<string>('');
  const [valorTotal, setValorTotal] = useState<number>(
    location.state.rowData.valor_total,
  );
  const [valorParcelas, setValorParcelas] = useState<number>(
    location.state.rowData.valor_parcelas,
  );
  const [conjugePrinicipal, setConjugePrincipal] = useState<any>({});
  const [documentosPessoais, setDocumentosPessoais] = useState([]);
  const [certidaoEstadoCivil, setCertidaoEstadoCivil] = useState([]);
  const [comprovanteResidencia, setComprovanteResidencia] = useState([]);
  const [comp_renda, setcomp_renda] = useState([]);
  const [documentosPessoaisLegal, setDocumentosPessoaisLegal] = useState([]);
  const [compEnderecoLegal, setCompEnderecoLegal] = useState([]);
  const [cnpjDoc, setCnpjDoc] = useState([]);
  const [juntaComercial, setJuntaComercial] = useState([]);
  const [faturamentoDoc, setFaturamentoDoc] = useState([]);
  const [balanco, setBalanco] = useState([]);
  const [balancete, setBalancete] = useState([]);
  const [contratoSocial, setContratoSocial] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [participantDocumentos, setParticipantDocumentos] = useState<any[]>([]);
  const [contDocumentosPhysical, setContDocumentosPhysical] = useState<any>([]);
  const [observacao, setObservacao] = useState<string>('');
  const [isDisapprovedProposal, setDisableProposal] = useState<boolean>(false);
  const [files, setFiles] = useState<any[]>([]);
  const [deletFilesData, setDeleteFilesData] = useState<any>('');
  const [dadosConjuge, setDadosConjuge] = useState<string>('');
  const [nomeConjuge, setNomeConjuge] = useState<string>('');
  const [cpfConjuge, setCPFConjuge] = useState<string>('');
  const [rgConjuge, setRGConjuge] = useState<string>('');
  const [orgaoExpedidorConjuge, setOrgaoExpedidorConjuge] =
    useState<string>('');
  const [telefoneConjuge, setTelefoneConjuge] = useState('');
  const [emailConjuge, setEmailConjuge] = useState('');
  const [sexoConjuge, setSexoConjuge] = useState<string>('');
  const [dataNascimentoConjuge, setDataNascimentoConjuge] =
    useState<string>('');
  const [nacionalidadeConjuge, setNacionalidadeConjuge] = useState<string>('');
  const [cargoConjuge, setCargoConjuge] = useState<string>('');
  const [regimeCasamento, setRegimeCasamento] = useState<string>('');
  const [dataCasamento, setDataCasamento] = useState<string>('');
  const [codMega, setCodMega] = useState<string>('');
  const [landId, setLandId] = useState<string>('');
  const [lote, setLote] = useState({
    lote: '',
    empreendimento: '',
    quadra: '',
    cod_emp: '',
    area: '',
  });
  const [rg, setRg] = useState('');
  const [nacionalidade, setNacionalidade] = useState('');
  const [orgaoRg, setOrgaoRg] = useState('');
  const [cep, setCep] = useState('');
  const [estado, setEstado] = useState('');
  const [cargo, setCargo] = useState('');
  const [clt, setClt] = useState('');
  const [moradiaAtual, setMoradiaAtual] = useState('');
  const [complemento, setComplemento] = useState('');
  const [numero, setNumero] = useState('');
  const [logradouro, setLogradouro] = useState('');
  const [bairro, setBairro] = useState('');
  const [cidade, setCidade] = useState('');
  const [idParticipante, setId] = useState<string>();
  const [empCod, setEmpCod] = useState('');
  const [objeto, setObjeto] = useState({});
  const [choiseModal, setChoiseModal] = useState(false);
  const [contratoCP, setContratoCP] = useState<any>();
  const [devolutionDocuments, setDevolutionDocuments] = useState<any>([]);
  const [hasDevolution, setHasDevolution] = useState(false);
  const [hasDevolutionContrato, setHasDevolutionContrato] = useState(false);
  const [hasDevolutionBoleto, setHasDevolutionBoleto] = useState(false);
  const [URLContrato, setURLContrato] = useState<any>('');
  const [nomeEmpreendimento, setNomeEmpreendimento] = useState<any>();
  const [modalWaitCP, setModalWaitCP] = useState(false);
  const [comentario, setComentario] = useState('');

  const [snackbarProps, setSnackbarProps] = useState({
    message: '',
    type: 'success',
  });
  const handleSnackbarClose = (
    event?: React.SyntheticEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };
  function formatCurrency(value: any) {
    if (typeof value === 'string') {
      value = Number(value);
    }
    if (Number.isInteger(value)) {
      return value.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      });
    } else {
      value = Number(value.toFixed(2));
      return value.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      });
    }
  }
  function calculaIdade(dataNascimento: string) {
    const anoNascimento = new Date(dataNascimento).getFullYear();
    const anoAtual = new Date().getFullYear();
    return anoAtual - anoNascimento;
  }

  async function updateStatus(
    params: { participants: any[]; status: string },
    propostaId: string,
  ) {
    setDisableProposal(false);
    setDisableProposal(false);

    try {
      await PropostasService.updateProposta(params, propostaId);
    } catch (err: any) {
      console.log(err);
    }
  }

  async function changeSatus(status: string, cod_mega: string) {
    await PropostasService.defeatProposal(location.state.rowData.id, {
      cod_mega: cod_mega,
      status: status,
    }).then(data => {
      setSnackbarProps({
        message: `Proposta ${status} com sucesso!'`,
        type: 'success',
      });
    });
  }

  const childToParent = () => {
    console.log('Child to parent');
  };

  function formataData(data: string) {
    const dataFormatada = new Date(data);
    return `${dataFormatada.toLocaleDateString()} às  ${dataFormatada.toLocaleTimeString()}`;
  }

  async function sendAnalise() {
    await PropostasService.updateProposta(
      {
        proposals_participants: [],
        status: 'Em Análise',
      },
      location.state.rowData.id,
    );
  }

  async function observationCall() {
    await PropostasService.defeatProposal(location.state.rowData.id, {
      cod_mega: proposalPhysical.cod_mega_proposta_ativa,
      status: proposalPhysical.status,
      comentary: observacao,
    });
  }

  async function salvarProposta() {
    setIsLoading(true);
    setSnackbarProps({
      message: 'Carregando...',
      type: 'info',
    });
    setSnackbarOpen(true);
    observationCall();
    if (location.state && location.state.rowData.tipo === 'legal') {
      try {
        await PropostasService.updateDocumentsProposalLegal(
          [
            { tag: 'contract', file: contratoSocial },
            { tag: 'personal', file: documentosPessoaisLegal },
            { tag: 'cartao_cnpj', file: cnpjDoc },
            { tag: 'junta_comercial', file: juntaComercial },
            { tag: 'faturamento', file: faturamentoDoc },
            { tag: 'balanco', file: balanco },
            { tag: 'balancete', file: balancete },
          ],
          proposalData.id,
        );
        setSnackbarProps({
          message: 'Os documentos foram enviados com sucesso!',
          type: 'success',
        });
      } catch (err) {
        setSnackbarProps({
          message: 'Não foi possível enviar os documentos!',
          type: 'error',
        });
      }
    } else {
      try {
        let arrSeparatedByParticipantID = [];
        let existis;
        for (let el of participantDocumentos) {
          existis = arrSeparatedByParticipantID.findIndex(
            (e: any) => e.participant_id === el.participant_id,
          );
          existis = Number(existis);
          if (existis >= 0) {
            let aux = [
              ...arrSeparatedByParticipantID[existis].docs,
              ...[el.docs],
            ];
            arrSeparatedByParticipantID[existis].docs = aux;
          } else {
            arrSeparatedByParticipantID.push({
              participant_id: el.participant_id,
              docs: [el.docs],
            });
          }
        }
        for (let el of arrSeparatedByParticipantID) {
          let doc_pessoal: any = [];
          let doc_res: any = [];
          let doc_estado_civil: any = [];
          let doc_comp_renda: any = [];

          for (let doc of el.docs) {
            if (doc.tag === 'documentos pessoais') {
              doc_pessoal = doc.file;
            }
            if (doc.tag === 'comprovante de residencia') {
              doc_res = doc.file;
            }
            if (doc.tag === 'certidao estado civil') {
              doc_estado_civil = doc.file;
            }
            if (doc.tag === 'comp_renda') {
              doc_comp_renda = doc.file;
            }
          }

          let arrToRequest = [];

          if (doc_pessoal.length > 0) {
            for (let el of doc_pessoal) {
              arrToRequest.push({
                tag: 'personal',
                file: el,
              });
            }
          }
          if (doc_estado_civil.length > 0) {
            for (let el of doc_estado_civil) {
              arrToRequest.push({
                tag: 'estado_civil',
                file: el,
              });
            }
          }

          if (doc_res.length > 0) {
            for (let el of doc_res) {
              arrToRequest.push({
                tag: 'comp_residencial',
                file: el,
              });
            }
          }

          if (doc_comp_renda.length > 0) {
            for (let el of doc_comp_renda) {
              arrToRequest.push({
                tag: 'comp_renda',
                file: el,
              });
            }
          }
          await PropostasService.updateDocumentsProposalPhysical(
            arrToRequest,
            proposalData.id,
            el.participant_id,
          );
          await PropostasService.analiseDocument(codMega);
        }

        setSnackbarProps({
          message: 'Os documentos foram enviados com sucesso!',
          type: 'success',
        });
        await sleep(750);
        history.goBack();
      } catch (err: any) {
        setSnackbarProps({
          message: `Status: ${err.message.response.status} - ${err.message.response.data.message}`,
          type: 'error',
        });
      }
    }
    setIsLoading(false);
    setSnackbarOpen(true);
  }

  const findByIdLegal = useCallback(async () => {
    if (!isSignedIn()) {
      history.push('/');
      return;
    }
    if (location.state && location.state.rowData.tipo === 'legal') {
      // eslint-disable-next-line no-unused-expressions
      await PropostasService.getProposalLegalById(
        location.state.rowData.id,
      ).then(response => {
        SetCnpj(response.cnpj);
        SetNomeFantasia(response.nome_fantasia);
        SetEmailLegal(response.email);
        SetNumEntradaLegal(response.num_entradas);
        SetValorEntradaLegal(response.valor_entrada);
        SetNumParcelasLegal(response.num_parcelas);
        SetValorTotalLegal(response.valor_total);
        SetStatusLegal(response.status);
        SetObjetivoLegal(response.objetivo);
        SetTelefoneLegal(response.telefone);
        SetEmailLegal(response.email);
        SetDocumentos(response.documentos);
      });
    }
  }, []);

  const getProposalPhysical = useCallback(async () => {
    await PropostasService.getProposalPhysicalById(
      location.state.rowData.id,
    ).then(response => {
      const data = response.proposal;
      const findPrincipal = data.proposals_participants_type_percentage.find(
        (proposals_participant_type_percentage: {
          tipo_participante: string;
        }) =>
          proposals_participant_type_percentage.tipo_participante ===
          'principal',
      );
      const principal = data.proposals_participants.find(
        (proposals_participant: { id: any }) =>
          proposals_participant.id === findPrincipal?.id_participante,
      );
      setPrincipal(principal);
      setProposalPhysical(data);
    });
  }, []);

  useEffect(() => {
    if (!isSignedIn()) {
      history.push('/');
    } else {
      setProposalData(location.state.rowData);
      findByIdLegal();
      getProposalPhysical();
    }
  }, []);

  useEffect(() => {
    if (proposalPhysical) {
      setObservacao(proposalPhysical.comentary);
      setProposalId(proposalPhysical.id);
      setValorEntrada(proposalPhysical.valor_entrada);
      setValorParcelas(proposalPhysical.valor_parcelas);
      setValorTotal(proposalPhysical.valor_total);
      setDataSinal(
        proposalPhysical.data_vencimento_sinal
          ?.split('T')[0]
          .split('-')
          .reverse()
          .join('/'),
      );
      setDataParcela(
        proposalPhysical.data_vencimento_parcela
          ?.split('T')[0]
          .split('-')
          .reverse()
          .join('/'),
      );
      setNumeroSinal(proposalPhysical.num_entradas);
      setNumeroParcela(proposalPhysical.num_parcelas);
      setRendaFamiliar(proposalPhysical.renda_familiar);
      setNumeroPessoasMorar(proposalPhysical.numero_pessoas_morar);
      setEscolaridade(proposalPhysical.escolaridade);
      setCodMega(proposalPhysical.cod_mega_proposta_ativa);
      setLandId(proposalPhysical.land_id);
      setId(proposalPhysical.proposals_participants[0].id);
      setEmpCod(lote.cod_emp);
      setComentario(proposalPhysical.comentary);

      const findMob = ImobiliariasService.findImobiliaria(
        proposalPhysical.user.agency_id,
      ).then((resp: any) => {
        setMob(resp.agency);
      });
      const findLand = LotesService.findLote(proposalPhysical.land_id).then(
        (resp: any) => {
          setLote(resp.land);
        },
      );
    }
  }, [location, principal, proposalPhysical]);

  useEffect(() => {
    if (!isSignedIn()) {
      history.push('/');
    } else {
      setProposalData(location.state.rowData);
      const {
        nome,
        documento,
        sexo,
        data_nascimento,
        num_filhos,
        renda_familiar,
        num_entradas,
        valor_entrada,
        num_parcelas,
        valor_total,
        valor_parcelas,
        objetivo,
        telefone,
        email,
        participantes,
        tipo,
        status,
        estado_civil,
        documentosFisica,
      } = location.state.rowData;

      setNome(nome);
      setTipoPessoa(tipo);
      setProposalStatus(status);
      setEstadoCivil(estado_civil);
      setDocumento(documento);
      setSexo(sexo);
      setDataNascimento(data_nascimento);
      setNumFilhos(num_filhos);
      setRendaFamiliar(renda_familiar);
      setNumEntrada(num_entradas);
      setNumParcelas(num_parcelas);
      setValorParcela(valor_parcelas);
      setValorTotal(valor_total);
      setObjetivo(objetivo);
      setTelefone(telefone);
      setEmail(email);
      setValorEntrada(valor_entrada);
      setDocumentosFisica(documentos);
    }
  }, [location]);

  async function getProposalParticipants() {
    await PropostasService.getProposalParticipants(
      location.state.rowData.participantes[0].id,
    )
      .then(response => {
        const data = response;
        if (data.conjuge) {
          setDadosConjuge(data.conjuge);
          setNomeConjuge(data.conjuge.nome);
          setCPFConjuge(data.conjuge.cpf);
          setRGConjuge(data.conjuge.rg);
          setOrgaoExpedidorConjuge(data.conjuge.orgao_rg);
          setSexoConjuge(data.conjuge.sexo);
          setDataNascimentoConjuge(
            data.conjuge.data_nascimento
              ?.split('T')[0]
              .split('-')
              .reverse()
              .join('/'),
          );
          setNacionalidadeConjuge(data.conjuge.nacionalidade);
          setCargoConjuge(data.conjuge.cargo);
          setRegimeCasamento(data.conjuge.regime_casamento);
          setDataCasamento(
            data.conjuge.data_casamento
              ?.split('T')[0]
              .split('-')
              .reverse()
              .join('/'),
          );
          setConjugePrincipal(data.conjuge);
        }
      })
      .catch(err => console.log('erro aqui', err));
  }

  useEffect(() => {
    getProposalParticipants();
    getProposalPhysical();
    setProposalData(location.state.rowData);
    setEstadoCivil(location.state.rowData.estado_civil);
    findByIdLegal();
  }, []);

  useEffect(() => {
    if (proposalPhysical) {
      setProposalId(proposalPhysical.id);
      setCodMega(proposalPhysical.cod_mega_proposta_ativa);
      setLandId(proposalPhysical.land_id);

      const findMob = ImobiliariasService.findImobiliaria(
        proposalPhysical.user.agency_id,
      ).then((resp: any) => {
        setMob(resp);
      });
      const findLand = LotesService.findLote(proposalPhysical.land_id).then(
        (resp: any) => {
          setLote(resp.land);
        },
      );
    }
  }, [location, principal, proposalPhysical]);

  const [voltar, setVoltar] = useState(false);

  function voltarTela() {
    history.goBack();
  }

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const [newUserInfo, setNewUserInfo] = useState({
    attachedDocuments: [],
  });

  const [userInfoLegal, setUserInfoLegal] = useState({
    documentos: [],
  });

  const updateUploadedFiles = (
    files: any,
    dropdown = 'documentos pessoais',
    participantID: any,
  ) => {
    let oldPartID = '';
    let docArr = [];
    let docFlag = -1;
    switch (dropdown) {
      case 'documentos pessoais':
        for (let i = 0; i < participantDocumentos.length; i++) {
          if (
            participantDocumentos[i].docs.tag === 'documentos pessoais' &&
            participantDocumentos[i].participant_id === participantID
          ) {
            docFlag = i;
          }
        }
        if (docFlag >= 0) {
          participantDocumentos[docFlag].docs.file = files;
          setParticipantDocumentos(participantDocumentos);
        } else {
          setParticipantDocumentos((oldArray: any) => [
            ...oldArray,
            {
              participant_id: participantID,
              docs: { tag: 'documentos pessoais', file: files },
            },
          ]);
        }
        const existisDocPessoal = contDocumentosPhysical.findIndex(
          (e: { participant_id: any }) => e.participant_id === participantID,
        );
        if (existisDocPessoal >= 0) {
          contDocumentosPhysical[existisDocPessoal].docPessoal = files.length;
          setContDocumentosPhysical(contDocumentosPhysical);
        } else {
          contDocumentosPhysical.push({
            participant_id: participantID,
            docPessoal: files.length,
            docRenda: 0,
            docResidencia: 0,
            docEstadoCivil: 0,
          });
          setContDocumentosPhysical(contDocumentosPhysical);
        }
        break;
      case 'certidao estado civil':
        for (let i = 0; i < participantDocumentos.length; i++) {
          if (
            participantDocumentos[i].docs.tag === 'certidao estado civil' &&
            participantDocumentos[i].participant_id === participantID
          ) {
            docFlag = i;
          }
        }
        if (docFlag >= 0) {
          participantDocumentos[docFlag].docs.file = files;
          setParticipantDocumentos(participantDocumentos);
        } else {
          setParticipantDocumentos((oldArray: any) => [
            ...oldArray,
            {
              participant_id: participantID,
              docs: { tag: 'certidao estado civil', file: files },
            },
          ]);
        }
        const existisDocEstadoCivil = contDocumentosPhysical.findIndex(
          (e: { participant_id: any }) => e.participant_id === participantID,
        );
        if (existisDocEstadoCivil >= 0) {
          contDocumentosPhysical[existisDocEstadoCivil].docEstadoCivil =
            files.length;
          setContDocumentosPhysical(contDocumentosPhysical);
        } else {
          contDocumentosPhysical.push({
            participant_id: participantID,
            docPessoal: 0,
            docRenda: 0,
            docResidencia: 0,
            docEstadoCivil: files.length,
          });
          setContDocumentosPhysical(contDocumentosPhysical);
        }
        break;
      case 'comprovante de residencia':
        for (let i = 0; i < participantDocumentos.length; i++) {
          if (
            participantDocumentos[i].docs.tag === 'comprovante de residencia' &&
            participantDocumentos[i].participant_id === participantID
          ) {
            docFlag = i;
          }
        }
        if (docFlag >= 0) {
          participantDocumentos[docFlag].docs.file = files;
          setParticipantDocumentos(participantDocumentos);
        } else {
          setParticipantDocumentos((oldArray: any) => [
            ...oldArray,
            {
              participant_id: participantID,
              docs: { tag: 'comprovante de residencia', file: files },
            },
          ]);
        }
        const existisDocCompRes = contDocumentosPhysical.findIndex(
          (e: { participant_id: any }) => e.participant_id === participantID,
        );
        if (existisDocCompRes >= 0) {
          contDocumentosPhysical[existisDocCompRes].docResidencia =
            files.length;
          setContDocumentosPhysical(contDocumentosPhysical);
        } else {
          contDocumentosPhysical.push({
            participant_id: participantID,
            docPessoal: 0,
            docRenda: 0,
            docResidencia: 0,
            docEstadoCivil: files.length,
          });
          setContDocumentosPhysical(contDocumentosPhysical);
        }
        break;
      case 'comp_renda':
        for (let i = 0; i < participantDocumentos.length; i++) {
          if (
            participantDocumentos[i].docs.tag === 'comp_renda' &&
            participantDocumentos[i].participant_id === participantID
          ) {
            docFlag = i;
          }
        }
        if (docFlag >= 0) {
          participantDocumentos[docFlag].docs.file = files;
          setParticipantDocumentos(participantDocumentos);
        } else {
          setParticipantDocumentos((oldArray: any) => [
            ...oldArray,
            {
              participant_id: participantID,
              docs: { tag: 'comp_renda', file: files },
            },
          ]);
        }
        const existisDocCompRenda = contDocumentosPhysical.findIndex(
          (e: { participant_id: any }) => e.participant_id === participantID,
        );
        if (existisDocCompRenda >= 0) {
          contDocumentosPhysical[existisDocCompRenda].docRenda = files.length;
          setContDocumentosPhysical(contDocumentosPhysical);
        } else {
          contDocumentosPhysical.push({
            participant_id: participantID,
            docPessoal: 0,
            docRenda: 0,
            docResidencia: 0,
            docEstadoCivil: files.length,
          });
          setContDocumentosPhysical(contDocumentosPhysical);
        }
        break;
      default:
        throw new AppError('Documento inválido');
    }
    setNewUserInfo({ ...newUserInfo, attachedDocuments: files });
  };

  const updateUploadedFilesLegal = (
    files: any,
    dropdown = 'documentos pessoais legal',
  ) => {
    switch (dropdown) {
      case 'documentos pessoais legal':
        setDocumentosPessoaisLegal(files);
        break;
      case 'cnpj':
        setCnpjDoc(files);
        break;
      case 'junta Comercial':
        setJuntaComercial(files);
        break;
      case 'faturamento':
        setFaturamentoDoc(files);
        break;
      case 'balanco':
        setBalanco(files);
        break;
      case 'comprovante de endereco':
        setCompEnderecoLegal(files);
        break;
      case 'contrato social':
        setContratoSocial(files);
        break;
      case 'balancete':
        setBalancete(files);
        break;
      default:
        setBalancete([]);
    }
    setUserInfoLegal({ ...userInfoLegal, documentos: files });
  };

  useEffect(() => {
    const obj = {
      ...location.state.rowData,
      lote,
      mob,
      proposalPhysical,
      conjugePrinicipal,
    };
    setObjeto(obj);
  }, [location, lote, mob, proposalPhysical, conjugePrinicipal]);

  const checkedAnexos = (arr: Array<File>) => {
    if (arr.length >= 1 && arr.length <= 4) {
      return <DoneIcon className="checked" />;
    }
    if (arr.length > 4) {
      return (
        <>
          <ClearIcon className="wrong" />
          <p className="anexos-mensagem">* Anexar 4 arquivos no máximo</p>
        </>
      );
    }
    return (
      <>
        <ClearIcon className="wrong" />
        <p className="anexos-mensagem">* Campo Obrigatório</p>
      </>
    );
  };

  async function analiseBru() {
    const analise = {
      valor_entrada: valorEntrada,
      num_parcelas: numParcelas,
      valor_parcelas: valorParcelas,
      valor_total: valorTotal,
      objetivo: objetivo,
      estado_civil: estadoCivil,
      data_nascimento: dataNascimento,
      num_filhos: numFilhos,
      renda_familiar: rendaFamiliar,
      moradial_atual: moradiaAtual,
      numero_pessoas_morar: numeroPessoasMorar,
      escolaridade: escolaridade,
      clt: clt,
      enterprise_id: empCod,
      cpf: cpf,
    };
    const resultadoAnalise = PropostasService.analiseBru(analise).catch(err => {
      setSnackbarProps({
        message: `Erro ao atualizar os dados do participante: ${err.message.response.status} - ${err.message.response.data.message}`,
        type: 'error',
      });
    });
  }

  async function atualiza() {
    const payloadPrincipal = {
      nome: nome,
      rg: rg,
      orgao_rg: orgaoRg,
      nacionalidade: nacionalidade,
      cep: cep,
      uf: estado,
      cidade: cidade,
      bairro: bairro,
      logradouro: logradouro,
      numero: numero,
      complemento: complemento,
      telefone: telefone,
      email: email,
      num_filhos: numFilhos,
    };
    const result = PropostasService.updateParticipante(
      payloadPrincipal,
      idParticipante,
    )
      .then(resp => {
        // analiseBru();
      })
      .catch(err => {
        setSnackbarProps({
          message: `Erro ao atualizar participantes status: ${err.message.response.status} - ${err.message.response.data.message}`,
          type: 'error',
        });
      });
  }

  useEffect(() => {
    if (proposalPhysical && principal) {
      setSexo(principal.sexo);
      setNome(principal.nome);
      setCpf(principal.cpf);
      setRg(() => {
        if (principal.rg === 'Nada') {
          return '';
        } else return principal.rg;
      });
      setOrgaoRg(() => {
        if (principal.orgao_rg === 'Nada') {
          return '';
        } else return principal.orgao_rg;
      });
      setTelefone(principal.telefone);
      setEmail(principal.email);
      setDataNascimento(
        principal.data_nascimento.split('T')[0].split('-').reverse().join('/'),
      );
      setNacionalidade(() => {
        if (principal.nacionalidade === 'Nada') {
          return '';
        } else return principal.nacionalidade;
      });
      setEstadoCivil(proposalPhysical.estado_civil);
      setObjetivo(proposalPhysical.objetivo);
      setNumFilhos(proposalPhysical.num_filhos);
      setRendaFamiliar(
        proposalPhysical.renda_familiar
          .replace('.', '')
          .replace(/(\d{1})(\d{1,5})$/, '$1.$2'),
      );

      setClt(proposalPhysical.clt);
      setCargo(principal.cargo);
      setEscolaridade(proposalPhysical.escolaridade);
      setMoradiaAtual(proposalPhysical.moradia_atual);
      if (
        principal.cep === '80740000' &&
        principal.complemento === 'Sala 1801'
      ) {
        setEstado('');
        setCidade('');
        setBairro('');
        setLogradouro('');
        setNumero('');
        setComplemento('');
      } else {
        setCep(
          principal.cep
            .replace(/\D/g, '')
            .replace(/^(\d{5})(\d{3})+?$/, '$1-$2'),
        );
        setEstado(principal.uf);
        setCidade(principal.cidade);
        setBairro(principal.bairro);
        setLogradouro(principal.logradouro);
        setNumero(principal.numero);
        setComplemento(principal.complemento);
      }
    }
  }, [proposalPhysical, principal]);

  const fetchData = async () => {
    setIsLoading(true);
    let num_entradas = Number(location.state.rowData.num_entradas);
    let data_parcela = proposalPhysical.data_vencimento_parcela
      .split('T')[0]
      .split('-')
      .reverse()
      .join('/');
    const date = new Date();
    const today = date.getDate();
    const currentMonth = date.getMonth() + 1;
    const nameMonth = date.toLocaleString('pt-BR', { month: 'long' });
    const filtrarCreci = (creci: any) => {
      if (
        creci.includes('E') ||
        creci.includes('J') ||
        creci.includes('e') ||
        creci.includes('j')
      ) {
        return '';
      }
      let resultado = creci.replace(/[\/\.\-]/g, '');
      resultado = resultado.replace(/[A-Za-z]/g, '');
      return resultado;
    };
    const creciFormatado = proposalPhysical.user.creci
      ? filtrarCreci(proposalPhysical.user.creci)
      : '__________';
    const creciFormatadoImob = mob.agency.creci
      ? filtrarCreci(mob.agency.creci)
      : '__________';
    const data = {
      nome_emp:
        nomeEmpreendimento === '...' ? '_____________' : nomeEmpreendimento,
      quadra: lote.quadra === '...' ? '_____________' : lote.quadra,
      lote: lote.lote === '...' ? '_____________' : lote.lote,
      area: lote.area ? `${lote.area} m²` : '_____________',
      nome_completo:
        location.state.rowData === '...'
          ? '____________'
          : location.state.rowData.participantes[0].nome,
      numero_rg: location.state.rowData.participantes[0]
        ? location.state.rowData.participantes[0].rg
        : '____________',
      orgao_expedidor_rg:
        location.state.rowData.participantes[0].orgao_rg === '...'
          ? '____________'
          : location.state.rowData.participantes[0].orgao_rg,
      numero_cpf: location.state.rowData
        ? mascaraCPF(location.state.rowData.participantes[0].cpf)
        : '____________',
      numero_cnh: '____________',
      orgao_expedidor_cnh: '____________',
      nacionalidade:
        location.state.rowData.participantes[0].nacionalidade === '...'
          ? '____________'
          : location.state.rowData.participantes[0].nacionalidade,
      profissao:
        location.state.rowData.participantes[0].cargo === '...'
          ? '____________'
          : location.state.rowData.participantes[0].cargo,
      estado_civil:
        location.state.rowData.participantes[0].estado_civil === '...'
          ? '____________'
          : location.state.rowData.participantes[0].estado_civil,
      escolaridade:
        proposalPhysical.escolaridade === '...'
          ? '____________'
          : proposalPhysical.escolaridade,
      tempo_profissao: '____________',
      email: location.state.rowData
        ? location.state.rowData.participantes[0].email
        : '____________',
      telefone: location.state.rowData
        ? location.state.rowData.participantes[0].telefone
        : '____________',
      data_casamento: '____________',
      regime_bens: '_____________',
      quantidade_filhos: location.state.rowData
        ? location.state.rowData.num_filhos
        : '____________',
      data: '____________',
      livro: '____________',
      folhas: '____________',
      cartorio: '____________',
      nome_completo_conjuge: conjugePrinicipal.nome
        ? conjugePrinicipal.nome
        : '____________',
      rg_conjuge:
        conjugePrinicipal.rg === '...' || '000'
          ? '____________'
          : conjugePrinicipal.rg,
      orgao_expedidor_uf_conjuge:
        conjugePrinicipal.orgao_rg === '...' || '000'
          ? '____________'
          : conjugePrinicipal.orgao_rg,
      cnh_conjuge: '____________',
      detran_uf_conjuge: '____________',
      cpf_conjuge: conjugePrinicipal.cpf
        ? mascaraCPF(conjugePrinicipal.cpf)
        : '____________',
      nacionalidade_conjuge:
        conjugePrinicipal.nacionalidade === '...'
          ? '____________'
          : conjugePrinicipal.nacionalidade,
      profissao_conjuge: conjugePrinicipal.cargo
        ? conjugePrinicipal.cargo
        : '____________',
      estado_civil_conjuge: conjugePrinicipal
        ? location.state.rowData.participantes[0].estado_civil
        : '____________',
      rua_av_conjuge:
        proposalPhysical.proposals_participants[0].logradouro === '...'
          ? '____________'
          : proposalPhysical.proposals_participants[0].logradouro,
      numero_conjuge:
        proposalPhysical.proposals_participants[0].numero === '...'
          ? '____________'
          : proposalPhysical.proposals_participants[0].numero,
      complemento_conjuge:
        proposalPhysical.proposals_participants[0].complemento === '...'
          ? '____________'
          : proposalPhysical.proposals_participants[0].complemento,
      bairro_conjuge:
        proposalPhysical.proposals_participants[0].bairro === '...'
          ? '____________'
          : proposalPhysical.proposals_participants[0].bairro,
      cidade_conjuge:
        location.state.rowData.participantes[0].cidade === '...'
          ? '___________'
          : location.state.rowData.participantes[0].cidade,
      estado_conjuge:
        proposalPhysical.proposals_participants[0].uf === '...'
          ? '____________'
          : proposalPhysical.proposals_participants[0].uf,
      email_conjuge: conjugePrinicipal
        ? conjugePrinicipal.email
        : '____________',
      telefone_conjuge: conjugePrinicipal
        ? conjugePrinicipal.telefone
        : '____________',
      data_uniao_estavel: '____________',
      livro_paragrafo4: '____________',
      folhas_paragrafo4: '____________',
      data_escritura: '____________',
      cartorio_paragrafo4: '____________',
      nome_empresa_paragraf05: '____________',
      cnpj_paragraf05: '____________',
      nome_completo_paragraf05: '____________',
      rg_paragraf05: '____________',
      orgao_expedidor_uf_paragraf05: '____________',
      cpf_paragraf05: '____________',
      nacionalidade_paragraf05: '____________',
      profissao_paragraf05: '____________',
      estado_civil_paragraf05: '____________',
      endereco_paragraf05: '____________',
      nome_completo_paragrafo6: '____________',
      rg_paragrafo6: '____________',
      orgao_expedidor_uf_paragrafo6: '____________',
      cpf_paragrafo6: '____________',
      nacionalidade_paragrafo6: '____________',
      profissao_paragrafo6: '____________',
      estado_civil_paragrafo6: '____________',
      tabeliao_paragrafo6: '____________',
      folhas_paragrafo6: '____________',
      data_procuracao_paragrafo6: '____________',
      valor_imovel_a_vista: location.state.rowData
        ? formatCurrency(location.state.rowData.valor_total)
        : '____________',
      valor_imovel_com_juros: proposalPhysical.valor_reduzidas
        ? formatCurrency(
            location.state.rowData.valor_entrada *
              location.state.rowData.num_entradas +
              location.state.rowData.num_parcelas *
                location.state.rowData.valor_parcelas +
              proposalPhysical.valor_reduzidas * 12,
          )
        : formatCurrency(
            location.state.rowData.valor_entrada *
              location.state.rowData.num_entradas +
              location.state.rowData.num_parcelas *
                location.state.rowData.valor_parcelas,
          ),
      valor_total_sinal: location.state.rowData
        ? formatCurrency(location.state.rowData.valor_entrada)
        : '____________',
      numero_parcelas_sinal: location.state.rowData
        ? location.state.rowData.num_entradas
        : '____________',
      valor_cada_parcela_sinal:
        num_entradas > 1
          ? formatCurrency(location.state.rowData.valor_entrada / num_entradas)
          : formatCurrency(location.state.rowData.valor_entrada),
      numero_total_prestacoes: location.state.rowData
        ? location.state.rowData.num_parcelas
        : '____________',
      doze_reduzidas: proposalPhysical.valor_reduzidas
        ? formatCurrency(proposalPhysical.valor_reduzidas)
        : '_____________',
      vencimento5: data_parcela.split('/')[0] === '05' ? 'x' : '',
      vencimento10: data_parcela.split('/')[0] === '10' ? 'x' : '',
      vencimento15: data_parcela.split('/')[0] === '15' ? 'x' : '',
      vencimento20: data_parcela.split('/')[0] === '20' ? 'x' : '',
      vencimento25: data_parcela.split('/')[0] === '25' ? 'x' : '',
      valor_da_parcela: location.state.rowData
        ? formatCurrency(location.state.rowData.valor_parcelas)
        : '____________',
      valor_balao:
        proposalPhysical.valor_balao === '0'
          ? 'Não tem balão'
          : proposalPhysical.valor_balao,
      data_balao:
        proposalPhysical.data_vencimento_balao
          .split('T')[0]
          .split('-')
          .reverse()
          .join('/') === '01/01/1970'
          ? 'Não tem balão'
          : proposalPhysical.data_vencimento_balao
              .split('T')[0]
              .split('-')
              .reverse()
              .join('/'),
      moradia_atual: proposalPhysical
        ? proposalPhysical.moradia_atual
        : '___________',
      intencao_morar:
        location.state.rowData.objetivo === 'Morar'
          ? location.state.rowData.objetivo
          : '____________',
      intencao_investir:
        location.state.rowData.objetivo === 'Vender' || 'Alugar'
          ? location.state.rowData.objetivo
          : '____________',
      nome_imobiliaria: mob ? mob.agency.nome : '____________',
      cnpj_imobiliaria: mob ? mascaraCNPJ(mob.agency.cnpj) : '____________',
      nome_creci_imobiliaria: 'JURÍDICO ',
      regiao_creci_imobiliaria: mob ? mob.agency.uf : '____________',
      numero_creci_imobiliaria: mob ? creciFormatadoImob : '____________',
      nome_corretor: proposalPhysical
        ? proposalPhysical.user.name
        : '____________',
      nome_creci_corretor: 'FÍSICO ',
      regiao_creci_corretor: '____________',
      numero_creci_corretor:
        proposalPhysical.user.creci === '' ? '_____________' : creciFormatado,
      porcentagem: '____________',
      cidade_assinatura: '____________',
      dia_assinatura: today,
      mes_assinatura: nameMonth,
      nome_imobiliaria_assinatura: mob ? mob.agency.nome : '____________',
      nome_corretor_assinatura: proposalPhysical
        ? proposalPhysical.user.name
        : '____________',
    };
    try {
      const response = await fetch(
        'https://api-servicos-automacao.bairru.com.br/appCorretores/GetContratoCPS',
        {
          method: 'POST',
          headers: {
            token: 'w5wac68Ho79ML577qRFDxjCiqaH6409d7b7cc31f',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        },
      );
      const result = await response.json();
      setURLContrato(result.link_documento);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    if (proposalPhysical) {
      setDevolutionDocuments(proposalPhysical.documentos);
    }
  }, [proposalPhysical]);

  useEffect(() => {
    devolutionDocuments.map((doc: any) => {
      if (doc.tag === 'devolution') {
        setHasDevolution(true);
      }
      if (doc.tag === 'devolution_contrato' && !doc.deletado) {
        setHasDevolutionContrato(true);
      }
      if (
        (doc.tag === 'devolution_boleto' ||
          doc.tag.includes('devolution_payment_platform')) &&
        !doc.deletado
      ) {
        setHasDevolutionBoleto(true);
      }
      return;
    });
  }, [devolutionDocuments]);

  useEffect(() => {
    if (URLContrato) {
      let body = {
        paths: [`${URLContrato}document/-/format/pdf/`],
        store: 1,
      };
      try {
        const ContratoEmDocx = async () => {
          const result = await fetch(
            'https://api.uploadcare.com/convert/document/',
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                Authorization:
                  'Uploadcare.Simple 5704632aeb0a1ba085ef:addc61e3d8294b2e44bb',
                Accept: 'application/vnd.uploadcare-v0.7+json',
              },
              body: JSON.stringify(body),
            },
          );
          const resposta = await result.json();
          let pdfLink = `https://ucarecdn.com/${resposta.result[0].uuid}/`;
          if (pdfLink) {
            let resp;
            setTimeout(async () => {
              do {
                const data = await fetch(
                  `https://api.uploadcare.com/convert/document/status/${resposta.result[0].token}/`,
                  {
                    method: 'GET',
                    headers: {
                      'Content-Type': 'application/json',
                      Authorization:
                        'Uploadcare.Simple 5704632aeb0a1ba085ef:addc61e3d8294b2e44bb',
                      Accept: 'application/vnd.uploadcare-v0.7+json',
                    },
                  },
                );
                resp = await data.json();
                if (resp.status === 'finished') {
                  window.open(pdfLink);
                  setIsLoading(false);
                  setModalWaitCP(false);
                  break;
                }
              } while (resp.status !== 'finished');
            }, 15000);
          }
        };
        ContratoEmDocx();
      } catch (err) {
        console.log(err);
      }
    } else {
      return;
    }
  }, [URLContrato]);

  useEffect(() => {
    if (lote.empreendimento) {
      setNomeEmpreendimento(lote.empreendimento);
    } else if (proposalPhysical) {
      setNomeEmpreendimento(proposalPhysical.empreendimento);
    } else {
      return;
    }
  }, [lote, proposalPhysical]);
  return voltar ? (
    <></>
  ) : (
    <>
      <HeaderProposta />
      <Content>
        <DetalhesContainer>
          <DetalhesHeader>
            <div className="titulos">
              <div className="top-header">
                <h1>Envio da proposta completa</h1>
              </div>
              <div className="top-header" style={{ marginTop: 10 }}>
                <h2>
                  Status: <span>{proposalStatus}</span>
                </h2>
                <h2 style={{ marginLeft: 0 }}>
                  <CheckCircleOutlineIcon style={{ fill: 'lightgreen' }} />
                  Realizada em{' '}
                  {location.state
                    ? formataData(location.state.rowData.criacao)
                    : ''}
                </h2>
                <h2>
                  <UpdateIcon style={{ fill: 'yellow' }} />
                  Última atualização em{' '}
                  {location.state
                    ? formataData(location.state.rowData.atualizacao)
                    : ''}
                </h2>
              </div>
            </div>
            <Botao
              tipo="editar"
              tamanho="grande"
              className="btn"
              onClick={voltarTela}
            >
              Voltar
            </Botao>
          </DetalhesHeader>
          <DetalhesContent>
            <InputsContent>
              <div className="info-dados-app">
                <h2>Dados enviados na proposta pelo app</h2>
                <p>Empreendimento: {nomeEmpreendimento}</p>
                <p>Lote: {lote.lote}</p>
                <p>Quadra: {lote.quadra}</p>
                <p>Proposta: {codMega}</p>
              </div>
            </InputsContent>
            {comentario !== '' &&
              comentario &&
              (proposalStatus === 'Proposta Inconsistente' ||
                proposalStatus === 'Documentacao insuficiente') && (
                <InputsContent
                  style={{
                    backgroundColor: cores.yellow,
                    width: '50%',
                    borderRadius: 10,
                    padding: 10,
                    border: '5px solid black',
                  }}
                >
                  <div className="info-dados-app">
                    <h2>Atenção!</h2>
                    <p>
                      Sua proposta está com o status:{' '}
                      {proposalStatus.toLocaleUpperCase()}
                    </p>
                    <p> Motivo: {comentario.toLocaleUpperCase()}</p>
                  </div>
                </InputsContent>
              )}

            {hasDevolutionContrato && (
              <DocumentDevolutionContrato filesUrl={devolutionDocuments} />
            )}

            {hasDevolutionBoleto && (
              <DocumentDevolutionBoleto filesUrl={devolutionDocuments} />
            )}

            {hasDevolution && (
              <DocumentDevolution filesUrl={devolutionDocuments} />
            )}
            {location.state.rowData.tipo === 'physical' ? (
              <InputsContent>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    width: '100%',
                  }}
                >
                  <h2
                    style={{
                      marginLeft: 0,
                      fontSize: 22,
                      marginBottom: 10,
                    }}
                  >
                    {'Dados Pessoais'}
                  </h2>
                </div>
                <InputsContainer>
                  <InputField
                    tipo="formulario"
                    className="inputs"
                    label="Nome"
                    value={nome}
                    width={140}
                    onChange={(e: any) => {
                      setNome(e.target.value);
                    }}
                  />
                </InputsContainer>
                <InputsContainer>
                  <InputField
                    tipo="formulario"
                    className="inputs"
                    label="CPF"
                    value={cpf
                      .replace(/\D/g, '')
                      .replace(/(\d{3})(\d)/, '$1.$2')
                      .replace(/(\d{3})(\d)/, '$1.$2')
                      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
                      .replace(/(-\d{2})\d+?$/, '$1')}
                    width={200}
                    onChange={(e: any) => {
                      setCpf(e.target.value);
                    }}
                    disabled
                  />
                </InputsContainer>
                <InputsContainer>
                  <InputField
                    tipo="formulario"
                    className="inputs"
                    label="RG"
                    value={rg}
                    width={140}
                    onChange={(e: any) => {
                      setRg(e.target.value);
                    }}
                  />
                </InputsContainer>
                <InputsContainer>
                  <InputField
                    tipo="formulario"
                    className="inputs"
                    label="Órgão Emissor"
                    value={orgaoRg}
                    width={140}
                    onChange={(e: any) => {
                      setOrgaoRg(e.target.value);
                    }}
                  />
                </InputsContainer>
                <InputsContainer>
                  <InputField
                    tipo="formulario"
                    className="inputs"
                    label="Telefone"
                    value={telefone}
                    width={140}
                    onChange={(e: any) => {
                      setTelefone(e.target.value);
                    }}
                  />
                </InputsContainer>
                <InputsContainer>
                  <InputField
                    tipo="formulario"
                    className="inputs"
                    label="Email"
                    value={email}
                    width={200}
                    onChange={(e: any) => {
                      setEmail(e.target.value);
                    }}
                  />
                </InputsContainer>
                <InputsContainer>
                  <InputField
                    tipo="formulario"
                    className="inputs"
                    label="Data Nascimento"
                    value={dataNascimento}
                    width={150}
                    onChange={(e: any) => {
                      setDataNascimento(e.target.value);
                    }}
                    disabled
                  />
                </InputsContainer>
                <InputsContainer>
                  <InputField
                    tipo="formulario"
                    className="inputs"
                    label="Nacionalidade"
                    value={nacionalidade}
                    width={190}
                    onChange={(e: any) => {
                      setNacionalidade(e.target.value);
                    }}
                  />
                </InputsContainer>
                <InputsContainer>
                  <InputField
                    tipo="formulario"
                    className="inputs"
                    label="Estado Cívil"
                    value={estadoCivil}
                    width={160}
                    onChange={(e: any) => {
                      setEstadoCivil(e.target.value);
                    }}
                    disabled
                  />
                </InputsContainer>
                <InputsContainer>
                  <InputField
                    tipo="formulario"
                    className="inputs"
                    label="Sexo"
                    value={sexo}
                    width={160}
                    onChange={(e: any) => {
                      setSexo(e.target.value);
                    }}
                  />
                </InputsContainer>
                <InputsContainer>
                  <InputField
                    tipo="formulario"
                    className="inputs"
                    label="Objetivo"
                    value={objetivo}
                    width={160}
                    onChange={(e: any) => {
                      setObjetivo(e.target.value);
                    }}
                    disabled
                  />
                </InputsContainer>
                <InputsContainer>
                  <InputField
                    tipo="formulario"
                    className="inputs"
                    label="Número de Filhos"
                    value={numFilhos}
                    width={160}
                    onChange={(e: any) => {
                      setNumFilhos(e.target.value);
                    }}
                    disabled
                  />
                </InputsContainer>
                <InputsContainer>
                  <InputField
                    tipo="formulario"
                    className="inputs"
                    label="Renda Familiar"
                    value={`R$ ${rendaFamiliar}`}
                    width={160}
                    onChange={(e: any) => {
                      setRendaFamiliar(e.target.value);
                    }}
                    disabled
                  />
                </InputsContainer>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    width: '100%',
                  }}
                >
                  <h2
                    style={{
                      marginLeft: 0,
                      fontSize: 22,
                      marginBottom: 10,
                    }}
                  >
                    {'Endereço'}
                  </h2>
                </div>
                <InputsContainer>
                  <InputField
                    tipo="formulario"
                    className="inputs"
                    label="CEP"
                    value={cep}
                    width={210}
                    onChange={(e: any) => {
                      setCep(e.target.value);
                    }}
                  />
                </InputsContainer>
                <InputsContainer>
                  <InputField
                    tipo="formulario"
                    className="inputs"
                    label="Estado"
                    value={estado}
                    width={180}
                    onChange={(e: any) => {
                      setEstado(e.target.value);
                    }}
                  />
                </InputsContainer>
                <InputsContainer>
                  <InputField
                    tipo="formulario"
                    className="inputs"
                    label="Cidade"
                    value={cidade}
                    width={140}
                    onChange={(e: any) => {
                      setCidade(e.target.value);
                    }}
                  />
                </InputsContainer>
                <InputsContainer>
                  <InputField
                    tipo="formulario"
                    className="inputs"
                    label="Bairro"
                    value={bairro}
                    width={140}
                    onChange={(e: any) => {
                      setBairro(e.target.value);
                    }}
                  />
                </InputsContainer>
                <InputsContainer>
                  <InputField
                    tipo="formulario"
                    className="inputs"
                    label="Logradouro"
                    value={logradouro}
                    width={200}
                    onChange={(e: any) => {
                      setLogradouro(e.target.value);
                    }}
                  />
                </InputsContainer>
                <InputsContainer>
                  <InputField
                    tipo="formulario"
                    className="inputs"
                    label="Número"
                    value={numero}
                    width={100}
                    onChange={(e: any) => {
                      setNumero(e.target.value);
                    }}
                  />
                </InputsContainer>
                <InputsContainer>
                  <InputField
                    tipo="formulario"
                    className="inputs"
                    label="Complemento"
                    value={complemento}
                    width={160}
                    onChange={(e: any) => {
                      setComplemento(e.target.value);
                    }}
                  />
                </InputsContainer>
                <InputsContainer>
                  <InputField
                    tipo="formulario"
                    className="inputs"
                    label="Moradia Atual"
                    value={moradiaAtual}
                    width={200}
                    onChange={(e: any) => {
                      setMoradiaAtual(e.target.value);
                    }}
                    disabled
                  />
                </InputsContainer>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    width: '100%',
                  }}
                >
                  <h2
                    style={{
                      marginLeft: 0,
                      fontSize: 22,
                      marginBottom: 10,
                    }}
                  >
                    {'Escolaridade e Trabalho'}
                  </h2>
                </div>
                <InputsContainer>
                  <InputField
                    tipo="formulario"
                    className="inputs"
                    label="CLT"
                    value={clt}
                    width={180}
                    onChange={(e: any) => {
                      setClt(e.target.value);
                    }}
                    disabled
                  />
                </InputsContainer>
                <InputsContainer>
                  <InputField
                    tipo="formulario"
                    className="inputs"
                    label="Cargo"
                    value={cargo}
                    width={160}
                    onChange={(e: any) => {
                      setCargo(e.target.value);
                    }}
                  />
                </InputsContainer>
                <InputsContainer>
                  <InputField
                    tipo="formulario"
                    className="inputs"
                    label="Escolaridade"
                    value={escolaridade}
                    width={160}
                    onChange={(e: any) => {
                      setEscolaridade(e.target.value);
                    }}
                    disabled
                  />
                </InputsContainer>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    width: '100%',
                  }}
                >
                  <h2
                    style={{
                      marginLeft: 0,
                      fontSize: 22,
                      marginBottom: 10,
                    }}
                  >
                    {'Financiamento'}
                  </h2>
                </div>
                <InputsContainer>
                  <InputField
                    tipo="formulario"
                    className="inputs"
                    label="Número de Entradas"
                    value={
                      proposalPhysical ? proposalPhysical.num_entradas : ''
                    }
                    width={160}
                    disabled
                  />
                </InputsContainer>
                <InputsContainer>
                  <InputField
                    tipo="formulario"
                    className="inputs"
                    label="Valor Entradas"
                    value={
                      proposalPhysical
                        ? `R$ ${proposalPhysical.valor_entrada.replace(
                            /(\d{1})(\d{1,3})$/,
                            '$1.$2,00',
                          )}`
                        : ''
                    }
                    width={160}
                    disabled
                  />
                </InputsContainer>
                <InputsContainer>
                  <InputField
                    tipo="formulario"
                    className="inputs"
                    label="Valor Parcelas"
                    value={
                      proposalPhysical
                        ? formatCurrency(proposalPhysical.valor_parcelas)
                        : ''
                    }
                    width={220}
                    disabled
                  />
                </InputsContainer>
                <InputsContainer>
                  <InputField
                    tipo="formulario"
                    className="inputs"
                    label="Valor Total"
                    value={
                      proposalPhysical
                        ? `R$ ${proposalPhysical.valor_total.replace(
                            /(\d{1})(\d{1,3})$/,
                            '$1.$2,00',
                          )}`
                        : ''
                    }
                    width={160}
                    disabled
                  />
                </InputsContainer>
                <InputsContainer>
                  <InputField
                    tipo="formulario"
                    className="inputs"
                    label="Número de Parcelas"
                    value={
                      proposalPhysical ? proposalPhysical.num_parcelas : ''
                    }
                    width={160}
                    disabled
                  />
                </InputsContainer>
                <InputsContainer>
                  <InputField
                    tipo="formulario"
                    className="inputs"
                    label="ID do terreno"
                    value={proposalPhysical ? proposalPhysical.land_id : ''}
                    width={160}
                    disabled
                  />
                </InputsContainer>
                <InputsContainer>
                  <InputField
                    tipo="formulario"
                    className="inputs"
                    label="Número de Pessoas Morar"
                    value={
                      proposalPhysical
                        ? proposalPhysical.numero_pessoas_morar
                        : ''
                    }
                    width={200}
                    disabled
                  />
                </InputsContainer>
                <InputsContainer>
                  <InputField
                    tipo="formulario"
                    className="inputs"
                    label="Data Venc. Sinal"
                    value={
                      proposalPhysical
                        ? proposalPhysical.data_vencimento_sinal
                            .split('T')[0]
                            .split('-')
                            .reverse()
                            .join('/')
                        : ''
                    }
                    width={200}
                    disabled
                  />
                </InputsContainer>
                <InputsContainer>
                  <InputField
                    tipo="formulario"
                    className="inputs"
                    label="Data Venc. Parcela"
                    value={
                      proposalPhysical
                        ? proposalPhysical.data_vencimento_parcela
                            .split('T')[0]
                            .split('-')
                            .reverse()
                            .join('/')
                        : ''
                    }
                    width={200}
                    disabled
                  />
                </InputsContainer>

                {proposalPhysical ? (
                  <>
                    {proposalPhysical.data_vencimento_reduzidas
                      .split('T')[0]
                      .split('-')
                      .reverse()
                      .join('/') !== '01/01/1970' && (
                      <>
                        <InputsContainer>
                          <InputField
                            tipo="formulario"
                            className="inputs"
                            label="Data Venc. Reduzidas"
                            value={
                              proposalPhysical
                                ? proposalPhysical.data_vencimento_reduzidas
                                    .split('T')[0]
                                    .split('-')
                                    .reverse()
                                    .join('/')
                                : ''
                            }
                            width={200}
                            disabled
                          />
                        </InputsContainer>
                        <InputsContainer>
                          <InputField
                            tipo="formulario"
                            className="inputs"
                            label="Valor Reduzidas"
                            value={
                              proposalPhysical
                                ? proposalPhysical.valor_reduzidas
                                : ''
                            }
                            width={160}
                            disabled
                          />
                        </InputsContainer>
                      </>
                    )}
                  </>
                ) : (
                  <></>
                )}

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    width: '100%',
                  }}
                >
                  <h2
                    style={{
                      marginLeft: 0,
                      fontSize: 22,
                      marginBottom: 10,
                    }}
                  >
                    <Botao
                      tipo="adicionar"
                      tamanho="medio"
                      onClick={() => {
                        setShowUpdateModal(true);
                      }}
                    >
                      Atualizar Dados
                    </Botao>
                    <Botao
                      tipo="salvar"
                      tamanho="grande"
                      isLoading={isLoading}
                      onClick={() => {
                        setShowModalCP(true);
                      }}
                    >
                      Gerar CP
                    </Botao>
                  </h2>
                </div>
              </InputsContent>
            ) : (
              <InputsContent>
                <InputsContainer>
                  <InputField
                    tipo="formulario"
                    className="inputs"
                    label="Nome Fantasia"
                    value={
                      location.state.rowData
                        ? location.state.rowData.nomeFantasia
                        : ''
                    }
                    width={140}
                    disabled
                  />
                </InputsContainer>
                <InputsContainer>
                  <InputField
                    tipo="formulario"
                    className="inputs"
                    label="Balanço"
                    value={
                      location.state.rowData
                        ? location.state.rowData.balanco
                        : ''
                    }
                    width={140}
                    disabled
                  />
                </InputsContainer>
                <InputsContainer>
                  <InputField
                    tipo="formulario"
                    className="inputs"
                    label="Cnpj"
                    value={
                      location.state.rowData ? location.state.rowData.cnpj : ''
                    }
                    disabled
                    width={200}
                  />
                </InputsContainer>

                <InputsContainer>
                  <InputField
                    tipo="formulario"
                    className="inputs"
                    label="Contrato Social"
                    value={
                      location.state.rowData
                        ? location.state.rowData.contratoSocial
                        : ''
                    }
                    width={150}
                    disabled
                  />
                </InputsContainer>

                <InputsContainer>
                  <InputField
                    tipo="formulario"
                    className="inputs"
                    label="Nome"
                    value={
                      location.state.rowData ? location.state.rowData.nome : ''
                    }
                    width={190}
                    disabled
                  />
                </InputsContainer>

                <InputsContainer>
                  <InputField
                    tipo="formulario"
                    className="inputs"
                    label="Faturamento"
                    value={
                      location.state.rowData
                        ? location.state.rowData.faturamento
                        : ''
                    }
                    width={210}
                    disabled
                  />
                </InputsContainer>
                <InputsContainer>
                  <InputField
                    tipo="formulario"
                    className="inputs"
                    label="e-mail"
                    value={
                      location.state.rowData ? location.state.rowData.Email : ''
                    }
                    width={180}
                    disabled
                  />
                </InputsContainer>
                <InputsContainer>
                  <InputField
                    tipo="formulario"
                    className="inputs"
                    label="Valor do lote"
                    value={
                      location.state.rowData
                        ? location.state.rowData.valorTotalLegal
                        : ''
                    }
                    width={140}
                    disabled
                  />
                </InputsContainer>
                <InputsContainer>
                  <InputField
                    tipo="formulario"
                    className="inputs"
                    label="Valor Parcela"
                    value={
                      location.state.rowData
                        ? location.state.rowData.valorParcelas
                        : ''
                    }
                    width={140}
                    disabled
                  />
                </InputsContainer>
                <InputsContainer>
                  <InputField
                    tipo="formulario"
                    className="inputs"
                    label="Valor entrada"
                    value={
                      location.state.rowData
                        ? location.state.rowData.valorEntrada
                        : ''
                    }
                    width={140}
                    disabled
                  />
                </InputsContainer>
                <InputsContainer>
                  <InputField
                    tipo="formulario"
                    className="inputs"
                    label="Objetivo"
                    value={
                      location.state.rowData
                        ? location.state.rowData.objetivo
                        : ''
                    }
                    width={160}
                    disabled
                  />
                </InputsContainer>
                <InputsContainer>
                  <InputField
                    tipo="formulario"
                    className="inputs"
                    label="Telefone"
                    value={
                      location.state.rowData
                        ? location.state.rowData.telefone
                        : ''
                    }
                    width={160}
                    disabled
                  />
                </InputsContainer>
              </InputsContent>
            )}
            {tipoPessoa === 'physical' ? (
              participantes.map((participante: any) => {
                const aux: any = [];
                let principal = true;
                let docPessoal = 0;
                let docEstadoCivil = 0;
                let docCompRenda = 0;
                let docCompRes = 0;
                const files = location.state.rowData.documentosFisica.map(
                  (doc: any) => {
                    let documentSelectName = doc.document;
                    const isPaymentPlatformDevolution = doc.tag.includes(
                      'devolution_payment_platform',
                    );
                    if (isPaymentPlatformDevolution) {
                      const numeroBoleto = doc.tag[doc.tag.length - 1];
                      documentSelectName = `boleto_comissao_${numeroBoleto}`;
                    } else {
                      documentSelectName = doc.document
                        .split('/')[4]
                        .split('-');
                      documentSelectName = documentSelectName.splice(1);
                    }
                    const docName = isPaymentPlatformDevolution
                      ? documentSelectName
                      : documentSelectName.join('_');
                    const tag = isPaymentPlatformDevolution
                      ? 'devolution'
                      : doc.tag;
                    if (
                      doc.tag === 'personal' ||
                      doc.tag === 'signed_cp' ||
                      doc.tag === 'signed-cp'
                    ) {
                      docPessoal += 1;
                    }
                    if (doc.tag === 'estado_civil') {
                      docEstadoCivil += 1;
                    }
                    if (doc.tag === 'comp_renda') {
                      docCompRenda += 1;
                    }
                    if (doc.tag === 'comp_residencial') {
                      docCompRes += 1;
                    }
                    return {
                      id: doc.id,
                      url: doc.document,
                      name: docName,
                      tag:
                        doc.tag === 'signed_cp' || doc.tag === 'signed-cp'
                          ? 'personal'
                          : tag,
                    };
                  },
                );
                return (
                  <>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        width: '100%',
                      }}
                    >
                      <h2
                        style={{
                          marginLeft: 0,
                          fontSize: 22,
                          marginBottom: 10,
                        }}
                      >
                        {participante.nome}{' '}
                      </h2>
                    </div>

                    <PessoaFisica
                      estadoCivil={participante.estado_civil}
                      cpf={participante.cpf}
                      participant_id={participante.id}
                      updateUploadedFiles={updateUploadedFiles}
                      principal={principal}
                      childToParent={childToParent}
                      files={{
                        comprovanteResidencia: docCompRes,
                        comp_renda: docCompRenda,
                        certidaoEstadoCivil: docEstadoCivil,
                        docPessoal: docPessoal,
                        files: files,
                      }}
                    />
                    <div style={{ margin: 10 }} />
                  </>
                );
              })
            ) : (
              <PessoaJuridica
                updateUploadedFilesLegal={updateUploadedFilesLegal}
                files={{
                  documentosPessoaisLegal,
                  compEnderecoLegal,
                  cnpjDoc,
                  juntaComercial,
                  faturamentoDoc,
                  balanco,
                  balancete,
                  contratoSocial,
                }}
              />
            )}

            <InputsContent className="descricao-container-inputs">
              <InputsContainer className="descricao-container">
                <h2>Observações</h2>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  multiline
                  maxRows="8"
                  name="answer"
                  type="text"
                  value={observacao}
                  onChange={e => {
                    setObservacao(e.target.value);
                  }}
                />
              </InputsContainer>
            </InputsContent>

            <BotoesContainer>
              <Botao
                tipo="salvar"
                tamanho="grande"
                className="btn-salvar"
                onClick={salvarProposta}
                isLoading={isLoading}
              >
                Salvar Proposta
              </Botao>

              <Botao
                tipo="deletar"
                tamanho="grande"
                className="btn-editar"
                onClick={() => {
                  setChoiseModal(true);
                }}
                isLoading={isLoading}
              >
                Cancelar Proposta
              </Botao>
            </BotoesContainer>
          </DetalhesContent>
        </DetalhesContainer>
      </Content>
      <Snackbars
        type={snackbarProps.type}
        handleClose={handleSnackbarClose}
        open={snackbarOpen}
        loading={isLoading}
      >
        {snackbarProps.message}
      </Snackbars>
      <ModalCP
        isModalOpen={showModalCP}
        onCloseModal={() => setShowModalCP(false)}
        onUpDateClick={() => {
          setModalWaitCP(true);
          fetchData();
        }}
      />
      <ModalWaitCP
        isModalOpen={modalWaitCP}
        isLoading={isLoading}
        onCloseModal={() => {
          setModalWaitCP(false);
        }}
      />
      <SendModal
        isModalOpen={showSendModal}
        onCloseModal={() => setShowSendModal(false)}
        onUpDateClick={() => {
          sendAnalise();
        }}
      />
      <CancelChoiceModal
        isModalOpen={choiseModal}
        onCloseModal={() => setChoiseModal(false)}
        onWaiverClick={() => {
          setChoiseModal(false);
        }}
        onInCancelClick={() => {
          changeSatus('Cancelada pelo corretor', codMega);
          setChoiseModal(false);
        }}
      />

      <UpdateModal
        isModalOpen={showUpdateModal}
        onCloseModal={() => setShowUpdateModal(false)}
        onUpDateClick={() => {
          atualiza();
        }}
      />
    </>
  );
};

export default EditarProposta;
