import React, { useEffect, useRef, useState } from 'react';
import MaterialTable from 'material-table';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import { TextField, TextFieldProps } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { Autocomplete } from '@material-ui/lab';
import Tabela from '../../components/Tabelas/Tabela';
import {
  BotaoContainer,
  ModalContainer,
  OpcoesContainer,
  TabelaContainer,
  ModalContainerDelete,
  Content,
} from './styles';
import Botao from '../../components/Botao/Botao';
import Modal from '../../components/Modal/Modal';
import SelectField from '../../components/SelectField/SelectField';
import DeleteModal from '../../components/DeleteModal';
import Snackbars from '../../components/Snackbars';

const Premios = () => {
  const [detalhes, setDetalhes] = useState(false);
  const [userData, setUserData] = useState({});
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [snackbarProps, setSnackbarProps] = useState({
    message: '',
    type: 'success',
  });
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const history = useHistory();

  const columns = [
    {
      title: 'Empreendimento',
      field: 'empreendimento',
    },
    {
      title: 'Posição',
      field: 'posicao',
    },
    {
      title: 'Nome',
      field: 'nome',
    },
    {
      title: 'Descrição',
      field: 'descricao',
    },
  ];

  const data = [
    {
      posicao: 1,
      nome: '',
      descricao: ``,
      empreendimento: '',
    },
  ];

  const [faqData, setFaqData] = useState<any[]>();
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);

  const perguntaInitialValue = '';
  const respostaInitialValue = '';

  const [pergunta, setPergunta] = useState(perguntaInitialValue);
  const [resposta, setResposta] = useState(respostaInitialValue);

  const rowSelected = (e: any, rowData: any) => {
    if (rowData) {
      setPergunta(rowData.pergunta);
      setResposta(rowData.resposta);
      setEditModalOpen(true);
    }
  };

  function abreModal1() {
    setCreateModalOpen(true);
  }

  useEffect(() => {
    setCreateModalOpen(!!selectedRow);
  }, [selectedRow]);

  useEffect(() => {
    if (selectedRow && !createModalOpen) {
      setSelectedRow(null);
    }
  }, [createModalOpen]);

  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);

  const handleClick = () => {
    setDeleteModalOpen(false);
    setOpen(true);
  };

  const handleSnackbarClose = (
    event?: React.SyntheticEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleClose2 = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen2(false);
  };

  function Alert(props: AlertProps) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const [empreendimento, setEmpreendimento] = useState({
    title: '',
    value: '',
  });

  function setEmpreendimentoValue(e: any) {
    setEmpreendimento({
      title: e.target.outerText,
      value: e.target.outerText.toLowerCase(),
    });
  }

  const empreendimentoOptions = [
    { title: 'Empreendimento 01', value: 'empreendimento 01' },
    { title: 'Empreendimento 02', value: 'empreendimento 02' },
    { title: 'Empreendimento 03', value: 'empreendimento 03' },
    { title: 'Empreendimento 04', value: 'empreendimento 04' },
    { title: 'Empreendimento 05', value: 'empreendimento 05' },
    { title: 'Empreendimento 06', value: 'empreendimento 06' },
    { title: 'Empreendimento 07', value: 'empreendimento 07' },
    { title: 'Empreendimento 08', value: 'empreendimento 08' },
    { title: 'Empreendimento 09', value: 'empreendimento 09' },
    { title: 'Empreendimento 10', value: 'empreendimento 10' },
  ];

  function deletarRegistro(deleteData: any) {
    setDeleteModalOpen(false);
    setSnackbarProps({
      message: 'Prêmio deletado com sucesso',
      type: 'success',
    });
    setSnackbarOpen(true);
    
  }

  function saveChanges(dataToSave: any) {
    
    // array.push
    // requisição na api

    setSnackbarProps({
      message: 'Prêmio salvo com sucesso',
      type: 'success',
    });
    setSnackbarOpen(true);
  }

  const EditAndCreateModal: any = () => {
    const initialValues = selectedRow
      ? {
          title: selectedRow!.title || '',
          descricao: selectedRow!.descricao || '',
        }
      : {
          title: '',
          descricao: '',
        };

    // function handleChange(values) {
    //   setPergunta(values);
    // }

    if (createModalOpen) {
      return (
        <Modal
          isOpen={createModalOpen}
          width={500}
          onClose={() => setCreateModalOpen(false)}
        >
          <>
            <h1>Novo Prêmio</h1>
            <Formik
              onSubmit={data3 => console.log('')
            }
              initialValues={initialValues}
            >
              {({ values, handleChange, handleSubmit, setFieldValue }) => (
                <>
                  <ModalContainer className="input-control-content">
                    <div className="input-control-2">
                      <p>Empreendimento</p>
                      <Autocomplete
                        id="combo-box-demo"
                        options={empreendimentoOptions}
                        getOptionLabel={(option: { title: any }) =>
                          option.title
                        }
                        fullWidth
                        // style={{ width: 200 }}
                        value={empreendimento}
                        loading
                        loadingText="carregando"
                        onChange={setEmpreendimentoValue}
                        disableClearable
                        renderInput={(
                          params: JSX.IntrinsicAttributes & TextFieldProps,
                        ) => (
                          <TextField
                            // label="Status"
                            variant="outlined"
                            fullWidth
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...params}
                            size="small"
                            error={false}
                            helperText=""
                          />
                        )}
                      />
                    </div>
                  </ModalContainer>
                  <ModalContainer>
                    <div className="input-control">
                      <p>Nome</p>
                      <TextField
                        variant="outlined"
                        required
                        style={{ width: 320 }}
                        name="quadra"
                        type="text"
                        id="password"
                        size="small"
                        autoComplete="current-password"
                      />
                    </div>

                    <div className="input-control-2">
                      <p>Posição</p>
                      <Autocomplete
                        id="combo-box-demo"
                        options={empreendimentoOptions}
                        getOptionLabel={(option: { title: any }) =>
                          option.title
                        }
                        fullWidth
                        // style={{ width: 200 }}
                        value={empreendimento}
                        loading
                        loadingText="carregando"
                        onChange={setEmpreendimentoValue}
                        disableClearable
                        renderInput={(
                          params: JSX.IntrinsicAttributes & TextFieldProps,
                        ) => (
                          <TextField
                            // label="Status"
                            variant="outlined"
                            fullWidth
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...params}
                            size="small"
                            error={false}
                            helperText=""
                          />
                        )}
                      />
                    </div>
                  </ModalContainer>

                  <ModalContainer>
                    <div className="input-control" style={{ marginRight: 0 }}>
                      <p>Descrição</p>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        style={{ width: '100%' }}
                        multiline
                        maxRows="8"
                        name="descricao"
                        type="text"
                        id="password"
                        autoComplete="current-password"
                        value={values.descricao}
                        onChange={handleChange('descricao')}
                      />
                    </div>
                  </ModalContainer>

                  <div className="btn-modalfull">
                    <Botao
                      tipo="salvar"
                      tamanho="fullwidth"
                      onClick={() => {
                        
                        setCreateModalOpen(false);
                        saveChanges(userData);
                        // setOpen2(true);
                        // setCreateModalOpen(false);
                      }}
                    >
                      Salvar
                    </Botao>
                  </div>
                </>
              )}
            </Formik>
          </>
        </Modal>
      );
    }
    // -------------------------- EDITAR
    return (
      <Modal
        isOpen={editModalOpen}
        width={500}
        onClose={() => setEditModalOpen(false)}
      >
        <>
          <h1>Editar prêmio</h1>
          <Formik
            onSubmit={data2 => console.log('')
          }
            initialValues={initialValues}
          >
            {({ values, handleChange, handleSubmit, setFieldValue }) => (
              <>
                <ModalContainer className="input-control-content">
                  <div className="input-control-2">
                    <p>Empreendimento</p>
                    <Autocomplete
                      id="combo-box-demo"
                      options={empreendimentoOptions}
                      getOptionLabel={(option: { title: any }) => option.title}
                      fullWidth
                      // style={{ width: 200 }}
                      value={empreendimento}
                      loading
                      loadingText="carregando"
                      onChange={setEmpreendimentoValue}
                      disableClearable
                      renderInput={(
                        params: JSX.IntrinsicAttributes & TextFieldProps,
                      ) => (
                        <TextField
                          // label="Status"
                          variant="outlined"
                          fullWidth
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...params}
                          size="small"
                          error={false}
                          helperText=""
                        />
                      )}
                    />
                  </div>
                </ModalContainer>
                <ModalContainer>
                  <div className="input-control">
                    <p>Nome</p>
                    <TextField
                      variant="outlined"
                      required
                      style={{ width: 320 }}
                      name="quadra"
                      type="text"
                      id="password"
                      size="small"
                      autoComplete="current-password"
                    />
                  </div>

                  <div className="input-control-2">
                    <p>Posição</p>
                    <Autocomplete
                      id="combo-box-demo"
                      options={empreendimentoOptions}
                      getOptionLabel={(option: { title: any }) => option.title}
                      fullWidth
                      // style={{ width: 200 }}
                      value={empreendimento}
                      loading
                      loadingText="carregando"
                      onChange={setEmpreendimentoValue}
                      disableClearable
                      renderInput={(
                        params: JSX.IntrinsicAttributes & TextFieldProps,
                      ) => (
                        <TextField
                          // label="Status"
                          variant="outlined"
                          fullWidth
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...params}
                          size="small"
                          error={false}
                          helperText=""
                        />
                      )}
                    />
                  </div>
                </ModalContainer>

                <ModalContainer>
                  <div className="input-control" style={{ marginRight: 0 }}>
                    <p>Descrição</p>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      style={{ width: '100%' }}
                      multiline
                      maxRows="8"
                      name="descricao"
                      type="text"
                      id="password"
                      autoComplete="current-password"
                      value={values.descricao}
                      onChange={handleChange('descricao')}
                    />
                  </div>
                </ModalContainer>
                <div className="buttons">
                  <Botao
                    tipo="salvar"
                    tamanho="medio"
                    className="btn-modal"
                    onClick={() => {
                      setEditModalOpen(false);
                      saveChanges(userData);
                      // setEditModalOpen(false);
                    }}
                  >
                    Salvar
                  </Botao>

                  <Botao
                    tipo="excluir"
                    tamanho="medio"
                    className="btn-modal"
                    onClick={() => {
                      setEditModalOpen(false);
                      setDeleteModalOpen(true);
                    }}
                  >
                    Excluir
                  </Botao>
                </div>
              </>
            )}
          </Formik>
        </>
      </Modal>
    );

    return <></>;
  };

  return (
    <>
    <Content>
        <OpcoesContainer>
          <Botao tipo="adicionar" tamanho="medio" onClick={abreModal1}>
            Adicionar Prêmio
          </Botao>
        </OpcoesContainer>

        <TabelaContainer>
          <Tabela
            title="Prêmios"
            haveActions="editdelete"
            columns={columns}
            data={data}
            onRowClick={(e: any, rowData: any): any => {
              setUserData(rowData);
              rowSelected(e, rowData);
            }}
            excluirClick={(e: any, rowData: any): any => {
              setDeleteModalOpen(true);
            }}
            editarClick={(e: any, rowData: any): any => {
              setPergunta(rowData.pergunta);
              setResposta(rowData.resposta);
              setEditModalOpen(true);
            }}
            pagination
            paginationPageSize={10}
          />
        </TabelaContainer>
        <Snackbars
          type={snackbarProps.type}
          handleClose={handleSnackbarClose}
          open={snackbarOpen}
        >
          {snackbarProps.message}
        </Snackbars>
        <DeleteModal
          isModalOpen={deleteModalOpen}
          onCloseModal={() => setDeleteModalOpen(false)}
          onDeleteClick={() => deletarRegistro(userData)}
        />
        <EditAndCreateModal />
      </Content>
    </>
  );
};

export default Premios;
