import React from 'react';

const RankingIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="682.667"
      height="682.667"
      version="1"
      viewBox="0 0 512 512"
    >
      <path
        d="M2470 5110c-242-38-445-217-516-455-25-82-25-248 0-330 25-84 70-172 117-227 21-26 39-49 39-52s-24-21-52-39c-212-135-367-351-422-586l-14-61h1876l-9 43c-40 181-159 386-294 505-27 24-80 64-117 88-37 25-68 48-68 50 0 3 18 26 39 52 89 106 135 239 135 392 0 178-56 312-184 440-113 114-242 172-405 184-38 2-95 1-125-4zM4017 3534c-99-24-214-90-292-169-115-115-173-246-182-410-8-160 37-303 134-422 25-30 43-56 41-57-49-29-132-87-160-110l-38-31v-545h790c746 0 790 1 790 18 0 74-97 298-174 399-60 79-172 183-248 230-32 20-58 40-58 45 0 6 15 28 34 50 47 54 101 160 120 234 20 79 21 232 1 309-57 223-244 407-465 459-80 19-215 19-293 0zM825 3174c-91-20-216-82-283-140-71-62-138-159-175-252-26-66-31-94-35-192-7-173 26-281 125-411l50-67-39-22c-124-70-266-216-344-357-39-68-88-199-99-265l-7-38h1582v538l-37 33c-21 18-64 50-97 71s-61 40-63 42 15 25 37 52c163 196 185 491 53 712-135 227-416 352-668 296zM1910 1525V0h1300v3050H1910V1525zM3520 740V0l748 2c712 3 748 4 775 22 15 10 38 33 50 50l22 31 3 688 3 687H3520V740zM2 613C5 106 5 105 27 74c12-17 35-40 50-50C104 6 140 5 853 2l747-2v1120H-1l3-507z"
        transform="matrix(.1 0 0 -.1 0 512)"
      />
    </svg>
  );
};

export default RankingIcon;
