import React from 'react';

const Imobiliarias = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="682.667"
      height="682.667"
      version="1"
      viewBox="0 0 512 512"
    >
      <path
        d="M1200 4820v-300H0V0h5120v4520H3920v600H1200v-300zm2420-2260V300h-300v1830H1800V300h-300v4520h2120V2560zm-2420-300V300H300v3920h900V2260zm3620 0V300h-900v3920h900V2260zm-1800-580v-150h-920v300h920v-150zm-610-915V300h-310v930h310V765zm610 0V300h-310v930h310V765z"
        transform="matrix(.1 0 0 -.1 0 512)"
      />
      <path
        d="M1800 4075v-445h1520v890H1800v-445zm1220 0v-145h-920v290h920v-145zM1800 2875v-445h1520v890H1800v-445zm1220 0v-145h-920v290h920v-145zM600 3770v-150h300v300H600v-150zM600 3170v-150h300v300H600v-150zM600 2570v-150h300v300H600v-150zM600 1970v-150h300v300H600v-150zM600 1370v-150h300v300H600v-150zM4220 3770v-150h300v300h-300v-150zM4220 3170v-150h300v300h-300v-150zM4220 2570v-150h300v300h-300v-150zM4220 1970v-150h300v300h-300v-150zM4220 1370v-150h300v300h-300v-150z"
        transform="matrix(.1 0 0 -.1 0 512)"
      />
    </svg>
  );
};

export default Imobiliarias;
