/* eslint-disable react/require-default-props */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/jsx-props-no-spreading */
import React, { ChangeEventHandler } from 'react';
import TextField, { BaseTextFieldProps } from '@material-ui/core/TextField';

import Input from '@material-ui/core/Input';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import ErrorIcon from '@material-ui/icons/Error';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
// import { useController } from 'react-hook-form';

import { Container, TextInput } from './styles';

interface ITextField extends BaseTextFieldProps {
  tipo: string;
  onChange?: any;
  width?: any;
  control?: any;
  name?: any;
  value: any
}

const InputField = (props: ITextField) => {
  const {
    tipo,
    onChange,
    error,
    helperText,
    label,
    type,
    width,
    value,
    control,
    name,
    ...otherProps
  } = props; // value e ...otherProps


  const inputTipo = tipo || 'default';

  if (inputTipo === 'default') {
    return (
      <Container>
        <InputLabel htmlFor="component-helper" />
        <TextInput
          id="component-helper"
          value={name}
          aria-describedby="component-helper-text"
          className="input"
          error={error}
          label={label}
          type={type || 'text'}
          onChange={onChange || undefined}
        />
        <FormHelperText id="component-helper-text" className="error-message" />
      </Container>
    );
  }

  if (inputTipo === 'outlined') {
    return (
      <Container>
        {/* <InputLabel htmlFor="component-outlined" /> */}
        <TextInput
          id="component-outlined"
          variant="outlined"
          error={error}
          label={label}
          type={type || 'text'}
          className="input"
          onChange={onChange || undefined}
          {...otherProps}
        />
        <FormHelperText id="component-helper-text" className="error-message">
          {error && helperText ? (
            <ErrorOutlineIcon className="error-message-icon" />
          ) : (
            ''
          )}
          {helperText}
        </FormHelperText>
      </Container>
    );
  }

  // if (inputTipo === 'formulario-teste') {
  //   const {
  //     field: { ref, ...inputProps }, // onchange onblur value name
  //     fieldState: { invalid, isTouched, isDirty },
  //     formState: { touchedFields, dirtyFields },
  //   } = useController({
  //     name,
  //     control,
  //     rules: { required: true },
  //     defaultValue: '',
  //   });

  //   return (
  //     <Container>
  //       {/* <TextInput
  //         {...inputProps}
  //         name={name}
  //         inputRef={ref}
  //         id="component-outlined"
  //         variant="outlined"
  //         value={value}
  //         error={error}
  //         type={type || 'text'}
  //         className="input"
  //         size="small"
  //         style={{ width }}
  //         label={label}
  //         onChange={onChange || undefined}
  //       /> */}
  //       <TextInput
  //         {...inputProps}
  //         inputRef={ref}
  //         variant="outlined"
  //         size="small"
  //         label={label}
  //         style={{ width }}
  //         id="component-outlined"
  //         error={error}
  //         className="input"
  //         onChange={onChange || undefined}
  //       />

  //       <FormHelperText id="component-helper-text" className="error-message">
  //         {error && helperText ? (
  //           <ErrorOutlineIcon className="error-message-icon" />
  //         ) : (
  //           ''
  //         )}
  //         {helperText}
  //       </FormHelperText>
  //     </Container>
  //   );
  // }

  if (inputTipo === 'formulario') {
    return (
      <Container>
        <TextInput
          id="component-outlined"
          variant="outlined"
          value={value}
          error={error}
          type={type || 'text'}
          className="input"
          size="small"
          style={{ width }}
          label={label}
          onChange={onChange || undefined}
          {...otherProps}
        />
        <FormHelperText id="component-helper-text" className="error-message">
          {error && helperText ? (
            <ErrorOutlineIcon className="error-message-icon" />
          ) : (
            ''
          )}
          {helperText}
        </FormHelperText>
      </Container>
    );
  }
  
  if (inputTipo === 'formulario-text-area') {
    return (
      <Container>
        <TextField
          id="component-outlined"
          variant="outlined"
          multiline
          value={value}
          error={error}
          style={{ width, textAlign: 'justify' }}
          label={label}
          onChange={onChange}
          inputProps={{ style: { resize: 'vertical' } }}
          {...otherProps}
        />
        <FormHelperText id="component-helper-text" className="error-message">
          {error && helperText && (
            <ErrorOutlineIcon className="error-message-icon" />
          )}
          {helperText}
        </FormHelperText>
      </Container>
    );
  }

  return (
    <Container>
      <InputLabel htmlFor="component-helper" />
      <Input
        id="component-helper"
        value={name}
        aria-describedby="component-helper-text"
        className="input"
        error={error}
        style={{ borderColor: 'blue' }}
        onChange={onChange || undefined}
      />
      <FormHelperText id="component-helper-text" />
    </Container>
  );
};

InputField.defaultProps = {
  onChange: '',
  width: '100%',
};

export default InputField;
