import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import Botao from '../../../components/Botao/Botao';
import {
  Content,
  DetalhesContainer,
  DetalhesContent,
  DetalhesHeader,
  EnterprisesTable,
  Imobiliaria,
  TitlesHeader,
} from './styles';
import EmpreendimentosIcon from '../../../assets/Icons/EmpreendimentosIcon';

const DetalhesImobiliaria = () => {
  const [id, setid] = useState('');
  const [nome, setnome] = useState('');
  const [telefone, settelefone] = useState('');
  const [email, setemail] = useState('');
  const [cnpj, setcnpj] = useState('');
  const [creciJ, setCreciJ] = useState('');
  const [creciF, setCreciF] = useState('');
  const [razao_social, setrazao_social] = useState('');
  const [endereco, setendereco] = useState('');
  const [cep, setcep] = useState('');
  const [logradouro, setlogradouro] = useState('');
  const [uf, setuf] = useState('');
  const [cidade, setcidade] = useState('');
  const [bairro, setBairro] = useState('');
  const [numero, setNumero] = useState('');
  const [enterprises, setenterprises] = useState([]);

  // const { id, nome, telefone, email, cnpj, razao_social, endereco, cep, logradouro, uf, cidade, enterprises } = agencyData.agencyData;
  const location = useLocation<any>();
  const history = useHistory();

  useEffect(() => {
    const {
      id,
      nome,
      telefone,
      email,
      cnpj,
      razao_social,
      endereco,
      cep,
      logradouro,
      uf,
      cidade,
      enterprises,
      bairro,
      numero,
      creci_f,
      creci_j,
    } = location.state.rowData;
    console.log(location.state.rowData)
    setid(id);
    setnome(nome);
    settelefone(telefone);
    setemail(email);
    setcnpj(cnpj);
    setrazao_social(razao_social);
    setendereco(endereco);
    setcep(cep);
    setlogradouro(logradouro);
    setuf(uf);
    setcidade(cidade);
    setenterprises(enterprises);
    setBairro(bairro);
    setNumero(numero);
    setCreciJ(creci_j)
    setCreciF(creci_f)
  }, [location]);

  const [editarImob, setEditarImobiliaria] = useState(false);

  function editarImobiliaria() {
    history.push({
      pathname: '/painel/imobiliarias/editar',
      state: {
        id,
        nome,
        telefone,
        email,
        cnpj,
        razao_social,
        endereco,
        cep,
        logradouro,
        uf,
        cidade,
        enterprises,
        bairro,
        numero,
        creciF,
        creciJ,
      },
    });
  }
  const goToEnterprise = (enterpriseName: string) => {
    history.push({ pathname: '/painel/lotes', state: enterpriseName });
  };

  return (
    <>
      <Content>
        <DetalhesContainer>
          <DetalhesHeader>
            <Imobiliaria>
              <h1>
                <span>Imobiliária</span> {nome}
              </h1>
            </Imobiliaria>
            <Botao
              tipo="editar"
              tamanho="grande"
              className="btn"
              onClick={editarImobiliaria}
            >
              Editar
            </Botao>
          </DetalhesHeader>
          <DetalhesContent className="first-row">
            <div className="item">
              <h2>Nome </h2>
              <p>{nome} </p>
            </div>
            <div className="item">
              <h2>Telefone </h2>
              <p>{telefone} </p>
            </div>
            <div className="item">
              <h2>Email </h2>
              <p>{email} </p>
            </div>
            <div className="item">
              <h2>CNPJ </h2>
              <p>{cnpj} </p>
            </div>
            <div className="item">
              <h2>CRECI FÍSICO</h2>
              <p>{creciF}</p>
            </div>
            <div className="item">
              <h2>CRECI JURÍDICO</h2>
              <p>{creciJ}</p>
            </div>
            <div className="item">
              <h2>Razão Social </h2>
              <p>{razao_social} </p>
            </div>
          </DetalhesContent>
          <TitlesHeader>
            <EmpreendimentosIcon />
            <h1>Empreendimentos</h1>
          </TitlesHeader>
          {/* <DetalhesContent>
            <Accordion className="acordion">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className="acordion-summary"
              >
                <Typography>Mostrar empreendimentos</Typography>
              </AccordionSummary>
              <AccordionDetails style={{ width: 300 }}>
                <ul>
                  {!enterprises ? <li>Imobiliária sem Empreendimentos</li> :
                    enterprises.map((enterprise: any)=>{
                    return <li>{enterprise.name}</li>
                  })}
                </ul>

              </AccordionDetails>
              
            </Accordion>
          </DetalhesContent> */}
          <DetalhesContent>
            <EnterprisesTable>
              {!enterprises || enterprises.length <= 0 ? (
                <>
                  <p className="emptyEmps">
                    Nenhum empreendimento associado à essa imobiliária!
                  </p>
                </>
              ) : (
                <>
                  <tr>
                    {enterprises.map((el: any, index: any) => {
                      return (
                        <>
                          {index < 10 && (
                            <td>
                              <div
                                onClick={() => goToEnterprise(el.name)}
                                onKeyDown={() => goToEnterprise(el.name)}
                                role="button"
                                tabIndex={0}
                              >
                                {' '}
                                {el.name}{' '}
                              </div>
                            </td>
                          )}
                        </>
                      );
                    })}
                  </tr>
                  <tr>
                    {enterprises.map((el: any, index: any) => {
                      return (
                        <>
                          {index >= 11 && index < 21 && (
                            <td>
                              <div
                                onClick={() => goToEnterprise(el.name)}
                                onKeyDown={() => goToEnterprise(el.name)}
                                role="button"
                                tabIndex={0}
                              >
                                {' '}
                                {el.name}{' '}
                              </div>
                            </td>
                          )}
                        </>
                      );
                    })}
                  </tr>
                  <tr>
                    {enterprises.map((el: any, index: any) => {
                      return (
                        <>
                          {index >= 22 && (
                            <td>
                              <div
                                onClick={() => goToEnterprise(el.name)}
                                onKeyDown={() => goToEnterprise(el.name)}
                                role="button"
                                tabIndex={0}
                              >
                                {' '}
                                {el.name}{' '}
                              </div>
                            </td>
                          )}
                        </>
                      );
                    })}
                  </tr>
                  
                </>
              )}
            </EnterprisesTable>
          </DetalhesContent>
          <TitlesHeader>
            <HomeOutlinedIcon />
            <h1>Endereço</h1>
          </TitlesHeader>
          <DetalhesContent className="second-row">
            <div className="item">
              <h2>CEP </h2>
              <p>{cep || ''} </p>
            </div>
            <div className="item">
              <h2>Logradouro </h2>
              <p>{logradouro || ''} </p>
            </div>
            <div className="item">
              <h2>Número </h2>
              <p>{numero || 'Ausente'} </p>
            </div>
            {/* <div className="item">
              <h2>Complemento </h2>
              <p>complemento </p>
            </div> */}
            <div className="item">
              <h2>Bairro </h2>
              <p>{bairro || 'Ausente'} </p>
            </div>
            <div className="item">
              <h2>Cidade </h2>
              <p>{cidade || ''} </p>
            </div>
            <div className="item">
              <h2>UF </h2>
              <p>{uf || ''} </p>
            </div>
          </DetalhesContent>
        </DetalhesContainer>
      </Content>
    </>
  );
};

export default DetalhesImobiliaria;
