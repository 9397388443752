import AppError from '../errors/AppError';
import apiAutomacao from './api-automacao';

class BonificacaoService {
  private headers: any;

  constructor() {
    this.headers = {
      'Content-Type': 'application/json',
      Token: 'w5wac68Ho79ML577qRFDxjCiqaH6409d7b7cc31f',
      Usuario: 'APP',
    };
  }

  // Lista as bonificações cadastradas para a imobiliaria
  public async listBonificacaoImobiliaria(id_imob: string) {
    const dados = { id_imobiliaria: id_imob };

    try {
      const { data } = await apiAutomacao.post(
        `/bonificacao/obter-bonificacoes-por-imob`,
        dados,
        { headers: this.headers },
      );
      return data;
    } catch (err: any) {
      throw new AppError(err.message, err.statusCode);
    }
  }
}

export default new BonificacaoService();
