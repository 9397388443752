import styled from 'styled-components';
import cores from '../../../styles/cores';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: fit-content;
  &:nth-of-type(1) {
    margin-top: 25px;
    border: 0px solid black;
    padding: 4px 0px 4px 20px;
    h1 {
      font-weight: bold;
      font-size: 20px;
    }
  }
`;

export const DetalhesContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  margin-top: 0px;
  margin-left: 0px;
  border-radius: 8px;
  box-shadow: ${cores.shadow_2};
  padding: 0px;

  .card-header {
    display: flex;
    align-self: flex-start;
    align-items: center;
    justify-content: space-between;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding-left: 10px;
    background: ${cores.purple_2};
    width: 100%;
    h1 {
      font-size: 20px ;
      font-weight: bold;
      color: white;
      margin: 0;
    }

    div:first-child {
      display: flex;
      align-items: center;
      svg {
        width: 42px;
        height: 42px;
        fill: white;
        margin-right: 15px;
      }
    }
    .btn {
      margin-right: 30px;
      height: 50px;
    }
  }
`;

export const DetalhesHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-items: center;
  margin: 0;
  padding: 0;
  
  img {
    align-self: center;
    margin-bottom: 10px;
    padding: 1rem;
    width: 350px;
    height: auto;
  }

  .caroussel {
    border: 0;
    width: 320px;
    height: 320px;
  }
`;

export const Usuario = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0px;

  h1 {
    align-self: center;
  }
  svg {
  }
`;
export const DetalhesContent = styled.div`
  width: 100%;
  height: 100%;
  padding: 4rem 2rem 2rem 2rem;

  display: flex;
  flex-flow: column wrap;
  align-items: flex-start;
  justify-content: space-around;

  h2 {
    color: #545454;
    font-size: 23px;
    font-weight: bold;
  }
  
  .valor {
    color: ${cores.purple_1};
    font-size: 30px;
    font-weight: bold;
    margin: 2.3rem 0rem;
  }

  .titulo-descricao {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.02);
    padding: 0.5rem;
    font-size: 20px;
    margin-bottom: 1.5rem;
    color: #737373;
    font-weight: bold;
  }

  .descricao {
    text-align: justify;
    margin-bottom: 1rem;
    margin-left: 0.5rem;
    font-size: 17px;
    line-height: 2.2rem;
    color: #595959;
  }

  .categoria {
    text-align: justify;
    margin-bottom: 1.5rem;
    font-size: 17px;
    color: #595959;
    margin-left: 0.5rem;

  }

  .codigo {
    text-align: justify;
    font-size: 17px;
    color: #595959;
    margin-bottom: 1.5rem;
    margin-left: 0.5rem;

  }

  .span-descricao {
    font-weight: bold;
    color: #828282;
  }

  .desc {
    margin-top: 10px;
  }
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const ProdutoContainer = styled.div`
  display: flex;
  border: 0px solid black;
`;

export const InformacoesContainer = styled.div`
  width: 100%;

  display: flex;
  border: 0px solid green;
  flex-direction: column;

`;
