import styled from 'styled-components';

export const teste = styled.div`
  border: 1px solid black;
`;

export const teste2 = styled.div`
  border: 1px solid black;
`;

export const TabelaContainer = styled.div`
  display: flex;
  width: 90%;
  border-radius: 12px;
  height: fit-content;
  align-self: center;
  // box-shadow: 0px 0px 6px 2px rgba(12, 12, 14, 0.2);
`;

export const OpcoesContainer = styled.div`
  display: flex;
  width: 90%;
  margin-top: 50px;
`;

export const ModalContainer = styled.div`
  outline: 0;
  height: fit-content;
  margin-bottom: 0px;
`;

export const BotaoContainer = styled.div`
  display: flex;
  border: 0px solid red;
  justify-content: space-between;
  align-items: center;
  height: fit-content;
  margin-bottom: 0px;

  .btn {
    width: 45%;
    margin-bottom: 0px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
`;