import LinearProgress from '@material-ui/core/LinearProgress';
import WarningIcon from '@material-ui/icons/Warning';
import { Formik } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Logo from '../../assets/svg/LogoBairru';
import Botao from '../../components/Botao/Botao';
import InputField from '../../components/Inputs/InputField';
import Modal from '../../components/Modal/Modal';
import api from '../../services/api';
import { getUserData, isSignedIn } from '../../services/auth';
import { userLogin } from '../../storage/modules/user/userAction';
import { botoes } from '../../styles/tema';
import { validaEmail } from '../../utils/validacoes';
import {
  Background,
  BotaoContainer,
  Container,
  FormContainer,
  Icone,
  IconeErro,
  InputsContainer,
  ModalContainer,
} from './styles';

const Login = () => {
  const [errorEmail, setErrorEmail] = useState(false);
  const [errorSenha, setErrorSenha] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [errorMessageEmail, setErrorMessageEmail] = useState('');
  const [errorMessageSenha, setErrorMessageSenha] = useState('');
  const dispatch = useDispatch();
  const [loginDados, setLoginDados] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isImob, setIsImob] = useState(false);
  const [isRegional, setIsRegional] = useState(false);
  const [tempPassword, setTempPassword] = useState(false);

  const errorMessages = {
    error1: 'Campo obrigatório!',
    error2: 'Email inválido!',
  };
  const history = useHistory();

  const userLogged = useCallback(() => {
    const resultData = isSignedIn();
    const dataToken = getUserData();
    setLoginDados(resultData);
    if (resultData) {
      if (dataToken.userType === 'ADMIN') {
        setIsAdmin(true);
      } else if (dataToken.userType === 'IMOB') {
        setIsImob(true);
      } else if (dataToken.userType === 'REGIONAL') {
        setIsRegional(true);
      }
    }
  }, []);

  useEffect(() => {
    userLogged();
  }, [loginDados, userLogged]);

  const sleep = (ms: any) => new Promise(resolve => setTimeout(resolve, ms));

  const tryToLogin = async (loginData: any) => {
    const { email, password } = loginData;

    if (email === '') {
      setErrorEmail(true);
      setErrorMessageEmail(errorMessages.error1);
    }
    if (password === '') {
      setErrorSenha(true);
      setErrorMessageSenha(errorMessages.error1);
    }

    if (!validaEmail(email)) {
      setErrorEmail(true);
      setErrorMessageEmail(errorMessages.error2);
    }

    setLoading(true);
    await sleep(500);
    try {
      const { data } = await api.post('/session', { email, password });
      const { user, token } = data;
      setTempPassword(data.user.senha_temporaria);
      if (token) {
        const id = user.id;
        const userType = user.user_type;
        setLoading(false);
        setErrorEmail(false);
        setErrorSenha(false);
        setErrorMessageEmail('');
        setErrorMessageSenha('');
        dispatch(userLogin({ token, id, userType }));
        if (userType === 'ADMIN') {
          history.push('/painel');
        } else if (data.user.senha_temporaria) {
          history.push({ pathname: '/troca-senha', state: { user } });
        } else if (
          userType === 'MANAGER' ||
          userType === 'COORDINATOR' ||
          userType === 'OVERVIEWER'
        ) {
          history.push('/painel');
        } else if (userType === 'IMOB') {
          history.push('/painel/imobiliaria/proposta');
        } else if (userType === 'REGIONAL') {
          history.push('/painel/regional/proposta');
        } else {
          history.push('/painel/proposta');
        }
      }
    } catch (err) {
      const message = err.response.data.message || 'Ocorreu um erro inesperado';
      toast.error(message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setLoading(false);
    }
  };

  const validation = () => {
    if (errorEmail && !errorSenha) {
      setErrorEmail(false);
      setErrorMessageEmail('');
    }

    if (errorSenha && !errorEmail) {
      setErrorSenha(false);
      setErrorMessageSenha('');
    }

    if (errorSenha && errorEmail) {
      setErrorSenha(false);
      setErrorMessageSenha('');
      setErrorEmail(false);
      setErrorMessageEmail('');
    }
  };

  const FormModal = () => (
    <ModalContainer>
      <Modal
        isOpen={isModalOpen}
        width={380}
        onClose={() => setModalOpen(false)}
      >
        <>
          <h1> Usuário não encontrado!</h1>
          <IconeErro>
            <WarningIcon />
          </IconeErro>
          <p>Se você não lembra de sua senha, clique em esqueci minha senha!</p>
        </>
      </Modal>
    </ModalContainer>
  );

  return (
    <>
      <Background>
        {isLoading && <LinearProgress className="loading" />}
        <Icone>
          <Logo />
        </Icone>
        <FormContainer>
          <Container>
            <h2> Bem Vindo!</h2>
            <p className="textoAux">
              Insira os dados abaixo para entrar na sua conta
            </p>
            <Formik
              onSubmit={data => {
                tryToLogin(data);
              }}
              validate={validation}
              initialValues={{
                email: '',
                password: '',
              }}
            >
              {({ values, handleChange, handleSubmit }) => (
                <>
                  <InputsContainer>
                    <div style={{ marginBottom: 15 }}>
                      <InputField
                        tipo="outlined"
                        label="Email"
                        className="input"
                        fullWidth
                        error={errorEmail}
                        helperText={errorMessageEmail}
                        value={values.email}
                        onChange={handleChange('email')}
                      />
                    </div>
                    <div>
                      <InputField
                        tipo="outlined"
                        label="Senha"
                        type="password"
                        className="input"
                        fullWidth
                        error={errorSenha}
                        helperText={errorMessageSenha}
                        value={values.password}
                        onChange={handleChange('password')}
                      />
                    </div>
                  </InputsContainer>

                  <BotaoContainer>
                    <Botao
                      tipo={botoes.tipo.login}
                      tamanho={botoes.tamanho.fullwidth}
                      onClick={() => handleSubmit()}
                      isLoading={isLoading}
                    >
                      Entrar
                    </Botao>
                  </BotaoContainer>
                  <BotaoContainer>
                    <Botao
                      tipo={botoes.tipo.recuperar}
                      tamanho={botoes.tamanho.fullwidth}
                      onClick={() => history.push('/redefinir-senha')}
                      isLoading={isLoading}
                      border={true}
                    >
                      Esqueci minha senha
                    </Botao>
                  </BotaoContainer>
                </>
              )}
            </Formik>
          </Container>
        </FormContainer>
        <FormModal />
        <ToastContainer
          theme="colored"
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </Background>
    </>
  );
};

export default Login;
