import React, { useCallback, useEffect} from 'react';
import {
  TextField,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import { useHistory, useLocation } from 'react-router';
import { InputsContainer, InputsContent } from '../EditarProposta/styles';
import FileUpload from '../../../components/FileUpload';
import PropostasServices from '../../../services/PropostasService';

const PessoaJuridica = (props: any) => {
  const { files, updateUploadedFilesLegal} = props;
  const location = useLocation<any>()


  const checkedAnexos = (arr: Array<File>) => {
    if (arr.length >= 1 && arr.length <= 8) {
      return <DoneIcon className="checked" />;
    }
    if (arr.length > 8) {
      return (
        <>
          <ClearIcon className="wrong" />
          <p className="anexos-mensagem">* Anexar 8 arquivos no máximo</p>
        </>
      );
    }
    return (
      <>
        <ClearIcon className="wrong" />
        <p className="anexos-mensagem">* Documento Obrigatório</p>
      </>
    );
  };

  return (
    <>
      <InputsContent className="acordion-1">
        <Accordion className="acordion">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="acordion-summary"
          >
            <Typography className="anexar-documentos">
              <AttachFileIcon />
              Documentos Pessoais (RG ou CNH não vencida,CPF e comprovante de
              residência dos sócios)
              {checkedAnexos(files.documentosPessoaisLegal)}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <InputsContainer className="upload-fotos">
              <FileUpload
                accept=".jpg,.png,.jpeg,.pdf,.docx,.doc"
                label=""
                multiple
                updateFilesCb={(e: any) => {
                  updateUploadedFilesLegal(e, 'documentos pessoais legal');
                }}
              />
            </InputsContainer>
          </AccordionDetails>
        </Accordion>
      </InputsContent>
      <InputsContent className="acordion-1">
        <Accordion className="acordion">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="acordion-summary"
          >
            <Typography className="anexar-documentos">
              <AttachFileIcon/>
              Contrato Social
              {checkedAnexos(files.contratoSocial)}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <InputsContainer className="upload-fotos">
              <FileUpload
                currentDocuments="https://s3.us-east-1.amazonaws.com/bairru-corretores/9081ff5aed674b83021e-Apostila_JavaScript.pdf"
                accept=".jpg,.png,.jpeg,.pdf,.docx,.doc"
                label=""
                multiple
                updateFilesCb={(e: any) => {
                  updateUploadedFilesLegal(e, 'contrato social');
                }}
              />
            </InputsContainer>
          </AccordionDetails>
        </Accordion>
      </InputsContent>
      <InputsContent className="acordion-1">
        <Accordion className="acordion">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="acordion-summary"
          >
            <Typography className="anexar-documentos">
              <AttachFileIcon />
              Cartão de CNPJ impresso na internet (link)
              {checkedAnexos(files.cnpjDoc)}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <InputsContainer className="upload-fotos">
              <p>* Máximo 2 arquivos</p>
              <FileUpload
                accept=".jpg,.png,.jpeg,.pdf,.docx,.doc"
                label=""
                multiple
                updateFilesCb={(e: any) => {
                  updateUploadedFilesLegal(e, 'cnpj');
                }}
              />
            </InputsContainer>
          </AccordionDetails>
        </Accordion>
      </InputsContent>
      <InputsContent className="acordion-1">
        <Accordion className="acordion">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="acordion-summary"
          >
            <Typography className="anexar-documentos">
              <AttachFileIcon />
              Certidão de Registro da junta comercial (30 dias)
              {checkedAnexos(files.juntaComercial)}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <InputsContainer className="upload-fotos">
              <p>* Máximo 2 arquivos</p>
              <FileUpload
                accept=".jpg,.png,.jpeg,.pdf,.docx,.doc"
                label=""
                multiple
                updateFilesCb={(e: any) => {
                  updateUploadedFilesLegal(e, 'junta Comercial');
                }}
              />
            </InputsContainer>
          </AccordionDetails>
        </Accordion>
      </InputsContent>
      <InputsContent className="acordion-1">
        <Accordion className="acordion">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="acordion-summary"
          >
            <Typography className="anexar-documentos">
              <AttachFileIcon />
              Relação de faturamento dos últimos 12 meses
              {checkedAnexos(files.faturamentoDoc)}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <InputsContainer className="upload-fotos">
              <p>* Máximo 2 arquivos</p>
              <FileUpload
                accept=".jpg,.png,.jpeg,.pdf,.docx,.doc"
                label=""
                multiple
                updateFilesCb={(e: any) => {
                  updateUploadedFilesLegal(e, 'faturamento');
                }}
              />
            </InputsContainer>
          </AccordionDetails>
        </Accordion>
      </InputsContent>
      <InputsContent className="acordion-1">
        <Accordion className="acordion">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="acordion-summary"
          >
            <Typography className="anexar-documentos">
              <AttachFileIcon />
              Balanço dos 2 últimos anos
              {checkedAnexos(files.balanco)}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <InputsContainer className="upload-fotos">
              <p>* Máximo 2 arquivos</p>
              <FileUpload
                accept=".jpg,.png,.jpeg,.pdf,.docx,.doc"
                label=""
                multiple
                updateFilesCb={(e: any) => {
                  updateUploadedFilesLegal(e, 'balanco');
                }}
              />
            </InputsContainer>
          </AccordionDetails>
        </Accordion>
      </InputsContent>
      <InputsContent className="acordion-1">
        <Accordion className="acordion">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="acordion-summary"
          >
            <Typography className="anexar-documentos">
              <AttachFileIcon />
              Balancete trimestral (se houver)
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <InputsContainer className="upload-fotos">
              <p>* Máximo 2 arquivos</p>
              <FileUpload
                accept=".jpg,.png,.jpeg,.pdf,.docx,.doc"
                label=""
                multiple
                updateFilesCb={(e: any) => {
                  updateUploadedFilesLegal(e, 'balancete');
                }}
              />
            </InputsContainer>
          </AccordionDetails>
        </Accordion>
      </InputsContent>
    </>
  );
};

export default PessoaJuridica;
