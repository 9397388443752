import React from 'react';

const CorretoresIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="682.667"
      height="682.667"
      version="1"
      viewBox="0 0 512 512"
    >
      <path
        d="M900 5114c-97-16-145-31-220-66-157-75-290-237-336-408-20-78-23-224-5-300 42-180 179-351 341-428 109-51 173-66 285-65 176 1 311 58 441 188 127 126 187 269 187 445 0 109-15 174-65 280-74 155-239 292-403 334-65 16-184 27-225 20zM2500 5114c-97-16-145-31-220-66-157-75-290-237-336-408-22-86-22-234 0-320 46-171 179-333 336-408 109-51 173-66 285-65 176 1 311 58 441 188 127 126 187 269 187 445 0 109-15 174-65 280-74 155-239 292-403 334-65 16-184 27-225 20zM4100 5114c-97-16-145-31-220-66-157-75-290-237-336-408-22-86-22-234 0-320 46-171 179-333 336-408 109-51 173-66 285-65 176 1 311 58 441 188 127 126 187 269 187 445 0 109-15 174-65 280-74 155-239 292-403 334-65 16-184 27-225 20zM403 3510c-120-24-215-114-242-230-6-25-44-307-85-627-81-634-81-626-25-682 39-39 84-51 197-51 80 0 92-2 92-17 0-49 132-1305 141-1343 22-92 88-167 187-213 44-21 61-22 292-22 244 0 245 0 301 26 69 32 128 89 158 152 25 52 29 81 94 724 41 399 42 423 25 429-9 3-44 25-76 48-110 82-182 226-182 368 0 94 147 1240 167 1303 9 28 24 67 35 88 11 22 16 42 13 45-12 13-211 14-255 2-35-9-66-34-162-127l-118-116-118 115c-89 87-128 119-158 127-45 12-219 13-281 1zM2003 3510c-120-24-215-114-242-230-6-25-45-307-85-627-81-635-81-626-25-682 39-39 84-51 197-51 87 0 92-1 92-21 0-68 132-1622 141-1659 22-92 88-167 187-213 44-21 61-22 292-22s248 1 292 22c99 46 165 121 187 213 9 38 141 1589 141 1658 0 21 3 22 93 22 112 0 157 12 196 51 56 56 57 45-29 710-84 650-84 651-150 731-17 21-59 51-96 69-60 30-74 32-187 37-179 6-187 3-329-135l-118-116-118 115c-89 87-128 119-158 127-45 12-219 13-281 1zM3634 3513c-21-4-21-4 3-51 13-26 30-69 38-97 8-27 49-315 91-640 83-648 87-709 45-817-34-88-102-173-177-221-51-32-64-46-64-66 0-55 101-1019 111-1061 22-92 88-167 187-213 44-21 61-22 293-22h246l69 35c80 41 136 106 159 185 11 40 145 1290 145 1358 0 15 11 17 93 17 112 0 157 12 196 51 56 56 57 45-29 710-84 650-84 651-150 731-17 21-59 51-96 69-60 30-74 32-187 37-179 6-187 3-329-135l-118-116-118 115c-89 87-128 119-158 127-37 10-205 13-250 4z"
        transform="matrix(.1 0 0 -.1 0 512)"
      />
    </svg>
  );
};

export default CorretoresIcon;
