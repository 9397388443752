import styled, { keyframes } from 'styled-components';

export const Progresso = styled.div`
  display: flex;
  margin-top: -2px;
  padding: 0;
  height: 2px;
  background-color: transparent;
`;

const progresso = keyframes`
from {
  width: 100%;
}

to {
  width: 0%;
}
`;

export const ProgressoValue = styled.div`
  display: flex;
  border-radius: 8px;
  background-color: white;
  opacity: 0.8;
  animation: ${progresso} 5s linear;
`;
