import styled from 'styled-components';
import cores from '../../styles/cores';

export const Container = styled.div`
  cursor: pointer;
  display: inline-block;

  svg {
    transition: 0.5s ease;

    &:hover {
      fill: ${cores.blue};
    }
  }
`;

export const Teste = styled.div`
  cursor: pointer;
  display: inline-block;

  svg {
    transition: 0.5s ease;

    &:hover {
      fill: ${cores.blue};
    }
  }
`;
