/* eslint-disable no-unused-expressions */
import { Snackbar } from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import React, { useEffect, useState } from 'react';
import { Progresso, ProgressoValue } from './styles';

interface ISnackBarProps {
  type: any;
  children: string;
  open: boolean;
  handleClose: any;
  loading?: boolean;
}

// const useAudio = () => {
//   const audio = useState(new Audio('./'));
//   audio.play();
// };

const SnackbarComponent = (props: ISnackBarProps) => {
  const { type, children, handleClose, open,loading } = props;

  // const toggle = useAudio();

  function Alert(alertProps: AlertProps) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <MuiAlert elevation={6} variant="filled" {...alertProps} />;
  }

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={open}
        autoHideDuration={loading ? null : 7000}
        onClose={() => {
          handleClose();
        }}
        style={{ width: 'fit-content' }}
        key="topcenter"
      >
        <div>
          <Alert onClose={handleClose} severity={type}>
            {children}
          </Alert>
          {loading ? <></> :  (          <Progresso>
            <ProgressoValue />
          </Progresso>)}

        </div>
      </Snackbar>
    </>
  );
};

SnackbarComponent.defaultProps = {
  loading:  false,
}

/* <Alert severity="error">This is an error message!</Alert>
        <Alert severity="warning">This is a warning message!</Alert>
        <Alert severity="info">This is an information message!</Alert>
        <Alert severity="success">This is a success message!</Alert> */

export default SnackbarComponent;
