import { CircularProgress } from '@material-ui/core';
import Botao from '../Botao/Botao';
import Modal from '../Modal/Modal';
import { BotaoContainer, ModalContainerDelete } from './styles';

interface ProposalInfo {
  imobiliaria: string;
  nomeCorretor: string;
  nomeCliente: string;
  valorSinal: string;
  quantidadeSinal: number;
  dataSinal: string;
}

interface aprovedModalProps {
  isModalOpen: boolean;
  onApprovedClick: any;
  onCloseModal: any;
  isModalLoading: boolean;
  proposalInfo: ProposalInfo;
  showProposalInfo: boolean;
}

const AprovedModal = (props: aprovedModalProps): any => {
  const { isModalOpen, onApprovedClick, onCloseModal, isModalLoading, proposalInfo, showProposalInfo } = props;

  return (
    <Modal
      isOpen={isModalOpen}
      width={420}
      onClose={() => {
        onCloseModal();
      }}
    >
      <>
        <ModalContainerDelete>
          {!isModalLoading ? (
            <>
              <h1> Você deseja aprovar essa proposta?</h1>
              {showProposalInfo && (
                <div className="info-dados-app">
                  <h2>Confirme os dados a serem enviados:</h2>
                  <p> <strong>Imobiliária:</strong> {proposalInfo.imobiliaria}</p>
                  <p> <strong>Corretor:</strong> {proposalInfo.nomeCorretor}</p>
                  <p> <strong>Cliente:</strong> {proposalInfo.nomeCliente}</p>
                  <p> <strong>Valor sinal:</strong> {proposalInfo.valorSinal}</p>
                  <p> <strong>Qtd. sinal:</strong> {proposalInfo.quantidadeSinal}</p>
                  <p> <strong>Data sinal:</strong> {proposalInfo.dataSinal}</p>
                </div>
              )}

              <BotaoContainer>
                <Botao
                  tipo="salvar"
                  tamanho="medio"
                  className="btn"
                  disabled={false}
                  onClick={() => {
                    onApprovedClick();
                  }}
                >
                  Aprovar
                </Botao>
                <Botao
                  tamanho="medio"
                  className="btn"
                  onClick={() => {
                    onCloseModal();
                  }}
                >
                  Cancelar
                </Botao>
              </BotaoContainer>
            </>
          ) : (
            <>
              <h1>Realizando aprovação da proposta...</h1>
              <CircularProgress className="loading-spinner"/>
            </>
          )}
        </ModalContainerDelete>
      </>
    </Modal>
  );
};

export default AprovedModal;
