import CircularProgress from '@material-ui/core/CircularProgress';
import Botao from '../Botao/Botao';
import Modal from '../Modal/Modal';
import { BotaoContainer, ModalContainerDelete, ModalTitle, Paragraph, SpinnerConatainer } from './styles';

interface updateModalProps {
  isModalOpen: boolean;
  onCloseModal: any;
  isLoading: boolean;
}

const ModalRecover = (props: updateModalProps): any => {
  const { isModalOpen, onCloseModal, isLoading} = props;
  const spinner = <CircularProgress color="primary" size={40} />;
  const texto = ''
  return (
    <Modal
      isOpen={isModalOpen}
      width={420}
      onClose={onCloseModal}
    >
      <>
          <ModalContainerDelete>
            <ModalTitle>Atenção!</ModalTitle>
            <p>{!isLoading && texto}</p>
            <SpinnerConatainer className={isLoading ? '' : 'hide'}>
              {isLoading && spinner}
            </SpinnerConatainer>
            <Paragraph>Aguarde enquanto enviamos o email.</Paragraph>
            <BotaoContainer>
              <Botao
                tamanho="medio"
                className="btn"
                onClick={onCloseModal}
              >
                Cancelar
              </Botao>
            </BotaoContainer>
          </ModalContainerDelete>
      </>
    </Modal>
  );
};

export default ModalRecover;
