/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import UpdateIcon from '@material-ui/icons/Update';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import PersonIcon from '@material-ui/icons/Person';
import { useHistory, useLocation } from 'react-router-dom';
import AvatarUsuario from '../../../assets/Icons/AvatarUsuario';
import CorretoresIcon from '../../../assets/Icons/CorretoresIcon';
import Botao from '../../../components/Botao/Botao';
import ListProductsIcon from '../../../assets/Icons/ListProductsIcon';

// import EditarCorretores from '../EditarCorretores';
import {
  Content,
  DetalhesContainer,
  DetalhesContent,
  DetalhesHeader,
  Usuario,
  HeaderContainer,
  TableRow,
} from './styles';
import PedidosService from '../../../services/PedidosService';
import Modal from '../../../components/Modal/Modal';
import { ModalContainer } from '../styles';
import MarketplaceService from '../../../services/MarketplaceService';

const DetalhesPedidos = () => {
  const [id, setId] = useState('');
  const [status, setStatus] = useState('');
  const [codRastreamento, setCodRastreamento] = useState('');
  const [codCompra, setCodCompra] = useState<number>();
  const [nome, setNome] = useState('');
  const [endereco, setEndereco] = useState<any>([]);
  const [telefone, setTelefone] = useState<number>();
  const [documento, setDocumento] = useState('');
  const [produtos, setProdutos] = useState<any>([]);
  const [agencyName, setAgencyName] = useState('');
  const location = useLocation<any>();
  const history = useHistory();

  const statusOptions = [
    { title: 'Normal', value: 'Normal' },
    { title: 'Moderado', value: 'Moderado' },
    { title: 'Urgente', value: 'Urgente' },
  ];

  useEffect(() => {
    const data = {
      id: location.state.rowData.id,
      status: location.state.rowData.status,
      codRastreamento: location.state.rowData.cod_rastreamento,
      codCompra: location.state.rowData.cod_compra,
      nome: location.state.rowData.user.name,
      telefone: location.state.rowData.user.phone,
      documento: location.state.rowData.user.document,
      endereco: location.state.rowData.user.address,
      agencyName: location.state.rowData.agencyName,
    };

    setId(data.id);
    setStatus(data.status);
    setCodCompra(data.codCompra);
    setCodRastreamento(data.codRastreamento);
    setNome(data.nome);
    setEndereco(data.endereco);
    setTelefone(data.telefone);
    setDocumento(data.documento);
    setProdutos(location.state.rowData.products);
    setAgencyName(data.agencyName);
  }, [location]);

  const [editarPedidos, setEditarPedidos] = useState(false);
  const [isModalOpen2, setModalOpen2] = useState<boolean>(false);

  function pedidos() {
    history.push({
      pathname: '/painel/pedidos',
      state: {
        status,
        codRastreamento,
        codCompra,
        nome,
        endereco,
        telefone,
        documento,
      },
    });
  }

  const formataValor = (valor: number | string) => {
    let formatado = new Intl.NumberFormat('de-DE', {
      style: 'currency',
      currency: 'BRL',
    }).format(Number(valor));
    formatado = formatado.replace('R$', '');
    formatado = ['R', '$', ' '].concat(formatado.split('')).join('');
    return formatado;
  };

  function formataData(data: string) {
    const dataFormatada = new Date(data);
    return `${dataFormatada.toLocaleDateString()} às  ${dataFormatada.toLocaleTimeString()}`;
  }

  const goToProduct = async (id: string) => {
    const rowData = await MarketplaceService.findProduct(id);
    // console.log('productData', productData)
    history.push({
      pathname: '/painel/marketplace/detalhes',
      state: rowData.products,
    });
  };

  const totalSum = () => {
    return formataValor(
      produtos
        .map((el: { valor: any }) => Number(el.valor))
        .reduce((ant: any, prox: any) => ant + prox, 0),
    );
  };

  return (
    <>
      <Content>
        <DetalhesHeader>
          <HeaderContainer>
            <div className="header-info-container">
              <h1>ID do pedido: </h1>
              <span style={{ fontSize: 16 }}>{location.state.rowData.id}</span>
              <h2> Status:</h2>
              <span>{status}</span>
            </div>
            <div className="time-container">
              <h2>
                <CheckCircleOutlineIcon />
                Realizado em {formataData(location.state.rowData.created_at)}
              </h2>
              <h2>
                <UpdateIcon />
                Última atualização em{' '}
                {formataData(location.state.rowData.updated_at)}
              </h2>
            </div>
          </HeaderContainer>
          {/* <Botao
              tipo="editar"
              tamanho="grande"
              className="btn"
              onClick={pedidos}
            >
              Editar
            </Botao> */}
        </DetalhesHeader>

        <DetalhesContainer className="detalhes-container">
          <div className="card-header">
            <PersonIcon />
            <h1>Dados do corretor</h1>
          </div>
          <DetalhesContent>
            <div className="item">
              <h2>Nome </h2>
              <p>{nome || 'Ausente'} </p>
            </div>
            <div className="item">
              <h2>Documento </h2>
              <p>{documento || 'Ausente'} </p>
            </div>
            <div className="item">
              <h2>Imobiliária </h2>
              <p> {agencyName || 'Ausente'}</p>
            </div>
            <div className="item">
              <h2>Telefone </h2>
              <p>{telefone || 'Ausente'} </p>
            </div>
            <div className="item">
              <h2>Email </h2>
              <p>{location.state.rowData.user.email || 'Ausente'} </p>
            </div>
          </DetalhesContent>
        </DetalhesContainer>

        <DetalhesContainer className="detalhes-container">
          <div className="card-header">
            <LocalShippingIcon />
            <h1>Dados da entrega</h1>
          </div>
          <DetalhesContent>
            <div className="item">
              <h2>CEP </h2>
              <p>{endereco.cep || 'Ausente'} </p>
            </div>
            <div className="item">
              <h2>Logradouro </h2>
              <p>{endereco.rua || 'Ausente'} </p>
            </div>
            <div className="item">
              <h2>Número </h2>
              <p>{endereco.numero || 'Ausente'} </p>
            </div>
            <div className="item">
              <h2>Complemento </h2>
              <p>{endereco.complemento || 'Ausente'} </p>
            </div>
            <div className="item">
              <h2>Bairro </h2>
              <p>{endereco.bairro || 'Ausente'} </p>
            </div>
            <div className="item">
              <h2>Cidade </h2>
              <p>{endereco.cidade || 'Ausente'} </p>
            </div>
            <div className="item">
              <h2>UF </h2>
              <p>{endereco.uf || 'Ausente'} </p>
            </div>
            <div className="item">
              <h2>Código de rastreamento </h2>
              <p>{codRastreamento || 'Ausente'} </p>
            </div>
          </DetalhesContent>
        </DetalhesContainer>

        <DetalhesContainer className="detalhes-container">
          <div className="card-header">
            <ListProductsIcon />

            <h1>Lista de produtos</h1>
          </div>
          <DetalhesContent>
            <table>
              <tr>
                <th>Código do produto </th>
                <th>Nome do produto</th>
                <th>Preço</th>
                <th>Quantidade</th>
              </tr>
              {produtos.map((el: any) => {
                return (
                  <TableRow
                    onClick={() => goToProduct(el.id)}
                    onKeyDown={() => goToProduct(el.id)}
                    role="button"
                    tabIndex={0}
                  >
                    <td>{el.id}</td>
                    <td>{el.nome}</td>
                    <td>{formataValor(el.valor)}</td>
                    <td>{el.quantidade_disponivel || ''}</td>
                  </TableRow>
                );
              })}
              <tr>
                <td>Valor Total: {totalSum()}</td>
              </tr>
            </table>
          </DetalhesContent>
        </DetalhesContainer>
      </Content>
    </>
  );
};

export default DetalhesPedidos;
