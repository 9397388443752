import React from 'react';
import { ButtonProps } from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ButtonContainer, SpinnerConatainer } from './styles';
import cores from '../../styles/cores';

interface IButton extends ButtonProps {
  tipo?: string;
  tamanho?: string;
  isLoading?: boolean;
}

const Botao: React.FC<IButton> = props => {
  const {
    tipo,
    tamanho,
    isLoading,
    color,
    disabled,
    variant,
    children,
    border,
    ...otherProps
  } = props;

  let cor: any;
  let fontColor = 'black';
  const btnTamanho = tamanho || 'pequeno';
  switch (tipo) {
    case 'recuperar':
      cor = 'white';
      fontColor = cores.purple_2;
      break;
    case 'salvar':
      cor = cores.lime;
      fontColor = 'white';
      break;
    case 'deletar':
    case 'excluir':
      cor = cores.red;
      fontColor = 'white';
      break;
    case 'detalhes':
      cor = cores.purple_2;
      fontColor = 'white';
      break;
    case 'adicionar':
    case 'login':
      cor = cores.purple_2;
      fontColor = 'white';
      break;
    case 'editar':
    case 'voltar':
      cor = cores.yellow;
      break;
    case 'falta':
      cor = cores.blue;
      fontColor = 'white';
      break;
    default:
      cor = color;
  }

  let spinnerSize = 25;

  switch (btnTamanho) {
    case 'pequeno':
      spinnerSize = 10;
      break;
    case 'medio':
      spinnerSize = 20;
      break;
    case 'grande':
      spinnerSize = 30;
      break;
    default:
  }

  const spinner = <CircularProgress color="primary" size={spinnerSize} />;

  const texto = children;

  return (
    <ButtonContainer
      color={cor}
      style={{color: fontColor}}
      variant={variant || 'contained'}
      disabled={isLoading || disabled}
      className={btnTamanho}
      border={border}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...otherProps}
    >
      <p>{isLoading ? '' : texto}</p>
      <SpinnerConatainer className={isLoading ? '' : 'hide'}>
        {isLoading ? spinner : ''}
      </SpinnerConatainer>
    </ButtonContainer>
  );
};

export default Botao;
