import { createGlobalStyle } from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';

import colors from './cores';

const GlobalStyle = createGlobalStyle`

      @font-face {
          font-family: 'TT Commons';
          src: url('../assets/fonts/TTCommons-Light.woff2') format('woff2'),
              url('../assets/fonts/TTCommons-Light.woff') format('woff');
          font-weight: 300;
          font-style: normal;
          font-display: swap;
      }

      @font-face {
          font-family: 'TT Commons';
          src: url('../assets/fonts/TTCommons-Bold.woff2') format('woff2'),
              url('../assets/fonts/TTCommons-Bold.woff') format('woff');
          font-weight: bold;
          font-style: normal;
          font-display: swap;
      }

      @font-face {
          font-family: 'TT Commons';
          src: url('../assets/fonts/TTCommons-Medium.woff2') format('woff2'),
              url('../assets/fonts/TTCommons-Medium.woff') format('woff');
          font-weight: 500;
          font-style: normal;
          font-display: swap;
      }

      @font-face {
          font-family: 'TT Commons';
          src: url('../assets/fonts/TTCommons-Regular.woff2') format('woff2'),
              url('../assets/fonts/TTCommons-Regular.woff') format('woff');
          font-weight: normal;
          font-style: normal;
          font-display: swap;
      }



    *{
        font-family: 'TT Commons', sans-serif;
        font-weight: normal;
        box-sizing: border-box;
        font-display: swap;
    }
    html, body, div, span, applet, object, iframe,
    h1, h2, h3, h4, h5, h6, p, blockquote, pre,
    a, abbr, acronym, address, big, cite, code,
    del, dfn, em, img, ins, kbd, q, s, samp,
    small, strike, strong, sub, sup, tt, var,
    b, u, i, center,
    dl, dt, dd, ol, ul, li,
    fieldset, form, legend,
    table, caption, tbody, tfoot, thead, tr, th, td,
    article, aside, canvas, details, embed,
    figure, figcaption, footer, header, hgroup,
    menu, nav, output, ruby, section, summary,
    time, mark, audio, video {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        vertical-align: baseline;
    }

    html{
        scroll-behavior: smooth;
    }


    ::-webkit-scrollbar {
    width: 10px;
    }


    ::-webkit-scrollbar-track {
    background: #f1f1f1;
    opacity: 0;
    }


    ::-webkit-scrollbar-thumb {
    border-radius: 0px;
    background: #888;
    }


    ::-webkit-scrollbar-thumb:hover {
      cursor: default;
    background: #555;
    }



    /* HTML5 display-role reset for older browsers */
    article, aside, details, figcaption, figure,
    footer, header, hgroup, menu, nav, section {
        display: block;
    }
    body {
        background-color: white;
        height: 100%;
        width: 100%;
        line-height: 1;
        padding-right: 0 !important;
        overflow: auto !important;
    }
    ol, ul {
        list-style: none;
    }
    blockquote, q {
        quotes: none;
    }
    section{
        padding-top: 144px;
    }
    blockquote:before, blockquote:after,
    q:before, q:after {
        content: '';
        content: none;
    }
    table {
        border-collapse: collapse;
        border-spacing: 0;
    }
    a{
        text-decoration: none;
        color: inherit;
    }

    h1, h2, h3, h4, h5, h6{
        margin-bottom: 0;
    }



    @media only screen and (max-width: 480px){
      p{
          font-size: 14px;
          line-height: 25px;
      }
    }
`;

export default GlobalStyle;
