import Botao from '../Botao/Botao';
import Modal from '../Modal/Modal';
import { BotaoContainer, ModalContainerDelete, TextContainer } from './styles';

interface disapprovedModal {
  isModalOpen: boolean;
  onWaiverClick: any;
  onInCancelClick: any;
  onCloseModal: any;
}

const CancelModal = (props: disapprovedModal): any => {
  const { isModalOpen, onWaiverClick, onInCancelClick, onCloseModal } = props;

  return (
    <Modal
      isOpen={isModalOpen}
      width={420}
      onClose={() => {
        onCloseModal();
      }}
    >
      <>
        <ModalContainerDelete>
          <TextContainer>
            <h1>Você tem certeza?</h1>
            <p>
              Uma proposta, uma vez cancelada, não pode mais mudar de status.
            </p>
          </TextContainer>

          <BotaoContainer>
            <Botao
              tipo="deletar"
              tamanho="medio"
              className="btn"
              disabled={false}
              onClick={() => {
                onInCancelClick();
              }}
            >
              Cancelar
            </Botao>

            <Botao
              tamanho="medio"
              className="btn"
              onClick={() => {
                onCloseModal();
              }}
            >
              Sair
            </Botao>
          </BotaoContainer>
        </ModalContainerDelete>
      </>
    </Modal>
  );
};

export default CancelModal;
