/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable class-methods-use-this */
import axios from 'axios';
import AppError from '../errors/AppError';
import api from './api';
import { getToken } from './auth';

class PedidosService {
  private headers: any;

  constructor() {
    this.headers = {
      Authorization: `Bearer: ${getToken()}`
    };
  }

  // listar todos os usuários
  public async listAllPedidos() {
    try {
      const { data } = await api.get(`/requests/list`, {
        headers: {
          Authorization: `Bearer: ${getToken()}`
        },
      });
      return data;
    } catch (err: any) {
      throw new AppError(err, err.statusCode);
    }
  }

  public async updatePedido(request_id: any, status: any) {
    const params = {
      request_id: request_id,
      status: status
    }
    try {
      const { data } = await api.patch(`/requests`,
        { ...params },
        {
          headers: this.headers,
        });
      return data;
    } catch (err: any) {
      throw new AppError(err, err.statusCode);
    }
  }

  public async getPedidoUser(id: string) {
    try {
      const { data } = await api.get(`/requests/find/${id}`, {
        headers: this.headers,
      });
      if (data) {
        return data;
      }
      return 'No Data Found';
    } catch (err: any) {
      throw new AppError(err, err.statusCode);
    }
  }
}

export default new PedidosService();
