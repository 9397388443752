/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-restricted-syntax */

import React, { useEffect, useRef, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {
  FileUploadContainer,
  FormField,
  DragDropText,
  UploadFileBtn,
  FilePreviewContainer,
  ImagePreview,
  PreviewContainer,
  PreviewList,
  FileMetaData,
  RemoveFileIcon,
  InputLabel,
} from './styles';


const FileUpload = (props: any) => {
  const KILO_BYTES_PER_BYTE = 1000;
  const maxFileSizeInBytes = 10000000;

  const { label, updateFilesCb, multiple, currentDocuments, ...otherProps } =
    props;

  const convertNestedObjectToArray = (nestedObj: { [x: string]: any }) =>
    Object.keys(nestedObj).map(key => nestedObj[key]);

  const convertBytesToKB = (bytes: number) =>
    Math.round(bytes / KILO_BYTES_PER_BYTE);

  const convertBytesToMB = (bytes: any) => (bytes / 1000000).toFixed(1);

  const fileInputField: React.MutableRefObject<any> = useRef(null);
  const [fileTooBig, setFileTooBig] = useState(false);
  const [files, setFiles]: any = useState({});

  async function getFileFromUrl(
    url: any,
    name: string,
    defaultType = 'image/jpeg',
  ) {
    const response: any = await fetch(`${url}`, {
      mode: 'no-cors',
      method: 'GET',
    });

    const downloadurl = new Blob([response.blob()]);
    const data = await response.blob();
    return new File([data], name, {
      type: response.headers.get('content-type') || defaultType,
    });
  }

  const handleUploadBtnClick = () => {
    fileInputField.current.click();
  };

  const addNewFiles = (newFiles: any) => {
    for (const file of newFiles) {
      if (file.size <= maxFileSizeInBytes) {
        setFileTooBig(false);
        if (!multiple) {
          return { file };
        }
        files[file.name] = file;
      } else {
        setFileTooBig(true);
      }
    }
    return { ...files };
  };

  const callUpdateFilesCb = (filesme: any) => {
    const filesAsArray = convertNestedObjectToArray(filesme);
    updateFilesCb(filesAsArray);
  };

  const handleNewFileUpload = (e: any) => {
    const { files: newFiles } = e.target;

    if (newFiles.length) {
      const updatedFiles = addNewFiles(newFiles);
      setFiles(updatedFiles);
      callUpdateFilesCb(updatedFiles);
    }
  };

  const removeFile = (fileName: any) => {
    delete files[fileName];
    setFiles({ ...files });
    callUpdateFilesCb({ ...files });
  };

  const downloadFile = () => {
    <Link
      to="https://s3.us-east-1.amazonaws.com/bairru-corretores/5124647c4a85362aff59-capa.indd.pdf"
      download
    />;
  };

  const [fileDragged, setFileDragged] = useState(false);
  const history = useHistory();
  const location = useLocation<any>();

  useEffect(() => {
    getFileFromUrl(
      'https://s3.us-east-1.amazonaws.com/bairru-corretores/5124647c4a85362aff59-capa.indd.pdf',
      'example',
    )
      .then(res => {
        if (currentDocuments) {
          const updatedFiles = addNewFiles([res]);
          setFiles(updatedFiles);

          callUpdateFilesCb(updatedFiles);
        }
      })
      .catch(err => console.log('err',err));
  }, []);

  return (
    <>
      <FileUploadContainer
        draggadFile={fileDragged}
        style={{ borderColor: fileTooBig ? 'red' : '' }}
      >
        <InputLabel>
          *Os tipos de arquivos aceitos são: .jpg,.png,.jpeg,.pdf,.docx e .doc{' '}
        </InputLabel>
        <DragDropText style={{ color: fileTooBig ? 'red' : '' }}>
          {fileTooBig
            ? `O arquivo anexado não pode ter mais que ${convertBytesToMB(
                maxFileSizeInBytes,
              )}MB`
            : 'Solte os arquivos aqui ou clique no botão abaixo'}
        </DragDropText>
        <UploadFileBtn type="button" onClick={handleUploadBtnClick}>
          <i className="fas fa-file-upload" />
          <span> Escolher {multiple ? 'arquivos' : 'arquivo'}</span>
        </UploadFileBtn>
        <FormField
          type="file"
          ref={fileInputField}
          onChange={handleNewFileUpload}
          onProgress={(e: any) => {
            //
          }}
          onDragEnter={() => setFileDragged(true)}
          onDragEnd={() => setFileDragged(false)}
          onDrop={() => setFileDragged(false)}
          onDragLeave={() => setFileDragged(false)}
          title=""
          value=""
          multiple
          {...otherProps}
        />
      </FileUploadContainer>
      <FilePreviewContainer>
        <PreviewList>
          {Object.keys(files).map((fileName, index) => {
            const file = files[fileName];
            const isImageFile = file.type.split('/')[0] === 'image';
            return (
              <PreviewContainer key={fileName}>
                <div>
                  {isImageFile && (
                    <ImagePreview
                      src={URL.createObjectURL(file)}
                      alt={`file preview ${index}`}
                    />
                  )}

                  <FileMetaData isImageFile={isImageFile}>
                    <span>{file.name}</span>
                    <aside>
                      <span>{convertBytesToKB(file.size)} kb</span>
                      <RemoveFileIcon
                        className="fas fa-trash-alt"
                        onClick={() => removeFile(fileName)}
                      />
                    </aside>
                  </FileMetaData>
                </div>
              </PreviewContainer>
            );
          })}
        </PreviewList>
      </FilePreviewContainer>
    </>
  );
};

FileUpload.defaultProps = {
  multiple: false,
};

export default FileUpload;
