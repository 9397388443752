import Botao from '../Botao/Botao';
import Modal from '../Modal/Modal';
import { BotaoContainer, ModalContainerDelete, TextContainer } from './styles';


interface vencerModal {
  isModalOpen: boolean;
  onVencerClick: any;
  onCloseModal: any;
}

const VencerModal = (props: vencerModal): any => {
  const {
    isModalOpen,
    onVencerClick,
    onCloseModal,
  } = props;

  return (
    <Modal
      isOpen={isModalOpen}
      width={420}
      onClose={() => {
        onCloseModal();
      }}
    >
      <>
        <ModalContainerDelete>
          <TextContainer>
            <h1>Você tem certeza?</h1>
            <p>Vencer a proposta permite que qualquer pessoa possa lançar uma nova proposta nesse lote.</p>
          </TextContainer>

          <BotaoContainer>
            <Botao
              tipo="voltar"
              tamanho="medio"
              className="btn"
              disabled={false}
              onClick={() => {
                onVencerClick();
              }}
            >
              Vencer
            </Botao>
            <Botao
              tamanho="medio"
              className="btn"
              onClick={() => {
                onCloseModal();
              }}
            >
              Sair
            </Botao>
          </BotaoContainer>
        </ModalContainerDelete>
      </>
    </Modal>
  );
};

export default VencerModal;
