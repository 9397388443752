/* eslint-disable react/require-default-props */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/jsx-props-no-spreading */
import FormHelperText from '@material-ui/core/FormHelperText';
import { BaseTextFieldProps } from '@material-ui/core/TextField';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import React, { useState } from 'react';

import { Container, TextInput } from './styles';

interface ITextField extends BaseTextFieldProps {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  width?: any;
  control?: any;
  name?: any;
  onBlur?: () => void;
}

const InputField: React.FC<ITextField> = ({
  onChange,
  error,
  helperText,
  label,
  type = 'text',
  width = '100%',
  value,
  onBlur,
  ...otherProps
}) => {
  const [valor, setValor] = useState(value || '');

  return (
    <Container>
      <TextInput
        onBlur={onBlur}
        id="component-outlined"
        variant="outlined"
        value={value}
        error={error}
        type={type}
        className="input"
        size="small"
        style={{ width }}
        label={label}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setValor(e.target.value);
          if (onChange) onChange(e);
        }}
        {...otherProps}
      />
      <FormHelperText id="component-helper-text" className="error-message">
        {error && helperText ? (
          <ErrorOutlineIcon className="error-message-icon" />
        ) : (
          ''
        )}
        {helperText}
      </FormHelperText>
    </Container>
  );
};

export default InputField;
