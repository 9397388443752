import * as React from "react"
import { SVGProps } from "react"

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
 
    <svg
      xmlns="http://www.w3.org/2000/svg"
      color="#fff"
      data-name="Layer 1"
      viewBox="0 0 95.9 122.88"
      width={64}
      height={64}
      
    >
      <path
        d="M37.06 5v5a2.52 2.52 0 0 1-2.28 2.5 2.86 2.86 0 0 1-.89.14H24.6V23h46.69V12.68H62a2.81 2.81 0 0 1-.89-.14A2.52 2.52 0 0 1 58.84 10V5ZM18.4 49.25a2.25 2.25 0 1 1 3.74-2.51l1.23 1.82 4.87-5.92a2.25 2.25 0 0 1 3.48 2.86L25 53.7a2 2 0 0 1-.54.5 2.24 2.24 0 0 1-3.12-.61l-2.94-4.34Zm0 23.28A2.25 2.25 0 1 1 22.14 70l1.23 1.82 4.87-5.93a2.25 2.25 0 0 1 3.48 2.86L25 77a1.88 1.88 0 0 1-.54.51 2.24 2.24 0 0 1-3.12-.62l-2.94-4.36Zm0 24.2a2.25 2.25 0 1 1 3.74-2.51l1.23 1.83 4.87-5.93A2.25 2.25 0 0 1 31.72 93L25 101.18a2 2 0 0 1-.54.5 2.24 2.24 0 0 1-3.12-.61l-2.94-4.34Zm5-68.57a3.85 3.85 0 0 1-2.68-1.11c-.09-.09-.14-.18-.23-.27a3.94 3.94 0 0 1-.89-2.41v-5.09h-14a.49.49 0 0 0-.4.18.67.67 0 0 0-.18.4v97.4a.42.42 0 0 0 .18.4.56.56 0 0 0 .4.18h84.72a.56.56 0 0 0 .4-.18.44.44 0 0 0 .18-.4v-97.4a.67.67 0 0 0-.18-.4.5.5 0 0 0-.4-.18h-14v5.09a3.89 3.89 0 0 1-.9 2.41c-.08.09-.13.18-.22.27a3.85 3.85 0 0 1-2.68 1.11ZM5.62 122.88A5.63 5.63 0 0 1 0 117.26v-97.4a5.63 5.63 0 0 1 5.62-5.62h14v-2.77a3.79 3.79 0 0 1 3.78-3.79h8.66V4.2a4.14 4.14 0 0 1 1.25-2.95A4.13 4.13 0 0 1 36.25 0h23.4a4.15 4.15 0 0 1 2.94 1.25 4.14 4.14 0 0 1 1.25 3v3.43h8.66a3.79 3.79 0 0 1 3.79 3.79v2.77h14a5.63 5.63 0 0 1 5.63 5.62v97.4a5.63 5.63 0 0 1-5.63 5.62ZM76.37 99.6a2.55 2.55 0 0 0 0-5.09H42.56a2.55 2.55 0 0 0 0 5.09h33.81Zm0-48.8a2.55 2.55 0 0 0 0-5.09H42.56a2.55 2.55 0 0 0 0 5.09Zm0 24.07a2.55 2.55 0 0 0 0-5.09H42.56a2.55 2.55 0 0 0 0 5.09Z"
        fill="#fff"
        className="color000 svgShape"
      />
    </svg>

)

export default SvgComponent