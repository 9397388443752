// eslint-disable-next-line import/no-extraneous-dependencies
import { createStore } from 'redux';
import Reactotron from '../config/ReactotronConfig';
import rootReducer from './rootReducer';

const store = createStore(rootReducer);

console.tron.log('STORE_STATE', store.getState());

export default store;
