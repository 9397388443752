/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import EditIcon from '@material-ui/icons/Edit';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import axios from 'axios';
import {
  Checkbox,
  FormControl,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import SendIcon from '@material-ui/icons/Send';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import AddToPhotosOutlinedIcon from '@mui/icons-material/AddToPhotosOutlined';
import { useHistory } from 'react-router';
import Botao from '../../../components/Botao/Botao';
import {
  Content,
  DetalhesContainer,
  DetalhesContent,
  BotoesContainer,
  InputsContainer,
  InputsContent,
  TrocarFoto,
  UploadLabel,
  Container
} from './styles';
import InputField from '../../../components/Inputs/InputField';
import MarketplaceService from '../../../services/MarketplaceService';
import Snackbars from '../../../components/Snackbars';
import FileUpload from '../../../components/FileUpload';

interface ChipData {
  key: number;
  label: string;
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const addIcon = <AddToPhotosOutlinedIcon fontSize='medium' style={{ marginRight: '0.6rem' }} />

const NovoProduto = () => {
  const history = useHistory();

  const [productName, setProductName] = React.useState('');
  const [productDescricao, setProductDescricao] = useState('');
  const [productCategoria, setProductCategoria] = useState('');
  const [productPreco, setProductPreco] = useState('');
  const [productDisponibilidade, setProductDisponibilidade] = useState('');
  const [productFoto, setProductFoto] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarProps, setSnackbarProps] = useState({
    message: '',
    type: 'success',
  });
  function voltarTela() {
    history.goBack();
  }

  const updateUploadedFiles = (files: any) => {
    setProductFoto(files[0])
  };

  const formatarPreco = (valor: string) => {
    const numeroLimpo = valor.replace(/\D/g, '');
    let numero = parseInt(numeroLimpo, 10);
    if (Number.isNaN(numero)) {
      return '';
    }

    const temCentavos = /\.\d{3,}$/.test(numeroLimpo);

    const valorFormatado = numero.toLocaleString('pt-BR', {
      minimumFractionDigits: temCentavos ? 2 : 0,
    });

    return valorFormatado + (valor.trim() === '' ? '' : ' Ucoins');
  };

  const handleChangePreco = (event: { target: { value: any; }; }) => {
    const { value } = event.target;
    const precoSemFormato = value.replace(/\D/g, '');
    setProductPreco(formatarPreco(precoSemFormato));
  };

  const formatarPrecoSemMascara = (valor: string) => {
    console.log('valor: ', valor)
    return valor.replace(/[^\d.,]/g, '').replace(',', '.');
  };

  const validationSchema = Yup.object().shape({
    productName: Yup.string().max(100, 'Nome do produto deve ter no máximo 100 caracteres').required('Nome do produto é obrigatório'),
    productCategoria: Yup.string().required('Categoria do produto é obrigatória'),
    productPreco: Yup.string().required('Preço do produto é obrigatório'),
    productDescricao: Yup.string().required('Descrição do produto é obrigatória'),
  });

  const [formEnviadoComSucesso, setFormEnviadoComSucesso] = useState(false);
  const [formEnviado, setFormEnviado] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const [errors, setErrors] = useState<{
    productName?: string,
    productCategoria?: string,
    productPreco?: string,
    productDescricao?: string,
  }>({});

  const validationFormErrors = async () => {
    try {
      await validationSchema.validate({
        productName,
        productCategoria,
        productPreco,
        productDescricao,
      }, { abortEarly: false })
      setErrors({});
      setFormValid(true);
    } catch (error: any) {
      const validationErrors: Record<string, string> = {};
      if (error.inner) {
        error.inner.forEach((err: any) => {
          validationErrors[err.path] = err.message;
        });
      }
      setErrors(validationErrors);
      setFormValid(false);
    }
  }

  useEffect(() => {
    validationFormErrors();
  }, [productName, productCategoria, productPreco, productDescricao]);


  const save = async () => {
    const precoSemMascara = formatarPrecoSemMascara(productPreco);
    if (!formValid) {
      setFormEnviado(true);
      return;
    }
    try {
      await validationFormErrors();
      const produto = await MarketplaceService.createProduct({
        nome: productName,
        descricao: productDescricao,
        categoria: productCategoria,
        valor: precoSemMascara,
        disponibilidade: productDisponibilidade,
        foto: productFoto,
        link: ""
      });
      setSnackbarProps({
        message: 'Produto salvo com sucesso!',
        type: 'success',
      })
      setSnackbarOpen(true);

      setProductName('');
      setProductDescricao('');
      setProductCategoria('');
      setProductPreco('');
      setProductDisponibilidade('');
      setProductFoto('');
      voltarTela()
      setSnackbarProps({
        message: 'Produto salvo com sucesso!',
        type: 'success',
      })
      setSnackbarOpen(true);
      setTimeout(() => {
        setSnackbarOpen(false);
        history.push('/painel/marketplace');
      }, 3000);
    } catch (error: any) {
      setSnackbarProps({
        message: error.message || 'Ocorreu um erro ao salvar o produto.',
        type: 'error',
      });
      setSnackbarOpen(true);
    }
  };


  useEffect(() => {
    if (formEnviadoComSucesso) {
      setErrors({});
      setFormValid(false);
      setFormEnviado(false);
      setFormEnviadoComSucesso(false);
    }
  }, [formEnviadoComSucesso]);

  const handleSnackbarClose = (
    event?: React.SyntheticEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  async function deletarRegistro(productData: any) {
    await MarketplaceService.deleteProduct(productData.id);
  }

  const categoriaOptions = [
    { title: 'TV', value: 'TV' },
    { title: 'Eletrodoméstico', value: 'Eletrodoméstico' },
    { title: 'Eletroeletrônicos', value: 'Eletroeletrônicos' },
    { title: 'Smartphones', value: 'Smartphones' },
    { title: 'Informática', value: 'Informática' },
    { title: 'Câmeras', value: 'Câmeras' },
    { title: 'Áudio', value: 'Áudio' },
    { title: 'Drones', value: 'Drones' },
    { title: 'Ar Condicionado', value: 'Ar Condicionado' },
    { title: 'Outros', value: 'Outros' }
  ];

  return (
    <>
      <Content>
        <DetalhesContainer>
          <h1>{addIcon}Adicionar novo produto:</h1>
          <DetalhesContent>
            <InputsContainer>
              <InputField
                tipo="formulario"
                className="inputs"
                label="Nome"
                value={productName}
                name='productName'
                onChange={(e: any) => {
                  setProductName(e.target.value);
                }}
                error={(formEnviado || formEnviadoComSucesso) && Boolean(errors.productName)}
                helperText={(formEnviado || formEnviadoComSucesso) && errors.productName}
              />
            </InputsContainer>
            <div className='div-inputs-preço-categoria'>
              <InputsContainer>
                <Autocomplete
                  size="small"
                  style={{ width: '95%', padding: '0px' }}
                  options={categoriaOptions}
                  getOptionLabel={(option) => option.title}
                  value={categoriaOptions.find(option => option.value === productCategoria) || null}
                  onChange={(event, newValue) => {
                    setProductCategoria(newValue ? newValue.value : '');
                  }}
                  renderInput={(params) => (
                    <InputField
                      {...params}
                      tipo="formulario"
                      className="inputs"
                      label="Categoria"
                      name="productCategoria"
                      value={productCategoria}
                      onChange={(e: any) => {
                        setProductCategoria(e.target.value);
                      }}
                      error={formEnviado && Boolean(errors.productCategoria)}
                      helperText={(formEnviado || formEnviadoComSucesso) && errors.productCategoria}
                    />
                  )}
                />
              </InputsContainer>

              <InputsContainer>
                <InputField
                  tipo="formulario"
                  className="inputs"
                  label="Preço"
                  type="text"
                  value={productPreco}
                  onChange={handleChangePreco}
                  error={(formEnviado || formEnviadoComSucesso) && Boolean(errors.productPreco)}
                  helperText={(formEnviado || formEnviadoComSucesso) && errors.productPreco}
                />
              </InputsContainer>
            </div>
            <InputsContainer>
              <InputField
                tipo="formulario-text-area"
                className="inputs"
                label="Descrição"
                value={productDescricao}
                onChange={(e: any) => {
                  setProductDescricao(e.target.value);
                }}
                error={formEnviado && Boolean(errors.productDescricao)}
                helperText={(formEnviado || formEnviadoComSucesso) && errors.productDescricao}
              />
            </InputsContainer>
            <InputsContainer>
              <h2>Upload de fotos do produto: </h2>
              <FileUpload
                label=""
                multiple
                updateFilesCb={updateUploadedFiles}
              />
            </InputsContainer>

            <BotoesContainer>
              <Botao
                tipo="salvar"
                tamanho="grande"
                className="btn-salvar"
                onClick={save}
              >
                Salvar Produto
              </Botao>
            </BotoesContainer>
          </DetalhesContent>
        </DetalhesContainer>
        <Snackbars
          type={snackbarProps.type}
          handleClose={handleSnackbarClose}
          open={snackbarOpen}
        >
          {snackbarProps.message}
        </Snackbars>
      </Content>
    </>
  );
};

export default NovoProduto;
function validationFormErrors() {
  throw new Error('Function not implemented.');
}

