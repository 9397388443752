import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PreviewFile from '../../../components/PreviewFile/PreviewFile';
import { InputsContainer, InputsContent } from '../EditarProposta/styles';

const DocumentsEvidences = ({ filesUrl }: any) => {
  if (!filesUrl || filesUrl.length === 0) {
    return null;
  }

  return (
    <InputsContent>
      <Accordion style={{ width: '100%' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{
            backgroundColor: '#533FE7',
            borderRadius: 20,
            height: 50,
            marginBottom: '1%',
            width: '100%',
          }}
        >
          <Typography className="anexar-documentos" style={{ color: 'white' }}>
            <AttachFileIcon />
            Evidências Anexadas
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <InputsContainer className="upload-fotos">
            {filesUrl.map((file: any) => (
              <PreviewFile url={file.link} nome={'Evidência'} isImage={true} />
            ))}
          </InputsContainer>
        </AccordionDetails>
      </Accordion>
    </InputsContent>
  );
};

export default DocumentsEvidences;
