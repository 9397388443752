import { combineReducers } from 'redux';

// import globalReducer from './modules/globals/globalReducer'
import userReducer from './modules/user/userReducer';

const rootReducer = combineReducers({
  userReducer,
  // globalReducer: globalReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
