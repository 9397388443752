import styled, { css } from 'styled-components';
import cores from '../../../styles/cores';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100vw;
  height: 100vh;
  overflow-x: auto;
`;

export const DetalhesContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  width: 90%;
  height: fit-content;
  align-self: center;
  border-radius: 8px;
  box-shadow: 0px 0px 6px 2px rgba(12, 12, 14, 0.2);
  margin-bottom: 120px;
  padding: 0 20px;
  padding: 0px;
`;

export const DetalhesHeader = styled.div`
  display: flex;
  border: 0px solid black;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0px;
  padding: 0 0 0 20px;
  background: ${cores.purple_1};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  .btn {
    margin-right: 30px;
    height: 50px;
  }
  h1 {
    border: 0px solid black;
    font-size: 24px;
    font-weigth: bold;
  }
  .titulos {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    color: white;
    h2 {
    }

    span {
      margin-left: 5px;
    }
    .top-header {
      display: flex;
      align-items: center;
      h2 {
        margin: 0 15px;
        display: flex;
        align-items: center;
      }
      h2:nth-of-type(1) {
        margin: 0 15px 0 0;
        span {
          font-weight: bold;
          font-size: 18px;
        }
      }
      svg {
        margin-right: 5px;
      }
    }
  }
`;

export const Usuario = styled.div`
  display: flex;
  border: 0px solid black;
  align-items: center;
  justify-content: center;
  margin-left: 20px;

  h1 {
    border: 0px solid black;
    align-self: center;
    width: 100%;
    margin-left: -25px;
  }
  img {
    border: 0px solid black;
  }

  svg {
    border: 0px solid black;
  }
`;

export const BotoesContainer = styled.div`
  display: flex;
  border: 0px solid black;
  align-self: flex-end;
  margin-top: 0px;
  padding: 0;
  width: 100%;

  .btn-salvar {
    margin-right: 20px;
  }

  .btn-exluir {
  }
`;

export const TrocarFoto = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(-45px, 45px);
  background-color: white;
  cursor: pointer;
  svg {
    font-size: 26px;
    fill: ${cores.purple_1};
  }
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: fit-content;
  height: 100%;
`;

export const DetalhesContent = styled.div`
  display: flex;

  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  max-width: 100%;
  margin-left: 0px;
  margin-bottom: 20px;
  padding: 0 20px;
  .item {
    display: flex;
    flex-direction: column;
    border: 0px solid black;
    width: fit-content;
    margin-top: 10px;
    margin-right: 50px;
  }

  .titulos {
    display: flex;
    align-items: center;
    width: 100%;
    border: 0px solid black;
    font-size: 18px;
    h2 {
      margin-left: 5px;
      font-weight: bold;
    }
  }

  .MuiAccordion-root:before {
    background-color: transparent;
  }

  .acordion-summary {
    border-radius: 8px;
    color: white;
    // background: ${cores.themeGradient_2};
     background: ${cores.purple_2};
    // box-shadow: 0px 0px 4px 4px rgba(12, 12, 14, 0.4);

    p {
      font-weight: bold;
    }

    svg {
      font-size: 32px;
      fill: white;
    }
  }

  .MuiAccordion-root {
    border: 0px solid black;
    box-shadow: none !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    aling-items: center;
    width: 100%;
    height: inherit;

    .MuiAccordionSummary-root {
      // box-shadow: 0px 0px 2px 2px rgba(12, 12, 14, 0.4) !important;
      .anexar-documentos {
        display: flex;
        align-items: center;
        svg {
          font-size: 24px;
          margin-right: 5px;
        }
        .checked {
          fill: lightgreen;
          margin-left: 5px;
        }
        .wrong {
          fill: red;
          margin-left: 5px;
        }
      }
    }

    .MuiAccordionDetails-root {
      border: 0px solid grey;
      margin-top: -5px;
      padding: 0;
    }
  }

  .acordion-summary-devolution {
    border-radius: 8px;
    color: black;
    // background: ${cores.themeGradient_2};
     background: #EEA332;
    // box-shadow: 0px 0px 4px 4px rgba(12, 12, 14, 0.4);

    p {
      font-weight: bold;
    }

    svg {
      font-size: 32px;
      fill: black;
    }
  }

  .MuiAccordion-root {
    border: 0px solid black;
    box-shadow: none !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    aling-items: center;
    width: 100%;
    height: inherit;

    .MuiAccordionSummary-root {
      // box-shadow: 0px 0px 2px 2px rgba(12, 12, 14, 0.4) !important;
      .anexar-documentos {
        display: flex;
        align-items: center;
        svg {
          font-size: 24px;
          margin-right: 5px;
        }
        .checked {
          fill: lightgreen;
          margin-left: 5px;
        }
        .wrong {
          fill: red;
          margin-left: 5px;
        }
      }
    }

    .MuiAccordionDetails-root {
      border: 0px solid grey;
      margin-top: -5px;
      padding: 0;
    }
  }

  .acordion {
    margin-top: 10px;
    box-shadow: 0;

    ul {
      display: flex;
    }

    li {
      display: flex;

      border-bottom: 0px solid rgba(10, 10, 200, 0.1);
      padding-bottom: 5px;
      margin-left: 0;
      margin: 10px;
      width: 200px;
    }
  }
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 0px;
  padding: 0;
  margin: 10px 10px;
  max-width: 100%;
  .input {
    width: 250px;
  }

  .visually-hidden {
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
    opacity: 0;
  }

  &.descricao-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 !important;

    h2 {
      font-size: 18px;
      font-weight: bold;
    }
  }

  &.upload-fotos {
    // border: 1px solid black;
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 0;
    margin-top: 0;
    p:first-child {
      margin-top: 10px;
      font-style: italic;
      font-size: 14px;
    }
  }
`;

export const UploadLabel = styled.label`
  display: flex;
  width: 300px;
  height: 200px;
  border: 2px dashed #ccc;
  margin: 20px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

// ${({ dragEnter }) =>
//   dragEnter &&
//   css`
//     border-color: #666;
//   `}

export const InputsContent = styled.div`
  display: flex;
  border: 0px solid black;
  flex-wrap: wrap;
  max-width: 100%;
  width: 100%;
  margin-bottom: 20px;

  &:first-child {
    // border: 1px solid black;
    margin-top: 20px;
    align-items: center;
    h2 {
      margin-right: 10px;
      font-weight: bold;
    }
  }

  padding: 0;

  .anexar-documentos {
    display: flex;
    align-items: center;
    p {
      font-size: 14px;
      font-weight: normal;
      font-style: italic;
      color: lightgrey;
    }
    .anexos-mensagem {
      align-self: center;
    }
  }

  &.acordion-1 {
    border: 0px solid black;
    display: flex;
    width: 100%;
    margin-bottom: 10px;
  }

  &.descricao-container-inputs {
    margin-top: 5px;
  }

  .info-dados-app {
    display: flex;
    flex-direction: column;
    p {
      margin-top: 10px;
      font-size: 14px;
      font-style: italic;
    }
  }

  .info-documentos {
    display: flex;
    align-items: center;

    h2 {
      margin-left: 5px;
    }
  }
`;

export const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const LoadingOverlay = styled.div`
    position: absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;  
    background-color: rgba(0,0,0,0.50);
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;


export const ModalWrapper = styled.div`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  background-color: transparent;
  padding: 24px 16px;
  padding-bottom: 8px;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;

  h1 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 24px;
  }

  .row {
    display: flex;
    width: 100%;
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .btn-modal {
    width: 50%;
  }
  .btn-modal {
    width: 100%;
  }

  .formWrapper {
    max-width: 100%;
    margin: 0 auto;

    p {
      margin-bottom: -10px;
      font-weight: bold;
    }
  }
`;