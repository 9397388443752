import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import LocalGroceryStoreOutlinedIcon from '@mui/icons-material/LocalGroceryStoreOutlined';
import AvatarUsuario from '../../../assets/Icons/AvatarUsuario';
import MarketplaceIcon from '../../../assets/Icons/MarketplaceIcon';
import Botao from '../../../components/Botao/Botao';
import {
  Container,
  Content,
  DetalhesContainer,
  DetalhesContent,
  DetalhesHeader,
  InformacoesContainer,
  ProdutoContainer
} from './styles';

const marketIcon =
  <LocalGroceryStoreOutlinedIcon
    fontSize="medium"
    style={{ marginLeft: "1.5rem" }}
  />
;

const DetalhesProduto = () => {
  const [id, setId] = useState('');
  const [nome, setNome] = useState('');
  const [descricao, setDescricao] = useState('');
  const [valor, setValor] = useState<number>();
  const [categoria, setCategoria] = useState('');
  const [foto, setFoto] = useState('');
  const [disponibilidade, setDisponibilidade] = useState('');
  const location = useLocation<any>();
  const history = useHistory();

  useEffect(() => {

    const { id, nome, descricao, valor, categoria, foto, disponibilidade } =
      location.state.rowData || location.state;

    setId(id);
    setNome(nome);
    setDescricao(descricao);
    setValor(valor);
    setCategoria(categoria);
    setFoto(foto);
    setDisponibilidade(disponibilidade);
  }, [location]);

  const [editarProduto, setEditarProduto] = useState(false);

  function editar() {
    history.push({
      pathname: '/painel/marketplace/editar',
      state: {
        id,
        nome,
        descricao,
        valor,
        categoria,
        foto,
        disponibilidade,
      },
    });
  }

  const dataImage = [
    {
      image:
        foto,
      caption: '',
    },
  ];

  const images = dataImage.map(props => ({
    src: props.image,
    caption: props.caption,
  }));

  return (
    <>
      <Content>
        <Container>
          <DetalhesContainer>
            <div className="card-header">
              <div>
                {marketIcon}
                <h1>{nome}</h1>
              </div>
              <Botao
                tipo="editar"
                tamanho="grande"
                className="btn"
                onClick={editar}
              >
                Editar
              </Botao>
            </div>
            <ProdutoContainer>
              <DetalhesHeader>

                <img
                  src={foto || ''}
                  alt="avatar"
                />
              </DetalhesHeader>
              <InformacoesContainer>
                <DetalhesContent>
                  <h2>{nome}</h2>
                  <p className='valor'>{valor} Ucoins</p>
                  <div className='titulo-descricao'>Descrição do Produto</div>
                  <p className='categoria'><span className='span-descricao'>Categoria:</span> {categoria}</p>
                  <p className='codigo'><span className='span-descricao'>Código:</span> {id}</p>
                  <p className='descricao'>{descricao}</p>
                </DetalhesContent>
              </InformacoesContainer>
            </ProdutoContainer>
          </DetalhesContainer>
        </Container>
      </Content>
    </>
  );
};

export default DetalhesProduto;
