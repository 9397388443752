import styled from 'styled-components';

export const ModalContainerDelete = styled.div`
  height: fit-content;
  margin-bottom: 0px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0px solid black;
    width: 100%;
    margin-bottom: 20px;
    margin-top: 25px;
  }

  .info-dados-app {
    display: flex;
    flex-direction: column;
    line-height: 1.5rem;
    flex-wrap: nowrap;
    p {
      margin-top: 8px;
      font-size: 14px;
      font-style: normal;
    }

    strong {
      font-weight: bold;
      margin-right: 8px;
    }
  }

  .aviso {
    border: 0px solid red;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
    font-weight: regular;
    font-size: 18px;

    p {
      border: 0px solid red;
      text-align: justify;
      line-height: 20px;
    }

    p:nth-of-type(1) {
      font-weight: bold;
      margin-bottom: 10px;
      text-align: center;
    }
    p:nth-of-type(2) {
      margin-bottom: 10px;
    }

    svg {
      fill: red;
      font-size: 128px;
      margin-bottom: 4px;
    }
  }

  .loading-spinner {
    margin-top: 16px;
    margin-bottom: 16px;
  }
`;
export const BotaoContainer = styled.div`
  display: flex;
  border: 0px solid red;
  justify-content: space-between;
  align-items: center;
  height: fit-content;
  margin-top: 24px;
  margin-bottom: 16px;

  .btn {
    width: 45%;
    margin-bottom: 0px;
  }
`;
