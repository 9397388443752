import React, { useCallback, useEffect, useRef, useState } from 'react';
import MaterialTable from 'material-table';
import { useHistory, useLocation } from 'react-router-dom';
import { Formik } from 'formik';
import { TextField, TextFieldProps } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { Autocomplete } from '@material-ui/lab';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Tabela from '../../components/Tabelas/Tabela';
import {
  BotaoContainer,
  ModalContainer,
  OpcoesContainer,
  TabelaContainer,
  Teste,
  ModalContainerDelete,
} from './styles';
import Botao from '../../components/Botao/Botao';
import Modal from '../../components/Modal/Modal';
import TrofeuIcon from '../../assets/Icons/TrofeuIcon';
import cores from '../../styles/cores';
import Snackbars from '../../components/Snackbars';
import LotesService from '../../services/LotesService';

const Lotes = () => {
  const [userData, setUserData] = useState({});
  const [isModalOpen, setModalOpen] = useState(false);
  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const [snackbarProps, setSnackbarProps] = useState({
    message: '',
    type: 'success',
  });
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [allLands, setAllLands] = useState<any>();
  const [loteSelecionado, setLoteSelecionado] = useState<any>();
  const [score, setScore] = useState<any>();
  const [changedData, setChangedData] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [recompensaDefaultValue, setRecompensaDefaultValue] = useState('');
  const [empName, setEmpName] = useState('');

  const history = useHistory();
  const location: any = useLocation();

  const fetchData = useCallback(async () => {
    setIsDataLoading(true);
    await LotesService.listAllLotes().then(response => {
      setAllLands(response.lands);

      console.log('log response' ,response)

    });

    setIsDataLoading(false);
  }, []);

  const enterpriseNameAdjustament = () => {
    if (!location.state) return;

    switch (location.state) {
      case 'BAIRRU DAS CEREJEIRAS': {
        setEmpName('CEREJEIRAS');
        break;
      }
      case 'BELLA VISTA FASE': {
        setEmpName('BELLA');
        break;
      }
      case 'RESIDENCIAL CAMPO VERDE': {
        setEmpName('CAMPO VERDE');
        break;
      }
      case 'GREEN PORTUGAL I':
      case 'GREEN PORTUGAL II': {
        setEmpName('PORTUGAL');
        break;
      }
      default: {
        setEmpName(location.state);
      }
    }
  };

  useEffect(() => {
    fetchData();
    enterpriseNameAdjustament();
    console.log('log location' , setEmpName)
  }, []);

  // adicionar um refresh automático periódico

  useEffect(() => {
    setChangedData(false);
    fetchData();
  }, [changedData]);

  const atualizaLote = async () => {
    await LotesService.atualizaLote({
      id_lote: loteSelecionado.id,
      recompensa: score.value,
    });
    setSnackbarProps({
      message: 'Suas alterações foram salvas com sucesso!',
      type: 'success',
    });
    fetchData();
    setSnackbarOpen(true);
  };

  const columns = [
    {
      title: 'Empreendimento',
      field: 'empreendimento',
      defaultFilter: empName || '',
      filtering: true,
      customFilterAndSearch: (term: any, rowData: any) => {
        if (rowData.empreendimento) {
          return rowData.empreendimento
            .toLowerCase()
            .includes(term.toLowerCase());
        }
        return false;
      },
    },
    {
      title: 'Quadra',
      field: 'quadra',
    },
    {
      title: 'Lote',
      field: 'lote',
    },
    {
      title: 'Área',
      field: 'area',
      render: (rowData: { area: string }) => {
        let formatado = rowData.area.replace('.', ',').concat(' m²');
        return formatado;
      },
    },
    {
      title: 'Preço',
      field: 'valor_atual',
      render: (rowData: any) => {
        let formatado = new Intl.NumberFormat('de-DE', {
          style: 'currency',
          currency: 'BRL',
        }).format(rowData.valor_atual);
        formatado = formatado.replace('R$', '');
        formatado = ['R', '$', ' '].concat(formatado.split('')).join('');
        return formatado;
      },
      customSort: (a: { valor_atual: number }, b: { valor_atual: number }) =>
        a.valor_atual - b.valor_atual,
    },
    // {
    //   title: 'Preço/m2',
    //   field: 'precom2',
    // },
    {
      title: 'Recompensa',
      field: 'recompensa',
      filtering: true,
      sorting: false,
      // customSort: (a: any, b: any) => a.recompensa > b.recompensa,
      customFilterAndSearch: (term: any, rowData: any) => {
        if (rowData.recompensa) {
          return rowData.recompensa.toLowerCase().includes(term.toLowerCase());
        }
        return false;
      },
      render: (rowData: any) => {
        if (rowData.recompensa === 'Normal') {
          return <Teste className="normal">Normal</Teste>;
        }
        if (rowData.recompensa === 'Moderado') {
          return <Teste className="moderado">Moderado</Teste>;
        }
        return <Teste className="indefinido">Indefinido</Teste>;
      },
    },
    {
      title: 'Status',
      field: 'status_atual',
    },
  ];

  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [isModalOpen2, setModalOpen2] = useState<boolean>(false);

  const scoreOptions = [
    { title: 'Normal', value: 'Normal' },
    { title: 'Moderado', value: 'Moderado' },
    { title: 'Urgente', value: 'Urgente' },
  ];

  const handleSnackbarClose = (
    event?: React.SyntheticEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const FormModal: any = () => {
    return (
      <Modal
        isOpen={isModalOpen2}
        width={500}
        onClose={() => setModalOpen2(false)}
      >
        <>
          <h1>Editar recompensa do lote</h1>

          <ModalContainer style={{ justifyContent: 'center' }}>
            <div className="input-control">
              <p>Recompensa</p>
              <Autocomplete
                id="combo-box-demo"
                options={scoreOptions}
                defaultValue={{ title: recompensaDefaultValue }}
                getOptionLabel={(option: { title: any }) => option.title}
                style={{ width: 440 }}
                value={score}
                loading
                loadingText="carregando"
                onChange={(e: any) => {
                  setScore({
                    title: e.target.textContent,
                    value: e.target.textContent,
                  });
                }}
                disableClearable
                renderInput={params => (
                  <TextField
                    // label="Status"
                    variant="outlined"
                    style={{ width: '100%' }}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...params}
                    size="small"
                    error={false}
                    helperText=""
                  />
                )}
              />
            </div>
          </ModalContainer>
          <div className="buttons">
            <Botao
              tipo="salvar"
              tamanho="medio"
              className="btn-modal"
              onClick={() => {
                setModalOpen2(false);
                atualizaLote();
              }}
            >
              Salvar
            </Botao>
          </div>
        </>
      </Modal>
    );

    return <></>;
  };

  return (
    <>
      <OpcoesContainer>
        {/* <Botao tipo="adicionar" tamanho="medio" onClick={abreModal1}>
          Atualizar tabela
        </Botao> */}
      </OpcoesContainer>

      <TabelaContainer>
        <Tabela
          title="Lotes"
          isLoading={isDataLoading}
          columns={columns}
          data={allLands}
          onRowClick={(e: any, rowData: any): any => {
            setLoteSelecionado(rowData);
            setRecompensaDefaultValue(rowData.recompensa);
            setModalOpen2(true);
          }}
          excluirClick={(e: any, rowData: any): any => {
            setModalOpen(true);
          }}
          editarClick={(e: any, rowData: any): any => {
            setLoteSelecionado(rowData);
            setRecompensaDefaultValue(rowData.recompensa);
            setModalOpen2(true);
          }}
          pagination
          paginationPageSize={10}
          haveActions="edit"
        />
      </TabelaContainer>
      <Snackbars
        type={snackbarProps.type}
        handleClose={handleSnackbarClose}
        open={snackbarOpen}
      >
        {snackbarProps.message}
      </Snackbars>

      <FormModal />
    </>
  );
};

export default Lotes;
