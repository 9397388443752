import styled from 'styled-components';
import cores from '../../../styles/cores';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100vw;
  height: 100vh;
  overflow-x: auto;
`;

export const DetalhesContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  width: 90%;
  // height: 600px;
  align-self: center;
  border-radius: 8px;
  // border: 1px solid black;
  // padding-top: 8px;
  // padding-bottom: 8px;
  box-shadow: 0px 0px 6px 2px rgba(12, 12, 14, 0.2);
  /* background-color: #DCDCDC; */
  h1 {
    align-self: flex-start;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 24px;
    font-weigth: bold;
  }
  // h1:nth-of-type(1){
  //   align-self: center;
  // }
`;

export const DetalhesHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 50px;
  .btn {
    margin-right: 30px;
    height: 50px;
  }
`;

export const Usuario = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  // border: 1px solid grey;
  h1 {
    align-self: center;
  }
  svg {
  }
`;

export const EnterprisesTable = styled.table`
    // border: 1px solid black;
    align-self: flex-start;
    margin-bottom: 10px;
    // padding-left: 10px;
    margin-left: 10px;
    tr{
      // border: 1px solid black;
    }

    td{
        padding: 10px;
        font-size: 14px;
        cursor: pointer;
        transition: ease 250ms;
        // border: 1px solid black;
    }
    td:hover{
      opacity: 0.8;
      color: ${cores.purple_1}
    }

    .emptyEmps{
      padding-left: 20px;
    }

`

export const TitlesHeader = styled.div`
      display: flex;
      align-items: center;
      // border: 1px solid black;
      margin-bottom: 10px;
      padding-left: 20px;
      padding-right: 10px;
      svg{
        height: 32px;
        width: 32px;
        margin-right: 5px;
      }
`

