/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable prefer-template */

import React, { useCallback, useEffect, useState, useMemo } from 'react';
import TextField from '@material-ui/core/TextField';
import { BiChat } from "react-icons/bi";
import { useHistory, useLocation, Route, Switch } from 'react-router-dom';
import { AiOutlineWarning, AiOutlineWhatsApp } from "react-icons/ai";
import EditIcon from '@material-ui/icons/Edit';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import UpdateIcon from '@material-ui/icons/Update';
import { useSelector } from 'react-redux';
// import { RootState } from '../../storage/rootReducer';
// import MenuLateral from '../../components/MenuLateral/MenuLateral';
// import Header from '../../components/Header/Header';
import Modal from '../../../components/Modal/Modal';
import {
  Container,
  Content,
  HeaderContainer,
  MenuLateralContainer,
  SecondRowContainer,
  TabelaContainer,
  OpcoesContainer,
  DetalhesContainer,
  DetalhesHeader,
  Usuario,
  DetalhesContent,
  ModalContainer,
  BotaoContainer,
  ModalContainerDelete,
  PropostasHeader,
  Status,
  Ball,
  LeadTicketInfo,
  ContatctButton,
} from './styles';

import Botao from '../../../components/Botao/Botao';
import HeaderProposta from '../../../components/HeaderProposta';
import MenuLateralImob from '../../../components/MenuLateralImob/MenuLateralImob';
import Tabela from '../../../components/Tabelas/Tabela';
import { isSignedIn } from '../../../services/auth';
import PropostasService from '../../../services/PropostasService';
import { RootState } from '../../../storage/rootReducer';
import cores from '../../../styles/cores';
import LeadsService from '../../../services/LeadsService';






const Leads: React.FC = () => {

  const [userData, setUserData] = useState({});
  const [hotLead, setHotLead] = useState();
  const [listarLeads, setListarLeads] = useState<any>();
  const [listarLead, setListarLead] = useState<any>();

  const [warningModal, setWarningModal] = useState(false);

  const [leadModal, setLeadModal] = useState(false);

  const history = useHistory();
  const [isDataLoading, setIsDataLoading] = useState(false);

  const [newLead, setNewLead] = useState(false);
  const [infoModal, setInfoModal] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const [leadsInfo, setLeadsInfo] = useState<any>()
  const [leadContact, setLeadContact] = useState<any>([])


  const { state } = useLocation();
  const { token } = useSelector((state: RootState) => state.userReducer);


  // const updateVisto = useCallback(async () => {
  //   const result = await LeadsService.updateTicketOpened();
  //   console.log('update visto ',result);
  // },[])

  const teste_passed = function (seconds_passed: string) {
    let sec_num = parseInt(seconds_passed, 10); // don't forget the second param
    let hours: any = Math.floor(sec_num / 3600);
    let minutes: any = Math.floor((sec_num - (hours * 3600)) / 60);
    let seconds: any = sec_num - (hours * 3600) - (minutes * 60);

    if (hours < 10) { hours = "0" + hours; }
    if (minutes < 10) { minutes = "0" + minutes; }
    if (seconds < 10) { seconds = "0" + seconds; }
    return hours + ':' + minutes + ':' + seconds;
  }

  const timeAwaiting = (created_at: any) => {
    const now = new Date();
    const created_date = new Date(created_at);
    created_date.setHours(created_date.getHours() - 6)

    console.log('criado date', created_date)
    const time_awaiting = (now.getTime() - created_date.getTime()) / 1000
    console.log('time_awaiting date', time_awaiting)// specify value for SECONDS here
    let timeString = teste_passed(time_awaiting.toString());
    let splitTimeString = timeString.split(":");
    const returnedString = `${Number(splitTimeString[0]) - 6 || '0'}h ${splitTimeString[1] || '0'}m ${splitTimeString[2] || '0'}s`
    return returnedString
  }



  const list = useCallback(async () => {
    setIsDataLoading(true);
    try {
      const leads = await LeadsService.listTickets();
      console.log('leads ', leads)
      const formatedData = leads.blipTickets.map((ticket: any) => {
        let obj = {
          id: ticket.id,
          id_lead: ticket.lead.id,
          nome: ticket.lead.nome,
          observacoes: ticket.observacoes,
          entrada_simulada: ticket.entrada_simulada,
          parcelamento_simulado: ticket.parcelamento_simulado,
          visto: ticket.visto,
          status: ticket.last_lead_action.acao === 'Ticket criado' ? 'Não atendido' : ticket.last_lead_action.acao,
          empreendimento: ticket.lead_land.empreendimento,
          lote: ticket.lead_land.lote,
          quadra: ticket.lead_land.quadra,
          quadra_lote: `${ticket.lead_land.quadra}/${ticket.lead_land.lote}`,
          data: ticket.last_lead_action.created_at,
          created_at: ticket.created_at,
          telefone: ticket.lead.telefone
        }

        if (ticket.visto === false) {
          obj.status = 'Não atendido'
          setNewLead(true)
        }

        return obj
      })

      console.log('formatedData ', formatedData)

      const filteredFormatedData = formatedData.filter((lead: { status: string; }) => lead.status === 'Lead criada' || lead.status === 'Ticket criado' || lead.status === 'Em espera' || lead.status === 'Atendido' || lead.status === 'Visita marcada' || lead.status === 'Não atendido')

      console.log('filteredFormatedData ', filteredFormatedData)

      setListarLeads(filteredFormatedData)
      console.log('aq', filteredFormatedData)

      const awaiting_leads = formatedData.filter((lead: { visto: boolean; }) => !lead.visto)
      console.log('awaiting_leads', awaiting_leads)
      const awaiting_leads_date_times = awaiting_leads.map((awaiting_lead: any) => awaiting_lead.created_at)
      console.log('oldest_date', awaiting_leads_date_times)
      const oldest_date = awaiting_leads_date_times.reduce(function (prev: number, current: number) {
        return (prev < current) ? prev : current
      })

      console.log('oldest_date', oldest_date)
      const oldest_awaiting_lead = formatedData.filter((lead: { visto: any; created_at: number; }) => !lead.visto && lead.created_at === oldest_date)
      console.log('oldest_lead_date_time', oldest_awaiting_lead)
      setHotLead(oldest_awaiting_lead[0])

      setIsDataLoading(false);
    } catch (err) {
      setIsDataLoading(false);
    }

    console.log('newLead ', newLead);


    const result = await LeadsService.updateTicketOpened();
    console.log('update visto ', result);


  }, []);

  useEffect(() => {
    if (!isSignedIn()) {
      history.push('/');
    }
    list();
    // if(newLead){
    //   console.log('new lead latest',hotLead)
    //   if(hotLead){
    //     setListarLead(hotLead)
    //     setInfoModal(true)
    //   }
    // }


  }, []);

  useEffect(() => {
    if (newLead) {
      console.log('new lead latest', hotLead)
      if (hotLead) {
        setListarLead(hotLead)
        console.log('new lead latest dentro da condicional', hotLead)
        setInfoModal(true)
      }
    }

  }, [newLead, hotLead])



  const { pathname } = history.location;

  const columns = [
    {
      title: 'Última atualização',
      field: 'data',
      defaultSort: 'desc',
      type: 'date',
    },
    {
      title: 'Nome',
      field: 'nome',
    },
    {
      title: 'Quadra/Lote',
      field: 'quadra_lote',
    },
    {
      title: 'Empreendimento',
      field: 'empreendimento',
    },
    {
      title: 'Status',
      field: 'status',
      lookup: {
        'Atendido': 'Atendido',
        'Visita marcada': 'Visita marcada',
        'Não atendido': 'Não atendido',
        'Desistiu': 'Desistiu',
        'Lead criada': 'Lead criada'
      },
      // initialEditValue: listarLead.sta,
      editable: 'always'
    },
  ];



  const [isModalOpen, setModalOpen] = useState(false);

  function rowSelected(e: any, rowData: any) {
    setInfoModal(true);
    console.log('isso vai para o editar ', listarLeads);
    console.log('info modal ', infoModal);

  }

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setModalOpen(false);
    setOpen(true);
  };

  function deleteTicket(telefone: string) {

    const req = async () => {
      try {
        const response = await fetch('https://msging.net/commands', {
          method: 'POST',
          headers: {
            'Authorization': 'Key dGVzdGVzaXRldml6aWRpZ2l0YWw6YlM2bHF3eFdudmZsek9lWENGR0k=',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            "id": "765b1d9b-3db2-4212-bdc3-f375af5974c6",
            "to": "postmaster@desk.msging.net",
            "method": "get",
            "uri": "/monitoring/waiting-tickets"
          })
        })
        const json = await response.json()
        // console.log('json aq',json)

        setLeadsInfo(json.resource.items.map((resp: any) => {
          return {
            CID: resp.customerIdentity,
            costumer_name: resp.customerName,
            ticket_id: resp.id,
            telefone: telefone
          }
        }))
      } catch (error) {
        console.log(error)
      }
    }
    req()
  }

  useEffect(() => {
    if (leadsInfo) {
      console.log('leadsInfo', leadsInfo)
      const fetchData = async () => {
        const result = await Promise.all(leadsInfo.map(async (resp: any) => {
          try {
            const response = await fetch('https://msging.net/commands', {
              method: 'POST',
              headers: {
                'Authorization': 'Key dGVzdGVzaXRldml6aWRpZ2l0YWw6YlM2bHF3eFdudmZsek9lWENGR0k=',
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                "id": "6649627d-16ee-42a6-a7ee-d3eaa0fcfc01",
                "to": "postmaster@crm.msging.net",
                "method": "get",
                "uri": `/contacts/${resp.CID}`
              })
            })
            const json = await response.json()
            return json
          } catch (error) {
            console.log(error)
            return null;
          }
        }))
        setLeadContact(result.filter(item => item !== null))
      }

      fetchData()
    } else {
      console.log('caiu no else')
    }
  }, [leadsInfo])

  useEffect(() => {
    console.log('leadcontact', leadContact)
  }, [leadContact])

  useEffect(() => {
    if (leadsInfo && leadContact) {
      console.log('aqqqqq', leadsInfo)
      console.log('aqwdsads', leadContact)

      const idLeadsInfo = leadsInfo.map((resp: any) => {
        return resp.telefone
      })
      console.log(idLeadsInfo)
      const resultadoFiltro = leadContact.filter((lead: any) => idLeadsInfo.includes(lead.resource.phoneNumber))
      console.log('vai brasil', resultadoFiltro)

      if (resultadoFiltro) {
        const deleteTicketID = leadsInfo.filter((lead: any) => resultadoFiltro[0].resource.phoneNumber.includes(lead.telefone))
        console.log('deu certo', deleteTicketID)
        const req = async () => {
          try {
            const response = await fetch('https://msging.net/commands', {
              method: 'POST',
              headers: {
                'Authorization': 'Key dGVzdGVzaXRldml6aWRpZ2l0YWw6YlM2bHF3eFdudmZsek9lWENGR0k=',
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                "id": "43ff390c-f349-11ed-a05b-0242ac120003",
                "to": "postmaster@desk.msging.net",
                "method": "set",
                "uri": "/tickets/change-status",
                "type": "application/vnd.iris.ticket+json",
                "resource": {
                  "id": `${deleteTicketID[0].ticket_id}`,
                  "status": "ClosedClient"
                }
              })
            })
            const json = await response.json()
            console.log('json aq', json)

          } catch (error) {
            console.log(error)
          }
          
        }
        req()
      }
    }
  }, [leadContact]);


  const WarningModal: any = () => {
    if (warningModal) {
      return (
        <Modal
          isOpen={warningModal}
          width={500}
          onClose={() => setWarningModal(false)}
        >
          <>
            <h1>Sem permissão!</h1>
            <>
              <div className="formWrapper" style={{ height: 200 }}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 20 }}>
                  <AiOutlineWarning size={140} color="red" />
                </div>
                <p style={{ fontSize: 20 }}>
                  Você não pode alterar esse status!
                </p>
              </div>
            </>
          </>
        </Modal>
      );
    }

    return <></>;
  };

  const InfoModal: any = () => {
    if (infoModal && listarLead) {
      return (
        <Modal
          isOpen={infoModal}
          width={500}
          onClose={() => setInfoModal(false)}
        >
          <>
            <h1>Lead</h1>
            <>
              <LeadTicketInfo >
                <p>
                  <span>Nome: </span> {listarLead ? listarLead.nome : ''}
                </p>
                <p>
                  <span>Tempo de espera: </span> {listarLead ? timeAwaiting(listarLead.data) : ''}
                </p>
                <p>
                  <span>Entrada simulada:</span> {listarLead ? listarLead.entrada_simulada : ''}
                </p>
                <p>
                  <span>Parcelamento simulado:</span> {listarLead ? listarLead.parcelamento_simulado : ''}
                </p>
                <p>
                  <span>Empreendimento: </span> {listarLead ? listarLead.empreendimento : ''}
                </p>
                <p>
                  <span>Quadra/Lote: </span> {listarLead ? listarLead.quadra_lote : ''}
                </p>
                <p>
                  <span>Telefone: </span> {listarLead ? listarLead.telefone

                    : ''}
                </p>
                <p>
                  <span>Observacoes: </span> {listarLead ? listarLead.observacoes : ''}
                </p>
              </LeadTicketInfo>
              {/* <div className="btn-modalfull">
                    <Botao
                      tipo="salvar"
                      tamanho="fullwidth"
                      onClick={() => {setInfoModal(false) }}
                    >
                      OK
                    </Botao>
                  </div> */}
              <ContatctButton>
                <Botao
                  tipo="salvar"
                  tamanho="fullwidth"
                  onClick={() => deleteTicket(listarLead.telefone)}
                >
                  <a href={`https://api.whatsapp.com/send?phone=${listarLead.telefone}`}
                    target="_blank" rel="noreferrer">
                    Whatsapp <BiChat size={28} style={{ marginLeft: 5 }} />
                  </a>
                </Botao>
              </ContatctButton>
            </>
          </>
        </Modal>
      );
    }

    return <></>;
  };

  return (
    <>
      <HeaderProposta />


      <Content>

        <MenuLateralContainer>
          <MenuLateralImob
            className="menuLateral"
            currentPage={{ pathname, data: userData }}
            data={userData}
            style={{ marginTop: '100px' }}
            notification={newLead}
          />
        </MenuLateralContainer>
        <TabelaContainer>
          <Tabela
            title="Leads"
            haveActions="none"
            columns={columns}
            isLoading={isDataLoading}
            data={listarLeads}
            onRowClick={(e: any, rowData: any): any => {
              setListarLead(rowData);
              setOpenModal(true);
              rowSelected(e, rowData);
            }}
            updateCellCallbackFunction={
              async (newValue, oldValue, rowData) => {
                console.log('newValue', newValue)

                if (newValue !== oldValue) {
                  const params = {
                    "id_lead": rowData.id_lead,
                    "id_user": 'fb95ded4-d440-4701-86c0-998d1e8b6acb',
                    "acao": newValue,
                    "land_id": 0
                  }

                  console.log('rowData ', rowData)

                  const savedNewAction = await LeadsService.createLeadAction(params)

                  if (savedNewAction) {
                    listarLeads.forEach((e: {
                      status: any; id: string;
                    }) => {
                      if (e.id === rowData.id) {
                        e.status = newValue
                      }
                    })
                  }
                  setListarLeads(listarLeads);
                  console.log('listarLeads dps de atualziar', listarLeads)
                }

              }
            }
            excluirClick={() => {
              console.log('');
            }}
            editarClick={(e: any, rowData: any): any => {
              setListarLead(rowData);
              rowSelected(e, rowData);
            }}
            pagination
            paginationPageSize={10}
          />
        </TabelaContainer>
        {/* <LeadModal /> */}
        <InfoModal />
        <WarningModal />
      </Content>

    </>
  );
};

export default Leads;
