import { useEffect, useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import { useHistory, useLocation } from 'react-router-dom';
import { botoes } from '../../styles/tema';
import Logo from '../../assets/svg/LogoBairru';
import {
  FormContainer,
  Background,
  BotaoContainer,
  Container,
  Icone,
  ModalContainer,
  Title,
  Paragraph,
  IconeErro,
} from './styles';
import InputField from '../../components/Inputs/InputField';
import Botao from '../../components/Botao/Botao';
import Modal from '../../components/Modal/Modal';
import UserService from '../../services/UserService';

const ChangePassword = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);
  const [errorTempPassword, setErrorTempPassword] = useState(false);
  const [errorConfirmPassword, setErrorConfirmPassword] = useState(false);
  const [errorMessageTempPassword, setErrorMessageTempPassword] = useState('');
  const [errorMessagePassword, setErrorMessagePassword] = useState('');
  const [errorMessageConfirmPassword, setErrorMessageConfirmPassword] = useState('');
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [tempPassword, setTempPassword] = useState('')
  const [passwordsMatch, setPasswordsMatch] = useState(false);

  const errorMessages = {
    campoObrigatorio: 'Campo obrigatório!'
  };
  const history = useHistory();

  const FormModal: any = (): any => {
    return (
      <ModalContainer>
        <Modal
          isOpen={isModalOpen}
          width={380}
          onClose={() => setModalOpen(false)}
        >
          <>
            <Title>Sucesso!</Title>
            <Paragraph>Troca de senha efetuada.</Paragraph>
            <IconeErro>
              <CheckIcon htmlColor='green' />
            </IconeErro>
            <BotaoContainer>
              <Botao
                tipo={botoes.tipo.salvar}
                tamanho={botoes.tamanho.fullwidth}
                onClick={() => history.push('/')}
              >
                Continuar
              </Botao>
            </BotaoContainer>
          </>
        </Modal>
      </ModalContainer>
    );
  };

  useEffect(() => {
    if (password && confirmPassword) {
      setPasswordsMatch(password === confirmPassword);
    } else {
      setPasswordsMatch(false);
    }
  }, [password, confirmPassword]);


  const change = async () => {
    if (errorTempPassword) {
      setErrorTempPassword(true);
      setErrorMessageTempPassword(errorMessages.campoObrigatorio);
      return
    }
    if (errorPassword) {
      setErrorPassword(true);
      setErrorMessagePassword(errorMessages.campoObrigatorio);
      return
    }
    if (errorConfirmPassword) {
      setErrorConfirmPassword(true);
      setErrorMessageConfirmPassword(errorMessages.campoObrigatorio);
      return
    }
    if (tempPassword === '') {
      setErrorTempPassword(true);
      setErrorMessageTempPassword(errorMessages.campoObrigatorio);
      return
    }
    if (password === '') {
      setErrorPassword(true);
      setErrorMessagePassword(errorMessages.campoObrigatorio);
      return
    }
    if (confirmPassword === '') {
      setErrorConfirmPassword(true);
      setErrorMessageConfirmPassword(errorMessages.campoObrigatorio);
      return
    }
    if (!passwordsMatch) {
      setErrorPassword(true);
      setErrorMessagePassword('');
      setErrorConfirmPassword(true);
      setErrorMessageConfirmPassword('As senhas não coincidem!');
      return
    }

    try {
      setModalOpen(true)
      await UserService.changePassword(tempPassword, password)
    } catch (err) {
      console.log(err)
    }
  };

  const tempPasswordValidation = (password: string) => {
    setTempPassword(password)
    if (password.length > 4 || password.length <= 3) {
      setErrorTempPassword(true);
      setErrorMessageTempPassword('A senha temporária tem um total de 4 dígitos.');
    }
    else {
      setErrorTempPassword(false);
      setErrorMessageTempPassword('');
    }
  }

  const passwordValidation = (password: string) => {
    setPassword(password)
    if (password.length <= 5) {
      setErrorPassword(true);
      setErrorMessagePassword('A senha precisa ter no mínimo 6 dígitos.');
    }
    else {
      setErrorPassword(false);
      setErrorMessagePassword('');
    }
  }

  return (
    <>
      <Background>
        <Icone>
          <Logo />
        </Icone>
        <FormContainer className="digite-email">
          <Container>
            <h2> Redefinição de senha</h2>
            <p className="textoAux">
              Defina uma nova senha.
            </p>
            <>
              <InputField
                tipo="outlined"
                label="Senha temporária"
                type="password"
                className="input"
                error={errorTempPassword}
                helperText={errorMessageTempPassword}
                value={tempPassword}
                onChange={(e: any) => tempPasswordValidation(e.target.value)}
              />
              <InputField
                tipo="outlined"
                label="Nova Senha"
                type="password"
                className="input"
                error={errorPassword}
                helperText={errorMessagePassword}
                value={password}
                onChange={(e: any) => passwordValidation(e.target.value)}
              />
              <InputField
                tipo="outlined"
                label="Confirme nova senha"
                type="password"
                className="input"
                error={errorConfirmPassword}
                helperText={errorMessageConfirmPassword}
                value={confirmPassword}
                onChange={(e: any) => setConfirmPassword(e.target.value)}
              />
              <BotaoContainer>
                <Botao
                  tipo={botoes.tipo.login}
                  tamanho={botoes.tamanho.fullwidth}
                  onClick={change}
                >
                  Enviar
                </Botao>
              </BotaoContainer>
            </>
          </Container>
        </FormContainer>
        <FormModal />
      </Background>
    </>
  );
};

export default ChangePassword;
