import React, { ReactElement } from 'react';
import FileIcon from '../../assets/Icons/FileIcon';
import { FilePreview, FilesContainer, Preview } from './styles';

interface IClickableIcon {
  isImage: boolean;
  url: string;
  nome?:string;
}

const PreviewFile = (props: IClickableIcon) => {
  const { isImage, url,nome } = props;
  return (
      <FilesContainer>
      {isImage ? (
        <div onClick={()=> window.open(url, "_blank")} onKeyDown = {()=> window.open(url, "_blank")} role = "button" tabIndex={0} className='imgContainer'>
          <img src={url} alt = "imagem" className='imgFile' />
          <p className='nome'>{nome || 'Arquivo'}</p>
        </div>
      ) :  (
        <FilePreview onClick={()=> window.open(url, "_blank")}>
            <Preview>
              <FileIcon className='viewer'/>
            </Preview>
            <p className='nome'>{nome || 'Arquivo'}</p>
        </FilePreview>
      )
        }
      </FilesContainer>
  )
};

PreviewFile.defaultProps = {
    nome:''
  };

export default PreviewFile;
