import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { IoLogOutOutline } from 'react-icons/io5';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useDispatch } from 'react-redux';
import Botao from '../Botao/Botao';
import Modal from '../Modal/Modal';
import {
  Container,
  Sair,
  UpperBar,
  LowerBar,
  Logo,
  LogoContainer,
  ModalContainer,
  BotaoContainer,
} from './styles';
import { userLogout } from '../../storage/modules/user/userAction';

interface LocationState {
  fromEdit?: boolean;
}

const Header = (e: any) => {
  const [menuSair, setModalSair] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [nomePage, setNomePage] = useState<any>('');
  

  const history = useHistory();
  const location = useLocation<LocationState>();
  const dispatch = useDispatch();

  const signOut = () => {
    setModalOpen(false)
    dispatch(userLogout())
    history.replace('/')
  }

  function goBack() {
    if (location.state && location.state.fromEdit) {
      history.replace('/painel/marketplace');
    } else {
      history.goBack();
    }
  }

  function sairMenu() {
    setModalOpen(true);
  }

  function sairAction(logout: boolean) {
    if (logout) {
      dispatch(userLogout())
      history.replace('/')
    }
    setModalOpen(false);
    history.push('/');
  }

  function nomeAllPages () {
    switch (history.location.pathname) {
      case '/painel/carrinho':
        return 'Testes';
      case '/painel/corretores':
        return 'Corretores';
      case '/painel/corretores/adicionar':
        return 'Corretores';
      case '/painel/corretores/editar':
        return 'Corretores';
      case '/painel/corretores/detalhes/':
        return 'Corretores';
      case '/painel/empreendimentos':
        return 'Empreendimentos';
      case '/painel/empreendimentos/detalhes':
        return 'Empreendimentos';
      case '/painel/faq':
        return 'Faq';
      case '/painel/imobiliarias':
        return 'Imobiliárias';
      case '/painel/imobiliarias/adicionar':
        return 'Imobiliárias';
      case '/painel/imobiliarias/editar':
        return 'Imobiliárias';
      case '/painel/imobiliarias/detalhes':
        return 'Imobiliárias';
      case '/painel/lotes':
        return 'Lotes';
      case '/painel/marketplace':
        return 'Marketplace';
      case '/painel/marketplace/editar':
        return 'Marketplace';
      case '/painel/marketplace/detalhes':
      return 'Marketplace';
        case '/painel/marketplace/adicionar':
        return 'Marketplace';
      case '/painel/pedidos':
        return 'Pedidos';
      case '/painel/pedidos/adicionar':
        return 'Pedidos';
      case '/painel/pedidos/editar':
        return 'Pedidos';
      case '/painel/pedidos/detalhes':
        return 'Pedidos';
      case '/painel/proposta':
        return 'Proposta';
      case '/painel/premios':
        return 'Prêmios';
      case '/painel/ranking':
        return 'Ranking';
      case '/painel/propostas-admin':
        return 'Propostas';
      case '/painel/propostas-admin/editar':
        return 'Propostas';
      case '/painel/leads':
        return 'Imobiliárias';
      default:
        return '';
  }
}

useEffect(() => {
 const nome =  nomeAllPages();
 setNomePage(nome);
}, [history.location.pathname])

  const SairModal: any = (): any => {

    return (
      <Modal
        isOpen={isModalOpen}
        width={380}
        onClose={() => setModalOpen(false)}
      >
        <>
          <ModalContainer>
            <h1> Você deseja sair mesmo?</h1>
            <BotaoContainer>
              <Botao
                tipo="salvar"
                tamanho="medio"
                className="btn"
                onClick={() => sairAction(true)}
              >
                Sim
              </Botao>
              <Botao
                tipo="excluir"
                tamanho="medio"
                className="btn"
                onClick={() => setModalOpen(false)}
              >
                Não
              </Botao>
            </BotaoContainer>
          </ModalContainer>
        </>
      </Modal>
    );
  };

  return (
    <Container>
      <UpperBar>
        <LogoContainer>
          <Logo />
        </LogoContainer>
        <Sair onClick={sairMenu}>
          <h3>
            <strong>Sair</strong>
          </h3>
          <IoLogOutOutline className="icone" />
        </Sair>
      </UpperBar>
      <LowerBar>
        <ArrowBackIcon onClick={goBack} className="voltar-pagina-principal" />
        <h2>{nomePage || ''}</h2>
      </LowerBar>
      <SairModal />
    </Container>
  );
};

export default Header;
