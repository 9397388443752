import * as React from "react"
import { SVGProps } from "react"

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg width="80px" height="80px" xmlns="http://www.w3.org/2000/svg" >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      viewBox="0 0 114.88 122.88"
      width="80px"
      height="80px"
      
    >
      <path
        fillRule="evenodd"
        d="M108.58 118.8h-3.16a.33.33 0 0 1-.33-.33V96.65a2.13 2.13 0 0 0-.22-1l-.09-.14-.11-.12a1.32 1.32 0 0 0-.51-.31L104 95a2.39 2.39 0 0 0-.62-.08H73.76a1.91 1.91 0 0 0-1 .23l-.13.08-.13.1a1.79 1.79 0 0 0-.42 1.29v21.81a.33.33 0 0 1-.33.33h-3.57a.36.36 0 0 1-.23-.09l-5.49-5.51a.34.34 0 0 1-.13-.27V70.53a1.46 1.46 0 0 1 1.61-1.65h11.69a.34.34 0 0 1 .34.33v12.7a3.3 3.3 0 0 0 3.31 3.38H101a3.36 3.36 0 0 0 3.4-3.39V69.2a.33.33 0 0 1 .33-.33h3.77a1.45 1.45 0 0 1 1.66 1.6v46.67a1.44 1.44 0 0 1-1.59 1.65ZM61.73 11.9l20.33 19H61.73v-19ZM21.51 66.26a2.21 2.21 0 0 0-2.07 2.3 2.17 2.17 0 0 0 2.07 2.29H47v-4.59Zm.69 15.29a2.2 2.2 0 0 0-2.06 2.29 2.17 2.17 0 0 0 2.06 2.29h25.51v-4.58Zm-.72-45.85a2.21 2.21 0 0 0-2.07 2.3 2.17 2.17 0 0 0 2.07 2.29h22.07A2.19 2.19 0 0 0 45.61 38a2.17 2.17 0 0 0-2.06-2.29Zm0-15.28a2.2 2.2 0 0 0-2.07 2.29 2.17 2.17 0 0 0 2.1 2.29h11.43A2.2 2.2 0 0 0 35 22.71a2.17 2.17 0 0 0-2.06-2.29Zm0 30.56a2.21 2.21 0 0 0-2.07 2.3 2.17 2.17 0 0 0 2.07 2.29h39.91a2.21 2.21 0 0 0 2.06-2.29A2.18 2.18 0 0 0 61.39 51Zm71.89-17.3a3.39 3.39 0 0 0-2.46-3.27L61 1.25A3.34 3.34 0 0 0 58.34 0H6.09A6.08 6.08 0 0 0 0 6.08v104.17a6.08 6.08 0 0 0 6.09 6.08h41v-6.79H6.8V6.77h48.13v27.56a3.41 3.41 0 0 0 3.41 3.41h28.24v14.62h6.79V33.68ZM83.28 104.2H95a1.78 1.78 0 0 1 1.75 1.8 1.78 1.78 0 0 1-1.75 1.76H83.28A1.78 1.78 0 0 1 81.5 106a1.78 1.78 0 0 1 1.78-1.78Zm0 7.43H95a1.78 1.78 0 0 1 1.78 1.78 1.78 1.78 0 0 1-1.78 1.77H83.28a1.78 1.78 0 0 1-1.78-1.77 1.78 1.78 0 0 1 1.78-1.78Zm-3.21-42.24h20.09v10.68a1.83 1.83 0 0 1-1.82 1.82H81.88a1.82 1.82 0 0 1-1.81-1.82V69.39ZM76 98.3h25.69a.3.3 0 0 1 .3.29v19.92a.31.31 0 0 1-.3.29H76a.29.29 0 0 1-.29-.29V98.6a.29.29 0 0 1 .29-.3ZM62.1 64.41h48.77a4 4 0 0 1 4 4v50.43a4 4 0 0 1-4 4H65.58l-.11-.11-7.3-6.54-.48-.32V68.4a4.16 4.16 0 0 1 4.41-4Z"
        fill="#afadad"
        className="color000 svgShape"
      />
    </svg>
  </svg>
)

export default SvgComponent