import styled from 'styled-components';
import cores from '../../../styles/cores';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100vw;
  height: 100vh;
  overflow-x: auto;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: center;
  width: 100%;
  margin-top: 50px;
  // height: 100%;
`;


export const DetalhesContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  min-width: 80%;
  max-width: 80%;
  margin: 0% 60% -10% 60%;
  height: fit-content;
  border-radius: 8px;
  box-shadow: 0px 0px 6px 2px rgba(12, 12, 14, 0.2);
  
  
  .titulos {
    border-radius: 0px;
  }

  h1 {
    font-size: 18px;
    font-weight: bold;
  }

  .endereco {
    width: 100%;
    align-self: flex-start;
    h2 {
      font-size: 20px;
      font-weigth: bold;
      margin-top: 15px;
      margin-bottom: 20px;
    }
  }

  .first-row {
  }
`;

export const DetalhesContent = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  max-width: 100%;
  margin-left: 0px;
  margin-bottom: 40px;

  .acordion-summary {
    margin-top: 10px;
    border: 0px solid black;

    svg {
      font-size: 32px;
    }
  }

  .MuiAccordion-root {
    border: 0px solid black;
    box-shadow: none !important;

    .MuiAccordionSummary-root {
      // box-shadow: 0px 0px 2px 2px rgba(12, 12, 14, 0.4) !important;
    }

    .MuiAccordionDetails-root {
      margin-left: 0px;
      border: 0px solid black;
      padding: 5px;
    }
  }

  .acordion {
    margin-top: 10px;
    box-shadow: 0;

    ul {
      display: flex;
    }

    li {
      display: flex;

      border-bottom: 0px solid rgba(10, 10, 200, 0.1);
      padding-bottom: 5px;
      margin-left: 0;
      margin: 10px;
      width: 200px;
    }
  }

  .item {
    display: flex;
    flex-direction: column;
    border: 0px solid black;
    width: fit-content;
    margin: 10px 25px;

    h2 {
      margin-bottom: 5px;
      font-size: 18px;
      font-weight: bold;
    }
  }
`;



export const DetalhesHeader = styled.div`
  display: flex;
  // border-bottom: 2px solid ${cores.black};
  background-color: ${cores.purple_2};
  justify-content: space-between;
  align-items: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  margin-bottom: 30px;
  padding: 0 10px;
  color: white;
  .btn {
    margin-right: 30px;
    height: 30px;
  }
  img {
    border: 0px solid black;
  }
`;

export const Imobiliaria = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0px;

  h1 {
    align-self: center;
    font-weight: normal;
    font-size: 18px;
  }

  span{
    font-size: 24px;
    font-weight: bold;
  }
  svg {
  }
`;

export const EnterprisesTable = styled.table`
    // border: 1px solid black;
    align-self: flex-start;
    margin-bottom: 10px;
    // padding-left: 10px;
    margin-left: 10px;
    width: 100%;
    tr{
      // border: 1px solid black;
    }

    td{
        padding: 10px;
        font-size: 14px;
        cursor: pointer;
        transition: ease 250ms;
        // border: 1px solid black;
    }
    td:hover{
      opacity: 0.8;
      color: ${cores.purple_1}
    }
    .emptyEmps{
      padding-left: 20px;
    }

`

export const TitlesHeader = styled.div`
      display: flex;
      align-items: center;
      // border: 1px solid black;
      margin-bottom: 10px;
      padding-left: 10px;
      padding-right: 10px;
      svg{
        height: 32px;
        width: 32px;
        margin-right: 5px;
      }
`