/* eslint-disable camelcase */
import React, { useCallback, useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { useHistory } from 'react-router-dom';
import { Autocomplete } from '@material-ui/lab';
import PedidosService from '../../services/PedidosService';
import {
  Content,
  TabelaContainer,
  ModalContainer,
} from './styles';
import Tabela from '../../components/Tabelas/Tabela';
import Botao from '../../components/Botao/Botao';
import Modal from '../../components/Modal/Modal';
import Snackbars from '../../components/Snackbars';

const Pedidos: React.FC = () => {
  const [pageMenu, setPageMenu] = useState('principal');
  const [snackbarProps, setSnackbarProps] = useState({
    message: '',
    type: 'success',
  });
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [list, setList] = useState();
  const history = useHistory();
  const [isModalOpen2, setModalOpen2] = useState<boolean>(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [editarPedido, setEditarPedido] = useState<any>('')
  const [atualizaTabela, setAtualizaTabela] = useState<any>('')

  const dataList = useCallback(async () => {
    setIsDataLoading(true);
    await PedidosService.listAllPedidos().then(response => {
      const ordenedList = response.request.sort((a: any, b: any) => {
        const dataA = new Date(a.updated_at);
        const dataB = new Date(b.updated_at);
        return dataB.getTime() - dataA.getTime()
      });
      setList(ordenedList);
    });
    setIsDataLoading(false);
  }, []);

  const statusOptions = [
    { title: 'Entregue', value: 'Entregue' },
    { title: 'Finalizado', value: 'Finalizado' },
    { title: 'Aberto', value: 'Aberto' },
  ];
  const [status, setStatus] = useState(statusOptions[0]);

  useEffect(() => {
    dataList();
  }, [atualizaTabela]);

  function voltarTela() {
    history.goBack();
  }

  const columns = [
    {
      title: 'Data do pedido',
      field: 'created_at',
      type: 'date',
    },
    {
      title: 'Última atualização',
      field: 'updated_at',
      type: 'date',
    },
    {
      title: 'Cod do pedido',
      field: 'id',
    },
    {
      title: 'Corretor',
      field: 'user.name',
    },
    {
      title: 'Status',
      field: 'status',
    },
  ];

  function editSelected(e: any, rowData: any) {
    history.push({ pathname: '/painel/pedidos/detalhes', state: { rowData } });
  }

  const [isModalOpen, setModalOpen] = useState(false);
  function rowSelected(e: any, rowData: any) {

    history.push({ pathname: '/painel/pedidos/detalhes', state: { rowData } });
  }
  function adicionarPedido() {
    setPageMenu('adicionar');
  }

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setModalOpen(false);
    setOpen(true);
  };

  const handleSnackbarClose = (
    event?: React.SyntheticEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  const AtualizaPedido = async (dadosPedido: any, status: any) => {
    if (status.value === '' || status.value === dadosPedido.status) {
      setModalOpen2(false);
    }
    const resp = await PedidosService.updatePedido(dadosPedido.id, status.value).then(() =>
      setTimeout(() => {
        setModalOpen2(false)
      }, 1000))
    setAtualizaTabela(resp)
  }

  const FormModal: any = () => {
    return (
      <Modal
        isOpen={isModalOpen2}
        width={500}
        onClose={() => setModalOpen2(false)}
      >
        <>
          <h1>Editar status do pedido </h1>

          <ModalContainer style={{ justifyContent: 'center' }}>
            <div className="input-control">
              <h2>Status</h2>
              <Autocomplete
                id="combo-box-demo"
                options={statusOptions}
                getOptionLabel={(option) => option.title}
                style={{ width: 450 }}
                value={status}
                loading
                loadingText="carregando"
                onChange={(e: any, newValue: any) => {
                  setStatus(newValue);
                }}
                disableClearable
                renderInput={params => (
                  <TextField
                    variant="outlined"
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...params}
                    size="small"
                    error={false}
                    helperText=""
                  />
                )}
              />
            </div>
          </ModalContainer>
          <div className="buttons">
            <Botao
              tipo="salvar"
              tamanho="medio"
              className="btn-modal"
              onClick={() => {
                AtualizaPedido(editarPedido, status)
              }}
            >
              Salvar
            </Botao>
          </div>
        </>
      </Modal>
    );
  };

  return (
    <>
      <Content>
        <TabelaContainer>
          <Tabela
            title="Pedidos"
            haveActions="edit"
            isLoading={isDataLoading}
            columns={columns}
            data={list}
            onRowClick={(e: any, rowData: any): any => {
              setList(rowData);
              rowSelected(e, rowData);
            }}
            excluirClick={(e: any, rowData: any): any => {
              setDeleteModalOpen(true);
            }}
            editarClick={(e: any, rowData: any): any => {
              setEditarPedido(rowData);
              setModalOpen2(true);
            }}
            pagination
            paginationPageSize={10}
          />
        </TabelaContainer>

        <Snackbars
          type={snackbarProps.type}
          handleClose={handleSnackbarClose}
          open={snackbarOpen}
        >
          {snackbarProps.message}
        </Snackbars>
        <FormModal />
      </Content>
    </>
  );
};

export default Pedidos;
