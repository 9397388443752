import styled, { css } from 'styled-components';
import cores from '../../../styles/cores';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100vh;
  overflow-x: auto;
`;

export const DetalhesContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  width: 75%;
  height: fit-content;
  align-self: center;
  border-radius: 8px;
  box-shadow: ${cores.shadow_1};

  h1 {
    align-self: flex-start;
    margin: 2rem;
    font-size: 23px;
    font-weight: bold;
    color: #6b6b6b;
    display: flex;
    align-items: center;
  }
`;

export const DetalhesHeader = styled.div`
  display: flex;
  border: 0px solid black;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;

  .btn {
    margin-right: 30px;
    margin-left: 30px;
    height: 50px;
  }
`;

export const Usuario = styled.div`
  display: flex;
  border: 0px solid black;
  align-items: center;
  justify-content: center;
  margin-left: 20px;

  h1 {
    border: 0px solid black;
    align-self: center;
    width: 100%;
    margin-left: 0;
  }
  img {
    border: 0px solid black;
  }

  svg {
    border: 0px solid black;
  }
`;

export const BotoesContainer = styled.div`
  display: flex;
  border: 0px solid black;
  align-self: flex-end;
  padding: 0;
  width: 100%;

  .btn-salvar {
    margin: 1rem 0rem;
    width: 100%;
    height: 3rem;
  }

  .btn-exluir {
  }
`;

export const TrocarFoto = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 1px solid lightgrey;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(-45px, 45px);
  background-color: white;
  cursor: pointer;
  svg {
    font-size: 26px;
    fill: ${cores.purple_1};
  }
`;
export const Content = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  height: 100%;
  margin: 0 auto;
`;

export const DetalhesContent = styled.div`
  display: flex;

  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  max-width: 100%;
  padding: 1rem 1.5rem 1.5rem;
  
  .item {
    display: flex;
    flex-direction: column;
    border: 0px solid black;
    width: fit-content;
    margin-top: 10px;
    margin-right: 50px;
  }

  .endereco {
    width: 100%;
    border: 0px solid black;
    font-size: 18px;
    margin: 10px 0;
    padding-left: 10px;
    h2 {
      font-weight: bold;
    }
  }

  .div-inputs-preço-categoria {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
  }
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 0px;
  padding: 0;
  max-width: 100%;
  width: 100%;
  align-items: center;
  margin: 0.5rem 0rem;

  .input {
    width: 100%;
  }

  .visually-hidden {
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
    opacity: 0;
  }

  &.descricao-container {
    border: 0px solid black;
    display: flex;
    flex-direction: column;
    width: 92%;
    h2 {
      font-size: 18px;
      font-weight: bold;
    }
  }
`;

export const UploadLabel = styled.label`
  display: flex;
  width: 100%;
  height: 200px;
  border: 2px dashed #ccc;
  margin: 20px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

// ${({ dragEnter }) =>
//   dragEnter &&
//   css`
//     border-color: #666;
//   `}

export const InputsContent = styled.div`
  display: flex;

  flex-wrap: wrap;
  max-width: 100%;
  width: 100%;

  padding: 0;

  .dropbox {
    border: 0px solid black;
    align-self: flex-start;
    width: 100%;
    .picked-photos {
      display: flex;
      flex-wrap: wrap;
      align-self: center;
      width: 100%;
      // max-width: 340px;
      height: inherit;
      border: 1px solid #ccc;
      border-radius: 4px;
      padding: 6px 8px;

      li {
        list-style-type: none;
        margin-right: 4px;
        margin-bottom: 4px;
      }
      ul {
      }
    }

    .enviar-fotos {
      display: flex;
      border: 0px solid black;
      justify-content: center;
      height: 60px;

      .enviar-fotos-btn {
        border: 0px solid black;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;

        svg {
          margin-right: 5px;
        }
      }
    }
  }
`;
