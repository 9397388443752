import styled from 'styled-components';

export const FilePreview = styled.div`
  display: flex;
  margin-top: 10px;
  margin-right: 10px;
  flex-direction: column;
  justify-content: center;
  align-items:center;
  width: 150px;
  height: 150px;
  cursor: pointer;
  border-radius: 8px;
  padding: 10px;
  background: transparent;
//   border: 1px solid black;

  svg{
    //   border: 1px solid black;
      align-selt:center;
  }

`;

export const Preview = styled.div `
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    border-radius: 8px;
    align-items: center;
    // border: 1px solid black;
    justify-content: center;
    background-color: rgba(25, 25, 25, 0.15);
    &.viewer{
        border: 1px solid black;
    }
`;

export const FilesContainer = styled.div`
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 10px 0;

        .imgContainer{
            width: 150px;
            height: 130px;
            border-radius:8px;
            background-size: contain;
            margin-bottom: 10px;
        }

        .imgFile{
            width: inherit;
            height: inherit;
            cursor: pointer;
            border-radius:8px;
        }

        &:hover{
            opacity: 0.8;
        }

        .nome{
            margin-top: 5px;
            font-size: 11px;
            word-break: break-all;
        }

`;