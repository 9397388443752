import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  border: 0px solid green;
`;

export const DetalhesContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  width: 85%;
  height: fit-content;
  align-self: center;
  border-radius: 8px;
  box-shadow: 0px 0px 6px 2px rgba(12, 12, 14, 0.2);

  h1 {
    align-self: flex-start;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 20px;
    font-size: 20px;
    font-weight: bold;
  }
  &.carousel {
    width: 800px;
    height: 400px;
    margin-bottom: 50px;
    h1 {
      margin: 10px;
      align-self: center;
    }
  }
`;

export const DetalhesHeader = styled.div`
  border: 0px solid black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  .btn {
    margin-right: 30px;
    height: 50px;
  }
  .headerButtons{
    display: flex;
  }
`;

export const Empreendimento = styled.div`
  border: 0px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0px;

  h1 {
    /* margin-left: -5%; */
    align-self: center;
  }
  svg {
  }
`;

export const BotoesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DetalhesContent = styled.div`
  display: flex;
  border: 0px solid black;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  max-width: 100%;
  width: fit-content;
  margin-left: 20px;
  margin-bottom: 20px;
  .item {
    border: 0px solid black;
    display: flex;
    flex-direction: column;
    width: fit-content;
    flex-wrap: wrap;
    margin: 10px 0;
    margin-right: 25px;
    p {
      word-wrap: break-world;
      line-height: 24px;
      max-width: 800px;
    }

    h2 {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 8px;
    }
  }

  .endereco {
    h1 {
      font-size: 16px;
    }
  }

  .item:last-child {
    margin-left: 0;
  }

  .item:nth-of-type(1) {
    margin-left: 0;
  }

  .acordion-summary {
    margin-top: 10px;
    border: 0px solid black;

    svg {
      font-size: 32px;
    }
  }

  .MuiAccordion-root {
    border: 0px solid black;
    box-shadow: none !important;

    .MuiAccordionSummary-root {
      // box-shadow: 0px 0px 2px 2px rgba(12, 12, 14, 0.4) !important;
    }

    .MuiAccordionDetails-root {
      margin-left: 0px;
      border: 0px solid black;
      padding: 5px;
    }
  }

  .acordion {
    margin-top: 10px;
    box-shadow: 0;

    ul {
      display: flex;
    }

    li {
      display: flex;

      border-bottom: 0px solid rgba(10, 10, 200, 0.1);
      padding-bottom: 5px;
      margin-left: 0;
      margin: 10px;
      width: 200px;
    }
  }

  .descricao-container {
    text-align: justify;
    width: 100%;
  }
`;

export const ChartContainer = styled.div`
  display: flex;
  border: 0px solid black;
  justify-content: center;
  align-items: center;

  .chart {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
    width: fit-content;
    height: fit-content;
  }

  .chartContent {
    border: 1px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
