import styled, { css } from 'styled-components';
import cores from '../../styles/cores';
import tema from '../../styles/tema';

interface INavigationMenuItem {
  isCurrentPage: boolean;
}

export const Item = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 60px;
  color: white;
  font-size: 16px;
  text-align: center;
  padding-left: 50px;

  .link {
    width: 100%;
    display: flex;
    align-items: center;
  }

  &:hover {
    transition: 0.8s ease;
    cursor: pointer;
    background-color: ${cores.purple_2};
    svg {

    }
  }


`;

export const Icone = styled.div`
  display: flex;
  width: 32px;
  height: 32px;
  margin-right: 10px;
  color: white;

  svg {
    width: 32px;
    height: 32px;
    fill: white;
  }
`;

export const Notificacao = styled.div`
  border-radius: 12px;
  width: 32px;
  height: 26px;
  background-color: ${cores.red};
  opacity: 0.9;
  color: white;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  padding: 0;
  font-size: 14px;
`;

export const UsuarioAvatar = styled.div`
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;

  h2 {
    margin-top: 8px;
    font-weight: bold;
    font-size: 18px !important;
  }
`;

export const AvatarContainer = styled.div`
  .editar {
    margin-left: -30px;
    transform: scaleX(1);
    font-size: 32px;
    color: ${cores.purple_1};
  }
`;

export const Container = styled.div`
  background-color: #474a51;
  height: 120%;
  width: 250px;
  margin: 0;
  /* margin-top: -48px; */
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0;

  .selected {
    transition: 0.5s ease;
    background-color: ${cores.purple_2};
    // border-right: 6px solid ${cores.purple_2};
    // border-radius: 0px;
    // color: ${cores.purple_2};
    // svg {
    //   fill: ${cores.purple_2};
    // }
  }

  h2 {
    font-size: 16px;
    color: white;
  }

  p {
    color: white;
    font-size: 16px;
    text-align: center;
    margin-bottom: 40px;
  }

  .ultimo-item {
    margin-bottom: 50px;
  }
`;
