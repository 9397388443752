import React, { useMemo } from "react";
import MaterialTable, { Column } from "material-table";
import { Container } from './styles';

interface IDataItem {
  id: number;
  titulo: string;
  valor_bonificacao: string;
  quant_propostas: number;
  data_criacao: string;
}

interface TabelaBonificacaoProps {
  data: IDataItem[];
  titulo: string;
}

const TabelaBonificacao: React.FC<TabelaBonificacaoProps> = ({ titulo, data }) => {
  const columns = useMemo(() => [
    {
      title: "Descrição",
      field: "descricao"
    },
    {
      title: "Valor",
      field: "valor_bonificacao",
    },
    {
      title: "Quant Prop",
      field: "quant_propostas",
    },
    {
      title: "Tipo",
      field: "tipo_bonificacao",
    },
    {
      title: "Propostas",
      field: "id_propostas",
    }], []);

  return (
    <Container>
      <MaterialTable
        columns={columns}
        data={data}
        title={titulo.toUpperCase()}
        options={{ search: false }}
        localization={{
          pagination: {
            labelDisplayedRows: '{from}-{to} de {count}',
            labelRowsSelect: 'linhas',
            labelRowsPerPage: 'Linhas por página:',
            firstAriaLabel: 'Primeira Página',
            firstTooltip: 'Primeira Página',
            previousAriaLabel: 'Página Anterior',
            previousTooltip: 'Página Anterior',
            nextAriaLabel: 'Próxima Página',
            nextTooltip: 'Próxima Página',
            lastAriaLabel: 'Última Página',
            lastTooltip: 'Última Página'
          }
        }}
      />
    </Container>
  );
};

export default TabelaBonificacao;
