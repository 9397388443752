import React, { useCallback, useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { useHistory, useLocation } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import MenuLateral from '../../components/MenuLateral/MenuLateral';
import Header from '../../components/Header/Header';
import DetalhesImobiliaria from './DetalhesImobiliaria';

import {
  Container,
  Content,
  HeaderContainer,
  MenuLateralContainer,
  SecondRowContainer,
  TabelaContainer,
  OpcoesContainer,
  DetalhesContainer,
  DetalhesHeader,
  Usuario,
  DetalhesContent,
  ModalContainer,
  BotaoContainer,
} from './styles';
import Tabela from '../../components/Tabelas/Tabela';
import Botao from '../../components/Botao/Botao';
import AvatarUsuario from '../../assets/Icons/AvatarUsuario';
// import DetalhesCorretores from './DetalhesCorretores';
// import EditarCorretores from './EditarCorretores';
// import NovoCorretor from './NovoCorretor';
import Modal from '../../components/Modal/Modal';
import NovaImobiliaria from './NovaImobiliaria';
import DeleteModal from '../../components/DeleteModal';
import EditarImobiliaria from './EditarImobiliaria';
import Snackbars from '../../components/Snackbars';
import ImobiliariasService from '../../services/ImobiliariasService';

const Imobiliaria: React.FC = () => {
  const [agencyData, setAgencyData] = useState();
  const history = useHistory()
  const location = useLocation();
  const [pageMenu, setPageMenu] = useState('principal');
  const [allAgencyData, SetAllAgencyData] = useState<any[]>()
  const [snackbarProps, setSnackbarProps] = useState({
    message: '',
    type: 'success',
  });
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);


  const fetchData = useCallback(async()=>{
    setIsDataLoading(true);
    await ImobiliariasService.listAllImobiliarias().then((response)=>{
      SetAllAgencyData(response.agencies)
    })
    setIsDataLoading(false);
  }, [])

  useEffect(()=>{
    fetchData()
  }, [])



  const columns = [
    // {
    //   title: 'Logo',
    //   field: 'imageUrl',
    //   filtering: false,
    //   export: false,

    //   render: (rowData: any) => (
    //     <img
    //       src={rowData.imageUrl}
    //       style={{ width: 64, borderRadius: '50%' }}
    //       alt="avatar"
    //     />
    //   ),
    // },
    {
      title: 'Nome',
      field: 'nome',
    },
    {
      title: 'Telefone',
      field: 'telefone',
    },
    {
      title: 'Email',
      field: 'email',
    },
  ];

  function rowSelected(e: any, rowData: any) {
    history.push({pathname:'/painel/imobiliarias/detalhes', state: {rowData}})
  }

  function editSelected(e: any, rowData: any) {
    
    history.push({pathname:'/painel/imobiliarias/editar', state: rowData})
  }

  function adicionarImobiliaria() {
    // setPageMenu('adicionar');
    history.push('/painel/imobiliarias/adicionar')
  }

  const handleSnackbarClose = (
    event?: React.SyntheticEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  async function deletarRegistro(agencyData: any) {
    setDeleteModalOpen(false);
    await ImobiliariasService.deleteImobiliaria(agencyData.id)
    setSnackbarProps({
      message: 'Imobiliária deletada com sucesso',
      type: 'success',
    });
    
    setSnackbarOpen(true);
    history.go(0);
  }
    return (
      <>
        <Content>
          <OpcoesContainer>
            <Botao tipo="adicionar" tamanho="medio" onClick={adicionarImobiliaria}>
              Adicionar Imobiliária
            </Botao>
          </OpcoesContainer>

          <TabelaContainer>
            <Tabela
              title="Imobiliárias"
              haveActions="editdelete"
              isLoading = {isDataLoading}
              columns={columns}
              data={allAgencyData}
              onRowClick={(e: any, rowData: any): any => {
                setAgencyData(rowData);
                rowSelected(e, rowData);
              }}
              excluirClick={(e: any, rowData: any): any => {
                setAgencyData(rowData);
                setDeleteModalOpen(true);
              }}
              editarClick={(e: any, rowData: any): any => {
                setAgencyData(rowData);
                editSelected(e, rowData);
              }}
              pagination
              paginationPageSize={10}
            />
          </TabelaContainer>

          <DeleteModal
            isModalOpen={deleteModalOpen}
            onCloseModal={() => {
              setDeleteModalOpen(false);
            }}
            onDeleteClick={() => deletarRegistro(agencyData)}
          />
          <Snackbars
            type={snackbarProps.type}
            handleClose={handleSnackbarClose}
            open={snackbarOpen}
          >
            {snackbarProps.message}
          </Snackbars>
        </Content>
      </>
    );
};

export default Imobiliaria;
