import CircularProgress from '@material-ui/core/CircularProgress';
import Botao from '../Botao/Botao';
import Modal from '../Modal/Modal';
import { ModalContainerDelete, SpinnerConatainer } from './styles';

interface updateModalProps {
  isModalOpen: boolean;
  onUpDateClick?: any;
  onCloseModal: any;
  isLoading?: boolean;
  openEvidence: () => void;
  calculoDescontoImplicito?: number;
  descontoImplicitoAlcada?: string;
}

const ModalAlcadaDenied = (props: updateModalProps): any => {
  const {
    isModalOpen,
    onUpDateClick,
    onCloseModal,
    isLoading,
    calculoDescontoImplicito,
    descontoImplicitoAlcada,
    openEvidence,
  } = props;
  const spinner = <CircularProgress color="primary" size={40} />;
  const texto = '';

  return (
    <Modal
      isOpen={isModalOpen}
      width={420}
      onClose={() => {
        onCloseModal();
      }}
    >
      <>
        <ModalContainerDelete>
          <h1 style={{ marginBottom: '12%', marginTop: '-2%' }}>Atenção!</h1>
          <p>{isLoading ? '' : texto}</p>
          <SpinnerConatainer
            style={{ marginLeft: '45%', marginBottom: '5%' }}
            className={isLoading ? '' : 'hide'}
          >
            {isLoading ? spinner : ''}
          </SpinnerConatainer>
          <p style={{ textAlign: 'center', marginBottom: '5%' }}>
            Alçada não permitida!
          </p>
          <p
            style={{ textAlign: 'left', marginBottom: '2%', marginLeft: '30%' }}
          >
            O desconto da sua Alçada é de no máximo {descontoImplicitoAlcada}
          </p>
          <p style={{ textAlign: 'left', marginLeft: '30%' }}>
            e o desconto aplicado na proposta foi{' '}
            {calculoDescontoImplicito
              ? `${calculoDescontoImplicito.toPrecision(3)}%`
              : ''}
          </p>
          <div
            style={{
              marginTop: '10%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 15,
            }}
          >
            <Botao
              tamanho="grande"
              tipo="deletar"
              className="btn"
              onClick={() => {
                openEvidence();
                onCloseModal();
              }}
            >
              Lançar com evidência
            </Botao>
            <Botao
              tamanho="grande"
              tipo="salvar"
              className="btn"
              onClick={() => {
                onCloseModal();
              }}
            >
              Voltar
            </Botao>
          </div>
        </ModalContainerDelete>
      </>
    </Modal>
  );
};

export default ModalAlcadaDenied;
