import * as React from 'react';

function ListProductsIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={682.667}
      height={682.667}
      viewBox="0 0 512 512"
    >
      <path d="M240.7 1.5c-15.4 3.9-30.1 15.5-36.6 28.7l-3.2 6.8h-20.2c-27.9 0-28.7.5-28.7 19.5v10.3l-27.7.5c-21.5.3-28.8.8-32.4 2-16.2 5.4-28.2 17-34.5 33.3-1.8 4.7-1.9 11.5-2.2 186.9C55 469.8 55 471.6 57 478c5.3 17 20.9 29.8 40.2 33 8.7 1.4 308.9 1.4 317.6 0 19.3-3.2 34.9-16 40.2-33 2-6.4 2-8.2 1.8-188.5-.3-175.4-.4-182.2-2.2-186.9-6.3-16.3-18.3-27.9-34.5-33.3-3.6-1.2-10.9-1.7-32.2-2l-27.7-.5-.3-11.7c-.6-17.8-1-18.1-29.8-18.1h-20l-4.2-8c-6.6-12.8-17.3-21.6-31.8-26.1-8.8-2.7-25.4-3.4-33.4-1.4zM269 26c9.2 4.7 15.8 12.4 18.6 21.7.8 2.8 2.4 6.3 3.5 7.7 4 5.1 6.3 5.6 25.9 5.6h18v49H177V61h17.3c22.7 0 23.8-.5 28.8-14.3 3.7-10.4 11.6-18.5 21.1-21.9 7.2-2.6 18.5-2 24.8 1.2zm-117 80.7c0 13.6.3 16.2 2 19.8 3.9 8.1-3.8 7.5 101.8 7.5h93.9l3.4-2.3c6-4 6.9-7.2 6.9-25V90.8l25.8.4c21.3.3 26.3.7 29.5 2.1 6.5 2.8 11.4 7.5 14.4 13.5l2.8 5.7.3 176c.2 123.8 0 177.5-.8 181.1-2 9.2-9.7 15.5-21.7 17.4-7.8 1.3-300.8 1.3-308.6 0-12-1.9-19.7-8.2-21.7-17.4-.8-3.6-1-57.3-.8-181.1l.3-176 2.8-5.7c2.6-5.4 7.1-9.9 12.6-12.8 4.2-2.2 11.2-2.8 34.4-2.9L152 91v15.7z" />
      <path d="M195.5 172.4c-1.6.8-11.8 9.8-22.5 20S153.2 211 152.9 211c-.3 0-4.1-3.7-8.5-8.3-10-10.3-11.9-11.7-17-11.7-8.4 0-13.6 8.9-9.9 17 2 4.4 28.8 31.4 32.2 32.4 5.2 1.6 9.4-1 24.8-15.5 40-37.9 40.1-38 36.9-45.7-2.6-6.5-9.9-9.6-15.9-6.8zM239.4 202.4c-7.9 3.5-10 12.7-4.1 18.9 2.7 2.9 4.2 3.6 8.7 4.2 3 .3 36.6.5 74.5.3 65.8-.3 69.1-.4 71.8-2.2 5.7-3.9 7.2-10.6 3.7-16.4-3.9-6.4-1.6-6.2-80.2-6.2-57.4.1-71.9.3-74.4 1.4zM194 270.7c-1.4.9-11.1 9.9-21.6 19.9l-19.1 18.3-9.9-9.8c-11-10.9-13.7-12.2-20.4-9.5-5.8 2.3-8.4 8.7-6 14.9 1.2 3.3 27.5 30.9 30.7 32.4 3.5 1.6 8.5 1.3 11.3-.6 1.4-.9 13.6-12.2 27.3-25.1 27.2-25.9 28.3-27.4 25.4-34.5-2.9-6.7-12.1-9.9-17.7-6zM237.8 300.6c-5.4 2.9-7.4 10.9-4.1 16.5 3.6 6.1 1.1 5.9 80.3 5.9 80.1 0 77.2.2 80.4-6.5 2-4.2 2-6.8 0-11.1-3.2-6.7.4-6.4-80.6-6.4-62.9.1-73.7.3-76 1.6zM196.5 367c-1.8.6-9.8 7.4-19.5 16.6-9.1 8.7-18.2 17.3-20.2 19.3l-3.8 3.5-10.3-10.2c-10.1-9.9-10.5-10.2-14.9-10.2-8.8 0-14 8.7-10.3 17 2 4.4 28.8 31.4 32.2 32.4 6.4 2 11.6-2.2 50.4-40 12.8-12.5 14-14.7 11.3-21.2-2.5-6.1-8.5-9-14.9-7.2zM239.4 397.4c-11.5 5.1-9 21.2 3.6 23.1 2.5.3 36 .5 74.5.3 66.8-.3 70.1-.4 72.8-2.2 5.7-3.9 7.2-10.6 3.7-16.4-3.9-6.4-1.6-6.2-80.2-6.2-57.4.1-71.9.3-74.4 1.4z" />
    </svg>
  );
}

export default ListProductsIcon;
