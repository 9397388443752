import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import { TextField } from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import Tabela from '../../components/Tabelas/Tabela';
import {
  Content,
  OpcoesContainer,
  TabelaContainer,
} from './styles';
import Botao from '../../components/Botao/Botao';
import Modal from '../../components/Modal/Modal';
import DeleteModal from '../../components/DeleteModal';
import Snackbars from '../../components/Snackbars';

const Faq = () => {
  const [detalhes, setDetalhes] = useState(false);
  const [userData, setUserData] = useState({});
  const [deleteModalControl, setDeleteModalControl] = useState(false);
  const [progressValue, setProgressValue] = useState(0);
  const history = useHistory();
  const [faqData, setFaqData] = useState<any[]>();
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [createModal, setCreateModal] = useState<boolean>(false);
  const [editModal, setEditModal] = useState<boolean>(false);

  const perguntaInitialValue = '';
  const respostaInitialValue = '';
  const [pergunta, setPergunta] = useState(perguntaInitialValue);
  const [resposta, setResposta] = useState(respostaInitialValue);
  const [snackbarProps, setSnackbarProps] = useState({
    message: '',
    type: 'success',
  });

  function abreModal1() {
    setCreateModal(true);
  }

  useEffect(() => {
    setCreateModal(!!selectedRow);
  }, [selectedRow]);

  useEffect(() => {
    if (selectedRow && !createModal) {
      setSelectedRow(null);
    }
  }, [createModal]);

  const [open, setOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  //  Definição das colunas que serão mostradas na tabela, relacionando as com os dados
  //  recebidos do backend pelos services

  const columns = [
    {
      title: 'Pergunta',
      field: 'pergunta',
    },
    {
      title: 'Resposta',
      field: 'resposta',
    },
  ];

  // Dados utilizados para simular as respostas das requisições

  const data = [
    {
      pergunta: 'QUEM É A BAIRRU URBANISMO?',
      resposta: `A Bairru Urbanismo é uma marca Bairru e nasceu com o propósito de transformar vidas
                 por meio do desenvolvimento e democratização do espaço urbano. Nossos projetos são
                 elaborados pensando nas necessidades das pessoas, sempre unindo segurança e bem-estar
                 à conveniência de viver em um grande centro, pois entendemos que cidades não são feitas de concreto, mas de pessoas.`,
    },
    {
      pergunta:
        'COMPREI UM LOTE EM UM CONDOMÍNIO. COM O QUE DEVO ME PREOCUPAR?',
      resposta: `Caso o lote pertença a um condomínio, após a assinatura do contrato, o comprador é responsável pelo pagamento do condomínio. Além disso, o futuro morador deve respeitar o regimento interno e convenções do condomínio. Para saber mais, consulte o item 1.20 do Manual do Empreendimento.`,
    },
    {
      pergunta: 'QUEM É A VIZI LOTES?',
      resposta: `A Vizi Lotes é uma empresa do grupo Bairru que comercializa lotes com o objetivo de
mudar a vida das pessoas. Nossa missão, é viabilizar o acesso à boa moradia para
todas as classes sociais. Para isso, nos tornamos caçadores de lotes: negociamos com
proprietários, compramos os melhores e financiamos para os nossos clientes com uma
política de crédito que incentiva os bons pagadores.`,
    },
    {
      pergunta: 'NÃO TENHO O BOLETO PARA PAGAR. COMO POSSO SOLICITÁ-LO?',
      resposta: `Os boletos estão disponíveis no aplicativo, ou podem ser solicitados por telefone ou WhatsApp pelo número (41) 3779-1190.
Eles podem ser pagos antes do vencimento ou com até 60 dias de atraso, em qualquer banco.`,
    },
    {
      pergunta: 'MINHA PARCELA ESTÁ MUITO ALTA. POSSO RENEGOCIAR MINHA DÍVIDA?',
      resposta: `Para renegociações entre em contato conosco por telefone ou WhatsApp pelo numero (41) 3779-1193.`,
    },
    {
      pergunta: 'POSSO INCLUIR/EXCLUIR ALGUEM DO CONTRATO?',
      resposta: `Tanto para a inclusão, quanto para a exclusão, é necessária uma nova análise de crédito. Caso o comprador que entra e/ou o que permanece seja aprovado pela empresa, a minuta do aditivo contratual será confeccionada, devendo ser assinada por todos os envolvidos na referida transação.`,
    },
  ];

  // função chamada quando o usuário clica em alguma linha da tabela

  const rowClicked = (e: any, rowData: any) => {
    if (rowData) {
      setPergunta(rowData.pergunta);
      setResposta(rowData.resposta);
      setEditModal(true);
    }
  };

  const handleSnackbarClose = (
    event?: React.SyntheticEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  // const handleClick = () => {
  //   setModalOpen(false);
  //   setOpen(true);
  // };

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleClose2 = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  function Alert(props: AlertProps) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  function deletarRegistro(deleteData: any) {
    // delete data[deleteData];
    
    setDeleteModalControl(false);
    setSnackbarProps({
      message: 'Pergunta/Resposta deletada com sucesso',
      type: 'success',
    });
    setSnackbarOpen(true);
  }

  function saveChanges(dataToSave: any) {
    
    // array.push
    // requisição na api
    // edit modal false
    setSnackbarProps({
      message: 'Pergunta/Resposta salva com sucesso',
      type: 'success',
    });
    setSnackbarOpen(true);
  }

  // ----------------Editar e Salvar ------------------------------------------------------------

  const EditAndCreateModal: any = () => {
    const initialValues = selectedRow
      ? {
          question: selectedRow!.title || '',
          answer: selectedRow!.answer || '',
        }
      : {
          question: '',
          answer: '',
        };

    if (createModal) {
      return (
        <Modal
          isOpen={createModal}
          width={500}
          onClose={() => setCreateModal(false)}
        >
          <>
            <h1>Nova pergunta/resposta</h1>
            <Formik
              onSubmit={data3 => console.log('')
            }
              initialValues={initialValues}
            >
              {({
                values,
                handleChange,
                handleSubmit,
                setFieldValue,
                validateForm,
                validateField,
                errors,
                touched,
              }) => (
                <>
                  <div className="formWrapper">
                    <p>Pergunta</p>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      style={{ width: 450 }}
                      multiline
                      maxRows="8"
                      name="question"
                      type="text"
                      id="password"
                      autoComplete="current-password"
                      value={values.question}
                      onChange={handleChange('question')}
                    />
                    <p>Resposta</p>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      style={{ width: 450 }}
                      multiline
                      maxRows="8"
                      name="answer"
                      type="text"
                      id="password"
                      autoComplete="current-password"
                      value={values.answer}
                      onChange={handleChange('answer')}
                    />
                  </div>
                  <div className="btn-modalfull">
                    <Botao
                      tipo="salvar"
                      tamanho="fullwidth"
                      onClick={() => {
                        if (!(values.answer === '' || values.question === '')) {
                          setSnackbarOpen(true);
                          setCreateModal(false);
                        }
                      }}
                    >
                      Salvar
                    </Botao>
                  </div>
                </>
              )}
            </Formik>
          </>
        </Modal>
      );
    }

    return (
      <Modal isOpen={editModal} width={500} onClose={() => setEditModal(false)}>
        <>
          <h1>Editar pergunta/resposta</h1>
          <Formik
            onSubmit={data2 => console.log('')
          }
            initialValues={initialValues}
          >
            {({ values, handleChange, handleSubmit, setFieldValue }) => (
              <>
                <div className="formWrapper">
                  <p>Pergunta</p>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    multiline
                    name="question"
                    type="text"
                    maxRows="8"
                    style={{ width: 450 }}
                    value={pergunta}
                    onChange={e => {
                      setPergunta(e.target.value);
                    }}
                  />
                  <p>Resposta</p>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    multiline
                    maxRows="8"
                    name="answer"
                    style={{ width: 450 }}
                    type="text"
                    value={resposta}
                    onChange={e => {
                      setResposta(e.target.value);
                    }}
                  />
                </div>
                <div className="buttons">
                  <Botao
                    tipo="salvar"
                    tamanho="medio"
                    className="btn-modal"
                    onClick={() => {
                      setEditModal(false);
                      saveChanges(userData);
                    }}
                  >
                    Salvar
                  </Botao>

                  <Botao
                    tipo="excluir"
                    tamanho="medio"
                    className="btn-modal"
                    onClick={() => {
                      setEditModal(false);
                      setDeleteModalControl(true);
                      // deletarRegistro(userData);
                    }}
                  >
                    Excluir
                  </Botao>
                </div>
              </>
            )}
          </Formik>
        </>
      </Modal>
    );

    return <></>;
  };


  return (
    <>
    <Content>
      <OpcoesContainer>
        <Botao
          tipo="adicionar"
          tamanho="medio"
          onClick={() => saveChanges(userData)}
        >
          Adicionar pergunta/resposta
        </Botao>
      </OpcoesContainer>

      <TabelaContainer>
        <Tabela
          title="Perguntas e respostas"
          haveActions="editdelete"
          columns={columns}
          data={data}
          onRowClick={(e: any, rowData: any): any => {
            setUserData(rowData);
            rowClicked(e, rowData);
          }}
          excluirClick={(e: any, rowData: any): any => {
            setUserData(rowData);
            setDeleteModalControl(true);
            // deletarRegistro(rowData);
          }}
          editarClick={(e: any, rowData: any): any => {
            setPergunta(rowData.pergunta);
            setResposta(rowData.resposta);
            setEditModal(true);
          }}
          pagination
          paginationPageSize={10}
        />
      </TabelaContainer>
      <DeleteModal
        isModalOpen={deleteModalControl}
        onCloseModal={() => setDeleteModalControl(false)}
        onDeleteClick={() => deletarRegistro(userData)}
      />
      <EditAndCreateModal />
      <Snackbars
        type={snackbarProps.type}
        handleClose={handleSnackbarClose}
        open={snackbarOpen}
      >
        {snackbarProps.message}
      </Snackbars>
      </Content>
    </>
  );
};

export default Faq;
