import styled from 'styled-components';
import cores from '../../styles/cores';

export const StyledH1 = styled.h1`
  font-weight: bold;
  text-decoration: underline;
  text-align: center;
  color: ${cores.purple_2};
  font-size: 2rem;
`;

export const StyledH2 = styled.h2`
  font-size: 1.2rem;
  color: ${cores.purple_2};
  font-weight: bold;
  text-align: left; /* Texto alinhado à esquerda */
`;

export const UnderlinePart = styled.span`
  color: ${cores.purple_2};
  text-decoration: underline;
`;

export const CenteredText = styled.div`
  max-width: 70%;
  margin: 0 auto; /* Centraliza horizontalmente */
  text-align: left; /* Mantém o texto alinhado à esquerda */
  margin-bottom: 30rem;
`;

export const UnorderedList = styled.ul`
  list-style-type: disc;
  text-align: left;
  max-width: 80%;
  margin: 0 auto; /* Centraliza horizontalmente */
`;

export const OrderedList = styled.ol`
  max-width: 80%;
  list-style-type: lower-alpha;
  margin: 0 auto; /* Centraliza horizontalmente */
`;
