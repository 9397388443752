/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import {
  Container,
  Content,
  HeaderContainer,
  MenuLateralContainer,
  SecondRowContainer,
} from './styles';
// import Icone from "../../components/IconeClicavel/IconeClicavel";
// import Icones from "../../assets/Icons/lotes.svg";
import Header from '../../components/Header/Header';
import MenuLateral from '../../components/MenuLateral/MenuLateral';
import Corretores from '../Corretores';
import Empreendimentos from '../Empreendimentos';
import Faq from '../Faq';
import Imobiliarias from '../Imobiliarias';
import Lotes from '../Lotes';
import Marketplace from '../Marketplace';
import DetalhesProduto from '../Marketplace/DetalhesProduto';
import EditarProduto from '../Marketplace/EditarProduto';
import NovoProduto from '../Marketplace/NovoProduto';
import Pedidos from '../Pedidos';
import DetalhesPedidos from '../Pedidos/DetalhesPedido';
import Premios from '../Premios';
import Ranking from '../Ranking';
import EsqueciSenha from '../RecoverPassword';
// import DetalhesPedidos from '../Pedidos/DetalhesPedido';
import { getUserData, isSignedIn, TOKEN_KEY } from '../../services/auth';
import UsuariosService from '../../services/UserService';
import { RootState } from '../../storage/rootReducer';
import AcompanhamentoPropostaAdmin from '../AcompanhamentoPropostaAdmin';
import EditarPropostaManager from '../AcompanhamentoPropostaAdmin/EditarPropostaManager';
import DetalhesCorretores from '../Corretores/DetalhesCorretores';
import EditarCorretores from '../Corretores/EditarCorretores';
import NovoCorretor from '../Corretores/NovoCorretor';
import DetalhesEmpreendimentos from '../Empreendimentos/DetalhesEmpreendimentos';
import DetalhesImobiliaria from '../Imobiliarias/DetalhesImobiliaria';
import EditarImobiliaria from '../Imobiliarias/EditarImobiliaria';
import NovaImobiliaria from '../Imobiliarias/NovaImobiliaria';

import EditarPropostaAdmin from '../AcompanhamentoPropostaAdmin/EditarProposta';
import Comissao from '../Comissao';
import LeadsAdmin from '../Leads';

const Painel: React.FC = () => {
  const history = useHistory();
  const { token } = useSelector((state: RootState) => state.userReducer);

  const { pathname } = history.location;
  const [userTipo, setUserTipo] = useState('');
  const [userData, setUserData] = useState('');

  const paginaAtual = useMemo(() => {
    switch (pathname) {
      case '/painel/carrinho':
        return { titulo: 'Testes', caminho: pathname };
      case '/painel/corretores':
        return { titulo: 'Corretores', caminho: pathname };
      case '/painel/corretores/adicionar':
        return { titulo: 'Corretores', caminho: pathname };
      case '/painel/corretores/editar':
        return { titulo: 'Corretores', caminho: pathname };
      case '/painel/corretores/detalhes ':
        return { titulo: 'Corretores', caminho: pathname };
      case '/painel/empreendimentos':
        return { titulo: 'Empreendimentos', caminho: pathname };
      case '/painel/empreendimentos/detalhes':
        return { titulo: 'Empreendimentos', caminho: pathname };
      case '/painel/faq':
        return { titulo: 'Faq', caminho: pathname };
      case '/painel/imobiliarias':
        return { titulo: 'Imobiliárias', caminho: pathname };
      case '/painel/imobiliarias/adicionar':
        return { titulo: 'Imobiliárias', caminho: pathname };
      case '/painel/imobiliarias/editar':
        return { titulo: 'Imobiliárias', caminho: pathname };
      case '/painel/imobiliarias/detalhes':
        return { titulo: 'Imobiliárias', caminho: pathname };
      case '/painel/lotes':
        return { titulo: 'Lotes', caminho: pathname };
      case '/painel/marketplace':
        return { titulo: 'Marketplace', caminho: pathname };
      case '/painel/marketplace/editar':
        return { titulo: 'Marketplace', caminho: pathname };
      case '/painel/marketplace/detalhes':
        return { titulo: 'Marketplace', caminho: pathname };
      case '/painel/marketplace/adicionar':
        return { titulo: 'Marketplace', caminho: pathname };
      case '/painel/pedidos':
        return { titulo: 'Pedidos', caminho: pathname };
      case '/painel/pedidos/adicionar':
        return { titulo: 'Pedidos', caminho: pathname };
      case '/painel/pedidos/editar':
        return { titulo: 'Pedidos', caminho: pathname };
      case '/painel/pedidos/detalhes':
        return { titulo: 'Pedidos', caminho: pathname };
      case '/painel/proposta':
        return { titulo: 'Proposta', caminho: pathname };
      case '/painel/premios':
        return { titulo: 'Prêmios', caminho: pathname };
      case '/painel/ranking':
        return { titulo: 'Ranking', caminho: pathname };
      case '/painel/comissao':
        return { titulo: 'Comissao', caminho: pathname };
      case '/painel/leads':
        return { titulo: 'Imobiliárias', caminho: pathname };
      default:
        return '';
    }
  }, [pathname]);

  const get = useCallback(async () => {
    let token: any = localStorage.getItem(TOKEN_KEY);
    token = JSON.parse(token);
    if (!token) {
      return;
    } else {
      const dataToken = await getUserData();
      setUserTipo(token.userType);
      const userData = await UsuariosService.getUserData(dataToken.id);
      setUserData(userData);
    }
  }, []);

  useEffect(() => {
    if (isSignedIn()) {
      get();
      if (userTipo === 'COMMON') {
        history.push('/painel/proposta');
      } else if (userTipo === 'IMOB') {
        history.push('/painel/imobiliaria/proposta');
      } else if (userTipo === 'ADMIN') {
        history.push('/painel/corretores');
      }
    } else {
      history.push('/');
    }
  }, []);

  return isSignedIn() ? (
    userTipo === 'COMMON' ? (
      <Redirect to="/painel/proposta" />
    ) : userTipo === 'IMOB' ? (
      <Redirect to="/painel/imobiliaria/proposta" />
    ) : userTipo === 'MANAGER' ||
      userTipo === 'COORDINATOR' ||
      userTipo === 'OVERVIEWER' ? (
      <Container>
        <HeaderContainer>
          <Header className="header" paginaAtual={paginaAtual} />
        </HeaderContainer>

        <SecondRowContainer>
          <MenuLateralContainer>
            <MenuLateral
              className="menuLateral"
              currentPage={{ pathname, data: userData }}
            />
          </MenuLateralContainer>
          <Content>
            <Switch>
              <Route
                path="/painel/propostas-admin"
                exact
                component={AcompanhamentoPropostaAdmin}
              />
              <Route
                path="/painel/propostas-admin/editar"
                exact
                component={EditarPropostaManager}
              />
              <Route
                path="/painel/empreendimentos"
                exact
                component={Empreendimentos}
              />
              <Route
                path="/painel/empreendimentos/detalhes"
                exact
                component={DetalhesEmpreendimentos}
              />
              <Route path="/painel/faq" exact component={Faq} />
              <Route
                path="/painel/imobiliarias"
                exact
                component={Imobiliarias}
              />
              <Route
                path="/painel/imobiliarias/adicionar"
                exact
                component={NovaImobiliaria}
              />
              <Route
                path="/painel/imobiliarias/editar"
                exact
                component={EditarImobiliaria}
              />
              <Route
                path="/painel/imobiliarias/detalhes"
                exact
                component={DetalhesImobiliaria}
              />
              <Route path="/painel/lotes" exact component={Lotes} />
              <Route
                path="/painel/esquecisenha"
                exact
                component={EsqueciSenha}
              />
            </Switch>
          </Content>
        </SecondRowContainer>
      </Container>
    ) : (
      <Container>
        <HeaderContainer>
          <Header className="header" paginaAtual={paginaAtual} />
        </HeaderContainer>

        <SecondRowContainer>
          <MenuLateralContainer>
            <MenuLateral
              className="menuLateral"
              currentPage={{ pathname, data: userData }}
            />
          </MenuLateralContainer>
          <Content>
            <Switch>
              <Route path="/painel/corretores" exact component={Corretores} />
              <Route
                path="/painel/corretores/adicionar"
                exact
                component={NovoCorretor}
              />
              <Route
                path="/painel/corretores/editar"
                exact
                component={EditarCorretores}
              />
              <Route
                path="/painel/corretores/detalhes/"
                exact
                component={DetalhesCorretores}
              />
              <Route
                path="/painel/empreendimentos"
                exact
                component={Empreendimentos}
              />
              <Route
                path="/painel/empreendimentos/detalhes"
                exact
                component={DetalhesEmpreendimentos}
              />
              <Route path="/painel/faq" exact component={Faq} />
              <Route
                path="/painel/imobiliarias"
                exact
                component={Imobiliarias}
              />
              <Route
                path="/painel/imobiliarias/adicionar"
                exact
                component={NovaImobiliaria}
              />
              <Route
                path="/painel/imobiliarias/editar"
                exact
                component={EditarImobiliaria}
              />
              <Route
                path="/painel/imobiliarias/detalhes"
                exact
                component={DetalhesImobiliaria}
              />
              <Route path="/painel/lotes" exact component={Lotes} />
              <Route path="/painel/marketplace" exact component={Marketplace} />
              <Route
                path="/painel/marketplace/editar"
                exact
                component={EditarProduto}
              />
              <Route
                path="/painel/marketplace/detalhes"
                exact
                component={DetalhesProduto}
              />
              <Route
                path="/painel/marketplace/adicionar"
                exact
                component={NovoProduto}
              />
              <Route path="/painel/pedidos" exact component={Pedidos} />
              <Route
                path="/painel/pedidos/detalhes"
                exact
                component={DetalhesPedidos}
              />
              <Route
                path="/painel/pedidos/adicionar"
                exact
                component={Pedidos}
              />
              <Route path="/painel/premios" exact component={Premios} />
              <Route path="/painel/ranking" exact component={Ranking} />
              <Route
                path="/painel/esquecisenha"
                exact
                component={EsqueciSenha}
              />

              <Route
                path="/painel/propostas-admin"
                exact
                component={AcompanhamentoPropostaAdmin}
              />
              <Route
                path="/painel/propostas-admin/editar"
                exact
                component={EditarPropostaAdmin}
              />
              <Route path="/painel/comissao" exact component={Comissao} />
              <Route path="/painel/leads" exact component={LeadsAdmin} />
            </Switch>
          </Content>
        </SecondRowContainer>
      </Container>
    )
  ) : (
    <Redirect to="/" />
  );
};

export default Painel;
