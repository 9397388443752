import React from "react";
import {
    CenteredText,
    OrderedList,
    StyledH1,
    StyledH2,
    UnderlinePart,
    UnorderedList
} from "./styles";

const EsclusaoDeConta: React.FC = () => {
    return (
        <CenteredText>
            <br />
            <br />
            <StyledH1>Solicitação de Exclusão de Conta - BairrU Corretores</StyledH1>
            <br />
            <p>
                Prezado(a) Usuário(a),

                Agradecemos por utilizar os serviços do Bairru Corretores. Caso tenha decidido encerrar sua conta, estamos aqui para auxiliá-lo(a) nesse processo.

                Para solicitar a exclusão da sua conta, por favor, envie um e-mail para <UnderlinePart>omiranda@bairru.com.br</UnderlinePart>  informando sua decisão. Este é o canal designado para solicitações de exclusão de conta.
            </p>

            <br />
            <p>
                Ao enviar o e-mail, certifique-se de incluir as informações necessárias para identificarmos a sua conta de forma segura e eficaz. Após recebermos sua solicitação, nossa equipe realizará o processo de exclusão em até 30 dias corridos.
            </p>
            <br />
            <p>
                Ao término do procedimento, você receberá uma confirmação no mesmo endereço de e-mail utilizado para a solicitação. Pedimos que esteja atento(a) a este e-mail durante o período de processamento.
            </p>
            <br />
            <p>
                Agradecemos pela compreensão e colaboração durante este processo. Se precisar de suporte adicional ou tiver dúvidas, estamos à disposição para ajudar. Basta responder a este e-mail.
            </p>
            <br />
            <br />
            <p>
                A equipe Bairru Corretores agradece pela sua confiança e deseja sucesso.
            </p>
            <br />
            <br />
            <p>
                Atenciosamente,
            </p>
            <br />
            <br />
            <p>
            Equipe de Suporte BairrU/Vizi
            </p>

        </CenteredText >
    );
};

export default EsclusaoDeConta;
