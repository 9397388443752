import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  justify-content: flex-start;
  align-items: center;
  border: 0px solid black;
  overflow-y: auto;
  overflow-x: hidden;
  // background-color: rgb(241, 241, 241);
  h1 {
    font-size: 24px;
  }
`;

export const HeaderContainer = styled.div`
  border: 0px solid red;
  display: flex;
  width: 100vw;
`;

export const SecondRowContainer = styled.div`
  display: flex;
  width: inherit;
  height: fit-content;
`;

export const MenuLateralContainer = styled.div`
  border: 0px dashed green;
  display: flex;
  width: auto;
  height: auto;
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: fit-content;
  height: 100%;
  width: 100%;
`;
