import React, { ReactElement } from 'react';

import { Container } from './styles';

interface IClickableIcon {
  children: ReactElement;
  action(): any;
}

const IconeClicavel = (props: IClickableIcon) => {
  const { children, action } = props;
  return <Container onClick={() => action()}>{children}</Container>;
};

export default IconeClicavel;
