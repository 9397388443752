import React, { useCallback, useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { useHistory, useLocation } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import MenuLateral from '../../components/MenuLateral/MenuLateral';
import Header from '../../components/Header/Header';
// import DetalhesImobiliaria from './DetalhesImobiliaria';

import {
  Container,
  Content,
  HeaderContainer,
  MenuLateralContainer,
  SecondRowContainer,
  TabelaContainer,
  OpcoesContainer,
  DetalhesContainer,
  DetalhesHeader,
  Usuario,
  DetalhesContent,
  ModalContainer,
  BotaoContainer,
} from './styles';
import Tabela from '../../components/Tabelas/Tabela';
import Botao from '../../components/Botao/Botao';
import AvatarUsuario from '../../assets/Icons/AvatarUsuario';
// import DetalhesCorretores from './DetalhesCorretores';
// import EditarCorretores from './EditarCorretores';
// import NovoCorretor from './NovoCorretor';
import Modal from '../../components/Modal/Modal';
import DetalhesProduto from './DetalhesProduto';
import NovoProduto from './NovoProduto';
import DeleteModal from '../../components/DeleteModal';
import EditarProduto from './EditarProduto';
import Snackbars from '../../components/Snackbars';
import MarketplaceService from '../../services/MarketplaceService';
// import DetalhesEmpreendimentos from './DetalhesEmpreendimentos';
// import NovoEmpreendimento from './NovoEmpreendimento';

const Pedidos: React.FC = () => {
  const [detalhes, setDetalhes] = useState(false);
  const [productData, setProductData] = useState({});
  const [pageMenu, setPageMenu] = useState('principal');
  const [listAll, setListAll] = useState<any[]>();
  const [id, setId] = useState<string>();
  const [snackbarProps, setSnackbarProps] = useState({
    message: '',
    type: 'success',
  });
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);

  const history = useHistory();
  const { state } = useLocation();
  console.log('id: ', state)

  const list = useCallback(async () => {
    setIsDataLoading(true);
    await MarketplaceService.listAllProducts().then(response => {
      setListAll(response.products);

    });
    setIsDataLoading(false);
  }, []);

  useEffect(() => {
    list();
  }, []);

  const columns = [
    // {
    //   title: 'Imagem',
    //   field: 'imageUrl',
    //   filtering: false,
    //   export: false,
    //   cellStyle: {
    //     whiteSpace: 'nowrap',
    //   },
    //   render: (rowData: any) => (
    //     <img src={rowData.imageUrl} style={{ width: 52 }} alt="avatar" />
    //   ),
    // },
    {
      title: 'Nome do produto',
      field: 'nome',
      cellStyle: {
        whiteSpace: 'nowrap',
      },
    },
    {
      title: 'Cod do produto',
      field: 'id',
      defaultFilter: state || ''
    },

    // {
    //   title: 'Descrição',
    //   field: 'descricao',
    // },
    {
      title: 'Preço',
      field: 'valor',
      render: (rowData: any) => {
        return `${rowData.valor} Ucoins`;
      },
      customSort: (a: { valor: number; }, b: { valor: number; }) => a.valor - b.valor
    },

    {
      title: 'Categoria',
      field: 'categoria',
    },
    {
      title: 'Disponibilidade',
      field: 'disponibilidade',
    },
  ];


  const [isModalOpen, setModalOpen] = useState(false);
  function rowSelected(e: any, rowData: any) {
    history.push({
      pathname: '/painel/marketplace/detalhes',
      state: { rowData },
    });
    // setPageMenu('detalhes');
  }

  function editSelected(e: any, rowData: any) {
    history.push({
      pathname: '/painel/marketplace/editar',
      state: { rowData },
    });
  }

  function adicionarProduto() {
    // setPageMenu('adicionar');
    history.push('/painel/marketplace/adicionar');
  }

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setModalOpen(false);
    setOpen(true);
  };

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  function Alert(props: AlertProps) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleSnackbarClose = (
    event?: React.SyntheticEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  async function deletarRegistro(productData: any) {
    await MarketplaceService.deleteProduct(productData.id);
    setDeleteModalOpen(false);
    setSnackbarProps({
      message: 'Produto deletado com sucesso',
      type: 'success',
    });
    setSnackbarOpen(true);
    list();

  }

  return (
    <>
      <Content>
        <OpcoesContainer>
          <Botao tipo="adicionar" tamanho="medio" onClick={adicionarProduto}>
            Adicionar Produto
          </Botao>
        </OpcoesContainer>

        <TabelaContainer>
          <Tabela
            title="Produtos"
            haveActions="editdelete"
            isLoading={isDataLoading}
            columns={columns}
            data={listAll}
            onRowClick={(e: any, rowData: any): any => {
              setListAll(rowData);
              rowSelected(e, rowData);
            }}
            excluirClick={(e: any, rowData: any): any => {
              setListAll(rowData);
              setDeleteModalOpen(true);
              setProductData(rowData);
            }}
            editarClick={(e: any, rowData: any): any => {
              editSelected(e, rowData);
            }}
            pagination
            paginationPageSize={10}
          />
        </TabelaContainer>

        <DeleteModal
          isModalOpen={deleteModalOpen}
          onCloseModal={() => setDeleteModalOpen(false)}
          onDeleteClick={() => deletarRegistro(productData)}
        />
        <Snackbars
          type={snackbarProps.type}
          handleClose={handleSnackbarClose}
          open={snackbarOpen}
        >
          {snackbarProps.message}
        </Snackbars>
      </Content>
    </>
  );

};

export default Pedidos;
