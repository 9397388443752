/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import UpdateIcon from '@material-ui/icons/Update';
import { useSelector } from 'react-redux';
import { RootState } from '../../storage/rootReducer';

import { Content, TabelaContainer, Status } from './styles';
import Tabela from '../../components/Tabelas/Tabela';
import HeaderProposta from '../../components/HeaderProposta';
import cores from '../../styles/cores';
import PropostasService from '../../services/PropostasService';
import { isSignedIn } from '../../services/auth';

const Propostas: React.FC = () => {
  const [detalhes, setDetalhes] = useState(false);
  const [userData, setUserData] = useState({});
  const [pageMenu, setPageMenu] = useState('principal');
  const [listarPropostas, setListarPropostas] = useState<any[]>();
  const [listPhysical, setListPhysical] = useState([]);
  const [listLegal, setListLegal] = useState([]);
  const history = useHistory();
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [id, setId] = useState('');
  const { state } = useLocation();
  const { token } = useSelector((state: RootState) => state.userReducer);

  const list = useCallback(async () => {
    setIsDataLoading(true);
    const physical = await PropostasService.getProposalsPhysical();
    let physicalFormatted = physical.proposalsPhysical.map((item: any) => {
      let documento;
      let nome;
      let physical_formatted;

      if (item.proposals_participants.length > 0) {
        const findPrincipal = item.proposals_participants_type_percentage.find(
          (proposals_participant_type_percentage: {
            tipo_participante: string;
          }) =>
            proposals_participant_type_percentage.tipo_participante ===
            'principal',
        );
        const principal = item.proposals_participants.find(
          (proposals_participant: { id: any }) =>
            proposals_participant.id === findPrincipal?.id_participante,
        );
        documento = principal?.cpf;
        nome = principal?.nome;
      }

      physical_formatted = {
        id: item.id,
        nome: nome,
        documento: documento,
        status: item.status,
        atualizacao: item.updated_at,
        estado_civil: item.estado_civil,
        sexo: item.sexo,
        data_nascimento: item.data_nascimento,
        num_filhos: item.num_filhos,
        renda_familiar: item.renda_familiar,
        num_entradas: item.num_entradas,
        valor_entrada: item.valor_entrada,
        num_parcelas: item.num_parcelas,
        valor_parcelas: item.valor_parcelas,
        valor_total: item.valor_total,
        objetivo: item.objetivo,
        telefone: item.telefone,
        email: item.email,
        criacao: item.created_at,
        documentosFisica: item.documentos,
        participantes: item.proposals_participants,
        empreendimento: item.empreendimento,
        clt: item.clt,
        cod_mega_proposta_ativa: item.cod_mega_proposta_ativa,
        quadra_lote:
          item.quadra_lote === '0/0' ? 'Não se aplica' : item.quadra_lote,
        tipo: 'physical',
      };

      if (
        item.data_vencimento_parcela === null ||
        item.data_vencimento_parcela === undefined ||
        item.data_vencimento_sinal === null ||
        item.escolaridade === null ||
        item.escolaridade === '' ||
        item.clt === '' ||
        item.clt === undefined ||
        item.clt === null ||
        item.clt === 'null' ||
        item.clt === 'undefined' ||
        item.clt === 'null' ||
        item.clt === 'undefined'
      ) {
        physical_formatted = {};
      }

      return physical_formatted;
    });
    physicalFormatted.sort((a: any, b: any) => {
      const dataA = new Date(a.atualizacao);
      const dataB = new Date(b.atualizacao);
      return dataB.getTime() - dataA.getTime();
    });
    setListarPropostas(physicalFormatted);
    setIsDataLoading(false);
  }, []);

  useEffect(() => {
    if (!isSignedIn()) {
      history.push('/');
    }
    list();
  }, []);

  const columns = [
    {
      title: 'Última atualização',
      field: 'atualizacao',
      type: 'date',
    },
    {
      title: 'Participante Principal',
      field: 'nome',
    },
    {
      title: 'Documento (CPF/CNPJ)',
      field: 'documento',
    },
    {
      title: 'Empreedimento',
      field: 'empreendimento',
    },
    {
      title: 'Quadra / Lote',
      field: 'quadra_lote',
    },
    {
      title: 'Código',
      field: 'cod_mega_proposta_ativa',
    },
    {
      title: 'Status',
      field: 'status',
      render: (rowData: any) => {
        if (rowData.status === 'Em Análise') {
          return (
            <Status>
              {/* <Ball color="yellow" /> */}
              <UpdateIcon
                style={{ fill: 'orange', marginRight: 5, fontSize: 26 }}
              />
              Em análise
            </Status>
          );
        }
        if (rowData.status === 'Aprovado') {
          return (
            <Status>
              {/* <Ball color="green" /> */}
              <DoneIcon
                style={{ fill: 'green', marginRight: 5, fontSize: 26 }}
              />
              Aprovado
            </Status>
          );
        }
        if (rowData.status === 'Anexar Documentos') {
          return (
            <Status>
              {/* <Ball color="blue" /> */}
              <AttachFileIcon
                style={{ fill: cores.blue, marginRight: 5, fontSize: 26 }}
              />
              Anexar documentos
            </Status>
          );
        }
        if (rowData.status === 'Reprovado') {
          return (
            <Status>
              {/* <Ball color="red" /> */}
              <ClearIcon
                style={{ fill: 'red', marginRight: 5, fontSize: 26 }}
              />
              Reprovado
            </Status>
          );
        }
        return <Status>{rowData.status || 'Status não definido'}</Status>;
      },
    },
  ];

  const [isModalOpen, setModalOpen] = useState(false);

  function rowSelected(e: any, rowData: any) {
    history.push({
      pathname: '/painel/proposta/editar',
      state: { rowData },
    });
  }
  function adicionarCorretor() {
    setPageMenu('adicionar');
  }

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setModalOpen(false);
    setOpen(true);
  };

  return (
    <>
      <HeaderProposta />
      <Content>
        <TabelaContainer>
          <Tabela
            title="Propostas"
            haveActions="edit"
            columns={columns}
            isLoading={isDataLoading}
            data={listarPropostas}
            onRowClick={(e: any, rowData: any): any => {
              setListarPropostas(rowData);
              rowSelected(e, rowData);
            }}
            excluirClick={() => {
              console.log('');
            }}
            editarClick={(e: any, rowData: any): any => {
              setListarPropostas(rowData);
              rowSelected(e, rowData);
            }}
            pagination
            paginationPageSize={10}
          />
        </TabelaContainer>
      </Content>
    </>
  );
};

export default Propostas;
