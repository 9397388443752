import styled from 'styled-components';

export const BotaoContainer = styled.div`
  display: flex;
  border: 0px solid red;
  justify-content: center;
  align-items: center;
  height: fit-content;
  margin-bottom: 0px;

  .btn {
    width: 50%;
    margin-bottom: 0px;
  }
`;

export const Container = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;

  h2 {
    font-size: 1.3rem;
    font-weight: 600;
    color: var(--grey);
    
  }

  h3 {
    font-size: 1rem;
    color: var(--grey);
    justify-content: flex-start;
    padding: 10px;
    width: 150px;
  }

  #input-row {
    display: flex;
    justify-content: flex-start;
    font-size: 1rem;
    align-items: center;
    /* margin-left:100px; */
  }

  #loading-div {
    display: flex;
    height:10px;
    justify-content: center;
    font-size: 1rem;
    align-items: center;
    /* margin-left:100px; */
  }

  .spinner {
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }



  /* #input, .input-data {
    width: 100%;
    width: 270px;
    height: 2rem;
    padding: 0.5rem;
    border: none;
    border-radius: 0.5rem;
  } */

  #botoes {
    display: flex;
    justify-content: space-between;
  }

  .input-file {
    display: inline-block;
    position: relative;
    /* overflow: hidden; */
    width: 300px;
  }

  /* .input-file input[type="file"] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }

  .input-file::before {
    content: 'Selecione um arquivo';
    display: inline-block;
    padding: 10px;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    background-color: #333;
    border-radius: 3px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }

  .input-file:hover::before {
    background-color: #008ba3;
  }

  .input-file input:focus + ::before {
    outline: 1px solid #00bcd4;
  } */

  
`
