import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import Botao from '../../../components/Botao/Botao';
import UsuariosService from '../../../services/UserService';
import { Content, DetalhesContent, DetalhesHeader, Usuario } from '../styles';
import { DetalhesContainer, EnterprisesTable, TitlesHeader } from './styles';
import user from '../../../storage/modules/user/userReducer';
import EmpreendimentosIcon from '../../../assets/Icons/EmpreendimentosIcon';

const DetalhesCorretores = () => {
  const history = useHistory();
  const location = useLocation<any>();
  const id = location.state.id;

  const [name, setName] = useState();
  const [document, setDocument] = useState();
  const [email, setEmail] = useState();
  const [creci, setCreci] = useState('');
  const [birth_date, setBirth_date] = useState<any>();
  const [phone, setPhone] = useState();
  const [agency_name, setAgency_name] = useState();
  const [avatar_url, setAvatar_url] = useState(
    'https://upload.wikimedia.org/wikipedia/commons/thumb/2/24/Missing_avatar.svg/240px-Missing_avatar.svg.png',
  );
  const [cep, setCep] = useState();
  const [rua, setRua] = useState();
  const [numero, setNumero] = useState();
  const [bairro, setBairro] = useState();
  const [cidade, setCidade] = useState();
  const [uf, setUf] = useState();
  const [complemento, setComplemento] = useState();
  const [agency_id, setAgencyId] = useState();
  const [enterprises, setEnterprises] = useState([]);
  let userData;

  const fetchUserData = useCallback(async () => {
    userData = await UsuariosService.getUserData(id);

    setName(userData.user.name);
    setDocument(userData.user.document);
    setEmail(userData.user.email);
    const date = new Date(userData.user.birth_date);

    setBirth_date(
      `${date.getDate()+1}/${date.getMonth() + 1}/${date.getFullYear()}`,
      
    );

    setPhone(userData.user.phone);
    setAgency_name(userData.user.agency.nome ? userData.user.agency.nome : '');
    setAgencyId(userData.user.agency_id);
    setAvatar_url(userData.user.avatar_url);
    setCreci(userData.user.creci || '');
    setCep(userData.user.address.cep);
    setRua(userData.user.address.rua);
    setNumero(userData.user.address.numero);
    setBairro(userData.user.address.bairro);
    setCidade(userData.user.address.cidade);
    setUf(userData.user.address.uf);
    setComplemento(userData.user.address.complemento);
    setEnterprises(userData.user.enterprises);
  }, []);

  useEffect(() => {
    fetchUserData();
  }, []);

  function editarUsuario() {
    history.push({
      pathname: '/painel/corretores/editar',
      state: {
        id,
        name,
        document,
        email,
        birth_date,
        phone,
        agency_name,
        agency_id,
        avatar_url,
        cep,
        rua,
        numero,
        bairro,
        cidade,
        uf,
        complemento,
        enterprises,
        creci,
      },
    });
  }

  const goToEnterprise = (enterpriseName: string) => {
    history.push({ pathname: '/painel/lotes', state: enterpriseName });
  };

  return (
    <>
      <Content>
        <DetalhesContainer>
          <DetalhesHeader>
            <Usuario>
              <img
                src={
                  avatar_url ||
                  'https://upload.wikimedia.org/wikipedia/commons/thumb/2/24/Missing_avatar.svg/240px-Missing_avatar.svg.png'
                }
                style={{ width: 128, height: 128, borderRadius: '100%' }}
                alt="avatar"
              />
              <h1 className="nomeUser">{name}</h1>
            </Usuario>
            <Botao
              tipo="editar"
              tamanho="grande"
              className="btn"
              onClick={editarUsuario}
            >
              Editar
            </Botao>
          </DetalhesHeader>
          <DetalhesContent>
            <div className="item">
              <h2>Nome </h2>
              <p>{name || 'Ausente'} </p>
            </div>
            <div className="item">
              <h2>CPF </h2>
              <p>{document || 'Ausente'} </p>
            </div>
            <div className="item">
              <h2>Imobiliária </h2>
              <p>{agency_name || 'Ausente'} </p>
            </div>
            <div className="item">
              <h2>Telefone </h2>
              <p>{phone || 'Ausente'} </p>
            </div>
            <div className="item">
              <h2>Email </h2>
              <p>{email || 'Ausente'} </p>
            </div>
            <div className="item">
              <h2>CRECI </h2>
              <p>{creci || 'Ausente'}</p>
            </div>
            <div className="item">
              <h2>Data de nascimento </h2>
              <p>{birth_date || 'Ausente'} </p>
            </div>
          </DetalhesContent>

          <TitlesHeader>
            <EmpreendimentosIcon />
            <h1>Empreendimentos</h1>
          </TitlesHeader>

          <EnterprisesTable>
            {enterprises.length <= 0 && (
              <>
                <p className="emptyEmps">Nenhum empreendimento associado!</p>
              </>
            )}
            <tr>
              {enterprises.map((el: any, index) => {
                return (
                  <>
                    {index < 10 && (
                      <td>
                        <div
                          onClick={() => goToEnterprise(el.name)}
                          onKeyDown={() => goToEnterprise(el.name)}
                          role="button"
                          tabIndex={0}
                        >
                          {' '}
                          {el.name}{' '}
                        </div>
                      </td>
                    )}
                  </>
                );
              })}
            </tr>
            <tr>
              {enterprises.map((el: any, index) => {
                return (
                  <>
                    {index >= 11 && index < 21 && (
                      <td>
                        <div
                          onClick={() => goToEnterprise(el.name)}
                          onKeyDown={() => goToEnterprise(el.name)}
                          role="button"
                          tabIndex={0}
                        >
                          {' '}
                          {el.name}{' '}
                        </div>
                      </td>
                    )}
                  </>
                );
              })}
            </tr>
            <tr>
                    {enterprises.map((el: any, index: any) => {
                      return (
                        <>
                          {index >= 22 && (
                            <td>
                              <div
                                onClick={() => goToEnterprise(el.name)}
                                onKeyDown={() => goToEnterprise(el.name)}
                                role="button"
                                tabIndex={0}
                              >
                                {' '}
                                {el.name}{' '}
                              </div>
                            </td>
                          )}
                        </>
                      );
                    })}
                  </tr>
          </EnterprisesTable>

          <TitlesHeader>
            <HomeOutlinedIcon />
            <h1>Endereço</h1>
          </TitlesHeader>

          <DetalhesContent>
            <div className="item">
              <h2>CEP </h2>
              <p>{cep || ''} </p>
            </div>
            <div className="item">
              <h2>Logradouro </h2>
              <p>{rua || ''} </p>
            </div>
            <div className="item">
              <h2>Número </h2>
              <p>{numero || ''} </p>
            </div>
            <div className="item">
              <h2>Complemento </h2>
              <p>{complemento || ''} </p>
            </div>
            <div className="item">
              <h2>Bairro </h2>
              <p>{bairro || ''} </p>
            </div>
            <div className="item">
              <h2>Cidade </h2>
              <p>{cidade || ''} </p>
            </div>
            <div className="item">
              <h2>UF </h2>
              <p>{uf || ''} </p>
            </div>
          </DetalhesContent>
        </DetalhesContainer>
      </Content>
    </>
  );
};

export default DetalhesCorretores;
