import CheckIcon from '@mui/icons-material/Check';
import Botao from '../Botao/Botao';
import Modal from '../Modal/Modal';
import {
  BotaoContainer,
  ModalContainerDelete,
  ModalTitle,
  Paragraph,
  SpinnerConatainer,
} from './styles';

interface UpdateModalProps {
  isModalOpen: boolean;
  onCloseModal: () => void;
  valorParcela: any;
  onLancarProposta: () => void;
  VPL: string;
  desconto: any;
}

const ModalCalcApproved = (props: UpdateModalProps): any => {
  const {
    isModalOpen,
    onCloseModal,
    valorParcela,
    onLancarProposta,
    VPL,
    desconto,
  } = props;
  return (
    <Modal isOpen={isModalOpen} width={420} onClose={onCloseModal}>
      <>
        <ModalContainerDelete>
          <ModalTitle>Calculo feito com sucesso!</ModalTitle>
          <SpinnerConatainer>
            <CheckIcon htmlColor="green" style={{ fontSize: 50 }} />
          </SpinnerConatainer>
          <Paragraph>O valor da parcela é {valorParcela}</Paragraph>
          <Paragraph>O valor do VPL é {VPL}</Paragraph>
          {desconto && (
            <Paragraph>
              O desconto implícito da proposta é{' '}
              {desconto ? `${desconto.toPrecision(3)}%` : ''}
            </Paragraph>
          )}
          <BotaoContainer>
            <Botao
              tamanho="medio"
              tipo="salvar"
              className="btn"
              onClick={() => {
                onLancarProposta();
                onCloseModal();
              }}
            >
              Lançar proposta
            </Botao>
          </BotaoContainer>
        </ModalContainerDelete>
      </>
    </Modal>
  );
};

export default ModalCalcApproved;
