import { DataArray } from '@mui/icons-material';
import { useRef, useState } from 'react';
import ReactToPrint from 'react-to-print';
import PdfCp from '../Pdfs/PdfCp';

interface IData {
  data: any;
}

const PrintPdf = (props: IData): any => {
  const { data } = props;
  let componentRef = useRef(null);
  const title = `Contrato_de_pre_Compra_${data.nome}_${data.quadra_lote}` 

  return (
    <div>
      <ReactToPrint
        trigger={() => <button type="button">IMPRIMIR PDF</button>}
        content={() => componentRef.current}
        documentTitle={title}
      />

      <PdfCp data={{ ...data }} ref={componentRef} />
    </div>
  );
};

export default PrintPdf;
