import React from 'react';

const PedidosIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="682.667"
      height="682.667"
      version="1"
      viewBox="0 0 512 512"
    >
      <path
        d="M1065 4310c-629-363-954-556-962-572-10-18-12-261-10-1120 2-1083 2-1098 22-1117 17-17 1713-1002 1863-1082 49-26 56-28 86-15 17 7 247 138 511 290l480 276 5-237c5-230 6-240 31-293 33-70 79-116 149-149l55-26h1500l52 24c65 29 134 101 159 166 18 48 19 89 19 1120v1070l-21 46c-31 67-87 124-152 156-53 26-66 28-218 31-162 4-162 4-168 28-9 35-73 102-120 127-36 19-61 22-203 25l-163 4v327c0 239-4 332-13 350-14 27-1900 1121-1932 1120-11 0-447-247-970-549zm1383 170c204-118 368-218 364-222-20-19-1727-998-1737-996-18 4-775 442-775 448 0 3 391 230 868 506 759 439 870 500 888 490 11-6 188-108 392-226zm918-530c220-127 400-234 402-238 4-12-1709-997-1733-997-19 1-815 454-815 465 0 7 1723 1000 1736 1000 7 0 191-104 410-230zm-2256-863c459-264 841-485 848-491 9-9 12-220 10-1014l-3-1002-865 498-865 499-3 1007-2 1007 22-12c13-7 399-228 858-492zm2730 238v-265l-27-5c-59-12-134-58-162-99-15-23-30-48-34-57-5-14-29-17-164-21-136-4-165-7-208-27-65-29-128-91-157-155l-23-51-3-760-3-760-472-273-472-272-3 1002c-1 551 2 1006 7 1011 8 8 1712 997 1718 997 2 0 3-119 3-265zm481-434c24-24 29-38 29-76s-5-52-29-76l-29-29h-494l-29 29c-24 24-29 38-29 76s5 52 29 76l29 29h494l29-29zm-685-194c28-57 90-103 159-117 36-7 137-10 283-8l227 3 55 31c41 23 63 44 85 82l30 51 149 1c170 0 206-12 245-80l21-38V1570c0-1164 4-1094-64-1143l-31-22-745-3c-730-2-746-2-778 18-18 11-41 34-52 52-20 33-20 49-18 1103l3 1070 22 31c40 55 71 64 238 63h150l21-42z"
        transform="matrix(.1 0 0 -.1 0 512)"
      />
      <path
        d="M3412 2330c-37-15-43-52-40-242 3-203 5-208 85-215 60-6 100 9 115 42s-2 72-37 85c-24 9-25 12-25 105v95h95c82 0 98-3 115-20 24-24 72-26 94-4 39 38 30 129-14 151-30 15-352 17-388 3z"
        transform="matrix(.1 0 0 -.1 0 512)"
      />
      <path
        d="M3845 2160c-60-60-114-110-119-110s-28 18-52 40c-49 45-74 50-104 20-38-38-27-69 53-147 39-39 83-74 96-78 20-6 43 12 178 148 136 136 154 158 148 178-11 37-36 59-64 59-20 0-52-26-136-110zM4182 2160c-53-22-51-99 4-120 37-15 457-13 489 2 46 21 46 101-1 118-32 13-461 12-492 0zM3412 1690c-37-16-43-52-40-242 3-154 5-180 21-195 16-16 39-18 212-18s196 2 212 18 18 39 18 212-2 196-18 212c-15 16-40 18-200 20-100 1-192-2-205-7zm288-225v-95h-190v190h190v-95zM4182 1520c-53-22-51-99 4-120 37-15 457-13 489 2 46 21 46 101-1 118-32 13-461 12-492 0zM3412 1050c-37-15-43-52-40-242 3-203 5-208 85-215 60-6 100 9 115 42s-2 72-37 85c-24 9-25 12-25 105v95h94c94 0 95 0 108-27 10-22 21-29 48-31 50-4 71 15 77 71 7 57-10 100-43 116-30 13-348 14-382 1z"
        transform="matrix(.1 0 0 -.1 0 512)"
      />
      <path
        d="M3841 865l-117-115-41 41c-45 46-81 53-113 24-39-35-27-71 54-151 55-56 80-74 101-74s56 28 162 133c74 72 141 144 149 159 23 45-10 98-62 98-9 0-69-52-133-115zM4182 880c-53-22-51-99 4-120 37-15 457-13 489 2 46 21 46 101-1 118-32 13-461 12-492 0z"
        transform="matrix(.1 0 0 -.1 0 512)"
      />
    </svg>
  );
};

export default PedidosIcon;
