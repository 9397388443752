import { TextField } from '@material-ui/core';
import React, { useState } from 'react';
import { FaSpinner } from 'react-icons/fa';

import Snackbars from '../Snackbars';
import { BotaoContainer, Container } from './styles';
import Botao from '../Botao/Botao';
import Modal from '../Modal/Modal';
import apiAutomacao from '../../services/api-automacao';

interface deleteModalProps {
  isModalOpen: boolean;
  onDeleteClick: any;
  onCloseModal: any;
  value: Array<any>;
  dadosImobiliaria: { [key: string]: any };
}

const ModalComissaoNF = (props: deleteModalProps): any => {
  const { isModalOpen, onDeleteClick, onCloseModal, value, dadosImobiliaria} = props;
  const [areYouSure, setAreYouSure] = useState(true);
  // Constantes de carregamento 
  const [isLoading, setIsLoading] = useState(true);
  const [enviando, setEnviando] = useState(false);
  // Constantes do snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarProps, setSnackbarProps] = useState({message: 'Atualizado com Sucesso', type: 'success'});
  // Constantes de informações do Modal
  const [numeroNota, setNumeroNota] = useState("");
  const [pdfFileNF, setPdfFileNF] = useState<File | null>(null);
  // const [xmlFileNF, setXmlFileNF] = useState<File | null>(null);
  // Variaveis que vieram da tela 
  const dadosNf = value
  const valorNota = value[0]?.valor_total ?? 0;
  // Dentro do componente ModalComissaoNF
  const dadosImob = dadosImobiliaria

  // Função que chama o snackbar
  const handleSnackbarClose = ( 
    event?: React.SyntheticEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  // Função que envia informação para back
  const enviarNotaFiscal = () => {
    setSnackbarProps({message: `Estamos analisando a Nota, por favor aguarde.`, type: 'info'});
    setSnackbarOpen(true);

    setEnviando(true)
    // Verifique se todos os campos obrigatórios foram preenchidos corretamente
    if (numeroNota === "" || pdfFileNF === null) {
      // Caso algum campo obrigatório esteja faltando, exiba uma mensagem de erro ou aviso ao usuário
      setEnviando(false)
      setSnackbarProps({message: `Por favor preencha todos os dados`, type: 'warning'});
      setSnackbarOpen(true);
      return;
    }
  
    // Transforma os dados em uma string JSON
    const jsonData = JSON.stringify(value);
    const jsonImob = JSON.stringify(dadosImob);

    const formData = new FormData();
    if (pdfFileNF) {
      formData.append("pdf_file", pdfFileNF);
    }
    // if (xmlFileNF) {
    //   formData.append("xml_file", xmlFileNF);
    // }
    if (dadosNf) {
      formData.append("dados_nf", jsonData);
    }
    if (jsonImob) {
      formData.append("dadosImob", jsonImob);
    }
    if (numeroNota) {
      formData.append("numero_nf", numeroNota);
    }
    formData.append("token", "w5wac68Ho79ML577qRFDxjCiqaH6409d7b7cc31f");
    
    apiAutomacao.post(`/comissao/PostNotaFiscalImobiliaria`, formData)
    .then(response => {
      const data = response.data;
      if (data.status === '200') {
        setSnackbarProps({ message: data.message, type: 'success' });
        setSnackbarOpen(true);
        setEnviando(false);
      } else if (data.status === '500') {
        setSnackbarProps({ message: data.message, type: 'error' });
        setSnackbarOpen(true);
        setEnviando(false);
      } else {
        setSnackbarProps({ message: data.message, type: 'error' });
        setSnackbarOpen(true);
        setEnviando(false);
      }
    })
    .catch(error => {
      console.error('erro', error);
      setSnackbarProps({ message: 'Erro interno', type: 'error' });
      setSnackbarOpen(true);
      setEnviando(false);
    });



  }
  
  return (
    <Modal
      isOpen={isModalOpen}
      width={540}
      onClose={() => {
        setAreYouSure(true);
        onCloseModal();
        setSnackbarOpen(false);
        setNumeroNota(""); 
        setPdfFileNF(null); 
        // setXmlFileNF(null);
      }}
    >
      <>
        <Container>
          <h2> INSERIR NOTA FISCAL </h2>
          <br/>
          <br/>
          
          <div id="input-row">
            <h3> Número NF </h3>
            <input id="input" type="number" value={numeroNota} onChange={(e) => setNumeroNota(e.target.value)} />
          </div>
          <br/>
          
          {/* <div id="input-row">
            <h3>Arquivo XML</h3>
            <div className="input-file">
              <input id="input" type="file" accept="text/xml" className="file-input" onChange={(e) => setXmlFileNF(e.target.files ? e.target.files[0] : null)} />
            </div>
          </div>
          <br/> */}
          
          <div id="input-row">
            <h3>Arquivo PDF</h3>
            <div className="input-file">
              <input id="input" type="file" accept="application/pdf" onChange={(e) => setPdfFileNF(e.target.files ? e.target.files[0] : null)} />
            </div>
          </div>
          <br/>
          
          <div id="input-row">
            <h3>Valor Esperado</h3>
            <input id="input" type="text" value={`R$ ${valorNota.toLocaleString('pt-br')}`} readOnly />
          </div>
          <br/>

          <BotaoContainer>
            <Botao
              tipo='adicionar'
              tamanho="medio"
              className="btn"
              onClick={() => {
                enviarNotaFiscal();
              }}
              disabled={enviando}
            >
              {enviando ? (
                <> 
                  {/* Enviando {'  '} */}
                  <FaSpinner className="spinner" /> 
                </>
                ) : ('Enviar Nota Fiscal')}
            </Botao>
          </BotaoContainer>
          
        </Container>

      <Snackbars
        type={snackbarProps.type}
        handleClose={handleSnackbarClose}
        open={snackbarOpen}
      >
        {snackbarProps.message}
      </Snackbars>
      </>
    </Modal>
  );
};

export default ModalComissaoNF;
