import Botao from '../Botao/Botao';
import Modal from '../Modal/Modal';
import { BotaoContainer, ModalContainerDelete } from './styles';

interface updateModalProps {
  isModalOpen: boolean;
  onUpDateClick: any;
  onCloseModal: any;
}

const ModalCP = (props: updateModalProps): any => {
  const { isModalOpen, onUpDateClick, onCloseModal } = props;

  return (
    <Modal
      isOpen={isModalOpen}
      width={420}
      onClose={() => {
        onCloseModal();
      }}
    >
      <>
        <ModalContainerDelete>
          <h1 style={{marginBottom: '12%', marginTop: '-2%'}}>Atenção!</h1>
          <p style={{textAlign: 'center', marginBottom: '1%'}}>Por favor, preencha todos os campos da CP.</p>
          <p style={{textAlign: 'center', width: '93%', lineHeight: 1.5}}>Logo disponibilizaremos CP preenchida automáticamente.</p>
          <BotaoContainer>
            <Botao
              tipo="editar"
              tamanho="medio"
              className="btn"
              disabled={false}
              onClick={() => {
                onUpDateClick();
                setTimeout(() => {
                  onCloseModal()
                }, 100)
              }}
            >
              OK
            </Botao>
            <Botao
              tamanho="medio"
              className="btn"
              onClick={() => {
                onCloseModal();
              }}
            >
              Cancelar
            </Botao>
          </BotaoContainer>
        </ModalContainerDelete>
      </>
    </Modal>
  );
};

export default ModalCP;
