import styled, { css } from 'styled-components';
import Input from '@material-ui/core/Input';
import { TextField } from '@material-ui/core';

// props: InputProps

export const InputContainer = styled.div`
  display: flex;
  margin-top: 100px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  /* width: 100%; */
  height: auto;

  .toplabel {
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 16px;
  }

  .input {
    display: flex;
    width: 300px;
  }

  .error-message {
    display: flex;
    align-items: center;
    color: red;
    margin: 5px 20px 0 0;

    .error-message-icon {
      margin-right: 5px;
      font-size: 20px;
    }
  }

  .error {
    color: red;
  }


`;


export const TextInput = styled(TextField)`

.MuiInputLabel-formControl.Mui-disabled{
  color: #666666 !important;
}

.MuiOutlinedInput-root{
  
  .Mui-disabled{
    border-color: #666666 !important;
    border-radius: 4px;
    color: #666666 !important;

  }
}

`