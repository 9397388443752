import styled from 'styled-components';
import { Modal } from '@material-ui/core';
import cores from '../../styles/cores';

interface IModalWrapper {
  width?: number;
}

export const Container = styled(Modal)``;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ModalWrapper = styled.div`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  width: ${(props: IModalWrapper) =>
    props.width ? `${props.width}px` : '50%'};
  background-color: white;
  padding: 24px 16px;
  padding-bottom: 8px;
  border-radius: 8px;
  position: relative;

  h1 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 24px;
  }

  .row {
    display: flex;
    width: 100%;
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .btn-modal {
    width: 50%;
  }
  .btn-modal {
    width: 100%;
  }

  .formWrapper {
    max-width: 100%;
    margin: 0 auto;

    p {
      margin-bottom: -10px;
      font-weight: bold;
    }
  }
`;

export const CloseModal = styled.div`
  position: absolute;
  display: flex;
  top: 10px;
  right: 16px;

  .closeIcone {
    width: 42px;
    height: 42px;
    fill: ${cores.purple_1} !important;
  }

  .closeIcone:hover {
    transition: 0.5s;
    transform: rotate(90deg);
    fill: red !important;
  }
`;
