/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable class-methods-use-this */
import AppError from '../errors/AppError';
import api from './api';
import { getToken } from './auth';

class EnterprisesService {
  private headers: any;

  constructor() {
    this.headers = {
      Authorization: `Bearer: ${getToken()}`,
    };
  }

  public async listAllEmpreendimentos() {
    try {
      const { data } = await api.get(`/enterprises/list`, {
        headers: {
          Authorization: `Bearer: ${getToken()}`,
        },
      });
      return data;
    } catch (err: any) {
      throw new AppError(err, err.statusCode);
    }
  }

  public async findEmpreendimento(id: string | number) {
    try {
      const { data } = await api.get(`/enterprises/${id}`);
      return data;
    } catch (err: any) {
      throw new AppError(err, err.statusCode);
    }
  }

  public async findEmpreendimentoByCodEmpreendimento(code: string) {
    try {
      const { data } = await api.get(`/enterprises/find-by-cod-empreendimento/${code}`);
      return data;
    } catch (err: any) {
      throw new AppError(err, err.statusCode);
    }
  }

  public async createEmpreendimento() {
    try {
      const { data } = await api.post(`/enterprises`);
      return data;
    } catch (err: any) {
      throw new AppError(err, err.statusCode);
    }
  }

  public async deleteEmpreendimento(id: string | number) {
    try {
      const { data } = await api.delete(`/enterprises/${id}`);
      return data;
    } catch (err: any) {
      throw new AppError(err, err.statusCode);
    }
  }

  public async updateEmpreendimento(id: string | number, params: any) {
    try {
      const { data } = await api.patch(`/enterprises/${id}`, ...params);
      return data;
    } catch (err: any) {
      throw new AppError(err, err.statusCode);
    }
  }
}

export default new EnterprisesService();

