import WarningIcon from '@mui/icons-material/Warning';
import Botao from '../Botao/Botao';
import Modal from '../Modal/Modal';
import {
  BotaoContainer,
  ModalContainerDelete,
  ModalTitle,
  Paragraph,
  SpinnerConatainer,
} from './styles';

interface UpdateModalProps {
  isModalOpen: boolean;
  onCloseModal: () => void;
  errorText: string;
}

const ModalErrors = (props: UpdateModalProps): any => {
  const { isModalOpen, onCloseModal, errorText } = props;
  return (
    <Modal isOpen={isModalOpen} width={420} onClose={onCloseModal}>
      <>
        <ModalContainerDelete>
          <ModalTitle>Ocorreu um erro!</ModalTitle>
          <SpinnerConatainer>
            <WarningIcon htmlColor="red" style={{ fontSize: 50 }} />
          </SpinnerConatainer>
          <Paragraph>{errorText}</Paragraph>
          <BotaoContainer>
            <Botao
              tamanho="medio"
              tipo="salvar"
              className="btn"
              onClick={() => {
                onCloseModal();
              }}
            >
              Continuar
            </Botao>
          </BotaoContainer>
        </ModalContainerDelete>
      </>
    </Modal>
  );
};

export default ModalErrors;
