import AppError from '../errors/AppError';
import apiAutomacao from './api-automacao';
import { getToken } from './auth';

class ComissaoService {
  private headers: any;

  constructor() {
    this.headers = {
      "Content-Type": "application/json",
      "Token": 'w5wac68Ho79ML577qRFDxjCiqaH6409d7b7cc31f',
      "Usuario":"APP"
    };
  }

  // Retornar parcelas de comissão da imobiliária
  public async listComissaoImobiliarias(creci_imob: string | number) {    
    let dados = {"creci_imob":creci_imob}
   
    try {
      const { data } = await apiAutomacao.post(`/comissao/GetComissaoInterfaceImob`, dados, {headers: this.headers});
      return data;
    } catch (err: any) {
      throw new AppError(err, err.statusCode);
    }
  }

    // Retornar parcelas de comissão da imobiliária
    public async listHistoricoComissao(creci_imob: string | number) {    
      let dados = {"creci_imob":creci_imob}
     
      try {
        const { data } = await apiAutomacao.post(`/comissao/GetProjecaoImobiliaria`, dados, {headers: this.headers});
        return data;
      } catch (err: any) {
        throw new AppError(err, err.statusCode);
      }
    }


  // Enviar dados de comissão para back
  public async sendNotaFiscalImobiliaria(params: FormData){
    console.log('params', params)
    try {
      const { data } = await apiAutomacao.post(`/comissao/PostNotaFiscalImobiliaria`, params, {headers: this.headers});
      return data;
    } catch (err: any) {
      throw new AppError(err, err.statusCode);
    }
  }
}

export default new ComissaoService();
