import CircularProgress from '@material-ui/core/CircularProgress';
import Modal from '../Modal/Modal';
import { ModalContainerDelete, SpinnerConatainer } from './styles';

interface updateModalProps {
  isModalOpen: boolean;
  onUpDateClick?: any;
  onCloseModal: any;
  isLoading: boolean;
}

const ModalWaitAlcada = (props: updateModalProps): any => {
  const { isModalOpen, onUpDateClick, onCloseModal, isLoading } = props;
  const spinner = <CircularProgress color="primary" size={40} />;
  const texto = '';
  return (
    <Modal
      isOpen={isModalOpen}
      width={420}
      onClose={() => {
        onCloseModal();
      }}
    >
      <>
        <ModalContainerDelete>
          <h1 style={{ marginBottom: '12%', marginTop: '-2%' }}>Atenção!</h1>
          <p>{isLoading ? '' : texto}</p>
          <SpinnerConatainer
            style={{ marginLeft: '45%', marginBottom: '5%' }}
            className={isLoading ? '' : 'hide'}
          >
            {isLoading ? spinner : ''}
          </SpinnerConatainer>
          <p style={{ textAlign: 'center', marginBottom: '1%' }}>
            Aguarde enquanto criamos a proposta personalizada.
          </p>
        </ModalContainerDelete>
      </>
    </Modal>
  );
};

export default ModalWaitAlcada;
