import styled, { css } from 'styled-components';
import cores from '../../../styles/cores';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100vw;
  height: 100vh;
  overflow-x: auto;
`;

export const DetalhesContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  width: 90%;
  height: fit-content;
  align-self: center;
  border-radius: 8px;
  box-shadow: ${cores.shadow_1};
  margin-bottom: 120px;
  padding: 2.5rem 2.5rem 0rem;

  h1 {
    align-self: flex-start;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 20px;
    font-size: 24px;
    font-weight: bold;
  }
`;

export const DetalhesHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  .btn {
    height: 50px;
  }
`;

export const Usuario = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;


  h1 {
    border: 0px solid black;
    align-self: center;
    width: 100%;
    margin: 2rem;
    font-size: 23px;
    font-weight: bold;
    color: #6b6b6b;
  }

  img {
    border: 0px solid black;
  }

  svg {
    border: 0px solid black;
  }
`;

export const BotoesContainer = styled.div`
  display: flex;
  border: 0px solid black;
  align-self: flex-end;
  padding: 0;
  width: 100%;

  .btn-salvar {
    margin-right: 20px;
  }

  .btn-exluir {
  }
`;

export const TrocarFoto = styled.div`
  position: absolute;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 1px solid lightgrey;
  display: flex;
  justify-content: center;
  align-self: flex-end;
  align-items: center;
  transform: translate(85px, -30px);
  background-color: white;
  cursor: pointer;
  svg {
    font-size: 26px;
    fill: ${cores.purple_1};
  }
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: fit-content;
  height: 100%;
`;

export const DetalhesContent = styled.div`
  display: flex;

  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  max-width: 100%;

  margin: 2.5rem 0rem;

  .div-inputs {
    width: 100%;
  }

  .div-categoria-preço {
    width: 100%;

    display: flex;
    flex-flow: row nowrap;
    
  }

  .item {
    display: flex;
    flex-direction: column;
    border: 0px solid black;
    width: fit-content;
    margin-top: 10px;
    margin-right: 50px;
  }

  .endereco {
    width: 100%;
    border: 0px solid black;
    font-size: 18px;
    margin: 10px 0;
    padding-left: 10px;
    h2 {
      font-weight: bold;
    }
  }
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0.8rem 0rem;
  width: 100%;

  .input {
    width: 250px;
  }

  .visually-hidden {
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
    opacity: 0;
  }

  &.descricao-container {
    border: 0px solid black;
    display: flex;
    flex-direction: column;
    width: 100%;
    h2 {
      font-size: 18px;
      font-weight: bold;
    }
  }

  &.upload-fotos {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`;

export const UploadLabel = styled.label`
  display: flex;
  width: 300px;
  height: 200px;
  border: 2px dashed #ccc;
  margin: 20px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

// ${({ dragEnter }) =>
//   dragEnter &&
//   css`
//     border-color: #666;
//   `}

export const InputsContent = styled.div`
  display: flex;
  border: 0px solid black;
  flex-wrap: wrap;
  max-width: 100%;
  width: 100%;

  padding: 0;

  .dropbox {
    border: 0px solid black;
    align-self: flex-start;

    .picked-photos {
      display: flex;
      flex-wrap: wrap;
      align-self: center;
      width: 100%;
      max-width: 340px;
      height: inherit;
      border: 1px solid #eee;
      border-radius: 4px;
      padding: 6px 8px;

      li {
        list-style-type: none;
        margin-right: 4px;
        margin-bottom: 4px;
      }
      ul {
      }
    }

    .enviar-fotos {
      display: flex;
      border: 0px solid black;
      justify-content: center;
      height: 60px;

      .enviar-fotos-btn {
        border: 0px solid black;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;

        svg {
          margin-right: 5px;
        }
      }
    }
  }
`;
