import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useHistory, useLocation, Route, Switch } from 'react-router-dom';
import * as XLSX from "xlsx";
// import MaterialTable, { Column } from 'material-table';

import {
  Content,
  MenuLateralContainer,
  Header,
  TabelaContainer,
  ContainerTabela,
  Footer
} from './styles';
import Tabela from '../../components/Tabelas/Tabela';
import HeaderProposta from '../../components/HeaderProposta';
import MenuLateralImob from '../../components/MenuLateralImob/MenuLateralImob';
import Botao from '../../components/Botao/Botao';
import ModalComissaoNF from '../../components/ModalComissaoNF'
import ComissaoService from '../../services/ComissaoService';
import Snackbars from '../../components/Snackbars';
import BonificacaoService from '../../services/BonificacaoService';
import TabelaBonificacao from '../../components/Tabelas/TabelaBonificacao';
import ModalBonificacaoNF from '../../components/ModalBonificacaoNF';

import { getUserData, TOKEN_KEY } from '../../services/auth';
import UserService from "../../services/UserService";


interface DadoHistorico {
  id_proposta: number;
  data_proposta: Date;
  cliente: string;
  empreendimento: string;
  lote: string;
  quadra: string;
  valor_comissao: number;
  valor_pago_cliente: number;
  porcentagem_pgto_cliente: number;
  contrato_assinado: string;
  valor_pago_para_imobiliaria: number;
  valor_disponivel: number;
  tipo_nota: string;
  liberado_interface: string;
  // Adicione outras propriedades e seus tipos, se necessário
}

interface linhaBonificacao {
  id: number;
  valor_bonificacao: number;
  data_criacao: string;
  quant_propostas: number;
}

interface Bonificacao {
  total_bonificacao: number;
  cnpj_empreendimento: string;
  razao_social: string;
  cod_empreendimento: number;
  id_cond_imobiliaria: number;
  bonificacoes: linhaBonificacao[];
}


const Comissao: React.FC = () => {
  const history = useHistory();
  const { pathname } = history.location;
  // Constantes do snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarProps, setSnackbarProps] = useState({ message: 'Atualizado com Sucesso', type: 'success' });
  // Variavel de infos do usuario
  const [userData, setUserData] = useState({});
  // Varivel dos dados de comissão
  const [dadosComissao, setDadosComissao] = useState<Record<string, any>>({});
  const [dadosComissaoVazio, setDadosComissaoVazio] = useState([]);
  const [dadosHistorico, setDadosHistorico] = useState([]);
  const [dadosImobiliaria, setDadosImobiliaria] = useState({});
  // Varivel de carregamento
  const [isDataLoading, setIsDataLoading] = useState(false);
  // Dados selecionados
  const [dadoSelecionado, setDadoSelecionado] = useState<Array<any>>([]);
  const [dadoBonificacaoSelecionado, setDadoBonificacaoSelecionado] = useState<Array<any>>([]);
  // Modal
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [modalBonificacaoOpen, setModalBonificacaoOpen] = useState(false);
  const [dadosBonificacao, setDadosBonificacao] = useState<Record<string, any>>({});
  const [dadosBonificacaoSelecionado, setDadosBonificacaoSelecionado] = useState<Array<any>>([]);
  const [bonificacaoIds, setBonificacaoIds] = useState<number[]>([]);

  // Colunas da tabela
  const columns = [
    {
      title: 'Id Proposta',
      field: 'id_proposta',
    },
    {
      title: 'Cliente',
      field: 'cliente',
    },
    {
      title: 'Quadra',
      field: 'quadra',
    },
    {
      title: 'Lote',
      field: 'lote',
    },
    {
      title: 'Valor total comissão ',
      field: 'valor_comissao',
      render: (rowData: { valor_comissao: { toLocaleString: (arg0: string, arg1: { style: string; currency: string; }) => any; }; }) => rowData.valor_comissao.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
    },
    {
      title: 'Tipo da Nota',
      field: 'tipo_nota',
    },
    {
      title: 'Valor disponível para comissão',
      field: 'valor_disponivel',
      render: (rowData: { valor_disponivel: { toLocaleString: (arg0: string, arg1: { style: string; currency: string; }) => any; }; }) => rowData.valor_disponivel.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
    },


  ];

  // Função que chama o snackbar
  const handleSnackbarClose = (
    event?: React.SyntheticEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  // Função que chama os dados de comissão da imobiliaria
  const dadosComissaoImobiliaria = useCallback(async () => {
    console.log('Carregando dados....')
    setIsDataLoading(true);

    // Pegar infos do usuario 
    const dataToken = await getUserData();
    const userData = await UserService.getUserData(dataToken.id);

    console.log(userData)

    let creci_imob = userData.user.agency.creci_j
    let agency_cnpj = userData.user.agency.cnpj
    let user_email = userData.user.email
    let agency_id = userData.user.agency_id
    let agency_name = userData.user.agency.nome
    // creci_imob = "30270"

    let dados_imob = {
      "creci_imob": creci_imob,
      "agency_cnpj": agency_cnpj,
      "user_email": user_email,
      "agency_id": agency_id,
      "agency_name": agency_name
    }
    setDadosImobiliaria(dados_imob)
    // console.log(creci_imob)

    if (creci_imob === "") {
      // Creci não registrado na tabela agencies
      setIsDataLoading(false);
    } else {
      // Busca infos de comissão da imobiliária
      const infosComissao = await ComissaoService.listComissaoImobiliarias(creci_imob);
      const infosHistorico = await ComissaoService.listHistoricoComissao(creci_imob);
      // console.log('Dados comissão',infosComissao)

      // Atualiza o estado com os dados agrupados
      setDadosComissao(infosComissao);
      setDadosHistorico(infosHistorico);
      setIsDataLoading(false);
    }

  }, []);

  // Função que chama os dados de bonificação da imobiliária
  const dadosBonificacaoImobiliaria = useCallback(async () => {
    try {
      const dataToken = await getUserData();
      const userData = await UserService.getUserData(dataToken.id);

      let agencie = userData.user.agency_id

      const infoBonificacao = await BonificacaoService.listBonificacaoImobiliaria(agencie);
      setDadosBonificacao(infoBonificacao.empreendimentos);
    } catch (error) {
      console.error("Erro ao carregar as bonificações:", error);
    }
  }, []);

  useEffect(() => {
    dadosComissaoImobiliaria();
    dadosBonificacaoImobiliaria();
  }, []);

  function formatarData(data: string) {
    const dataObj = new Date(data);
    return dataObj.toLocaleDateString();
  }

  // Função para gerar o relatorio de projeção
  function gerarRelatorio(dados: DadoHistorico[]) {
    // Verifica se existem dados para gerar o relatório
    if (dados.length === 0) {
      console.log('A base de dados está vazia.');
      setSnackbarProps({ message: `Sem registro de dados`, type: 'error' });
      setSnackbarOpen(true);
      return;
    }

    // Percorra os dados para formatar a data
    const dadosFormatados = dados.map(item => ({
      ...item,
      // data_proposta: formatarData(item.data_proposta.toString()),
      valor_comissao: item.valor_comissao.toFixed(2), // Formata para 2 casas decimais
      valor_pago_cliente: item.valor_pago_cliente.toFixed(2),
      valor_pago_para_imobiliaria: item.valor_pago_para_imobiliaria.toFixed(2),
      valor_disponivel: item.valor_disponivel.toFixed(2),
    }));

    // Defina as larguras desejadas para as colunas
    const columnWidths = [
      { wch: 10 }, // Largura da primeira coluna
      { wch: 12 }, // Largura da segunda coluna
      { wch: 35 },
      { wch: 15 },
      { wch: 4 },
      { wch: 10 },
      { wch: 13 },
      { wch: 16 },
      { wch: 5 },
      { wch: 15 },
      { wch: 23 },
      { wch: 26 },
      { wch: 8 },
      { wch: 15 },
    ];
    // Define o estilo para o título em negrito
    const boldTitleStyle = {
      font: { bold: true },
      alignment: {
        horizontal: 'center',
        vertical: 'center',
      },
    };

    // Cria um novo workbook e worksheet
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(dadosFormatados);

    // Define as larguras das colunas no workbook
    worksheet['!cols'] = columnWidths;

    // Define titulo das colunas
    worksheet.A1 = { v: 'Id Proposta', s: boldTitleStyle };
    worksheet.B1 = { v: 'Data Proposta', s: boldTitleStyle };
    worksheet.C1 = { v: 'Cliente', s: boldTitleStyle };
    worksheet.D1 = { v: 'Empreendimento', s: boldTitleStyle };
    worksheet.E1 = { v: 'Lote', s: boldTitleStyle };
    worksheet.F1 = { v: 'Quadra', s: boldTitleStyle };
    worksheet.G1 = { v: 'Valor Comissão', s: boldTitleStyle };
    worksheet.H1 = { v: 'Valor Pago Cliente', s: boldTitleStyle };
    worksheet.I1 = { v: '%', s: boldTitleStyle };
    worksheet.J1 = { v: 'Contrato Assinado', s: boldTitleStyle };
    worksheet.K1 = { v: 'Valor Pago Para Imobiliaria', s: boldTitleStyle };
    worksheet.L1 = { v: 'Valor Disponivel Para Receber', s: boldTitleStyle };
    worksheet.M1 = { v: 'Tipo nota', s: boldTitleStyle };
    worksheet.N1 = { v: 'Liberado Interface', s: boldTitleStyle };

    // Adiciona o worksheet ao workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Relatório');

    // Gera o arquivo Excel em formato binário
    const excelBuffer = XLSX.write(workbook, { type: 'array', bookType: 'xlsx' });

    // Converte o arquivo binário para um blob
    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    // Cria um link para fazer o download do arquivo
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'projecao_comissao.xlsx';

    // Simula um clique no link para iniciar o download
    link.click();
  }

  return (
    <>
      <HeaderProposta />
      <Content>

        <MenuLateralContainer>
          <MenuLateralImob
            className="menuLateral"
            currentPage={{ pathname, data: userData }}
            data={userData}
            style={{ marginTop: '100px' }}
          />
        </MenuLateralContainer>
        <ContainerTabela>
          {isDataLoading || Object.keys(dadosComissao).length === 0 ? (
            <>
              <Header>
                <Botao
                  tipo="adicionar"
                  tamanho="medio"
                  className="btn"
                  disabled={false}
                  onClick={() => {
                    gerarRelatorio(dadosHistorico);
                  }}
                >
                  Baixar Projeção
                </Botao>
              </Header>
              {/*  Carregando ou tabela Vazia */}
              <TabelaContainer>
                <h2 id="titulo_tabela">Comissões</h2>
                <Tabela
                  title={"Empreendimento"}
                  haveActions="none"
                  columns={columns}
                  isLoading={isDataLoading}
                  data={dadosComissaoVazio}
                  onRowClick={(e: any, rowData: any): any => { console.log('') }}
                  excluirClick={() => { console.log('') }}
                  editarClick={(e: any, rowData: any): any => { console.log('') }}
                  pagination
                  paginationPageSize={10}
                />
              </TabelaContainer>
            </>
          ) : (
            <>
              <Header>
                <Botao
                  tipo="adicionar"
                  tamanho="medio"
                  className="btn"
                  disabled={false}
                  onClick={() => {
                    gerarRelatorio(dadosHistorico);
                  }}
                >
                  Baixar Projeção
                </Botao>
              </Header>
              {Object.entries(dadosComissao).map(([empreendimento, dados], index) => (
                <TabelaContainer key={empreendimento}>
                  {index === 0 && <h2 id="titulo_tabela">Comissões</h2>}
                  <Tabela
                    title={`${empreendimento}`}
                    haveActions="none"
                    columns={columns}
                    isLoading={isDataLoading}
                    data={dados}
                    onRowClick={(e: any, rowData: any): any => { console.log('') }}
                    excluirClick={() => { console.log('') }}
                    editarClick={(e: any, rowData: any): any => { console.log('') }}
                    pagination
                    paginationPageSize={10}
                  />
                  <Footer>
                    <div id="labelTotal">
                      {`CNPJ ${dados[0].cnpj_emp} | Total Nota Fiscal R$ ${dados.length > 0 ? dados[0].valor_total.toLocaleString('pt-br') : 0}`}
                    </div>
                    <Botao
                      tipo="adicionar"
                      tamanho="medio"
                      className="btn"
                      disabled={false}
                      onClick={() => {
                        setDadoSelecionado(dados);
                        setDeleteModalOpen(true)
                      }}
                    >
                      Inserir NF
                    </Botao>
                  </Footer>

                </TabelaContainer>
              ))}
            </>
          )
          }
          {(!isDataLoading && Object.keys(dadosBonificacao).length > 0) && (
            <>
              {Object.entries(dadosBonificacao).map(([key, linha]: [string, any], index) => (
                <TabelaContainer key={key}>
                  {index === 0 && <h2 id="titulo_tabela">Bonificações</h2>}
                  <TabelaBonificacao
                    titulo={`${linha.nome.toUpperCase()}`}
                    data={linha.bonificacoes.map((bonificacao: any) => ({
                      id: bonificacao.id_bonificacao,
                      descricao: bonificacao.descricao_bonificacao,
                      valor_bonificacao: `${new Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL'
                      }).format(bonificacao.valor_bonificacao)}`,
                      data_criacao: new Date().toLocaleDateString('pt-BR', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric'
                      }),
                      tipo_bonificacao: bonificacao.tipo_bonificacao,
                      quant_propostas: bonificacao.qtd_propostas,
                      id_propostas: bonificacao.propostas
                    }))}
                  />

                  <Footer>
                    <div id="labelTotal">
                      {`CNPJ ${linha.cnpj} | Total Nota Fiscal ${new Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL'
                      }).format(linha.valor_total)}`}
                    </div>
                    <Botao
                      tipo="adicionar"
                      tamanho="medio"
                      className="btn"
                      onClick={() => {
                        setDadosBonificacaoSelecionado([
                          linha.valor_total,
                          key,
                          linha.nome,
                          linha.cnpj
                        ]);
                        const bonificacaoIds = linha.bonificacoes.map((
                          bonificacao: any) => (
                          {
                            id_bonificacao: bonificacao.id_bonificacao,
                            tipo_bonificacao: bonificacao.tipo_bonificacao
                          }
                        ));
                        setBonificacaoIds(bonificacaoIds);
                        setModalBonificacaoOpen(true);
                      }}
                    >
                      Inserir NF
                    </Botao>
                  </Footer>
                </TabelaContainer>
              ))}
            </>
          )}
        </ContainerTabela>

        <ModalComissaoNF
          isModalOpen={deleteModalOpen}
          onCloseModal={() => {
            dadosComissaoImobiliaria()
            setDeleteModalOpen(false)
          }}
          onDeleteClick={() => console.log('')}
          value={dadoSelecionado}
          dadosImobiliaria={dadosImobiliaria}
        />

        <ModalBonificacaoNF
          title='Bonificações'
          isModalOpen={modalBonificacaoOpen}
          onCloseModal={() => {
            dadosComissaoImobiliaria();
            dadosBonificacaoImobiliaria();
            setModalBonificacaoOpen(false);
          }}
          onDeleteClick={() => console.log('')}
          value={dadosBonificacaoSelecionado}
          dadosBonificacao={dadosImobiliaria}
          bonificacaoIds={bonificacaoIds}
        />

      </Content>

      <Snackbars
        type={snackbarProps.type}
        handleClose={handleSnackbarClose}
        open={snackbarOpen}
      >
        {snackbarProps.message}
      </Snackbars>
    </>
  );
};

export default Comissao;
