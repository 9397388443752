import React, { useCallback, useState } from 'react';
import { PieChart, Pie, Sector } from 'recharts';
// import Carousel from 'react-gallery-carousel';
// import 'react-gallery-carousel/dist/index.css';

import { useHistory, useLocation } from 'react-router-dom';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AvatarUsuario from '../../../assets/Icons/AvatarUsuario';
import Botao from '../../../components/Botao/Botao';
import EditarEmpreendimento from '../EditarEmpreendimento';
// import EditarCorretores from '../EditarCorretores';
import {
  Container,
  BotoesContainer,
  ChartContainer,
  DetalhesContent,
  DetalhesContainer,
  DetalhesHeader,
  Empreendimento,
} from './styles';

const DetalhesCorretores = (e: any) => {
  const location = useLocation<any>();
  const data = location.state;
  const {
    name,
    cnpj,
    empresa,
    razao_social,
    uf,
    cidade,
    bairro,
    cep,
    logradouro,
    cod_empreendimento,
    cod_filial,
    lotes_totais,
    lotes_disponiveis,
    foto_implantacao,
    valor_minimo_entrada,
    quantidade_parcelas
  } = data;

  const [paginaAtual, setPaginaAtual] = useState('detalhes');
  const history = useHistory();

  console.log('data ', data);

  function verLotes() {
    history.push({ pathname: '/painel/lotes', state: name });
  }

  const goToAgencie = (agencieName: string) => {
    history.push({ pathname: '/painel/imobiliarias', state: agencieName });
  };

  return (
    <>
      <Container>
        <DetalhesContainer>
          <DetalhesHeader>
            <Empreendimento>
              <h1>{name}</h1>
            </Empreendimento>
            <div className="headerButtons">
              <Botao
                tipo="adicionar"
                tamanho="grande"
                className="btn"
                onClick={verLotes}
              >
                Ver lotes
              </Botao>
              <Botao
                tipo="adicionar"
                tamanho="grande"
                className="btn"
                onClick={() => window.open(foto_implantacao, '_blank')}
              >
                Ver mapa
              </Botao>
            </div>
          </DetalhesHeader>

          <DetalhesContent>
            <div className="item">
              <h2>Razão Social </h2>
              <p>{razao_social} </p>
            </div>
            <div className="item">
              <h2>Cod. Empreendimento </h2>
              <p>{cod_empreendimento} </p>
            </div>
          </DetalhesContent>

          <DetalhesContent>
            <div className="item">
              <h2>Nome </h2>
              <p>{name} </p>
            </div>
            <div className="item">
              <h2>CNPJ </h2>
              <p>{cnpj} </p>
            </div>
            <div className="item">
              <h2>Empresa </h2>
              <p>{empresa} </p>
            </div>
            <div className="item">
              <h2>Lotes Totais </h2>
              <p>{lotes_totais} </p>
            </div>
            <div className="item">
              <h2>Lotes Disponíveis </h2>
              <p>{lotes_disponiveis} </p>
            </div>
          </DetalhesContent>
          <DetalhesContent>
            <div className="item">
              <h2> Valor mínimo de entrada </h2>
              <p>{valor_minimo_entrada} </p>
            </div>
            <div className="item">
              <h2> Quantidade máxima de parcelas</h2>
              <p>{quantidade_parcelas} </p>
            </div>
          </DetalhesContent>

          <h1 className="endereco">Imobiliárias </h1>
          <DetalhesContent>
            <Accordion className="acordion">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className="acordion-summary"
              >
                <Typography>Mostrar imobiliárias</Typography>
              </AccordionSummary>
              <AccordionDetails style={{ width: 300 }}>
                {/* <tr>
                  {enterprises.map((el: any, index) => {
                    return (
                      <>
                        {index < 4 && <td><div onClick={() => goToAgencie(el.name)}
                          onKeyDown={() => goToAgencie(el.name)} role="button" tabIndex={0}
                        > {el.name} </div></td>}
                      </>
                    )
                  })}
                </tr>
                <tr>
                  {enterprises.map((el: any, index) => {
                    return (
                      <>
                        {index >= 4 && <td><div onClick={() => goToAgencie(el.name)}
                          onKeyDown={() => goToAgencie(el.name)} role="button" tabIndex={0}
                        > {el.name} </div></td>}
                      </>
                    )
                  })}
                </tr> */}
              </AccordionDetails>
            </Accordion>
          </DetalhesContent>

          <h1 className="endereco">Endereço </h1>
          <DetalhesContent>
            <div className="item">
              <h2>CEP </h2>
              <p>{cep} </p>
            </div>
            <div className="item">
              <h2>Logradouro </h2>
              <p>{logradouro} </p>
            </div>
            <div className="item">
              <h2>Bairro </h2>
              <p>{bairro} </p>
            </div>
            <div className="item">
              <h2>Cidade </h2>
              <p>{cidade} </p>
            </div>
            <div className="item">
              <h2>UF </h2>
              <p>{uf} </p>
            </div>
          </DetalhesContent>
          {/* <DetalhesContent className="descricao-container-maj">
            <div className="item">
              <h2>Descrição </h2>
              <p className="descricao-container">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Ad
                laboriosam facere hic omnis vel, laudantium aliquam aperiam esse
                doloribus et quos architecto quod eveniet, sed, distinctio
                provident magni sapiente ipsam. Suscipit sequi sapiente nihil
                molestiae, asperiores, quo neque tempora soluta eaque culpa
                nesciunt incidunt ipsam ab est adipisci magnam quis facilis sint
                aut repellendus magni in possimus nam. Dolor, nihil. Fugiat.
              </p>
            </div>
          </DetalhesContent> */}
        </DetalhesContainer>

        {/* <DetalhesContainer className="carousel">
          <h1>Imagens do Empreendimento</h1>
          <Carousel images={images} /> */}
        {/* <Carousel
            data={data}
            time={2000}
            width="850px"
            height="500px"
            // captionStyle={captionStyle}
            radius="10px"
            slideNumber
            // slideNumberStyle={slideNumberStyle}
            captionPosition="bottom"
            automatic
            dots
            pauseIconColor="white"
            pauseIconSize="40px"
            slideBackgroundColor="darkgrey"
            slideImageFit="cover"
            thumbnails
            thumbnailWidth="100px"
            style={{
              textAlign: 'center',
              maxWidth: '850px',
              maxHeight: '500px',
              margin: '40px auto',
            }}
          /> */}
        {/* </DetalhesContainer> */}
      </Container>
    </>
  );
};

export default DetalhesCorretores;
