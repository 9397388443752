/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-useless-computed-key */
import { TextField } from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import ClearIcon from '@material-ui/icons/Clear';
import DoneIcon from '@material-ui/icons/Done';
import UpdateIcon from '@material-ui/icons/Update';
import { Autocomplete } from '@material-ui/lab';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import InputField from '../../components/Inputs/InputField';
import PaginatedTable from '../../components/PaginatedTable/PaginatedTable';
import PropostasService from '../../services/PropostasService';
import cores from '../../styles/cores';
import { status } from '../../utils/selects';
import { Content, OpcoesContainer, Status, TabelaContainer } from './styles';

interface IFilterInterface {
  page: number;
  limit: number;
  status: string;
  enterprise: string;
  customer: string;
  block: string;
  megaCod: string;
  created_at: string;
  updated_at: string;
}

const Propostas: React.FC = () => {
  const [pageMenu, setPageMenu] = useState('principal');
  const [listarPropostas, setListarPropostas] = useState<any>();
  const history = useHistory();
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [empSelected, setEmpSelected] = useState({ title: '', value: '' });
  const [count, setCount] = useState<number>(30);
  const [totalCount, setTotalCount] = useState(1);
  const [filter, setFilter] = useState<IFilterInterface>({
    page: 1,
    limit: 10,
  } as IFilterInterface);
  const [searchTimeout, setSearchTimeout] = useState<any>(null);
  const [dataCreatedAt, setDataCreatedAt] = useState<any>('');
  const [dataUpdatedAt, setUpdatedAt] = useState<any>('');

  function handleFilterChange(key: string, value: string) {
    if (key === 'created_at') {
      const formattedValue = value.split('/').reverse().join('-');
      setFilter(currentState => ({
        ...currentState,
        [key]: formattedValue,
      }));
    } else if (key === 'updated_at') {
      const formattedValue = value.split('/').reverse().join('-');
      setFilter(currentState => ({
        ...currentState,
        [key]: formattedValue,
      }));
    } else {
      setFilter(currentState => ({
        ...currentState,
        [key]: value,
      }));
    }
  }

  async function statusSelect(e: any, value: any) {
    setEmpSelected({
      title: value.title,
      value: value.value,
    });
    setFilter(currentState => ({
      ...currentState,
      status: value.value,
    }));
  }
  const list = useCallback(async params => {
    setIsDataLoading(true);
    await PropostasService.listAllPropostasAdmin(params)
      .then(response => {
        setTotalCount(response.proposals.count);
        const listLegal = response.proposals.listProposalsLegal.map(
          (proposal: any) => {
            return {
              id: proposal.id,
              nome: '',
              documento: proposal.cnpj,
              status: proposal.status,
              atualizacao: proposal.updated_at,
              cnpj: proposal.cnpj,
              numEntradas: proposal.num_entrada,
              valorEntrada: proposal.valor_entrada,
              valorParcelas: proposal.valor_parcelas,
              objetivo: proposal.objetivo,
              email: proposal.email,
              contratoSocial: proposal.contrato_social,
              nomeFantasia: proposal.nome_fantasia,
              valorTotalLegal: proposal.valor_total,
              documentos: proposal.documentos,
              criacao: proposal.created_at,

              tipo: 'legal',
            };
          },
        );
        const listPhysical = response.proposals.listProposalPhysical.map(
          (proposal: any) => {
            let proposalNome = '';
            let proposalCpf = '';
            let proposalSexo = '';
            let proposalBairro = '';
            let proposalEmail = '';
            let proposalCep = '';
            let proposalRg = '';
            let proposalLogradouro = '';
            let proposalNumero = '';
            let proposalComplemento = '';
            let proposalNacionalidade = '';
            let proposalOrgaoEmissor = '';
            let proposalUf = '';
            let proposalCargo = '';
            let participanteId = '';
            let proposalTelefone = '';
            let proposalCidade = '';
            let proposalParticipantes = proposal.proposals_participants;

            if (proposalParticipantes.length > 0) {
              participanteId = proposal.proposals_participants[0].id;
              // proposalNome = proposal.proposals_participants[0].nome;
              const findPrincipal =
                proposal.proposals_participants_type_percentage.find(
                  (proposals_participant_type_percentage: {
                    tipo_participante: string;
                  }) =>
                    proposals_participant_type_percentage.tipo_participante ===
                    'principal',
                );
              const findProposalNome = proposal.proposals_participants.find(
                (proposals_participant: { id: any }) =>
                  proposals_participant.id === findPrincipal?.id_participante,
              );
              proposalNome = findProposalNome
                ? findProposalNome.nome
                : proposal.proposals_participants[0].nome;
              proposalSexo = proposal.proposals_participants[0].sexo;
              proposalCpf = proposal.proposals_participants[0].cpf;
              proposalTelefone = proposal.proposals_participants[0].telefone;
              proposalBairro = proposal.proposals_participants[0].bairro;
              proposalEmail = proposal.proposals_participants[0].email;
              proposalCep = proposal.proposals_participants[0].cep;
              proposalRg = proposal.proposals_participants[0].rg;
              proposalOrgaoEmissor =
                proposal.proposals_participants[0].orgao_rg;
              proposalLogradouro =
                proposal.proposals_participants[0].logradouro;
              proposalNumero = proposal.proposals_participants[0].numero;
              proposalComplemento =
                proposal.proposals_participants[0].complemento;
              proposalUf = proposal.proposals_participants[0].uf;
              proposalNacionalidade =
                proposal.proposals_participants[0].nacionalidade;
              proposalCidade = proposal.proposals_participants[0].municipio;
              proposalCargo = proposal.proposals_participants[0].cargo;
            } else {
              return {};
            }
            return {
              id: proposal.id,
              id_participante: participanteId,
              nome: proposalNome,
              cpf: proposalCpf,
              status: proposal.status,
              atualizacao: proposal.updated_at,
              estado_civil: proposal.estado_civil,
              sexo: proposalSexo,
              cargo: proposalCargo,
              escolaridade: proposal.escolaridade,
              clt: proposal.clt,
              data_nascimento: proposal.data_nascimento,
              num_filhos: proposal.num_filhos,
              renda_familiar: proposal.renda_familiar,
              num_entradas: proposal.num_entradas,
              valor_entrada: proposal.valor_entrada,
              num_parcelas: proposal.num_parcelas,
              valor_parcelas: proposal.valor_parcelas,
              valor_total: proposal.valor_total,
              objetivo: proposal.objetivo,
              cep: proposalCep,
              rg: proposalRg,
              orgao_emissor: proposalOrgaoEmissor,
              telefone: proposalTelefone,
              email: proposalEmail,
              bairro: proposalBairro,
              logradouro: proposalLogradouro,
              numero: proposalNumero,
              complemento: proposalComplemento,
              nacionalidade: proposalNacionalidade,
              uf: proposalUf,
              criacao: proposal.created_at,
              documentos: proposal.documentos,
              valor_reduzidas: proposal.valor_reduzidas,
              data_vencimento_reduzidas: proposal.data_vencimento_reduzidas,
              quadra_lote: proposal.quadra_lote,
              empreendimento: proposal.empreendimento,
              cod_mega_proposta_ativa: proposal.cod_mega_proposta_ativa,
              tipo: 'physical',
            };
          },
        );
        setListarPropostas([...listPhysical, ...listLegal]);
      })
      .catch(err => console.log('erro -> ', err));
    setIsDataLoading(false);
  }, []);

  useEffect(() => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    const timeoutId = setTimeout(() => {
      list(filter);
    }, 500);

    setSearchTimeout(timeoutId);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [filter]);

  const columns = [
    {
      title: 'Data de criação',
      field: 'criacao',
      type: 'date',
    },
    {
      title: 'Última atualização',
      field: 'atualizacao',
      type: 'date',
    },
    {
      title: 'Cliente',
      field: 'nome',
    },
    {
      title: 'Empreedimento',
      field: 'empreendimento',
    },
    {
      title: 'Quadra / Lote',
      field: 'quadra_lote',
    },
    {
      title: 'Código mega',
      field: 'cod_mega_proposta_ativa',
    },
    {
      title: 'Status',
      field: 'status',

      render: (rowData: any) => {
        if (
          rowData.status === 'Em Análise' ||
          rowData.status === 'Enviar documentos'
        ) {
          return (
            <Status>
              {/* <Ball color="yellow" /> */}
              <UpdateIcon
                style={{ fill: 'orange', marginRight: 5, fontSize: 26 }}
              />
              Em análise
            </Status>
          );
        }
        if (rowData.status === 'Aprovado') {
          return (
            <Status>
              {/* <Ball color="green" /> */}
              <DoneIcon
                style={{ fill: 'green', marginRight: 5, fontSize: 26 }}
              />
              Aprovado
            </Status>
          );
        }
        if (rowData.status === 'Anexar Documentos') {
          return (
            <Status>
              {/* <Ball color="blue" /> */}
              <AttachFileIcon
                style={{ fill: cores.blue, marginRight: 5, fontSize: 26 }}
              />
              Anexar documentos
            </Status>
          );
        }
        if (rowData.status === 'Reprovado') {
          return (
            <Status>
              {/* <Ball color="red" /> */}
              <ClearIcon
                style={{ fill: 'red', marginRight: 5, fontSize: 26 }}
              />
              Reprovado
            </Status>
          );
        }
        if (rowData.status === 'Documentacao insuficiente') {
          return (
            <Status>
              {/* <Ball color="red" /> */}
              Documentação insuficiente
            </Status>
          );
        }
        return <Status>{rowData.status}</Status>;
      },
    },
  ];

  const [isModalOpen, setModalOpen] = useState(false);

  function rowSelected(e: any, rowData: any) {
    let URLArray = [];
    if (rowData.documentos.length > 0) {
      for (let i = 0; i < rowData.documentos.length; i++) {
        let elemento = rowData.documentos[i].document;
        URLArray.push(elemento);
      }
    }
    history.push({
      pathname: '/painel/propostas-admin/editar',
      state: { rowData, URLArray },
    });
  }
  function adicionarCorretor() {
    setPageMenu('adicionar');
  }

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setModalOpen(false);
    setOpen(true);
  };

  const handleDataCreatedAt = (e: any) => {
    const inputData = e.target.value;
    const dataSemBarras = inputData.replace(/\//g, '');

    if (dataSemBarras === '') {
      setDataCreatedAt('');
      return;
    }
    const dia = dataSemBarras.slice(0, 2);
    const mes = dataSemBarras.slice(2, 4);
    const ano = dataSemBarras.slice(4, 8);
    let dataFormatada = '';
    if (dia) {
      dataFormatada += dia;
      if (mes) {
        dataFormatada += `/${mes}`;
        if (ano) {
          dataFormatada += `/${ano}`;
        }
      }
    }
    setDataCreatedAt(dataFormatada);
  };

  const handleDataUpdatedAt = (e: any) => {
    const inputData = e.target.value;
    const dataSemBarras = inputData.replace(/\//g, '');

    if (dataSemBarras === '') {
      setUpdatedAt('');
      return;
    }
    const dia = dataSemBarras.slice(0, 2);
    const mes = dataSemBarras.slice(2, 4);
    const ano = dataSemBarras.slice(4, 8);
    let dataFormatada = '';
    if (dia) {
      dataFormatada += dia;
      if (mes) {
        dataFormatada += `/${mes}`;
        if (ano) {
          dataFormatada += `/${ano}`;
        }
      }
    }
    setUpdatedAt(dataFormatada);
  };

  useEffect(() => {
    setFilter(currentState => ({
      ...currentState,
      page: 1,
    }));
  }, [
    filter.status,
    filter.enterprise,
    filter.customer,
    filter.block,
    filter.megaCod,
    filter.created_at,
    filter.updated_at,
  ]);

  return (
    <>
      <Content>
        <OpcoesContainer>
          <div
            style={{
              display: 'flex',
              marginTop: '4%',
              marginBottom: '2%',
              flexDirection: 'row',
              gap: '15px',
            }}
          >
            <InputField
              tipo="formulario"
              className="inputField"
              label="Data Criação"
              onChange={(e: any) => {
                handleFilterChange('created_at', e.target.value);
                handleDataCreatedAt(e);
              }}
              value={dataCreatedAt}
              width={160}
            />

            <InputField
              tipo="formulario"
              className="inputField"
              label="Última Atualização"
              onChange={(e: any) => {
                handleFilterChange('updated_at', e.target.value);
                handleDataUpdatedAt(e);
              }}
              value={dataUpdatedAt}
              width={160}
            />
            <InputField
              tipo="formulario"
              className="inputField"
              label="Cliente"
              onChange={(e: any) => {
                handleFilterChange('customer', e.target.value);
              }}
              value={filter.customer}
              width={160}
            />

            <InputField
              tipo="formulario"
              className="inputField"
              label="Empreendimento"
              onChange={(e: any) => {
                handleFilterChange('enterprise', e.target.value);
              }}
              value={filter.enterprise}
              width={160}
            />

            <InputField
              tipo="formulario"
              className="inputField"
              label="Quadra / Lote"
              onChange={(e: any) => {
                handleFilterChange('block', e.target.value);
              }}
              value={filter.block}
              width={160}
            />

            <InputField
              tipo="formulario"
              className="inputField"
              label="Código mega"
              onChange={(e: any) => {
                handleFilterChange('megaCod', e.target.value);
              }}
              value={filter.megaCod}
              width={160}
            />

            <Autocomplete
              id="combo-box-demo"
              options={status}
              getOptionLabel={(option: any) => option.title}
              style={{ width: 200 }}
              value={empSelected}
              onChange={(e: any, value: any) => statusSelect(e, value)}
              disableClearable
              renderInput={params => (
                <TextField
                  style={{ width: 200 }}
                  label="Status"
                  variant="outlined"
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  size="small"
                  error={false}
                  helperText=""
                />
              )}
            />
          </div>
        </OpcoesContainer>
        <TabelaContainer>
          <PaginatedTable
            title="Propostas"
            haveActions="none"
            isLoading={isDataLoading}
            columns={columns}
            data={listarPropostas}
            onRowClick={(e: any, rowData: any): any => {
              setListarPropostas(rowData);
              rowSelected(e, rowData);
            }}
            excluirClick={() => {
              console.log('legal');
            }}
            editarClick={(e: any, rowData: any): any => {
              setListarPropostas(rowData);
              setPageMenu('editar');
            }}
            count={count}
            totalCount={totalCount}
            currentPage={filter.page}
            setCurrentPage={(page: string) => handleFilterChange('page', page)}
          />
        </TabelaContainer>
      </Content>
    </>
  );
};

export default Propostas;
