/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable prefer-template */
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import UpdateIcon from '@material-ui/icons/Update';
import { useSelector } from 'react-redux';
import { RootState } from '../../storage/rootReducer';

import {
  Content,
  MenuLateralContainer,
  TabelaContainer,
  ModalContainer,
  Status,
  LeadHeader,
  TimeAlert,
} from './styles';
import Tabela from '../../components/Tabelas/Tabela';
import Botao from '../../components/Botao/Botao';
import Modal from '../../components/Modal/Modal';
import HeaderProposta from '../../components/HeaderProposta';
import cores from '../../styles/cores';
import PropostasService from '../../services/PropostasService';
import MenuLateralImob from '../../components/MenuLateralImob/MenuLateralImob';
import LeadsService from '../../services/LeadsService';

const PropostasImob: React.FC = () => {
  const [detalhes, setDetalhes] = useState(false);
  const [userData, setUserData] = useState({});
  const [pageMenu, setPageMenu] = useState('principal');
  const [listarPropostas, setListarPropostas] = useState<any[]>();
  const [listPhysical, setListPhysical] = useState([]);
  const [listLegal, setListLegal] = useState([]);
  const [leadModal, setLeadModal] = useState(true);
  const [timeAwaitingLead, setTimeAwaitingLead] = useState('');
  const [leadAwaiting, setLeadAwaiting] = useState({
    created_at: '',
  });
  const [userTipo, setUserTipo] = useState('');

  const history = useHistory();
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [id, setId] = useState('');
  // const [newLead, setNewLead] = useState(false);
  const { state } = useLocation();
  const { token } = useSelector((state: RootState) => state.userReducer);

  const list = useCallback(async () => {
    setIsDataLoading(true);
    try {
      const physical = await PropostasService.listAllPropostasImob();
      console.log('physical', physical);
      let physicalFormatted = physical.proposal.map((item: any) => {
        let corretor;
        let nome;
        let physical_formatted_obj;
        console.log('item.proposals_participants ', item);
        //  console.log('nome aq', item.proposals_participants[0].nome)
        corretor = item.user ? item.user.name : '';

        // if (item.proposals_participants_type_percentage.length > 0) {
        //   const findPrincipal = item.proposals_participants_type_percentage.find((participant: { tipo_participante: string; }) => participant.tipo_participante === 'principal');

        //   if(item.proposals_participants.length > 0){
        //     const getParticipantPrincipal = item.proposals_participants.find((participant: { id: string; }) => participant.id === findPrincipal.id_participante);
        //     nome = getParticipantPrincipal.nome;
        //    }else{
        //      nome = '';
        //   }
        // }
        // else {
        //  nome = item.proposals_participants.length > 0 ? item.proposals_participants[0].nome : '';
        // }

        if (item.proposals_participants.length > 0) {
          nome = item.proposals_participants[0].nome;
          console.log('NOME AQ', nome);
        } else {
          console.log('CAIU ELSE');
          nome = 'PARTICIPANTE NÃO ENCONTRADO';
        }
        //  console.log('nome aq',nome)

        physical_formatted_obj = {
          id: item.id,
          nome: nome,
          corretor: corretor,
          status: item.status,
          atualizacao: item.updated_at,
          estado_civil: item.estado_civil,
          sexo: item.sexo,
          data_nascimento: item.data_nascimento,
          num_filhos: item.num_filhos,
          renda_familiar: item.renda_familiar,
          num_entradas: item.num_entradas,
          valor_entrada: item.valor_entrada,
          num_parcelas: item.num_parcelas,
          valor_parcelas: item.valor_parcelas,
          valor_total: item.valor_total,
          objetivo: item.objetivo,
          telefone: item.telefone,
          email: item.email,
          criacao: item.created_at,
          documentosFisica: item.documentos,
          participantes: item.proposals_participants,
          clt: item.clt,
          quadra_lote: item.quadra_lote,
          empreendimento: item.empreendimento,
          cod_mega_proposta_ativa: item.cod_mega_proposta_ativa,
          tipo: 'physical',
        };

        // if(item.data_vencimento_parcela === null || item.data_vencimento_parcela === undefined || item.data_vencimento_sinal === null || item.escolaridade === null || item.escolaridade === '' || item.clt === '' || item.clt === undefined || item.clt === null || item.clt === 'null' || item.clt === 'undefined' || item.clt === 'null' || item.clt === 'undefined') {
        //   physical_formatted = {

        //   }
        // }

        //  console.log(physical_formatted_obj)

        return physical_formatted_obj;
      });
      console.log('physicalFormatted ', physicalFormatted);
      setListarPropostas(physicalFormatted);
      setIsDataLoading(false);
    } catch (err) {
      setIsDataLoading(false);
    }

    setLeadModal(false);

    try {
      const checkTickets = await await LeadsService.listTickets();
      checkTickets.blipTickets.forEach((ticket: any) => {
        if (ticket.visto === false) {
          setLeadModal(true);
          setLeadAwaiting({
            created_at: ticket.created_at,
          });
          return;
        }
      });

      setIsDataLoading(false);
    } catch (err) {
      setIsDataLoading(false);
    }
  }, []);

  const checkTickets = useCallback(async () => {
    setIsDataLoading(true);
    setLeadModal(false);

    try {
      const checkTickets = await await LeadsService.listTickets();
      checkTickets.blipTickets.forEach((ticket: any) => {
        if (ticket.visto === false) {
          setLeadModal(true);
          setLeadAwaiting({
            created_at: ticket.created_at,
          });
          return;
        }
      });

      setIsDataLoading(false);
    } catch (err) {
      setIsDataLoading(false);
    }
  }, []);

  const { pathname } = history.location;

  const columns = [
    {
      title: 'Última atualização',
      field: 'atualizacao',
      type: 'date',
    },
    {
      title: 'Participante Principal',
      field: 'nome',
    },
    {
      title: 'Corretor',
      field: 'corretor',
    },
    {
      title: 'Empreendimento',
      field: 'empreendimento',
    },
    {
      title: 'Quadra / Lote',
      field: 'quadra_lote',
    },
    {
      title: 'Código',
      field: 'cod_mega_proposta_ativa',
    },
    {
      title: 'Status',
      field: 'status',
      render: (rowData: any) => {
        if (rowData.status === 'Em Análise') {
          return (
            <Status>
              {/* <Ball color="yellow" /> */}
              <UpdateIcon
                style={{ fill: 'orange', marginRight: 5, fontSize: 26 }}
              />
              Em análise
            </Status>
          );
        }
        if (rowData.status === 'Aprovado') {
          return (
            <Status>
              {/* <Ball color="green" /> */}
              <DoneIcon
                style={{ fill: 'green', marginRight: 5, fontSize: 26 }}
              />
              Aprovado
            </Status>
          );
        }
        if (rowData.status === 'Anexar Documentos') {
          return (
            <Status>
              {/* <Ball color="blue" /> */}
              <AttachFileIcon
                style={{ fill: cores.blue, marginRight: 5, fontSize: 26 }}
              />
              Anexar documentos
            </Status>
          );
        }
        if (rowData.status === 'Reprovado') {
          return (
            <Status>
              {/* <Ball color="red" /> */}
              <ClearIcon
                style={{ fill: 'red', marginRight: 5, fontSize: 26 }}
              />
              Reprovado
            </Status>
          );
        }
        return <Status>{rowData.status || 'Status não definido'}</Status>;
      },
    },
  ];

  // useEffect(() => {
  //   checkTickets();
  // }, []);

  const [isModalOpen, setModalOpen] = useState(false);

  function rowSelected(e: any, rowData: any) {
    console.log('isso vai para o editar ', rowData);
    history.push({
      pathname: '/painel/proposta/editar',
      state: { rowData },
    });
  }
  function adicionarCorretor() {
    setPageMenu('adicionar');
  }

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setModalOpen(false);
    setOpen(true);
  };

  const timePassedString = function (seconds_passed: string) {
    let sec_num = parseInt(seconds_passed, 10);
    let hours: any = Math.floor(sec_num / 3600);
    let minutes: any = Math.floor((sec_num - hours * 3600) / 60);
    let seconds: any = sec_num - hours * 3600 - minutes * 60;

    if (hours < 10) {
      hours = '0' + hours;
    }
    if (minutes < 10) {
      minutes = '0' + minutes;
    }
    if (seconds < 10) {
      seconds = '0' + seconds;
    }
    return hours + ':' + minutes + ':' + seconds;
  };

  const timeAwaiting = (created_at: any) => {
    let now = new Date();
    const created_date = new Date(created_at);
    created_date.setHours(created_date.getHours() - 6);
    console.log('criado date', created_date);
    const time_awaiting = (now.getTime() - created_date.getTime()) / 1000;
    console.log('time_awaiting date', time_awaiting);
    let timeString = timePassedString(time_awaiting.toString());
    let splitTimeString = timeString.split(':');
    const returnedString = `${Number(splitTimeString[0]) - 6 || '0'}h ${
      splitTimeString[1] || '0'
    }m ${splitTimeString[2] || '0'}s`;
    return returnedString;
  };

  useEffect(() => {
    list();
    // checkTickets();

    // setTimeout(() => {
    //   console.log('entrei no timer')
    //   const time = timeAwaiting('2022-11-03T23:40:19.271Z')
    //   setTimeAwaitingLead(time)
    // }, 1000);
    // return () => clearTimeout(timer);
  }, []);

  const LeadModal: any = () => {
    if (leadModal && leadAwaiting.created_at) {
      useEffect(() => {
        setTimeout(() => {
          console.log('entrei no timer');
          const time = timeAwaiting(leadAwaiting.created_at);
          setTimeAwaitingLead(time);
        }, 1000);
        // return () => clearTimeout(timer);
      }, []);
      return (
        <Modal
          isOpen={leadModal}
          width={500}
          onClose={() => setLeadModal(false)}
        >
          <>
            <LeadHeader>NOVO LEAD!</LeadHeader>
            <TimeAlert>
              Tempo de espera do lead:{' '}
              {leadAwaiting.created_at !== '' ? timeAwaitingLead : ''}
            </TimeAlert>
            <>
              <ModalContainer>
                <h3>Novos leads foram atribuidos à você!</h3>
                <p>Clique no botão abaixo para visualizar</p>
              </ModalContainer>
              <div className="btn-modalfull">
                <Botao
                  tipo="salvar"
                  tamanho="fullwidth"
                  onClick={() => {
                    history.push({
                      pathname: '/painel/imobiliaria/leads',
                      state: leadAwaiting,
                    });
                  }}
                >
                  Ver leads
                </Botao>
              </div>
            </>
          </>
        </Modal>
      );
    }
    return <></>;
  };

  return (
    <>
      <HeaderProposta />
      <Content>
        <MenuLateralContainer>
          <MenuLateralImob
            className="menuLateral"
            currentPage={{ pathname, data: userData }}
            data={userData}
            style={{ marginTop: '100px' }}
          />
        </MenuLateralContainer>
        <TabelaContainer>
          <Tabela
            title="Propostas"
            haveActions="edit"
            columns={columns}
            isLoading={isDataLoading}
            data={listarPropostas}
            onRowClick={(e: any, rowData: any): any => {
              setListarPropostas(rowData);
              rowSelected(e, rowData);
            }}
            excluirClick={() => {
              console.log('');
            }}
            editarClick={(e: any, rowData: any): any => {
              setListarPropostas(rowData);
              rowSelected(e, rowData);
            }}
            pagination
            paginationPageSize={10}
          />
        </TabelaContainer>
        <LeadModal />
      </Content>
    </>
  );
};

export default PropostasImob;
