/* eslint-disable no-restricted-syntax */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-param-reassign */
/* eslint-disable no-case-declarations */
/* eslint-disable no-await-in-loop */
/* eslint-disable react/no-array-index-key */
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import UpdateIcon from '@material-ui/icons/Update';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import ApprovedModal from '../../../components/ApprovedModal/ApprovedModal';
import Botao from '../../../components/Botao/Botao';
import InputField from '../../../components/InputsRefact/InputField';
import ModalAlcadaDenied from '../../../components/ModalAlcadaDenied/ModalAlcadaDenied';
import ModalCalcApproved from '../../../components/ModalCalcApproved/ModalCalcApproved';
import ModalErrors from '../../../components/ModalErrors/ModalErrors';
import ModalEvidence from '../../../components/ModalEvidence/ModalEvidence';
import ModalFinancialSerie from '../../../components/ModalFinancialSerie/ModalFinancialSerie';
import ModalWaitAlcada from '../../../components/ModalWaitAlcada/ModalWaitAlcada';
import ModalWaitCalc from '../../../components/ModalWaitCalc/ModalWaitCalc';
import Snackbars from '../../../components/Snackbars';
import EmpreendimentosService from '../../../services/EmpreendimentosService';
import ImobiliariasService from '../../../services/ImobiliariasService';
import LotesService from '../../../services/LotesService';
import PropostasService from '../../../services/PropostasService';
import UserService from '../../../services/UserService';
import DocumentsEvidences from '../../AcompanhamentoProposta/DocumentsEvidences';
import {
  Content,
  DetalhesContainer,
  DetalhesContent,
  DetalhesHeader,
  InputsContainer,
  InputsContent,
  SessionContente,
} from './styles';

interface Participante {
  id: string;
  index: number;
  cargo: string;
  cpf: string;
  data_nascimento: string;
  email: string;
  estado_civil: string;
  nacionalidade: string;
  nome: string;
  orgao_rg: string;
  porcentagem: number;
  rg: string;
  sexo: string;
  telefone: string;
  tipo_participante: string;
  cep: string;
  uf: string;
  municipio: string;
  bairro: string;
  logradouro: string;
  numero: string;
  complemento: string;
}

interface Balao {
  id: any;
  data: string;
  quantidade: number;
  valor: any;
}

interface Lote {
  lote: string;
  quadra: string;
  empreendimento: string;
}

interface ContratoData {
  nome_cliente: string;
  data_contrato: string;
  id_contrato: string;
  imobiliaria: string;
  inadimplente: string;
  lote: string;
  quadra: string;
  nome_empreendimento: string;
  status_contrato: string;
  tier: string;
}

interface Balao {
  valor: string;
  quantidade: any;
  data: string;
}

const formatCurrency = (value: string) => {
  if (!value) return '';

  const valorNumero = parseFloat(value.replace(',', '.'));
  return valorNumero.toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
  });
};

function formatCurrencyParcela(value: any) {
  if (typeof value === 'string') {
    value = Number(value);
  }
  if (Number.isInteger(value)) {
    return value.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
  } else {
    value = Number(value.toFixed(2));
    return value.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
  }
}

function formataData(data: string) {
  const dataFormatada = new Date(data);
  return `${dataFormatada.toLocaleDateString()} às  ${dataFormatada.toLocaleTimeString()}`;
}

const EditarPropostaManager: React.FC = () => {
  const [values, setValues] = useState<Participante[]>([]);
  const [imobiliaria, setImobiliaria] = useState('');
  const [lote, setLote] = useState<Lote>();
  const [isApproved, setIsApproved] = useState<boolean>(false);
  const [isApprovedModalLoading, setIsApprovedModalLoading] =
    useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [codMega, setCodMega] = useState('');
  const [landId, setLandId] = useState('');
  const [descontoGlobalInfos, setDescontoGlobalInfos] = useState<any>();
  const [oldDataVencimentoSinal, setOldDataVencimentoSinal] =
    useState<string>('');
  const [dataVencimentoSinal, setDataVencimentoSinal] = useState<string>('');
  const [dataVencimentoParcelas, setDataVencimentoParcelas] =
    useState<string>('');
  const [moradiaAtual, setMoradiaAtual] = useState<string>('');
  const [rendaFamiliar, setRendaFamiliar] = useState<string>('');
  const [dependentes, setDependentes] = useState<string>('');
  const [objetivo, setObjetivo] = useState<string>('');
  const [numMoradores, setNumMoradores] = useState<string>('');
  const [nomeCorretor, setNomeCorretor] = useState<string>('');
  const [telefoneCorretor, setTelefoneCorretor] = useState<string>('');
  const [empCod, setEmpCod] = useState<string>('');
  const [parcela_calculada, setParcelaCalculada] = useState<number>(0);
  const [empElegivelPagadoria, setEmpElegivelPagadoria] =
    useState<boolean>(false);
  const location = useLocation<any>();
  const [participantProposals, setParticipantProposals] = useState<any[]>([]);
  const [snackbarProps, setSnackbarProps] = useState({
    message: 'Atualizado com Sucesso',
    type: 'success',
  });
  const [statusValue, setStatusValue] = useState<any>();

  const [observacao, setObservacao] = useState<string>('');
  const {
    atualizacao,
    clt,
    criacao,
    data_vencimento_reduzidas,
    escolaridade,
    id,
    id_participante,
    num_entradas,
    num_parcelas,
    valor_entrada,
    valor_parcelas,
    valor_reduzidas,
    valor_total,
  } = location.state.rowData;

  const [editar, setEditar] = useState<boolean>(false);
  const [quantidadeParcelas, setQuantidadeParcelas] =
    useState<any>(num_parcelas);
  const [quantidadeSinal, setQuantidadeSinal] = useState(num_entradas);
  const [mostrarHistorico, setMostrarHistorico] = useState(false);
  const [nomeClienteSelecionado, setNomeClienteSelecionado] = useState('');
  const [valorEntrada, setValorEntrada] = useState<any>(valor_entrada);
  const [valorEntradaEdit, setValorEntradaEdit] = useState<string>(
    formatCurrency(valorEntrada),
  );
  const [calculaParcelas, setCalculaParcela] = useState({
    title: 'Não',
    value: false,
  });
  const [valorLote, setValorLote] = useState<any>(valor_total);
  const [valorLoteEdit, setValorLoteEdit] = useState<string>(
    formatCurrency(valorLote),
  );
  const [valorParcela, setValorParcela] = useState<any>(valor_parcelas);
  const [valorParcelaEdit, setValorParcelaEdit] = useState(
    formatCurrencyParcela(valorParcela),
  );
  const [baloesAnuais, setBaloesAnuais] = useState<Balao[]>([]);
  const [baloesUnicos, setBaloesUnicos] = useState<Balao[]>([]);
  const [principalDocumento, setPrincipalDocumento] = useState('');
  const [dadosClienteResumo, setDadosClienteResumo] = useState<ContratoData[]>(
    [],
  );
  const [financialSeries, setFinancialSeries] = useState<any>('');
  const [lancarProposta, setLancarProposta] = useState<boolean>(false);
  const [modalFinancialSerie, setModalFinancialSerie] =
    useState<boolean>(false);
  const [modalCalcApproved, setModalCalcApproved] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [modalWaitCalc, setModalWaitCalc] = useState<boolean>(false);
  const [modalErrors, setModalErrors] = useState<boolean>(false);
  const [modalWaitAlcada, setModalWaitAlcada] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>('');
  const [financialSeriesData, setFinancialSeriesData] = useState<any>('');
  const [VPL, setVPL] = useState<any>('');
  const [empreendimentoID, setEmpreendimentoID] = useState<string>('');
  const [descontoAlcada, setDescontoAlcada] = useState<string>('');
  const [modalAlcadaDenied, setModalAlcadaDenied] = useState<boolean>(false);
  const [quantidadeParcelasEmp, setQuantidadeParcelasEmp] =
    useState<string>('');
  const [calculoDescontoImplicito, setCalculoDescontoImplicito] =
    useState<any>('');
  const [sendMega, setSendMega] = useState<any>([]);
  const [modalEvidence, setModalEvidence] = useState<boolean>(false);
  const [evidenceFiles, setEvidenceFiles] = useState<any>('');
  const [userID, setUserID] = useState<any>('');
  const [evidenciaAnexada, setEvidenciaAnexada] = useState<boolean>(false);
  const [dataInserirManager, setDataInserirManager] = useState<any>('');
  const [linkEvidence, setLinkEvidence] = useState<string>('');

  const setModalCalcApprovedClose = () => {
    setModalCalcApproved(false);
  };

  const setModalFinancialSerieClose = () => {
    setModalFinancialSerie(false);
  };

  const setModalAlcadaWaitClose = () => {
    setModalWaitAlcada(false);
  };

  const handleSnackbarClose = (
    event?: React.SyntheticEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };
  const analysisResult = async (param: any) => {
    setIsApprovedModalLoading(true);
    await PropostasService.analysisResult(param)
      .then(() => {
        if (param.proposal_valid) {
          setSnackbarOpen(true);
          setSnackbarProps({
            message: `Proposta aprovada com sucesso!`,
            type: 'success',
          });
        } else if (!param.proposal_valid) {
          setSnackbarOpen(true);
          setSnackbarProps({
            message: `Inconsistência com sucesso!`,
            type: 'success',
          });
        }
      })
      .catch((err: any) => {
        let message = err;
        if (err.message.response.data.message) {
          message = err.message.response.data.message;
        }
        setSnackbarOpen(true);
        setSnackbarProps({
          message: `Erro ao salvar proposta: ${message}`,
          type: 'error',
        });
      });
    setIsApproved(false);
    setIsApprovedModalLoading(false);
  };
  const handleChange = (event: any, id: number) => {
    const updatedValues = values.map(value => {
      if (value.index === id) {
        return { ...value, [event.target.name]: event.target.value };
      }
      return value;
    });
    setValues(updatedValues);
  };

  const handleChangeDataVencimentosSinal = (e: any) => {
    let inputDate = e.target.value;

    inputDate = inputDate.replace(/\D/g, '');

    if (inputDate.length <= 2) {
      setDataVencimentoSinal(inputDate);
    } else if (inputDate.length <= 4) {
      setDataVencimentoSinal(`${inputDate.slice(0, 2)}/${inputDate.slice(2)}`);
    } else {
      setDataVencimentoSinal(
        `${inputDate.slice(0, 2)}/${inputDate.slice(2, 4)}/${inputDate.slice(
          4,
          8,
        )}`,
      );
    }
  };

  const handleChangeDataParcela = (e: any) => {
    let inputDate = e.target.value;
    inputDate = inputDate.replace(/\D/g, '');

    if (inputDate.length <= 2) {
      setDataVencimentoParcelas(inputDate);
    } else if (inputDate.length <= 4) {
      setDataVencimentoParcelas(
        `${inputDate.slice(0, 2)}/${inputDate.slice(2)}`,
      );
    } else {
      setDataVencimentoParcelas(
        `${inputDate.slice(0, 2)}/${inputDate.slice(2, 4)}/${inputDate.slice(
          4,
          8,
        )}`,
      );
    }
  };

  async function findImobName(id: any) {
    const response = await ImobiliariasService.findImobiliaria(id);
    return response.agency.nome;
  }

  async function findLand(id: any) {
    const response = await LotesService.findLote(id);
    return response.land;
  }

  async function getDescontoGlobal(id_empreendimento: any) {
    const desconto = await PropostasService.descontoImplicito(
      id_empreendimento,
    );
    console.log('desconto', desconto);
    console.log('desconto.data', desconto.data);
    return desconto.data;
  }

  async function getProposalPhysical() {
    await PropostasService.getProposalPhysicalById(id).then(response => {
      const res = response.proposal;
      setNomeCorretor(res.user.name);
      setTelefoneCorretor(res.user.phone);
      findImobName(res.user.agency_id).then((resp: any) => {
        setImobiliaria(resp);
      });
      findLand(res.land_id).then((resp: any) => {
        setLote(resp);
      });
      setCodMega(res.cod_mega_proposta_ativa);
      setLandId(res.land_id);
      setDataVencimentoSinal(res.data_vencimento_sinal);
      setOldDataVencimentoSinal(res.data_vencimento_sinal);
      setDataVencimentoParcelas(res.data_vencimento_parcela);
      setMoradiaAtual(res.moradia_atual);
      setRendaFamiliar(res.renda_familiar);
      setDependentes(res.num_filhos);
      setObjetivo(res.objetivo);
      setNumMoradores(res.numero_pessoas_morar);
      setObservacao(res.comentary);
      setPrincipalDocumento(res.cpf);
      setQuantidadeSinal(res.num_entradas);
      setEmpCod(res.emp_cod);
      const participante = res.proposals_participants.map(
        (participants: any, index: number) => {
          let tipo = {
            id: '',
            id_participante: '',
            id_proposta: '',
            porcentagem: '100',
            tipo_participante: 'principal',
          };
          if (res.proposals_participants_type_percentage.length > 1) {
            tipo = res.proposals_participants_type_percentage.find(
              (type: any) => type.id_participante === participants.id,
            );
          }

          return {
            id: participants.id,
            index: index,
            cargo: participants.cargo,
            cpf: participants.cpf,
            data_nascimento: participants.data_nascimento,
            email: participants.email,
            estado_civil: participants.estado_civil,
            nacionalidade: participants.nacionalidade,
            nome: participants.nome,
            orgao_rg: participants.orgao_rg,
            rg: participants.rg,
            sexo: participants.sexo,
            telefone: participants.telefone,
            tipo_participante: tipo.tipo_participante,
            porcentagem: tipo.porcentagem,
            cep: participants.cep,
            uf: participants.uf,
            municipio: participants.cidade,
            bairro: participants.bairro,
            logradouro: participants.logradouro,
            numero: participants.numero,
            complemento: participants.complemento,
          };
        },
      );
      setValues(
        participante.sort((a: any, b: any) =>
          a.tipo_participante.localeCompare(b.tipo_participante),
        ),
      );
    });
  }

  async function getEnterprise() {
    if (empCod.length > 0) {
      await EmpreendimentosService.findEmpreendimentoByCodEmpreendimento(
        empCod,
      ).then(response => {
        const res = response.enterprise;
        setQuantidadeParcelasEmp(res.quantidade_parcelas);
        setEmpreendimentoID(res.id);
        setEmpElegivelPagadoria(res.elegivel_pagadoria);
      });
    }
  }

  useEffect(() => {
    getProposalPhysical();
  }, []);

  useEffect(() => {
    getEnterprise();
  }, [empCod]);

  useEffect(() => {
    if (location.state.rowData) {
      setStatusValue(location.state.rowData.status);
    }
  }, [location.state.rowData]);

  async function getTokenAuth() {
    const response = await PropostasService.getTokenAuthClientResume();
    return response;
  }

  async function getDadosClienteResumo(): Promise<ContratoData[]> {
    try {
      const responseProposal = await PropostasService.getProposalPhysicalById(
        id,
      );
      const cpf = responseProposal.proposal.proposals_participants[0].cpf;
      const formattedCPF = cpf.replace(
        /^(\d{3})(\d{3})(\d{3})(\d{2})$/,
        '$1.$2.$3-$4',
      );

      const response = await PropostasService.getDataClientResume(formattedCPF);
      const contratosData: ContratoData[] = [];

      if (response.contratos) {
        for (const contratoId in response.contratos) {
          if (
            Object.prototype.hasOwnProperty.call(response.contratos, contratoId)
          ) {
            const contrato = response.contratos[contratoId];
            const contratoData: ContratoData = {
              nome_cliente: contrato.nome,
              data_contrato: contrato.data_contrato,
              id_contrato: contrato.id_contrato,
              imobiliaria: contrato.imobiliaria,
              inadimplente: contrato.inadimplente,
              lote: contrato.lote,
              quadra: contrato.quadra,
              nome_empreendimento: contrato.nome_empreendimento,
              status_contrato: contrato.status,
              tier: contrato.tier,
            };
            contratosData.push(contratoData);
          }
        }
        if (contratosData.length > 0) {
          setNomeClienteSelecionado(contratosData[0].nome_cliente);
        }
      }
      return contratosData;
    } catch (error) {
      throw new Error('Erro ao obter os dados do cliente resumo');
    }
  }

  useEffect(() => {
    getTokenAuth();
  }, []);

  useEffect(() => {
    async function fetchDadosClienteResumo() {
      const dados = await getDadosClienteResumo();
      setDadosClienteResumo(dados);
    }

    fetchDadosClienteResumo();
  }, []);

  function formatarData(data: string | number | Date) {
    const dataObj = new Date(data);
    const dia = String(dataObj.getDate()).padStart(2, '0');
    const mes = String(dataObj.getMonth() + 1).padStart(2, '0');
    const ano = dataObj.getFullYear();
    return `${dia}/${mes}/${ano}`;
  }

  const [vpBaloes, setVPBaloes] = useState<any>();
  const [calculoAlcada, setCalculoAlcada] = useState<any>('');
  const [evidenceButton, setEvidenceButton] = useState<boolean>(true);

  const getParcipantProposals = async () => {
    const participantProposals = await PropostasService.getProposalParticipants(
      id_participante,
    );
    setParticipantProposals(participantProposals.proposals_physical);
  };

  const handleValorLoteChange = (e: any) => {
    setValorLote(e);
  };

  const handleBlur = () => {
    setValorLoteEdit(formatCurrency(valorLote));
  };

  const handleValorEntradaChange = (e: any) => {
    setValorEntrada(e);
  };

  const handleBlurEntrada = () => {
    setValorEntradaEdit(formatCurrency(valorEntrada));
  };

  const handleBalaoAnualChange = (index: number, value: string) => {
    const updatedBaloes = [...baloesAnuais];
    updatedBaloes[index].valor = value;
    setBaloesAnuais(updatedBaloes);
  };

  const handleDataBalaoAnualChange = (index: number, value: string) => {
    const updatedBaloes = [...baloesAnuais];
    let inputDate = value.replace(/\D/g, '');

    if (inputDate.length <= 2) {
      updatedBaloes[index].data = inputDate;
    } else if (inputDate.length <= 4) {
      updatedBaloes[index].data = `${inputDate.slice(0, 2)}/${inputDate.slice(
        2,
      )}`;
    } else {
      updatedBaloes[index].data = `${inputDate.slice(0, 2)}/${inputDate.slice(
        2,
        4,
      )}/${inputDate.slice(4, 8)}`;
    }
    setBaloesAnuais(updatedBaloes);
  };

  const adicionarBalaoAnual = () => {
    setBaloesAnuais([...baloesAnuais, { valor: '', data: '', quantidade: '' }]);
  };

  const excluirBalaoAnual = (index: number) => {
    const updatedBaloes = baloesAnuais.filter((_, i) => i !== index);
    setBaloesAnuais(updatedBaloes);
  };

  const handleBalaoUnicoChange = (index: number, value: string) => {
    const updatedBaloes = [...baloesUnicos];
    updatedBaloes[index].valor = value;
    setBaloesUnicos(updatedBaloes);
  };

  const handleDataBalaoUnicoChange = (index: number, value: string) => {
    const updatedBaloes = [...baloesUnicos];
    let inputDate = value.replace(/\D/g, '');

    if (inputDate.length <= 2) {
      updatedBaloes[index].data = inputDate;
    } else if (inputDate.length <= 4) {
      updatedBaloes[index].data = `${inputDate.slice(0, 2)}/${inputDate.slice(
        2,
      )}`;
    } else {
      updatedBaloes[index].data = `${inputDate.slice(0, 2)}/${inputDate.slice(
        2,
        4,
      )}/${inputDate.slice(4, 8)}`;
    }
    setBaloesUnicos(updatedBaloes);
  };

  const adicionarBalaoUnico = () => {
    setBaloesUnicos([
      ...baloesUnicos,
      { valor: '', data: '', quantidade: '1' },
    ]);
  };

  const getEvidence = async (id: string) => {
    const getByID = await PropostasService.getEvidence(id);
    if (getByID) {
      console.log(getByID);
      setLinkEvidence(getByID);
    }
  };

  const handleUploadedFile = async (upload: any) => {
    if (upload) {
      setEvidenceButton(false);
    }
    const createEvidence = {
      file: upload[0],
      user_id: userID,
      id_proposta: location.state.rowData.id,
    };
    setEvidenceFiles(createEvidence);
  };

  const chamaMega = async (data: any) => {
    try {
      await PropostasService.lancaMega(data);
      return true;
    } catch (err) {
      setIsLoading(false);
      setModalWaitAlcada(false);
      if (err.message.response.data.message.Observacao) {
        setErrors(
          `Ocorreu um erro na chamada do mega ${err.message.response.data.message.Observacao}`,
        );
      } else {
        setErrors(`Ocorreu um erro na chamada ${err.statusCode.message}`);
      }
      setModalErrors(true);
      return false;
    }
  };

  const quantidadeBalaoAnual = (index: number, value: number) => {
    const updatedBaloes = [...baloesAnuais];
    updatedBaloes[index].quantidade = value;
    setBaloesAnuais(updatedBaloes);
  };

  const handleCalculaParcela = (e: any, value: any) => {
    setCalculaParcela({
      title: value.title,
      value: value.value,
    });
  };

  const handleValorParcelaChange = (e: any) => {
    setValorParcela(e);
  };

  const handleBlurParcela = () => {
    setValorParcelaEdit(formatCurrency(valorParcela));
  };

  const validaEntrada = (valor_lote: number, entrada: any) => {
    const entradaMinima = valor_lote * 0.1;

    if (entrada[0].valor < entradaMinima) {
      return false;
    }

    return true;
  };

  const transformData = (dados: any) => {
    const seriesFinanceiras: any[] = [];

    const parseValue = (value: string) => {
      return parseFloat(value.replace(',', '.'));
    };
    const regex = /^\d{4}-\d{2}-\d{2}$/;

    try {
      seriesFinanceiras.push({
        tipo: 'sinal',
        valor: parseValue(dados.valorSinal),
        qtd_total_financiamento: parseInt(dados.quantidadeSinal, 10),
        data_da_primeira_parcela: new Date(
          dados.dataVencimentoSinal.split('/').reverse().join('-'),
        )
          .toISOString()
          .split('T')[0],
        calcula: false,
        id_proposta: dados.id_proposta,
      });

      if (dados.baloesAnuais.length > 0) {
        dados.baloesAnuais
          .filter(
            (balao: Balao) => balao.valor && balao.data && balao.quantidade,
          )
          .forEach((balao: Balao) => {
            if (balao.data.length < 10) {
              throw new Error(`Data do balão inválida - ${balao.data}`);
            }
            if (!balao.data.match(regex)) {
              throw new Error(`Data do balão inválida - ${balao.data}`);
            }
            seriesFinanceiras.push({
              tipo: 'anual',
              valor: parseValue(balao.valor),
              qtd_total_financiamento: parseInt(balao.quantidade, 10),
              data_da_primeira_parcela: new Date(
                balao.data.split('/').reverse().join('-'),
              )
                .toISOString()
                .split('T')[0],
              calcula: false,
              id_proposta: dados.id_proposta,
            });
          });
      }

      if (dados.baloesUnicos.length > 0) {
        dados.baloesUnicos
          .filter(
            (balao: Balao) => balao.valor && balao.data && balao.quantidade,
          )
          .forEach((balao: Balao) => {
            seriesFinanceiras.push({
              tipo: 'unica',
              valor: parseValue(balao.valor),
              qtd_total_financiamento: parseInt(balao.quantidade, 10),
              data_da_primeira_parcela: new Date(
                balao.data.split('/').reverse().join('-'),
              )
                .toISOString()
                .split('T')[0],
              calcula: false,
              id_proposta: dados.id_proposta,
            });
          });
      }

      if (dados.temJuros) {
        seriesFinanceiras.push({
          tipo: 'mensal_com_juros',
          valor: parseValue(dados.valorParcela),
          qtd_total_financiamento: parseInt(dados.quantidadeParcelas, 10),
          data_da_primeira_parcela: new Date(
            dados.dataVencimentoParcelas.split('/').reverse().join('-'),
          )
            .toISOString()
            .split('T')[0],
          calcula: false,
          id_proposta: dados.id_proposta,
        });
      } else {
        seriesFinanceiras.push({
          tipo: 'mensal_com_juros',
          valor: parseValue(dados.valorParcela),
          qtd_total_financiamento: parseInt(dados.quantidadeParcelas, 10),
          data_da_primeira_parcela: new Date(
            dados.dataVencimentoParcelas.split('/').reverse().join('-'),
          )
            .toISOString()
            .split('T')[0],
          calcula: false,
          id_proposta: dados.id_proposta,
        });
      }
    } catch (err) {
      setErrors(`Erro ao tentar transformar os valores. ${err}`);
      setModalErrors(true); // Exibe o modal de erro, caso necessário
      return [];
    }
    return seriesFinanceiras;
  };

  const submit = async (params: any) => {
    const seriesFinanceiras = transformData(params);
    if (!seriesFinanceiras || seriesFinanceiras.length === 0) {
      return;
    }
    let balao_maximo: any;
    const isValidDate = (
      dateString: any,
      tipo: string,
      qtdTotalFinanciamentoSinal: number,
      qtdParcelasFinanciamento: number,
      dataInicioFinanciamento: string,
      qtdParcelasSerie: any,
      dataBalao: any,
    ) => {
      const regex = /^\d{4}-\d{2}-\d{2}$/;
      const prazoLimite = 15;

      if (!dateString.match(regex)) {
        setErrors(
          `Data no formato incorreto: ${dateString
            .split('-')
            .reverse()
            .join('/')}`,
        );
        return false;
      }

      const dateParts = dateString.split('-');
      const year = parseInt(dateParts[0], 10);
      const month = parseInt(dateParts[1], 10) - 1;
      const day = parseInt(dateParts[2], 10);
      const inputDate = new Date(year, month, day);

      if (Number.isNaN(inputDate.getTime())) {
        setErrors(
          `Data inválida: ${dateString.split('-').reverse().join('/')}`,
        );
        return false;
      }

      const today = new Date();
      const dataLimite = new Date(today.getTime());
      dataLimite.setDate(today.getDate() + prazoLimite);

      if (tipo === 'sinal' && inputDate > dataLimite) {
        setErrors(
          `A data de vencimento do sinal não pode ser posterior a ${prazoLimite} dias a partir de hoje. ${dateString
            .split('-')
            .reverse()
            .join('/')}`,
        );
        return false;
      }

      today.setHours(0, 0, 0, 0);
      if (inputDate < today) {
        setErrors(
          `Data no passado: ${dateString.split('-').reverse().join('/')}`,
        );
        return false;
      }

      if (tipo === 'mensal_com_juros') {
        const dataSinal = params.dataVencimentoSinal;
        const dataMensal = dateString.split('-').reverse().join('/');

        const dataSinalFormatado = new Date(
          `${dataSinal.split('/').reverse().join('-')}T00:00:00`,
        );

        dataSinalFormatado.setMonth(
          dataSinalFormatado.getMonth() + Number(qtdTotalFinanciamentoSinal),
        );

        const dataPrimeiraParcela = new Date(dataSinalFormatado);
        dataPrimeiraParcela.setDate(1);

        const dataMinimaParcela = new Date(dataPrimeiraParcela);
        const dataMaximaParcela = new Date(dataPrimeiraParcela);
        dataMaximaParcela.setDate(25);

        const mensalFormatado = new Date(
          `${dataMensal.split('/').reverse().join('-')}T00:00:00`,
        );

        if (
          mensalFormatado < dataMinimaParcela ||
          mensalFormatado > dataMaximaParcela
        ) {
          setErrors(
            `A data da parcela deve estar entre o dia 01 e o dia 25 do mês ${dataMinimaParcela.toLocaleDateString(
              'pt-BR',
              { month: 'long', year: 'numeric' },
            )}, ou seja, no mês subsequente ao término do sinal.`,
          );
          return false;
        }
      }

      if (tipo === 'anual' || tipo === 'unica') {
        let formattedDate = dataInicioFinanciamento;

        if (formattedDate.includes('/')) {
          const dateParts = formattedDate.split('/');
          formattedDate = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
        }

        const inicioParts = formattedDate.split('-');
        const inicioYear = parseInt(inicioParts[0], 10);
        const inicioMonth = parseInt(inicioParts[1], 10) - 1;
        const inicioDay = parseInt(inicioParts[2], 10);

        const inicioDate = new Date(inicioYear, inicioMonth, inicioDay);
        if (inputDate <= inicioDate) {
          setErrors(`A data do balão deve ser posterior à data da parcela. Data balão: ${inputDate
            .toISOString()
            .split('T')[0]
            .split('-')
            .reverse()
            .join('/')}
            Data parcela: ${inicioDate
              .toISOString()
              .split('T')[0]
              .split('-')
              .reverse()
              .join('/')}
            `);
          return false;
        }
        if (Number.isNaN(inicioDate.getTime())) {
          setErrors('Data de início do financiamento é inválida.');
          return false;
        }

        const totalParcelas = parseInt(qtdParcelasFinanciamento, 10);
        if (Number.isNaN(totalParcelas)) {
          setErrors('Quantidade de parcelas do financiamento é inválida.');
          return false;
        }

        const dataFinalFinanciamento = new Date(inicioDate);
        dataFinalFinanciamento.setMonth(inicioDate.getMonth() + totalParcelas);

        const qtdRepeticoes = parseInt(qtdParcelasSerie, 10);
        if (Number.isNaN(qtdRepeticoes)) {
          setErrors('Quantidade de repetições do balão é inválida.');
          return false;
        }

        const balaoParts = dateString.split('-');
        const balaoYear = parseInt(balaoParts[0], 10);
        const balaoMonth = parseInt(balaoParts[1], 10) - 1;
        const balaoDay = parseInt(balaoParts[2], 10);

        const dataUltimoBalao = new Date(balaoYear, balaoMonth, balaoDay);

        dataUltimoBalao.setFullYear(balaoYear + qtdRepeticoes - 1);

        if (dataUltimoBalao.getMonth() !== balaoMonth) {
          dataUltimoBalao.setDate(0);
        }

        if (dataUltimoBalao > dataFinalFinanciamento) {
          setErrors(
            `A quantidade de repetições do balão não pode ultrapassar o fim do financiamento (${dataFinalFinanciamento
              .toISOString()
              .split('T')[0]
              .split('-')
              .reverse()
              .join('/')}).`,
          );
          return false;
        }

        if (inputDate > dataFinalFinanciamento) {
          setErrors(
            `A data do balão não pode ultrapassar o prazo final do financiamento (${dataFinalFinanciamento
              .toISOString()
              .split('T')[0]
              .split('-')
              .reverse()
              .join('/')}).`,
          );
          return false;
        }
      }

      return true;
    };

    const parseValue = (value: string) => {
      return parseFloat(value.replace(',', '.'));
    };

    const participants = values.map((participants: any) => {
      return {
        id: participants.id,
        porcentagem: participants.porcentagem / 100,
        tipo: participants.tipo_participante,
      };
    });

    const data = {
      data_vencimento_parcela: params.dataVencimentoParcelas
        .split('/')
        .reverse()
        .join('-')
        .split('T')[0],
      data_vencimento_sinal: params.dataVencimentoSinal
        .split('/')
        .reverse()
        .join('-'),
      land_id: params.land_id,
      num_entradas: Number(params.quantidadeSinal),
      num_parcelas: Number(params.quantidadeParcelas),
      participants: participants,
      valor_entrada: parseValue(params.valorSinal),
    };

    setSendMega(data);
    const balloonDates: { [year: string]: number } = {};
    let datas = [];
    for (const series of seriesFinanceiras) {
      const year = series.data_da_primeira_parcela.split('-')[0];

      if (
        !isValidDate(
          series.data_da_primeira_parcela,
          series.tipo,
          params.quantidadeSinal,
          params.quantidadeParcelas,
          params.dataVencimentoParcelas,
          series.qtd_total_financiamento,
          params.baloesAnuais.data,
        )
      ) {
        setModalErrors(true);
        return;
      }

      if (series.tipo === 'anual') {
        datas.push(series.data_da_primeira_parcela);
      }

      let dateCount: { [key: string]: number } = {};

      datas.forEach(date => {
        dateCount[date] = (dateCount[date] || 0) + 1;
      });

      let hasDuplicates = Object.values(dateCount).some(count => count > 1);

      if (hasDuplicates) {
        setErrors(`Existem mais de um balão na mesma data.`);
        setModalErrors(true);
        return;
      }

      let userFiltered;

      try {
        const listedAlcada = await UserService.listAlcada();

        const alcadas = listedAlcada.data;

        const userData = listedAlcada.userData;
        setUserID(userData.id);

        userFiltered = alcadas.filter(
          (alcada: any) => alcada.user_type === userData.userType,
        );

        setDescontoAlcada(userFiltered[0].desconto);

        const user_type = userFiltered[0].user_type;
        balao_maximo = parseInt(userFiltered[0].balao_maximo, 10);

        if (series.tipo === 'mensal_com_juros') {
          if (user_type === 'MANAGER' && series.qtd_total_financiamento > 240) {
            setErrors(`A quantidade máxima de parcelas do financiamento é 240`);
            setModalErrors(true);
            return;
          } else if (
            user_type === 'OVERVIEWER' &&
            series.qtd_total_financiamento > quantidadeParcelasEmp
          ) {
            setErrors(
              `A quantidade máxima de parcelas do financiamento é ${quantidadeParcelasEmp}`,
            );
            setModalErrors(true);
            return;
          } else if (
            user_type === 'COORDINATOR' &&
            series.qtd_total_financiamento > quantidadeParcelasEmp
          ) {
            setErrors(
              `A quantidade máxima de parcelas do financiamento é ${quantidadeParcelasEmp}`,
            );
            setModalErrors(true);
            return;
          }
        }

        if (series.tipo === 'anual' || series.tipo === 'unica') {
          for (let i = 0; i < series.qtd_total_financiamento; i++) {
            const currentYear = parseInt(year, 10) + i;
            if (!balloonDates[currentYear]) {
              balloonDates[currentYear] = 0;
            }
            balloonDates[currentYear]++;

            if (user_type !== 'MANAGER' && balloonDates[currentYear] > 1) {
              setErrors(
                `Existem mais de um balão no ano de ${currentYear}. Apenas GERENTES podem ter até 2 balões no mesmo ano.`,
              );
              setModalErrors(true);
              return;
            } else if (
              user_type === 'MANAGER' &&
              balloonDates[currentYear] > 2
            ) {
              setErrors(
                `Existem mais de dois balões no ano de ${currentYear}. Mesmo GERENTES não podem ter mais de 2 balões no mesmo ano.`,
              );
              setModalErrors(true);
              return;
            }
          }
        }
      } catch (err) {
        setErrors(`Problema ao tentar aplicar as condições da alçada.`);
        setModalErrors(true);
      }
    }

    const objectToCalcular = {
      id_empreendimento: params.emp_cod,
      valor_lote: params.valorLote,
      juros: 0,
      series_financeiras: seriesFinanceiras,
    };

    const adaptedFinancialSeries = objectToCalcular.series_financeiras.map(
      (resp: any, index) => {
        const calcular_parcela =
          resp.tipo === 'mensal_com_juros' && calculaParcelas.value;
        return {
          tipo: resp.tipo,
          valor: calcular_parcela ? 0 : resp.valor,
          qtd_total_financimaneto: resp.qtd_total_financiamento,
          data_da_primeira_parcela: resp.data_da_primeira_parcela,
          calcula: calcular_parcela,
        };
      },
    );

    const entrada = adaptedFinancialSeries.filter(
      (series: any) => series.tipo === 'sinal',
    );

    const valid = validaEntrada(objectToCalcular.valor_lote, entrada);
    const entradaMinima = objectToCalcular.valor_lote * 0.1;

    if (
      entrada[0].qtd_total_financimaneto > 1 ||
      entrada[0].qtd_total_financimaneto === 0
    ) {
      setLancarProposta(false);
      setIsLoading(false);
      setModalWaitCalc(false);
      setErrors(
        `A quantidade de parcelas da entrada deve ser à vista, ou seja, 1x.
        `,
      );
      setModalErrors(true);
      return;
    }
    if (!valid) {
      setLancarProposta(false);
      setIsLoading(false);
      setModalWaitCalc(false);
      setErrors(
        `A entrada deve ser no mínimo 10% do valor do lote. Valor mínimo: ${formatCurrencyParcela(
          entradaMinima,
        )}.
        `,
      );
      setModalErrors(true);
      return;
    }

    const tempObjectToCalcular = {
      id_empreendimento: params.emp_cod,
      valor_lote: params.valorLote,
      juros: 0,
      series_financeiras: adaptedFinancialSeries,
    };

    let calculatorError = '';

    try {
      setModalWaitCalc(true);
      setIsLoading(true);
      const respCalculator = await PropostasService.calculator(
        tempObjectToCalcular,
      );
      calculatorError = respCalculator.message;

      if (respCalculator.status !== 200) {
        setLancarProposta(false);
        setIsLoading(false);
        setModalWaitCalc(false);
        setErrors(`${calculatorError}. Verifique as condições de pagamento.`);
        setModalErrors(true);
      }

      const valorPresente = respCalculator.vp_das_series_fixadas.filter(
        (vp: any) => vp.serie === 'total',
      );

      setVPBaloes(
        respCalculator.vp_das_series_fixadas.filter(
          (vp: any) => vp.serie === 'anual' || vp.serie === 'unica',
        ),
      );
      if (!calculaParcelas.value) {
        setVPL(valorPresente[0].valor_presente);
        setCalculoAlcada(
          (1 - valorPresente[0].valor_presente / valorLote) * 100,
        );
      } else {
        setVPL(Number(valorLote));
        setCalculoAlcada(0);
        handleValorParcelaChange(respCalculator.valor_calculado.toFixed(2));
      }

      for (const series of adaptedFinancialSeries) {
        if (series.tipo === 'unica' || series.tipo === 'anual') {
          const maxBalloonValue = valorParcela * Number(balao_maximo);
          if (series.valor > maxBalloonValue) {
            setLancarProposta(false);
            setIsLoading(false);
            setModalWaitCalc(false);
            setErrors(
              `O valor do balão ${series.tipo} excede o máximo permitido de ${balao_maximo} vezes o valor da parcela.`,
            );
            setModalErrors(true);
            return;
          }
        }
      }

      const valorTotalBalao = adaptedFinancialSeries.reduce(
        (acumulador: any, valor_atual: any) => {
          let valor = acumulador;
          if (valor_atual.tipo === 'anual' || valor_atual.tipo === 'unica') {
            valor += valor_atual.valor;
          }
          return valor;
        },
        0,
      );

      const maxBalloonValue = valorParcela * Number(balao_maximo);

      if (valorTotalBalao > maxBalloonValue) {
        setLancarProposta(false);
        setIsLoading(false);
        setModalWaitCalc(false);
        setErrors(
          `O valor do balão excede o máximo permitido de ${balao_maximo} vezes o valor da parcela.`,
        );
        setModalErrors(true);
        return;
      }

      setModalWaitCalc(false);
      setIsLoading(false);
      setModalCalcApproved(true);
    } catch (err) {
      setErrors(`Erro ao tentar calcular as condições. ${calculatorError}.`);
      setLancarProposta(false);
      setIsLoading(false);
      setModalWaitCalc(false);
      setModalErrors(true);
    }

    setFinancialSeries(
      objectToCalcular.series_financeiras.map((resp: any) => {
        return {
          data_da_primeira_parcela: resp.data_da_primeira_parcela,
          id_proposta: resp.id_proposta,
          qtd_total_financiamento: resp.qtd_total_financiamento,
          tipo: resp.tipo,
          valor: resp.valor,
        };
      }),
    );
    setLancarProposta(true);
  };

  const createFinancialSeries = async () => {
    if (financialSeries) {
      setIsLoading(true);
      setModalWaitAlcada(true);
      const onlyBaloes = financialSeries.filter(
        (series: any) => series.tipo === 'anual' || series.tipo === 'unica',
      );

      financialSeries.forEach((item: any) => {
        if (item.tipo === 'mensal_com_juros') {
          item.valor = Number(valorParcela);
        }
      });

      const send_mega = {
        ...sendMega,
        valor_total: VPL,
        valor_parcelas: Number(valorParcela),
      };

      const onlyBaloesUpdated = onlyBaloes.map((balao: any) => {
        const matchingVPBalao = vpBaloes.find(
          (vpBalao: any) =>
            vpBalao.data_da_primeira_parcela === balao.data_da_primeira_parcela,
        );

        return {
          ...balao,
          valor_parcela: balao.valor,
          valor_presente: matchingVPBalao
            ? matchingVPBalao.valor_presente
            : null,
        };
      });

      const balon = onlyBaloesUpdated.map((baloes: any) => {
        return {
          data_da_primeira_parcela: baloes.data_da_primeira_parcela,
          id_proposta: baloes.id_proposta,
          qtd_total_financiamento: baloes.qtd_total_financiamento,
          tipo: baloes.tipo,
          valor_parcela: baloes.valor,
          valor_presente_total: baloes.valor_presente,
        };
      });

      const data = {
        send_mega: send_mega,
        baloes: balon,
        cod_mega: location.state.rowData.cod_mega_proposta_ativa,
        id_proposta: location.state.rowData.id,
      };

      setDataInserirManager(data);

      try {
        let calculoAlcada = (1 - VPL / valorLote) * 100;
        setCalculoDescontoImplicito(calculoAlcada);
        if (calculoAlcada >= Number(descontoAlcada.replace('%', ''))) {
          setIsLoading(false);
          setModalWaitAlcada(false);
          setModalAlcadaDenied(true);
        } else {
          const validaMega = await chamaMega(data);
          if (validaMega) {
            const create = await PropostasService.createFinancialSeries(
              financialSeries,
            );
            if (create) {
              setIsLoading(false);
              setModalWaitAlcada(false);
              setModalFinancialSerie(true);
            }
          }
        }
      } catch (err) {
        setIsLoading(false);
        setModalWaitAlcada(false);
        setErrors(`Ocorreu um erro ao criar as séries financeiras. ${err}`);
        setModalErrors(true);
      }
    }
  };

  const getFinancialSeriesByID = async (id: string) => {
    const data = await PropostasService.getFinancialSeriesByID(id);
    if (data) {
      setFinancialSeriesData(data.filter((resp: any) => !resp.c_removed));
    }
  };

  useEffect(() => {
    getParcipantProposals();
    getFinancialSeriesByID(id);
  }, [id]);

  useEffect(() => {
    const fetchDescontoGlobal = async () => {
      console.log('empreendimentoID', empreendimentoID); // colocar aqui
      const resp = await getDescontoGlobal(
        '9db1dd16-9bdb-4bf4-a7e3-7501e0a2d741',
      );
      setDescontoGlobalInfos(resp);
    };

    fetchDescontoGlobal();
  }, [empreendimentoID]);

  useEffect(() => {
    setTimeout(() => {
      if (financialSeriesData.length > 0) {
        financialSeriesData.forEach((series: any) => {
          if (series.type.label === 'sinal') {
            setDataVencimentoSinal(series.data_inicio.split('T')[0]);
            setQuantidadeSinal(series.quantidade_parcelas);
            setValorEntrada(series.valor_parcela);
            setValorEntradaEdit(formatCurrency(series.valor_parcela));
          }
          if (series.type.label === 'mensal_com_juros') {
            setValorParcela(series.valor_parcela);
            setValorParcelaEdit(formatCurrency(series.valor_parcela));
            setQuantidadeParcelas(series.quantidade_parcelas);
            setDataVencimentoParcelas(series.data_inicio.split('T')[0]);
          }
          if (series.type.label === 'anual') {
            setBaloesAnuais(prevState => {
              if (!prevState.some((balao: any) => balao.id === series.id)) {
                return [
                  ...prevState,
                  {
                    id: series.id,
                    data: series.data_inicio
                      .split('T')[0]
                      .split('-')
                      .reverse()
                      .join('/'),
                    quantidade: series.quantidade_parcelas,
                    valor: series.valor_parcela,
                  },
                ];
              }
              return prevState;
            });
          }
          if (series.type.label === 'unica') {
            setBaloesUnicos((prevState: any) => {
              if (!prevState.some((balao: any) => balao.id === series.id)) {
                return [
                  ...prevState,
                  {
                    id: series.id,
                    data: series.data_inicio
                      .split('T')[0]
                      .split('-')
                      .reverse()
                      .join('/'),
                    valor: series.valor_parcela,
                  },
                ];
              }
              return prevState;
            });
          }
        });
      }
    }, 1000);
  }, [financialSeriesData]);

  useEffect(() => {
    if (editar) {
      setQuantidadeSinal(1);
    }
  }, [editar]);

  useEffect(() => {
    getEvidence(id);
  }, [id]);

  const handleCreateEvidence = async (params: any) => {
    const evidence = await PropostasService.createEvidence(params);
    setEvidenciaAnexada(true);
    if (evidence) {
      try {
        const validaMega = await chamaMega(dataInserirManager);
        if (validaMega) {
          const create = await PropostasService.createFinancialSeries(
            financialSeries,
          );
          if (create) {
            setIsLoading(false);
            setModalWaitAlcada(false);
            setModalFinancialSerie(true);
          }
        }
      } catch (err) {
        setErrors(`Erro ao tentar criar proposta com evidência ${err}`);
        setModalErrors(true);
      }
    }
  };

  return (
    <>
      <Content>
        <DetalhesContainer>
          <DetalhesHeader>
            <div className="titulos">
              <div className="top-header">
                <h1>Proposta completa</h1>
              </div>
              <div className="top-header" style={{ marginTop: 10 }}>
                <h2>
                  Status: <span>{statusValue}</span>
                </h2>
                <h2 style={{ marginLeft: 0 }}>
                  <CheckCircleOutlineIcon style={{ fill: 'lightgreen' }} />
                  Realizada em {formataData(criacao)}
                </h2>
                <h2>
                  <UpdateIcon style={{ fill: 'yellow' }} />
                  Última atualização em {formataData(atualizacao)}
                </h2>
              </div>
            </div>
          </DetalhesHeader>

          <DetalhesContent>
            <InputsContent>
              <div className="info-dados-app">
                <h2>Dados enviados na proposta pelo app</h2>
                <p>Alguns dados não pode ser alterados</p>
                <p>Proposta: {codMega}</p>
                <p>Land id: {landId}</p>
                <p>
                  <h2>imobiliária:</h2> <strong>{imobiliaria}</strong>
                </p>
                <p>
                  <h2>Lote:</h2>
                </p>
                <p>Quadra: {lote?.quadra}</p>
                <p>Lote: {lote?.lote}</p>
                <h2>Empreendimento:</h2>
                <div className="empreendimento-label">
                  <p>{lote?.empreendimento}</p>
                  {empElegivelPagadoria && (
                    <p className="tag-empreendimento-pagadoria">PAGADORIA</p>
                  )}
                </div>
                <h2>Corretor:</h2>
                <p> {nomeCorretor.toLocaleUpperCase()}</p>
                <h2>Telefone Corretor:</h2>
                <p> {telefoneCorretor}</p>

                <div className="dropdown-historico">
                  {dadosClienteResumo.length > 0 && (
                    <>
                      <button
                        type="button"
                        onClick={() => setMostrarHistorico(!mostrarHistorico)}
                        className={`historico-btn ${
                          mostrarHistorico ? 'ativo' : 'oculto'
                        }`}
                      >
                        {mostrarHistorico ? 'Ocultar' : 'Mostrar'} Histórico do{' '}
                        {nomeClienteSelecionado}
                      </button>
                      <div className="dropdown">
                        {mostrarHistorico && (
                          <ul>
                            {dadosClienteResumo.map(contrato => (
                              <li key={contrato.id_contrato}>
                                <p>Nome: {contrato.nome_cliente}</p>
                                <p>
                                  Data do contrato:{' '}
                                  {formatarData(contrato.data_contrato)}
                                </p>
                                <p>Id do contrato: {contrato.id_contrato}</p>
                                <p>Imobiliária: {contrato.imobiliaria}</p>
                                <p>
                                  Inadimplência:{' '}
                                  {!contrato.inadimplente
                                    ? 'Verdadeiro'
                                    : 'Falso'}
                                </p>
                                <p>
                                  Lote/Quadra: {contrato.lote} /{' '}
                                  {contrato.quadra}
                                </p>
                                <p>
                                  Nome empreendimento:{' '}
                                  {contrato.nome_empreendimento}
                                </p>
                                <p>
                                  Status do contrato: {contrato.status_contrato}
                                </p>
                                <p>Tier: {contrato.tier}</p>
                                <hr />
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </InputsContent>
            <SessionContente>
              <div className="info-dados-app">
                <h2>Valores</h2>
              </div>
            </SessionContente>
            <InputsContent>
              <InputsContainer>
                <InputField
                  className="inputs"
                  label="Valor do lote"
                  disabled={true}
                  value={valorLoteEdit}
                  onChange={(e: any) => handleValorLoteChange(e.target.value)}
                  onBlur={handleBlur}
                  width={140}
                />
              </InputsContainer>
              <SessionContente>
                <div className="info-dados-app">
                  <h2>Sinal</h2>
                </div>
              </SessionContente>
              <InputsContainer>
                <InputField
                  className="inputs"
                  label="Data sinal"
                  value={dataVencimentoSinal
                    ?.split('T')[0]
                    .split('-')
                    .reverse()
                    .join('/')}
                  onChange={handleChangeDataVencimentosSinal}
                  disabled={!editar}
                  width={140}
                />
              </InputsContainer>
              <InputsContainer>
                <InputField
                  className="inputs"
                  label="Qtd. sinal"
                  disabled={true}
                  value={quantidadeSinal}
                  onChange={(e: any) => {
                    setQuantidadeSinal(e.target.value);
                  }}
                  width={90}
                />
              </InputsContainer>
              <InputsContainer>
                <InputField
                  className="inputs"
                  label="Valor sinal"
                  disabled={!editar}
                  value={editar ? valorEntrada : valorEntradaEdit}
                  onChange={(e: any) =>
                    handleValorEntradaChange(e.target.value)
                  }
                  onBlur={handleBlurEntrada}
                  width={140}
                />
              </InputsContainer>
              <SessionContente />
              <SessionContente>
                <div className="info-dados-app">
                  <h2>Parcela</h2>
                </div>
              </SessionContente>
              <InputsContainer>
                <InputField
                  className="inputs"
                  label="Data parcela"
                  value={dataVencimentoParcelas
                    ?.split('T')[0]
                    .split('-')
                    .reverse()
                    .join('/')}
                  disabled={!editar}
                  onChange={handleChangeDataParcela}
                  width={140}
                />
              </InputsContainer>
              <InputsContainer>
                <InputField
                  className="inputs"
                  label="Qtd. parcelas"
                  disabled={!editar}
                  value={quantidadeParcelas}
                  width={110}
                  onChange={(e: any) => setQuantidadeParcelas(e.target.value)}
                />
              </InputsContainer>
              <InputsContainer>
                <InputField
                  className="inputs"
                  label="Valor parcela"
                  disabled={!editar}
                  value={
                    editar ? valorParcela : formatCurrencyParcela(valorParcela)
                  }
                  onChange={(e: any) =>
                    handleValorParcelaChange(e.target.value)
                  }
                  onBlur={handleBlurParcela}
                  width={140}
                />
              </InputsContainer>
              <div style={{ marginTop: '0.7%' }}>
                <Autocomplete
                  id="combo-box-demo"
                  disabled={!editar}
                  options={[
                    {
                      title: 'Não',
                      value: false,
                    },
                    {
                      title: 'Sim',
                      value: true,
                    },
                  ]}
                  getOptionLabel={(option: any) => option.title}
                  style={{ width: 150 }}
                  value={calculaParcelas}
                  onChange={(e: any, value: any) =>
                    handleCalculaParcela(e, value)
                  }
                  disableClearable
                  renderInput={params => (
                    <TextField
                      style={{ width: 150 }}
                      label="Calcular parcelas?"
                      variant="outlined"
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...params}
                      size="small"
                      error={false}
                      helperText=""
                    />
                  )}
                />
              </div>
              {valor_reduzidas !== '0' && (
                <InputsContainer>
                  <InputField
                    className="inputs"
                    label="Valor reduzidas"
                    disabled={!editar}
                    value={valor_reduzidas}
                    width={140}
                  />
                </InputsContainer>
              )}
              <SessionContente>
                <div className="info-dados-app">
                  <h2 style={{ marginBottom: 3 }}>Balão</h2>
                </div>
              </SessionContente>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {baloesAnuais.map((balao, index) => (
                  <div
                    style={{
                      display: 'flex',
                      marginLeft: '0.7%',
                      flexDirection: 'row',
                    }}
                    key={index}
                  >
                    <div
                      style={{
                        display: 'flex',
                        gap: 15,
                        marginBottom: 8,
                      }}
                    >
                      <InputField
                        className="inputs"
                        label="Data Balão"
                        value={balao.data}
                        onChange={(e: any) =>
                          handleDataBalaoAnualChange(index, e.target.value)
                        }
                        disabled={!editar}
                        width={140}
                      />
                      <InputField
                        className="inputs"
                        label="Qtd. Anual"
                        disabled={!editar}
                        value={balao.quantidade}
                        onChange={(e: any) => {
                          quantidadeBalaoAnual(index, e.target.value);
                        }}
                        width={90}
                      />
                      <InputField
                        className="inputs"
                        label="Valor Balão"
                        disabled={!editar}
                        onChange={(e: any) =>
                          handleBalaoAnualChange(index, e.target.value)
                        }
                        value={
                          editar ? balao.valor : formatCurrency(balao.valor)
                        }
                        width={140}
                      />
                    </div>
                    {editar && (
                      <div style={{ marginRight: '70%', marginTop: '-3%' }}>
                        <Botao
                          className="deleteButton"
                          tipo="excluir"
                          tamanho="pequeno"
                          onClick={() => excluirBalaoAnual(index)}
                        >
                          Excluir
                        </Botao>
                      </div>
                    )}
                  </div>
                ))}
              </div>
              <div style={{ marginLeft: '2%' }}>
                <Botao
                  className="addButton"
                  tipo="adicionar"
                  tamanho="grande"
                  onClick={adicionarBalaoAnual}
                >
                  Adicionar Balão
                </Botao>
              </div>
              {data_vencimento_reduzidas?.split('T')[0] !== '1970-01-01' && (
                <InputsContainer>
                  <InputField
                    className="inputs"
                    label="Data reduzidas"
                    disabled={!editar}
                    value={data_vencimento_reduzidas
                      ?.split('T')[0]
                      .split('-')
                      .reverse()
                      .join('/')}
                    width={140}
                  />
                </InputsContainer>
              )}
            </InputsContent>
            <SessionContente>
              <div
                className="info-dados-app"
                style={{
                  border: '1px solid #ddd',
                  borderRadius: '8px',
                  padding: '16px',
                  maxWidth: '400px',
                  backgroundColor: '#f9f9f9',
                  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                  textAlign: 'left', // Garante o alinhamento à esquerda
                }}
              >
                <h2
                  style={{
                    color: '#333',
                    marginBottom: '16px',
                  }}
                >
                  Descontos Implícitos acumulado
                </h2>

                <div
                  className="discount-limits"
                  style={{ marginBottom: '16px' }}
                >
                  <h3
                    style={{
                      fontSize: '16px',
                      color: '#555',
                      borderBottom: '2px solid #ddd',
                      paddingBottom: '8px',
                      marginBottom: '12px',
                      fontWeight: 'bold',
                    }}
                  >
                    Limites Permitidos
                  </h3>
                  <p style={{ fontSize: '16px', marginBottom: '8px' }}>
                    Limite anual:{' '}
                    <strong>
                      {descontoGlobalInfos?.DI_limit_anual?.toFixed(3) * 100}%
                    </strong>
                  </p>
                  <p style={{ fontSize: '16px' }}>
                    Limite mensal:{' '}
                    <strong>
                      {descontoGlobalInfos?.DI_limit_mensal?.toFixed(3) * 100}%
                    </strong>
                  </p>
                </div>

                <div className="discount-status">
                  <h3
                    style={{
                      fontSize: '16px',
                      color: '#555',
                      borderBottom: '2px solid #ddd',
                      paddingBottom: '8px',
                      marginBottom: '12px',
                      fontWeight: 'bold',
                    }}
                  >
                    Situação Atual
                  </h3>
                  <p
                    style={{
                      color: descontoGlobalInfos?.check_DI_anual
                        ? 'green'
                        : 'red',
                      fontSize: '16px',
                      marginBottom: '8px',
                    }}
                  >
                    Anual:{' '}
                    <strong>
                      {descontoGlobalInfos?.DI_anual?.toFixed(3) * 100}%
                    </strong>
                  </p>
                  <p
                    style={{
                      color: descontoGlobalInfos?.check_DI_mensal
                        ? 'green'
                        : 'red',
                      fontSize: '16px',
                    }}
                  >
                    Mensal:{' '}
                    <strong>
                      {descontoGlobalInfos?.DI_mensal?.toFixed(3) * 100}%
                    </strong>
                  </p>
                </div>
              </div>
            </SessionContente>

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: 10,
                marginRight: '55%',
                marginLeft: '-8%',
              }}
            >
              <Botao
                tipo="editar"
                tamanho="grande"
                onClick={() => {
                  setEditar(!editar);
                  setLancarProposta(false);
                }}
              >
                {editar ? 'Salvar dados' : 'Editar dados'}
              </Botao>
              <Botao
                disabled={editar}
                tipo="detalhes"
                tamanho="grande"
                onClick={() => {
                  submit({
                    valorLote: Number(valorLote),
                    valorSinal: valorEntrada,
                    quantidadeSinal: quantidadeSinal,
                    dataVencimentoSinal: dataVencimentoSinal,
                    baloesAnuais: baloesAnuais,
                    baloesUnicos: baloesUnicos,
                    temJuros: true,
                    quantidadeParcelas: quantidadeParcelas,
                    dataVencimentoParcelas: dataVencimentoParcelas,
                    valorParcela: valorParcela,
                    emp_cod: empCod,
                    id_proposta: location.state.rowData.id,
                    land_id: landId,
                  });
                  setLancarProposta(false);
                }}
              >
                Calcular
              </Botao>
              <Botao
                disabled={editar || !lancarProposta}
                tipo="salvar"
                tamanho="grande"
                onClick={() => createFinancialSeries()}
              >
                Lançar nova proposta
              </Botao>
            </div>
            <InputsContent>
              <form>
                {values.map((value: any) => {
                  return (
                    <div key={value.index}>
                      <SessionContente>
                        <div className="info-dados-app">
                          <h2>
                            Dados pessoais{' '}
                            {value.tipo_participante === 'principal'
                              ? '(principal)'
                              : '(secundario)'}
                          </h2>
                        </div>
                      </SessionContente>
                      <InputsContainer>
                        <InputField
                          disabled
                          label="Nome"
                          name="nome"
                          value={value.nome}
                          width={400}
                          onChange={(e: any) => {
                            handleChange(e, value.index);
                          }}
                        />
                      </InputsContainer>
                      <InputsContainer>
                        <InputField
                          disabled
                          label="Sexo"
                          name="sexo"
                          value={value.sexo}
                          width={300}
                          onChange={(e: any) => {
                            handleChange(e, value.index);
                          }}
                        />
                      </InputsContainer>
                      <InputsContainer>
                        <InputField
                          disabled
                          className="inputs"
                          label="Estado civil"
                          value={value.estado_civil}
                          width={200}
                        />
                      </InputsContainer>
                      <InputsContainer>
                        <InputField
                          disabled
                          className="inputs"
                          label="CPF"
                          value={value.cpf}
                          width={200}
                        />
                      </InputsContainer>
                      <InputsContainer>
                        <InputField
                          disabled
                          label="RG"
                          name="rg"
                          value={value.rg}
                          width={300}
                          onChange={(e: any) => {
                            handleChange(e, value.index);
                          }}
                        />
                      </InputsContainer>

                      <InputsContainer>
                        <InputField
                          disabled
                          label="Orgão emissor"
                          name="orgao_rg"
                          value={value.orgao_rg}
                          width={150}
                          onChange={(e: any) => {
                            handleChange(e, value.index);
                          }}
                        />
                      </InputsContainer>
                      <InputsContainer>
                        <InputField
                          disabled
                          label="Telefone"
                          name="telefone"
                          value={value.telefone}
                          onChange={(e: any) => {
                            handleChange(e, value.index);
                          }}
                          width={200}
                        />
                      </InputsContainer>

                      <InputsContainer>
                        <InputField
                          disabled
                          label="E-mail"
                          name="email"
                          value={value.email}
                          onChange={(e: any) => {
                            handleChange(e, value.index);
                          }}
                          width={400}
                        />
                      </InputsContainer>

                      <InputsContainer>
                        <InputField
                          className="inputs"
                          label="Data de nascimento"
                          disabled
                          value={value.data_nascimento
                            .split('T')[0]
                            .split('-')
                            .reverse()
                            .join('/')}
                          width={200}
                        />
                      </InputsContainer>
                      <InputsContainer>
                        <InputField
                          disabled
                          label="Nacionalidade"
                          name="nacionalidade"
                          value={value.nacionalidade}
                          onChange={(e: any) => {
                            handleChange(e, value.index);
                          }}
                          width={200}
                        />
                      </InputsContainer>

                      <SessionContente>
                        <div className="info-dados-app">
                          <h2>
                            Trabalho{' '}
                            {value.tipo_participante === 'principal' &&
                              'e Escolaridade'}
                          </h2>
                        </div>
                      </SessionContente>

                      <InputsContainer>
                        <InputField
                          className="inputs"
                          label="Cargo"
                          disabled
                          value={value.cargo}
                          width={300}
                        />
                      </InputsContainer>
                      {value.tipo_participante === 'principal' && (
                        <>
                          <InputsContainer>
                            <InputField
                              className="inputs"
                              label="CLT"
                              disabled
                              value={clt}
                              width={300}
                            />
                          </InputsContainer>
                          <InputsContainer>
                            <InputField
                              className="inputs"
                              label="Escolaridade"
                              disabled
                              value={escolaridade}
                              width={300}
                            />
                          </InputsContainer>
                          <SessionContente>
                            <div className="info-dados-app">
                              <h2>Família</h2>
                            </div>
                          </SessionContente>
                          <InputsContainer>
                            <InputField
                              className="inputs"
                              label="Moradia atual"
                              value={moradiaAtual}
                              disabled
                              width={300}
                            />
                          </InputsContainer>
                          <InputsContainer>
                            <InputField
                              className="inputs"
                              label="Renda familiar"
                              value={formatCurrency(rendaFamiliar)}
                              disabled
                              width={200}
                            />
                          </InputsContainer>
                          <InputsContainer>
                            <InputField
                              className="inputs"
                              label="Dependentes"
                              value={dependentes}
                              disabled
                              width={150}
                            />
                          </InputsContainer>
                          <InputsContainer>
                            <InputField
                              className="inputs"
                              label="Objetivo"
                              disabled
                              value={objetivo}
                              width={200}
                            />
                          </InputsContainer>
                          <InputsContainer>
                            <InputField
                              className="inputs"
                              label="Nº moradores"
                              value={numMoradores}
                              disabled
                              width={150}
                            />
                          </InputsContainer>

                          <SessionContente>
                            <div className="info-dados-app">
                              <h2>Endereço Principal</h2>
                            </div>
                          </SessionContente>
                          <InputsContainer>
                            <InputField
                              label="CEP"
                              name="cep"
                              value={value.cep}
                              onChange={(e: any) => {
                                handleChange(e, value.index);
                              }}
                              disabled
                              width={200}
                            />
                          </InputsContainer>
                          <InputsContainer>
                            <InputField
                              label="UF"
                              name="uf"
                              value={value.uf}
                              onChange={(e: any) => {
                                handleChange(e, value.index);
                              }}
                              disabled
                              width={150}
                            />
                          </InputsContainer>
                          <InputsContainer>
                            <InputField
                              label="Municipio"
                              name="municipio"
                              value={value.municipio}
                              onChange={(e: any) => {
                                handleChange(e, value.index);
                              }}
                              disabled
                              width={300}
                            />
                          </InputsContainer>
                          <InputsContainer>
                            <InputField
                              label="Bairro"
                              name="bairro"
                              value={value.bairro}
                              onChange={(e: any) => {
                                handleChange(e, value.index);
                              }}
                              disabled
                              width={300}
                            />
                          </InputsContainer>
                          <InputsContainer>
                            <InputField
                              label="Logradouro"
                              name="logradouro"
                              value={value.logradouro}
                              onChange={(e: any) => {
                                handleChange(e, value.index);
                              }}
                              disabled
                              width={300}
                            />
                          </InputsContainer>
                          <InputsContainer>
                            <InputField
                              label="Numero"
                              name="numero"
                              value={value.numero}
                              onChange={(e: any) => {
                                handleChange(e, value.index);
                              }}
                              disabled
                              width={150}
                            />
                          </InputsContainer>

                          <InputsContainer>
                            <InputField
                              label="Complemento"
                              name="complemento"
                              value={value.complemento}
                              onChange={(e: any) => {
                                handleChange(e, value.index);
                              }}
                              width={300}
                              disabled
                            />
                          </InputsContainer>
                        </>
                      )}
                    </div>
                  );
                })}
              </form>
            </InputsContent>
          </DetalhesContent>
          <DocumentsEvidences filesUrl={linkEvidence} />
        </DetalhesContainer>
        <ApprovedModal
          isModalLoading={isApprovedModalLoading}
          isModalOpen={isApproved}
          onCloseModal={() => setIsApproved(false)}
          onApprovedClick={() => {
            analysisResult({
              proposal_id: location.state.rowData.id,
              proposal_valid: true,
              comentary: observacao,
            });
          }}
          showProposalInfo={empElegivelPagadoria}
          proposalInfo={{
            imobiliaria,
            nomeCorretor: nomeCorretor.toLocaleUpperCase(),
            nomeCliente: Object(
              values.find(part => part.tipo_participante === 'principal'),
            ).nome,
            valorSinal: formatCurrency(valor_entrada),
            quantidadeSinal: quantidadeSinal,
            dataSinal: dataVencimentoSinal
              ?.split('T')[0]
              .split('-')
              .reverse()
              .join('/'),
          }}
        />
        <ModalFinancialSerie
          isModalOpen={modalFinancialSerie}
          onCloseModal={setModalFinancialSerieClose}
        />
        <ModalCalcApproved
          isModalOpen={modalCalcApproved}
          onCloseModal={setModalCalcApprovedClose}
          valorParcela={formatCurrency(valorParcela)}
          VPL={formatCurrencyParcela(VPL)}
          desconto={calculoAlcada}
          onLancarProposta={createFinancialSeries}
        />
        <ModalWaitCalc
          isLoading={isLoading}
          isModalOpen={modalWaitCalc}
          onCloseModal={() => setModalCalcApproved(false)}
        />
        <ModalErrors
          isModalOpen={modalErrors}
          onCloseModal={() => setModalErrors(false)}
          errorText={errors}
        />
        <ModalWaitAlcada
          isModalOpen={modalWaitAlcada}
          onCloseModal={setModalAlcadaWaitClose}
          isLoading={isLoading}
        />
        <ModalAlcadaDenied
          isModalOpen={modalAlcadaDenied}
          onCloseModal={() => setModalAlcadaDenied(false)}
          calculoDescontoImplicito={calculoDescontoImplicito}
          descontoImplicitoAlcada={descontoAlcada}
          openEvidence={() => setModalEvidence(true)}
        />
        <ModalEvidence
          isModalOpen={modalEvidence}
          onCloseModal={() => setModalEvidence(false)}
          onFileUpload={handleUploadedFile}
          isButtonEvidence={evidenceButton}
          onHandleClick={() => handleCreateEvidence(evidenceFiles)}
        />
      </Content>
      <Snackbars
        type={snackbarProps.type}
        handleClose={handleSnackbarClose}
        open={snackbarOpen}
      >
        {snackbarProps.message}
      </Snackbars>
    </>
  );
};

export default EditarPropostaManager;
