import axios from 'axios';

// const baseURL = process.env.API_URL;
// const baseURL = process.env.LOCALHOST_URL;
const baseURL = 'https://msging.net/commands';

// const baseURL = 'http://localhost:3334';

const apiBlip = axios.create({ baseURL });

export default apiBlip;
