/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable class-methods-use-this */
import api from './api';
import AppError from '../errors/AppError';
import { getToken } from './auth';

class PedidosService {
  private headers: any;

  constructor() {
    // this.token = token
    this.headers = {
      Authorization: `Bearer: ${getToken()}`   };
  }

  // listar todos os usuários
  public async listAllLotes() {
    try {
      const { data } = await api.get(`/lands`,
      {headers: {
        Authorization: `Bearer: ${getToken()}`}});
      return data;
    } catch (err: any) {
      
      throw new AppError(err, err.statusCode);
    }
  }

  public async listEmpreendimentoLotesByEmp(empreendimentoId: string) {
    try {
      const { data } = await api.get(`lands/enterprise/${empreendimentoId}`,
      {headers: this.headers});
      return data;
    } catch (err: any) {
      
      throw new AppError(err, err.statusCode);
    }
  }
  
  public async atualizaLote(params: any) {
    try {
      const { data } = await api.patch(`/lands/`,
      {  ...params   },
      {headers: this.headers});
      return data;
    } catch (err: any) {
      
      throw new AppError(err, err.statusCode);
    }
  }

  public async findLote(id: string | number) {
    try {
      const { data } = await api.get(`/lands/find/${id}`,
      {headers: this.headers});
      return data;
    } catch (err: any) {
      
      throw new AppError(err, err.statusCode);
    }
  }
}

export default new PedidosService();
