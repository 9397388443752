import React from 'react';

const FaqIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="682.667"
      height="682.667"
      version="1"
      viewBox="0 0 512 512"
    >
      <path
        d="M1804 5109c-292-21-605-122-853-273-397-242-691-606-841-1041-172-502-127-1083 121-1543 21-41 39-80 39-87 0-11-241-893-255-932-2-7 1-10 8-8 35 13 922 255 933 255 6 0 49-20 95-44 218-114 489-193 732-214l89-7 33-99c89-260 263-520 470-699 511-443 1221-537 1823-240l113 56 317-88c174-48 354-98 398-110l81-22-22 81c-12 44-62 223-110 397l-88 317 56 113c296 607 203 1313-240 1824-179 207-439 381-699 470l-99 33-8 94c-74 885-783 1632-1662 1752-148 20-297 25-431 15zm480-318c662-143 1153-636 1298-1301 20-91 23-134 23-325s-3-234-23-325c-145-667-635-1157-1302-1302-91-20-134-23-325-23-192 0-233 3-325 23-201 44-412 129-568 229l-54 34-279-77c-154-43-282-74-286-71-3 4 29 132 71 286l77 279-34 53c-112 175-201 409-237 624-19 112-21 156-18 315 4 155 9 204 32 304 117 509 429 908 891 1139 117 58 235 99 376 131 155 34 157 34 384 31 160-2 220-7 299-24zm1762-1910c403-192 683-563 759-1005 20-115 19-327-1-447-28-169-105-366-199-514l-36-55 57-205c32-113 55-208 51-211-4-4-99 19-212 50l-205 57-55-36c-148-94-345-171-514-199-120-20-332-21-447-1-442 76-813 357-1005 759-77 162-82 147 68 174 606 108 1139 517 1410 1082 65 135 129 334 155 483 27 150 12 145 174 68z"
        transform="matrix(.1 0 0 -.1 0 512)"
      />
      <path
        d="M1870 4210c-266-41-479-262-506-525l-7-65h290l7 42c17 102 74 181 168 228 154 76 339 9 412-149 15-34 21-67 21-121 0-126-20-156-250-367l-195-177v-366h290v236l118 106c211 192 257 251 304 387 33 95 33 267 0 362-46 134-130 243-247 320-108 71-278 108-405 89zM1810 2260v-150h290v300h-290v-150z"
        transform="matrix(.1 0 0 -.1 0 512)"
      />
    </svg>
  );
};

export default FaqIcon;
