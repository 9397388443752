import styled from 'styled-components';
import cores from '../../styles/cores';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100vw;
  height: 100vh;
  overflow-x: auto;

  // .header {
  //   display: flex;
  //   boder: 1px solid black;
  // }
  // .menuLateral {
  //   display: flex;
  //   boder: 1px solid black;
  //   margin-top: 100px;
  // }
  // .conteudo {
  //   display: flex;
  //   boder: 1px solid black;
  // }
`;

interface IBallProps {
  color: string;
}

export const Status = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 4px;
  color: black;
  // border-radius: 32px;
  width: 200px;
  height: fit-content;
  font-size: 14px;
  font-weight: normal;
  word-wrap: break-word;
  text-align: center;
  background-color: transparent;
`;

export const Ball = styled.span`
  display: flex;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: ${(props: IBallProps) => props.color};
  margin-right: 5px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  // background-image: ${cores.themeGradient_2};

  .snackbar-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 400px;
    .MuiAlert-icon {
    }
    .MuiSvgIcon-fontSizeSmall {
      font-size: 22px;
    }

    .MuiAlert-action {
      font-size: 64px;
    }
    .MuiAlert-message {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      font-size: 16px;
    }
  }
`;

export const PropostasHeader = styled.div`
  display: flex;
  width: 100%;
  height: 100px;
  border: 1px solid black;
  background-color: ${cores.purple_1};
`;

export const OpcoesContainer = styled.div`
  display: flex;
  // margin-top: 40px;
  // margin-bottom: 10px;
  // margin-left: 50px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  width: 100vw;
`;

export const SecondRowContainer = styled.div`
  display: flex;
  border: 0px solid blue;
  width: 100vw;
  height: fit-content;
`;

export const MenuLateralContainer = styled.div`
  display: flex;
  width: auto;
  height: auto;
`;

export const TabelaContainer = styled.div`
  display: flex;
  border: 0px solid black;
  width: fit-content;
  border-radius: 12px;
  height: fit-content;
  align-self: center;
  margin-bottom: 50px;
  margin-top: 20px;
`;

export const DetalhesContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  margin-left: 70px;
  width: 100%;
  height: fit-content;
  align-self: center;
  border-radius: 8px;
  box-shadow: 0px 0px 6px 2px rgba(12, 12, 14, 0.2);

  h1 {
    align-self: flex-start;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 20px;
    font-size: 22px;
    font-weigth: bold;
  }

  .endereco {
    width: 100%;
    align-self: flex-start;
    h2 {
      font-size: 20px;
      font-weigth: bold;
      margin-top: 15px;
      margin-bottom: 20px;
    }
  }
`;

export const DetalhesHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 50px;
  .btn {
    margin-right: 30px;
    height: 50px;
  }
`;

export const Usuario = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;

  h1 {
    align-self: center;
  }
  svg {
  }
`;

export const DetalhesContent = styled.div`
  display: flex;
  border: 0px solid black;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  max-width: 100%;
  margin-left: 20px;
  margin-bottom: 20px;
  .item {
    display: flex;
    flex-direction: column;
    border: 0px solid black;
    width: fit-content;
    margin-top: 10px;
    margin-right: 50px;

    h2 {
      font-size: 18px;
      font-weight: bold;
    }
  }
`;
export const ModalContainer = styled.div`
  outline: 0;
  border: 0px solid green;
  height: fit-content;
  margin-bottom: 0px;
`;

export const BotaoContainer = styled.div`
  display: flex;
  border: 0px solid red;
  justify-content: space-between;
  align-items: center;
  height: fit-content;
  margin-bottom: 0px;

  .btn {
    width: 45%;
    margin-bottom: 0px;
  }
`;
export const ModalContainerDelete = styled.div`
  height: fit-content;
  margin-bottom: 0px;
  height: fit-content;

  h1 {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0px solid black;
    width: 100%;
    margin-bottom: 20px;
    margin-top: 25px;
  }
  .aviso {
    border: 0px solid red;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
    font-weight: regular;
    font-size: 18px;

    p {
      border: 0px solid red;
      text-align: justify;
      line-height: 20px;
    }

    p:nth-of-type(1) {
      font-weight: bold;
      margin-bottom: 10px;
      text-align: center;
    }
    p:nth-of-type(2) {
      margin-bottom: 10px;
    }

    svg {
      fill: red;
      font-size: 128px;
      margin-bottom: 4px;
    }
  }
`;
