import { createTheme } from '@material-ui/core/styles';
import colors from './cores';

const theme = createTheme({
  palette: {
    primary: {
      main: colors.purple_2,
    },
    secondary: {
      main: colors.red,
    },
  },
});

export default theme;

export const botoes = {
  tipo: {
    salvar: 'salvar',
    deletar: 'deletar',
    excluir: 'excluir',
    detalhes: 'detalhes',
    adicionar: 'adicionar',
    login: 'login',
    editar: 'editar',
    voltar: 'voltar',
    recuperar: 'recuperar'
  },
  tamanho: {
    pequeno: 'pequeno',
    medio: 'medio',
    grande: 'grande',
    fullwidth: 'fullwidth',
  },
};

export const inputs = {
  tipo: {
    adicionar: 'Adicionar',
  },
};
