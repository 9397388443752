import AppError from '../errors/AppError';

export const TOKEN_KEY = '@Bairru_App:token';
export const TOKEN_KEY_DATA = "@user/AUTHENTICATE";
// export const KEEP_LOGGED = '@Bairru_App:keepLogged'
// export const USER_DATA = '@Bairru_App:user_data'
// export interface IUserData{

// }
export const onSignIn = async (token: string, id:any, userType:any) => {
  try {
    const created_at = Date.now();
    localStorage.removeItem(TOKEN_KEY);
    const data = {token, id, userType,created_at }
    localStorage.setItem(TOKEN_KEY,JSON.stringify(data));
    return true;
  } catch (err: any) {
    throw new AppError(err);
  }
};

export const onSignOut = async () => {
  try {
    localStorage.removeItem(TOKEN_KEY);
  } catch (err: any) {
    throw new AppError(err);
  }
};

export const isSignedIn = () => {
  try {
    const token =  localStorage.getItem(TOKEN_KEY);
    if(token){
      const userData = JSON.parse(token);
      const tokenDate = userData.created_at;
      const token_duration = Date.now() - tokenDate;
      const token_in_days = token_duration / (1000*60*60*24);
      if(token_in_days > 60){
        localStorage.removeItem(TOKEN_KEY);
        return false
      }
      return true
    }
    return false;
  } catch (err: any) {
    throw new AppError(err);
  }
};

export const getToken = () => {
  try {
    let token:any = localStorage.getItem(TOKEN_KEY);
    token = JSON.parse(token);
    
    if(token){
      return token.token
    }
    else{
      return null
    }
  } catch (err: any) {
    throw new AppError(err);
  }
};

export const getUserData =  () => {
  try {
    let token:any =  localStorage.getItem(TOKEN_KEY);
    token = JSON.parse(token);
    return token;
  } catch (err: any) {
    throw new AppError(err);
  }
};

export const getLoggedInUserId = () => {
  try {
    const userData = getUserData(); 
    if (userData) {
      return userData.id; 
    }
    return null;
  } catch (err) {
    console.error('Erro ao obter o ID do usuário logado:', err);
    return null;
  }
};

