import styled from 'styled-components';
import cores from '../../../styles/cores';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100vw;
  height: 100vh;
  overflow-x: auto;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: fit-content;

  .detalhes-container:nth-of-type(3) {
    margin-bottom: 30px;
  }
`;

export const DetalhesContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  width: 90%;
  border: 1px solid #dddddd;
  height: fit-content;
  align-self: center;
  border-radius: 8px;
  box-shadow: 0px 0px 6px 2px rgba(12, 12, 14, 0.2);

  h1 {
    font-size: 20px;
    font-weight: bold;
    color: white;
    margin-left: 10px;
  }
  .card-header {
    display: flex;
    align-self: flex-start;
    align-items: center;
    justify-content: flex-start;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    margin-bottom: 10px;
    padding-left: 10px;
    padding-bottom: 5px;
    padding-top: 5px;
    border-bottom: 0px solid grey;
    background-color: ${cores.purple_2};
    width: 100%;

    svg {
      width: 32px;
      height: 32px;
    }
  }

  svg {
    width: 26px;
    height: 26px;
    fill: white;
  }
`;

export const DetalhesHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 20px;
  width: 90%;
  .btn {
    margin-right: 30px;
    height: 50px;
  }
`;

export const Usuario = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;

  h1 {
    align-self: center;
  }
  svg {
  }
`;

export const DetalhesContent = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  max-width: 100%;
  margin-left: 0px;

  li {
    display: flex;
  }

  .item {
    display: flex;
    flex-direction: column;
    width: fit-content;
    margin: 10px 25px;

    h2 {
      margin-bottom: 5px;
      font-size: 18px;
      font-weight: bold;
    }
  }
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  td {
  }

  td,
  th {
    // border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
    padding-left: 20px;
  }
  th {
    font-weight: bold;
  }

  tr:nth-child(even) {
    background-color: #dddddd;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-top: 0px;

  .time-container {
    display: flex;
    margin-top: 10px;
  }

  .header-info-container {
    display: flex;
    align-items: center;


    h1 {
      margin-right: 0px;
      font-size: 20px;
      font-weight: bold;
    }

    h2 {
      font-size: 18px;
      font-weight: bold;
    }

    span {
      font-weight: regular;
      margin-right: 20px;
      margin-left: 5px;
      font-size: 16px;
    }

    span:nth-of-type(1) {
      font-size: 22px;
    }
  }

  h1 {
    margin-right: 10px;
  }
  h2 {
    display: flex;
    align-items: center;
    margin-right: 10px;
    svg {
      margin-right: 5px;
    }
    svg:nth-of-type(1) {
      fill: green;
    }
  }

  h2:nth-of-type(2) {
    svg:nth-of-type(1) {
      fill: ${cores.yellow};
    }
  }
`;


export const TableRow = styled.tr `

      font-size: 14px;
      cursor: pointer;
      transition: ease 250ms;

    &:hover{
    opacity: 0.8;
    color: ${cores.purple_1}
    }
`;
