import Botao from '../Botao/Botao';
import Modal from '../Modal/Modal';
import { BotaoContainer, ModalContainerDelete } from './styles';

interface updateModalProps {
  isModalOpen: boolean;
  onUpDateClick: any;
  onCloseModal: any;
}

const SendModal = (props: updateModalProps): any => {
  const { isModalOpen, onUpDateClick, onCloseModal } = props;

  return (
    <Modal
      isOpen={isModalOpen}
      width={420}
      onClose={() => {
        onCloseModal();
      }}
    >
      <>
        <ModalContainerDelete>
          <h1> Deseja enviar os dados para análise?</h1>
          <BotaoContainer>
            <Botao
              tipo="salvar"
              tamanho="medio"
              className="btn"
              disabled={false}
              onClick={() => {
                onUpDateClick();
                setTimeout(() => {
                  onCloseModal()
                },1000)
              }}
            >
              Enviar
            </Botao>
            <Botao
              tamanho="medio"
              className="btn"
              onClick={() => {
                onCloseModal();
              }}
            >
              Cancelar
            </Botao>
          </BotaoContainer>
        </ModalContainerDelete>
      </>
    </Modal>
  );
};

export default SendModal;
