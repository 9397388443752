import { useState } from 'react';
import WarningIcon from '@material-ui/icons/Warning';
import { botoes } from '../../styles/tema';
import { validaEmail } from '../../utils/validacoes';
import Logo from '../../assets/svg/LogoBairru';
import {
  FormContainer,
  Background,
  BotaoContainer,
  Container,
  Icone,
  ModalContainer,
  IconeErro,
  Paragraph,
  Title,
} from './styles';
import InputField from '../../components/Inputs/InputField';
import Botao from '../../components/Botao/Botao';
import UserService from '../../services/UserService';
import Modal from '../../components/Modal/Modal';
import ModalRecover from '../../components/ModalRecover/ModalRecover';
import ModalRecoveryApproved from '../../components/ModalRecoveryApproved/ModalRecoveryApproved';

const RecoverPassword = () => {
  const [errorEmail, setErrorEmail] = useState(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalRecover, setModalRecover] = useState(false)
  const [modalApproved, setModalApproved] = useState(false)
  const [errorMessageEmail, setErrorMessageEmail] = useState('');
  const [email, setEmail] = useState<string>('')


  const errorMessages = {
    campoObrigatorio: 'Campo obrigatório!',
    emailInvalido: 'Email inválido!',
  };

  const setModalClose = () => {
    setModalRecover(false)
    setLoading(false)
  }

  const setModalApprovedClose = () => {
    setModalApproved(false)
  }

  const FormModal: any = (): any => {
    return (
      <ModalContainer>
        <Modal
          isOpen={isModalOpen}
          width={380}
          onClose={() => setModalOpen(false)}
        >
          <>
            <Title>Email não encontrado!</Title>
            <Paragraph>Verifique o email digitado.</Paragraph>
            <IconeErro>
              <WarningIcon />
            </IconeErro>
          </>
        </Modal>
      </ModalContainer>
    );
  };

  const recover = async () => {
    if (email === '') {
      setErrorEmail(true);
      setErrorMessageEmail(errorMessages.campoObrigatorio);
      return
    }
    if (errorEmail) {
      return
    }

    try {
      setModalRecover(true)
      setLoading(true)
      await UserService.recoverPassword(email)
      setModalRecover(false)
      setLoading(false)
      setModalApproved(true)
    } catch (err: any) {
      setModalOpen(true)
      console.log(err.message.response.data.message)
    }

  };

  const emailValidation = (email: string) => {
    setEmail(email)
    let validEmail = validaEmail(email)

    if (email === '') {
      setErrorEmail(true);
      setErrorMessageEmail(errorMessages.campoObrigatorio);
    }
    if (!validEmail) {
      setErrorEmail(true);
      setErrorMessageEmail(errorMessages.emailInvalido);
    }
    else {
      setErrorEmail(false);
      setErrorMessageEmail('');
    }
  }

  return (
    <>
      <Background>
        <Icone>
          <Logo />
        </Icone>
        <FormContainer className="digite-email">
          <Container>
            <h2> Recuperação de senha</h2>
            <p className="textoAux">
              Digite o seu email abaixo para enviarmos uma senha temporária.
            </p>
            <>
              <InputField
                tipo="outlined"
                label="Email"
                className="input"
                error={errorEmail}
                helperText={errorMessageEmail}
                value={email}
                onChange={(e: any) => emailValidation(e.target.value)}
              />
              <BotaoContainer>
                <Botao
                  tipo={botoes.tipo.login}
                  tamanho={botoes.tamanho.fullwidth}
                  onClick={recover}
                  isLoading={isLoading}
                >
                  Enviar
                </Botao>
              </BotaoContainer>
            </>
          </Container>
        </FormContainer>
        <FormModal />
        <ModalRecover
          isModalOpen={modalRecover}
          isLoading={isLoading}
          onCloseModal={setModalClose}
        />
        <ModalRecoveryApproved
          isModalOpen={modalApproved}
          onCloseModal={setModalApprovedClose}
        />
      </Background>
    </>
  );
};

export default RecoverPassword;
