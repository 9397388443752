import { useHistory } from 'react-router-dom';
import PrintPdf from '../../../components/PrintPdf/PrintPdf';

const ContratoCp = () => {
  const history = useHistory();
  console.log('history aqui', history);

  return <PrintPdf data={history.location.state} />;
};

export default ContratoCp;
