// export function mascaraTelefone(telefone: string | number): string {
//   const telefoneFormatado = telefone
//     .toString()
//     .replace(/[^0-9]/g, '')
//     .split('');
//   return telefoneFormatado.join('');
// }

export function mascaraTelefone(value: string) {

  let r = value.replace(/\D/g, "");
  r = r.replace(/^0/, "");

  if (r.length > 11) {
    r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
  } else if (r.length > 7) {
    r = r.replace(/^(\d\d)(\d{5})(\d{0,4}).*/, "($1) $2-$3");
  } else if (r.length > 2) {
    r = r.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
  } else if (value.trim() !== "") {
    r = r.replace(/^(\d*)/, "($1");
  }
  return r;
}


export const mascaraCPF = (value: string) => {
  if (value === undefined || value === null) {
    return "";
  }
  return value
    .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
    .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1')
}

export function mascaraCNPJ(cnpj: string | number): string {
  const cnpjFormatado = cnpj
    .toString()
    .replace(/[^0-9]/g, '')
    .split('');

  if (cnpjFormatado.length >= 2) {
    cnpjFormatado.splice(2, 0, '.');
    if (cnpjFormatado.length >= 6) {
      cnpjFormatado.splice(6, 0, '.');
      if (cnpjFormatado.length >= 11) {
        cnpjFormatado.splice(10, 0, '/');
        if (cnpjFormatado.length >= 15) {
          cnpjFormatado.splice(15, 0, '-');
        }
      }
    }
  }
  return cnpjFormatado.join('');
}

export function mascaraCEP(cep: string | number): string {
  const cepFormatado = cep
    .toString()
    .replace(/[^0-9]/g, '')
    .split('');

  if (cepFormatado.length >= 5) {
    cepFormatado.splice(5, 0, '-');
  }

  return cepFormatado.join('');
}


export function mascaraReal(value: number) {
  const mascara = value
    .toString()
    .replace(/[^0-9]/g, '')
    .split('');
  return mascara;
}

export function moneyMask(value: number) {
  const mascara = value
    .toString()
    .replace(/[^0-9]/g, '')
    .split('');
  return mascara;
}

export function mascaraData(value: string) {
  if (value.includes('-')) {
    const date = value.split('-');
    return `${date[2]}/${date[1]}/${date[0]}`;
  } else {
    const data = value.replace(/[^0-9]/g, '').split('');
    if (data.length >= 2) {
      data.splice(2, 0, '/');
      if (data.length >= 5) {
        data.splice(5, 0, '/');
      }
    }
    return data.join('');
  }
}