/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable class-methods-use-this */
import AppError from '../errors/AppError';
import api from './api';
import { getToken } from './auth';

class LeadsService {

  private headers: any;

  private headerFile: any;


  constructor() {
    this.headers = {
      Authorization: `Bearer: ${getToken()}`
    };
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public async listTickets() {
    try {
      const { data } = await api.get(`leads/get-blip-ticket`, {
        headers: {
          Authorization: `Bearer: ${getToken()}`
        },
      });
      return data;
    } catch (err: any) {
      throw new AppError(err, err.statusCode);
    }
  }

  public async updateTicketOpened() {
    try {
      const { data } = await api.get(`/leads/opened-blip-tickets`,
        {
          headers: {
            Authorization: `Bearer: ${getToken()}`
          },
        }
      );
      return data;
    } catch (err: any) {
      throw new AppError(err, err.statusCode);
    }
  }

  public async createLeadAction(params: any) {
    try {
      const { data } = await api.post(`/leadsActions`,
        { ...params },
        {
          headers: {
            Authorization: `Bearer: ${getToken()}`
          },
        }
      );
      return data;
    } catch (err: any) {
      throw new AppError(err, err.statusCode);
    }
  }

  public async alteraDisponibilidade(idAlteracao: string, novoStatus: boolean, tipoAlteracao: string) {
    try {
      const response = await api.post('https://webhooks.bairru.com.br/leads_internas/altera_disponibilidade', {
        id_alteracao: idAlteracao,
        novo_status: novoStatus,
        tipo_alteracao: tipoAlteracao
      },
        {
          headers: this.headers
        });

      console.log('Requisição bem-sucedida:', response.data);
      return response.data;
    } catch (err: any) {
      console.error('Erro na requisição:', err);
      throw new AppError(err, err.statusCode);
    }
  }

  public async consultaCorretor(idsCorretores: string[]) {
    try {
      const response = await api.post(
        'https://webhooks.bairru.com.br/leads_internas/consulta_corretor',
        {
          ids_corretores: idsCorretores, 
        },
        {
          headers: this.headers,
        }
      );

      console.log('Requisição bem-sucedida:', response.data);
      return response.data;
    } catch (err: any) {
      console.error('Erro na requisição:', err);
      throw new AppError(err, err.statusCode);
    }
  }



}
export default new LeadsService();
