// import React, { useEffect } from 'react'
// import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux';
// import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';

import { ThemeProvider } from '@material-ui/core';
import { BrowserRouter as Router } from 'react-router-dom';
import GlobalStyle from './styles/global';
import theme from './styles/tema';
import Routes from './routes';
import store from './storage/index.js';

function App() {
  return (
    <Provider store={store}>
      <Router>
        <ThemeProvider theme={theme}>
          <GlobalStyle />
        </ThemeProvider>
        <Routes />
      </Router>
    </Provider>
  );
}

export default App;
