import React, { useState, useEffect, useCallback } from 'react';
import TextField from '@material-ui/core/TextField';
import { useHistory } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import MenuLateral from '../../components/MenuLateral/MenuLateral';
import Header from '../../components/Header/Header';
// import DetalhesImobiliaria from './DetalhesImobiliaria';

import {
  Container,
  Content,
  HeaderContainer,
  MenuLateralContainer,
  SecondRowContainer,
  TabelaContainer,
  OpcoesContainer,
  DetalhesContainer,
  DetalhesHeader,
  Usuario,
  DetalhesContent,
  ModalContainer,
  BotaoContainer,
} from './styles';
import Tabela from '../../components/Tabelas/Tabela';
import Botao from '../../components/Botao/Botao';
import AvatarUsuario from '../../assets/Icons/AvatarUsuario';
// import DetalhesCorretores from './DetalhesCorretores';
// import EditarCorretores from './EditarCorretores';
// import NovoCorretor from './NovoCorretor';
import Modal from '../../components/Modal/Modal';
import DetalhesEmpreendimentos from './DetalhesEmpreendimentos';
import NovoEmpreendimento from './NovoEmpreendimento';
import DeleteModal from '../../components/DeleteModal';
import EditarEmpreendimento from './EditarEmpreendimento';
import Snackbars from '../../components/Snackbars';
import EnterprisesServices from '../../services/EmpreendimentosService';

const Empreendimentos: React.FC = () => {
  const [detalhes, setDetalhes] = useState(false);
  const [enterpriseData, setEnterpriseData] = useState({});
  const [allEnterpriseData, SetAllEnterpriseData] = useState<any[]>();
  const [pageMenu, setPageMenu] = useState('principal');
  const [snackbarProps, setSnackbarProps] = useState({
    message: '',
    type: 'success',
  });
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);

  const history = useHistory();

  const fetchData = useCallback(async () => {
    setIsDataLoading(true);
    await EnterprisesServices.listAllEmpreendimentos().then(response => {
      SetAllEnterpriseData(response.enterprise);
    });
    setIsDataLoading(false);
  }, []);

  useEffect(() => {
    fetchData();
    console.log('allEnterpriseData: ', allEnterpriseData);
  }, []);

  const columns = [
    {
      title: 'Nome',
      field: 'name',
    },
    {
      title: 'Empresa',
      field: 'empresa',
    },
    {
      title: 'Cidade',
      field: 'cidade',
    },
    {
      title: 'UF',
      field: 'uf',
    },
    // {
    //   title: 'Imobiliárias',
    //   field: 'imboliarias',
    //   render: (rowData: any) => {
    //     let stringReturned = '';
    //     for (let i = 0; i < rowData.empreendimento.length; i++) {
    //       stringReturned += ` ${rowData.empreendimento[i]}`;
    //     }

    //     return `${rowData.empreendimento}`;
    //   },
    // lookup: {
    //   'Empreendimento 0': 'Empreendimento 0',
    //   'Empreendimento 1': 'Empreendimento 1',
    //   'Empreendimento 2': 'Empreendimento 2',
    //   'Empreendimento 3': 'Empreendimento 3',
    //   'Empreendimento 4': 'Empreendimento 4',
    // },
    // },
  ];

  const data = [
    {
      id: '1',
      name: 'Bairru das cerejeiras',
      doc: '123.456.789-00',
      empreendimento: ['Imobiliaria 1', 'Imobiliaria 2'],
      email: 'john@gmail.com',
      imageUrl:
        'https://i.pinimg.com/originals/61/d4/2a/61d42a2baf3e18a03e5f50025b3d9a04.png',
      telefone: '41987654321',
      cidade: 'Curitiba',
      empresa: 'Bairru Urbanismo',
      uf: 'PR',
    },
    {
      id: '2',
      name: 'Tramonto',
      empreendimento: 'Imobiliaria 1 Imobiliaria 2',
      email: 'bren@gmail.com',
      doc: '123.456.789-00',
      imobiliaria: 'coisaboa',
      telefone: '41987654321',
      cidade: 'Curitiba',
      empresa: 'Bairru Urbanismo',
      uf: 'PR',
      imageUrl:
        'https://i.pinimg.com/originals/61/d4/2a/61d42a2baf3e18a03e5f50025b3d9a04.png',
    },
  ];
  const [isModalOpen, setModalOpen] = useState(false);
  function rowSelected(e: any, rowData: any) {
    history.push({
      pathname: '/painel/empreendimentos/detalhes',
      state: rowData,
    });
  }

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setModalOpen(false);
    setOpen(true);
  };

  const handleSnackbarClose = (
    event?: React.SyntheticEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };
  function Alert(props: AlertProps) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  function deletarRegistro(deleteData: any) {
    setDeleteModalOpen(false);
    setSnackbarProps({
      message: 'Empreendimento deletado com sucesso',
      type: 'success',
    });
    setSnackbarOpen(true);
  }

  return (
    <>
      <Content>
        <OpcoesContainer>
          {/* <Botao tipo="adicionar" tamanho="medio" onClick={adicionarCorretor}>
              Adicionar Empreendimento
            </Botao> */}
        </OpcoesContainer>

        <TabelaContainer>
          <Tabela
            title="Empreendimentos"
            isLoading={isDataLoading}
            haveActions="none"
            columns={columns}
            data={allEnterpriseData}
            onRowClick={(e: any, rowData: any): any => {
              setEnterpriseData(rowData);
              rowSelected(e, rowData);
            }}
            excluirClick={(e: any, rowData: any): any => {
              setDeleteModalOpen(true);
            }}
            editarClick={(e: any, rowData: any): any => {
              setEnterpriseData(rowData);
              setPageMenu('editar');
            }}
            pagination
            paginationPageSize={10}
          />
        </TabelaContainer>
        {/* <Alert severity="error">This is an error message!</Alert>
        <Alert severity="warning">This is a warning message!</Alert>
        <Alert severity="info">This is an information message!</Alert>
        <Alert severity="success">This is a success message!</Alert> */}
        <DeleteModal
          isModalOpen={deleteModalOpen}
          onCloseModal={() => setDeleteModalOpen(false)}
          onDeleteClick={() => deletarRegistro(enterpriseData)}
        />
        <Snackbars
          type={snackbarProps.type}
          handleClose={handleSnackbarClose}
          open={snackbarOpen}
        >
          {snackbarProps.message}
        </Snackbars>
      </Content>
    </>
  );
};

export default Empreendimentos;
