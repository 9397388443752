/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { useHistory, useLocation } from 'react-router';
import Botao from '../../../components/Botao/Botao';
import {
  Content,
  DetalhesContainer,
  DetalhesContent,
  DetalhesHeader,
  Usuario,
  BotoesContainer,
  InputsContainer,
  InputsContent,
} from './styles';
import InputField from '../../../components/Inputs/InputField';
import FileUpload from '../../../components/FileUpload';
import MarketplaceService from '../../../services/MarketplaceService';
import Snackbars from '../../../components/Snackbars';
import DeleteModal from '../../../components/DeleteModal';
import sleep from '../../../utils/sleep';

const validationSchema = Yup.object().shape({
  nomeProduto: Yup.string().max(50, 'Nome do produto deve ter no máximo 50 caracteres').required('Nome do produto é obrigatório'),
  categoriaProduto: Yup.string().required('Categoria do produto é obrigatória'),
  valorProduto: Yup.string().matches(/^\d+(\.\d{1,2})?$/, 'Preço do produto deve ser um número').required('Preço do produto é obrigatório'),
  descricaoProduto: Yup.string().required('Descrição do produto é obrigatória'),
});

interface ChipData {
  key: number;
  label: string;
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;


const EditarProduto = () => {
  const [id, setId] = useState<string>('');
  const [nomeProduto, setNomeProduto] = useState('');
  const [descricaoProduto, setDescricaoProduto] = useState('');
  const [fotosProduto, setFotosProduto] = useState<any>();
  const [valorProduto, setValorProduto] = useState();
  const [categoriaProduto, setCategoriaProduto] = useState('');
  const [disponibilidadeProduto, setDisponibilidadeProduto] = useState('');
  const [foto, setFoto] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [snackbarProps, setSnackbarProps] = useState({
    message: '',
    type: 'success',
  });
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const location = useLocation<any>();
  const history = useHistory();


  useEffect(() => {
    const {
      id,
      nome,
      descricao,
      valor,
      categoria,
      link,
      disponibilidade,
      foto,
    } = location.state;

    setId(id);
    setFoto(foto);
    setFotosProduto(foto);
    setNomeProduto(nome);
    setDescricaoProduto(descricao);
    setValorProduto(valor);
    setDisponibilidadeProduto(disponibilidade);
    setCategoriaProduto(categoria);
  }, [location]);

  const voltarTela = async () => {
    history.push({
      pathname: '/painel/marketplace/detalhes',
      state: {
        updatedData: {
          nome: nomeProduto,
          descricao: descricaoProduto,
          valor: valorProduto,
          categoria: categoriaProduto,
          foto: foto
        },
        fromEdit: true,
      }
    });
  };

  const handleSnackbarClose = (
    event?: React.SyntheticEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  const [formEnviadoComSucesso, setFormEnviadoComSucesso] = useState(false);
  const [formEnviado, setFormEnviado] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const [errors, setErrors] = useState<{
    nomeProduto?: string,
    categoriaProduto?: string,
    valorProduto?: string,
    descricaoProduto?: string,
  }>({});

  const validationFormErrors = async () => {
    try {
      await validationSchema.validate({
        nomeProduto,
        categoriaProduto,
        valorProduto,
        descricaoProduto,
      }, { abortEarly: false })
      setErrors({});
      setFormValid(true);
    } catch (error: any) {
      const validationErrors: Record<string, string> = {};
      if (error.inner) {
        error.inner.forEach((err: any) => {
          validationErrors[err.path] = err.message;
        });
      }
      setErrors(validationErrors);
      setFormValid(false);
    }
  }

  useEffect(() => {
    validationFormErrors();
  }, [nomeProduto, categoriaProduto, valorProduto, descricaoProduto]);

  const salvarProduct = async () => {
    setIsLoading(true);
    setSnackbarProps({
      message: 'Carregando...',
      type: 'info',
    });
    setSnackbarOpen(true);
    if (!formValid) {
      setFormEnviado(true);
      return;
    }
    try {
      await validationFormErrors();

      await MarketplaceService.updateProduct({
        id,
        nome: nomeProduto,
        descricao: descricaoProduto,
        valor: valorProduto,
        categoria: categoriaProduto,
        foto: foto,
        disponibilidade: disponibilidadeProduto,
      });
      setSnackbarProps({
        message: 'As alterações foram salvas com sucesso!',
        type: 'success',
      });
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarProps({
        message: 'Não foi possível fazer as alterações!',
        type: 'error',
      });
      setSnackbarOpen(true);
    }

    setIsLoading(false);
    await sleep(500);
    setSnackbarOpen(false);
    voltarTela();
  };

  useEffect(() => {
    if (formEnviadoComSucesso) {
      setErrors({});
      setFormValid(false);
      setFormEnviado(false);
      setFormEnviadoComSucesso(false);
    }
  }, [formEnviadoComSucesso]);

  const deleteProduct = async () => {
    setDeleteModalOpen(false);
    setIsLoading(true);
    setSnackbarProps({
      message: 'Carregando...',
      type: 'info',
    });
    setSnackbarOpen(true);
    try {
      await MarketplaceService.deleteProduct(id);
      setSnackbarProps({
        message: 'Produto deletado com sucesso!',
        type: 'success',
      });
    } catch (error) {
      console.error(error);
      setSnackbarProps({
        message: 'Ocorreu um erro ao deletar o produto!',
        type: 'error',
      });
    }
    setIsLoading(false);
    await sleep(500);
    setSnackbarOpen(false);
    history.push('/painel/marketplace');
  };






  const [categoria, setCategoria] = useState({
    title: 'Não informado',
    value: 'null',
  });

  function setCategoriaValue(e: any) {
    setCategoria({
      title: e.target.outerText,
      value: e.target.outerText.toLowerCase(),
    });
  }

  const categoriaOptions = [
    { title: 'TV', value: 'tv' },
    { title: 'Eletrodoméstico', value: 'Eletrodoméstico' },
    { title: 'Eletroeletrônicos', value: 'Eletroeletrônicos' },
    { title: 'Smartphones', value: 'Smartphones' },
    { title: 'Informática', value: 'Informática' },
    { title: 'Câmeras', value: 'Câmeras' },
    { title: 'Áudio', value: 'Áudio' },
    { title: 'Drones', value: 'Drones' },
    { title: 'Ar Condicionado', value: 'Ar Condicionado' },
    { title: 'Outros', value: 'Outros' }
  ];

  const [dragEnter, setDragEnter] = useState(false);

  const [newUserInfo, setNewUserInfo] = useState({
    profileImages: [],
  });

  const updateUploadedFiles = (files: any) => {
    setNewUserInfo({ ...newUserInfo, profileImages: files });
    setFoto(files[0])
  };

  return (
    <>
      <Content>
        <DetalhesContainer>
          <DetalhesHeader>
            <Usuario>
              <img src={foto} style={{ width: 200 }} alt="avatar" />
              <h1>{nomeProduto}</h1>
            </Usuario>
            <Botao
              tipo="editar"
              tamanho="grande"
              className="btn"
              onClick={voltarTela}
            >
              Voltar
            </Botao>
          </DetalhesHeader>
          <DetalhesContent>
            <InputsContent>
              <InputsContainer>
                <InputField
                  tipo="formulario"
                  className="inputs"
                  label="Nome"
                  value={nomeProduto}
                  name="nomeProduto"
                  onChange={(e: any) => {
                    setNomeProduto(e.target.value);
                  }}
                  error={(formEnviado || formEnviadoComSucesso) && Boolean(errors.nomeProduto)}
                  helperText={(formEnviado || formEnviadoComSucesso) && errors.nomeProduto}
                />
              </InputsContainer>
              <InputsContainer>
                <InputField
                  tipo="formulario"
                  className="inputs"
                  label="Preço"
                  value={valorProduto}
                  name="valorProduto"
                  onChange={(e: any) => {
                    setValorProduto(e.target.value);
                  }}
                  error={(formEnviado || formEnviadoComSucesso) && Boolean(errors.valorProduto)}
                  helperText={(formEnviado || formEnviadoComSucesso) && errors.valorProduto}
                />
              </InputsContainer>

              <InputsContainer>
                <Autocomplete
                  id="combo-box-demo"
                  size="small"
                  options={categoriaOptions}
                  getOptionLabel={(option) => option.title}
                  style={{ width: 200 }}
                  value={categoriaOptions.find(option => option.value === categoriaProduto) || undefined}
                  loading
                  loadingText="carregando"
                  onChange={(event, newValue) => {
                    setCategoriaProduto(newValue ? newValue.value : '');
                  }}
                  disableClearable
                  renderInput={(params) => (
                    <InputField
                      {...params}
                      tipo="formulario"
                      className="inputs"
                      label="Categoria"
                      name="productCategoria"
                      value={categoriaProduto}
                      onChange={(e: any) => {
                        setCategoriaProduto(e.target.value);
                      }}
                      error={formEnviado && Boolean(errors.categoriaProduto)}
                      helperText={(formEnviado || formEnviadoComSucesso) && errors.categoriaProduto}
                    />
                  )}
                />
              </InputsContainer>


            </InputsContent>

            <InputsContent>
              <InputsContainer className="descricao-container">
                <InputField
                  tipo="formulario-text-area"
                  className="inputs"
                  label="Descrição"
                  type='text'
                  value={descricaoProduto}
                  name='descricaoProduto'
                  onChange={(e: any) => {
                    setDescricaoProduto(e.target.value);
                  }}
                  error={Boolean(errors.descricaoProduto)}
                />
                {<span className="error-message">{errors.descricaoProduto}</span>}
              </InputsContainer>
            </InputsContent>

            <InputsContent>
              <div className="endereco">
                <h2>Upload de fotos do produto: </h2>
              </div>
              <InputsContainer className="upload-fotos">
                <FileUpload
                  label=""
                  multiple
                  updateFilesCb={updateUploadedFiles}
                />
              </InputsContainer>
            </InputsContent>

            <BotoesContainer>
              <Botao
                tipo="salvar"
                tamanho="grande"
                className="btn-salvar"
                isLoading={isLoading}
                onClick={salvarProduct}
              >
                Salvar Produto
              </Botao>
              <Botao
                tipo="deletar"
                tamanho="grande"
                className="btn-excluir"
                isLoading={isLoading}
                onClick={() => setDeleteModalOpen(true)}
              >
                Excluir Produto
              </Botao>
            </BotoesContainer>
          </DetalhesContent>
        </DetalhesContainer>
        <Snackbars
          type={snackbarProps.type}
          handleClose={handleSnackbarClose}
          open={snackbarOpen}
        >
          {snackbarProps.message}
        </Snackbars>
        <DeleteModal
          isModalOpen={deleteModalOpen}
          onCloseModal={() => setDeleteModalOpen(false)}
          onDeleteClick={() => deleteProduct()}
        />
      </Content>
    </>
  );
};

export default EditarProduto;