/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable class-methods-use-this */
import AppError from '../errors/AppError';
import api from './api';
import { getToken } from './auth';

interface Parcela {
  idParcela: string;
  vencimentoParcela: string;
  pagamentoParcela: string | null;
  meioPagamento: string;
  status: string;
  urlBoleto: string;
  valor: number;
}

interface IWeBroPaySale {
  idVenda: string;
  status: string;
  pagamentoLiberado: string;
  motivoDistrato: string | null;
  motivoCancelamento: string | null;
  parcelas: Parcela[];
}

class CommissionSalesService {
  private headers: any;

  constructor() {
    this.headers = {
      Authorization: `Bearer: ${getToken()}`,
    };
  }

  public async getWebropaySales(cod_mega: string | number) {
    try {
      const { data } = await api.get<{weBroPaySales: IWeBroPaySale[]}>(`/commissionSales/webropay_sales`, {
        params: {
          codMega: cod_mega
        },
        headers: {
          Authorization: `Bearer: ${getToken()}`,
        },
      });
      return data;
    } catch (err: any) {
      return {
        weBroPaySales: []
      }
    }
  }
}

export default new CommissionSalesService();

