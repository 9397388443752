const cores = {
  green: '#80e2a7',
  lime: '#a1c40d',
  blue: '#3669cf',
  purple_1: '#4b40c5',
  purple_2: '#533FE7',
  purple_3: '#8974F4',
  lavender: '#8740e0',
  pink: '#d82a91',
  red: '#eb1726',
  orange: '#ea7432',
  yellow: '#ffb31c',
  lightgray: '#C2C1CB',
  grey: '#939393',
  black: '#242424',

  themeGradient: 'linear-gradient(to right, #3B2E9C 0%, #121214 99%)',
  reversedThemeGradient: 'linear-gradient(to right, #121214 0%, #3B2E9C 99%)',
  themeGradient_2: 'linear-gradient(to bottom, #453D75 0%, #523FE2 99%)',
  reversedThemeGradient_2: 'linear-gradient(to right, #523FE2 0%, #453D75 99%)',
  themeGradient_3: 'linear-gradient(to right, #8D3FA5 0%, #575AC0 99%)',

  shadow_1: '0px 1px 30px rgba(8, 18, 38, 0.10)',
  shadow_2: '0px 1px 30px rgba(8, 18, 38, 0.10)',
};

export default cores;
