import styled from 'styled-components';
import GoldMedal from '../../assets/Images/gold-medal.png';
import SilverMedal from '../../assets/Images/silver-medal.png';
import BronzeMedal from '../../assets/Images/bronze-medal.png';

export const teste = styled.div`
  border: 1px solid black;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const TabelaContainer = styled.div`
  display: flex;
  width: 90%;
  border-radius: 12px;
  height: fit-content;
  align-self: center;
  // box-shadow: 0px 0px 6px 2px rgba(12, 12, 14, 0.2);

  .medal {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .gold-medal {
    display: flex;
    width: 32px;
    height: 32px;

    background-image: url(${GoldMedal});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  .silver-medal {
    display: flex;
    width: 32px;
    height: 32px;

    background-image: url(${SilverMedal});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  .bronze-medal {
    display: flex;
    width: 32px;
    height: 32px;

    background-image: url(${BronzeMedal});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
`;

export const OpcoesContainer = styled.div`
    display: flex;
    width: 90%;
    margin-top: 50px;
`;

export const VariacaoContainer = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
  svg {
    font-size: 42px;
    font-weight: bold;
  }

  &.arrowup {
    color: green;
    svg {
      fill: green;
    }
  }

  &.arrowdown {
    color: red;
    svg {
      fill: red;
    }
  }

  &.equal {
    color: grey;
    svg {
      fill: grey;
    }
  }
`;


export const InputsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 0px;
  padding: 0;
  margin: 10px 10px;
  max-width: 100%;
  .input {
    width: 250px;
  }
`;
