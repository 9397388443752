import { useCallback, useEffect, useState } from 'react';
import { VscCircleFilled } from 'react-icons/vsc';


import { Link, NavLink } from 'react-router-dom';

import {
  Container,
  Item,
  Icone,
  UsuarioAvatar,
  AvatarContainer,
} from './styles';

import CorretoresIcon from '../../assets/Icons/CorretoresIcon';
import FaqIcon from '../../assets/Icons/FaqIcon';
import ImobiliariasIcon from '../../assets/Icons/ImobiliariasIcon';
import PropostasIcon from '../../assets/Icons/PropostasIcon';
import ComissaoIcon from '../../assets/Icons/ComissaoIcon';
import LeadsService from '../../services/LeadsService';
import { getUserData, TOKEN_KEY } from '../../services/auth';


const MenuLateralImob = ({ currentPage, data, notification }: any) => {

  const [newLead, setNewLead] = useState(false)
  const [userTipo, setUserTipo] = useState('');
  const [userData, setUserData] = useState({ id: '', token: '', userType: '', created_at: '' });
  const id_user = userData ? userData.id : ''

  console.log('ID MENU LATERAL', id_user)


  const newLeads = useCallback(
    async () => {
      const leads = await LeadsService.listTickets();
      console.log('leads ', leads)

      let newLead = false;

      const formatedData = leads.blipTickets.map((ticket: any) => {
        let obj = {
          nome: ticket.lead.nome,
          telefone: ticket.lead.telefone,
          observacoes: ticket.observacoes,
          entrada_simulada: ticket.entrada_simulada,
          parcelamento_simulado: ticket.parcelamento_simulado,
          visto: ticket.visto,
          empreendimento: ticket.lead_land.empreendimento ? ticket.lead_land.empreendimento : '',
          lote: ticket.lead_land.lote,
          quadra: ticket.lead_land.quadra,
          quadra_lote: `${ticket.lead_land.quadra}/${ticket.lead_land.lote}`,
          data: ticket.last_lead_action.created_at
        }

        if (ticket.visto === false) {
          setNewLead(true)
        }

        return obj;
      })

      let token: any = localStorage.getItem(TOKEN_KEY);
      token = JSON.parse(token);
      console.log('token do menu imob', token);
      if (!token) {
        return;
      } else {
        console.log('entrei ism', token.id)
        const dataToken = await getUserData();
        console.log('entrei ism', dataToken)
        setUserData(dataToken);
      }
    },
    [],
  )


  const allowedUserIds = [
    'ac713ad1-8a1c-4d35-ae64-21526857bccb',
    'ac713ad1-8a1c-4d35-ae64-21526857bccb',
    'fb95ded4-d440-4701-86c0-998d1e8b6acb'
  ];

  useEffect(() => {
    newLeads();
  }, [])

  return (
    <Container>
      <UsuarioAvatar>
        <AvatarContainer>
          <img
            src={
              currentPage.data.user
                ? currentPage.data.user.avatar_url
                : 'https://upload.wikimedia.org/wikipedia/commons/thumb/2/24/Missing_avatar.svg/240px-Missing_avatar.svg.png'
            }
            style={{
              width: 128,
              height: 128,
              borderRadius: '50%',
              boxShadow: '0px 0px 1px 1px rgba(12,12,14,0.1)',
            }}
            alt="avatar"
          />
        </AvatarContainer>
        <h2>
          Bem vindo(a) {currentPage.data.user ? `,${currentPage.data.user.name}` : ''}
        </h2>
      </UsuarioAvatar>

      {allowedUserIds.includes(id_user) && ( // Adicionando a condição aqui
        <Item
          className={
            currentPage.pathname.includes('/painel/imobiliaria/corretores')
              ? 'selected'
              : ''
          }
        >
          <NavLink to="/painel/imobiliaria/corretores" className="link">
            <Icone>
              <CorretoresIcon />
            </Icone>
            Corretores
          </NavLink>
        </Item>
      )}

      <Item
        className={
          currentPage.pathname.includes('/painel/imobiliaria/proposta')
            ? 'selected'
            : ''
        }
      >
        <Link to="/painel/imobiliaria/proposta" className="link">
          <Icone>
            <PropostasIcon />
          </Icone>
          Propostas
        </Link>
      </Item>

      <Item
        className={
          currentPage.pathname.includes('/painel/imobiliaria/leads')
            ? 'selected'
            : ''
        }
      >
        <Link to="/painel/imobiliaria/leads" className="link">
          <Icone>
            <ImobiliariasIcon />
          </Icone>
          Leads
          {newLead ? <VscCircleFilled size={32} color="orange" /> : <></>}
        </Link>
      </Item>


      <Item className={currentPage.pathname.includes('/painel/comissao') ? 'selected' : ''}>
        <Link to="/painel/comissao" className="link">
          <Icone><ComissaoIcon /></Icone>
          Comissão
        </Link>
      </Item>




      <Item
        className={
          currentPage.pathname.includes('/painel/faq') ? 'selected ultimo-item'
            : 'ultimo-item'
        }
      >
        <a href="https://account.blip.ai/" className="link" target="_blank" rel='noreferrer'>
          <Icone>
            <FaqIcon />
          </Icone>
          Blip Chat
        </a>
      </Item>

    </Container>
  );
};

export default MenuLateralImob;
