import React from 'react';

const MarketplaceIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="682.667"
      height="682.667"
      version="1"
      viewBox="0 0 512 512"
    >
      <path
        d="M0 4750v-160h829l10-37c66-250 811-3248 809-3255-2-5-26-18-54-28-161-56-303-215-343-384-17-70-15-211 5-276 69-234 287-400 524-400 303 1 550 247 550 550 0 57-12 121-35 198l-7 22h616c339 0 616-1 616-2 0-2-9-32-20-66-25-80-27-225-4-303 68-233 287-399 524-399 289 1 529 223 547 507 17 258-135 480-387 566l-65 22-1065 3-1065 3-57 232c-31 127-54 235-52 239 3 5 603 8 1333 8h1329l286 1145c157 630 286 1152 286 1160 0 13-220 15-1912 17l-1912 3-99 398-99 397H0v-160zm1960-977c0-5 18-150 40-323s40-321 40-328c0-10-54-12-252-10l-251 3-84 325c-45 179-83 328-83 333 0 4 133 7 295 7s295-3 295-7zm910-328v-335h-250c-137 0-250 3-250 8 0 4-18 149-40 322s-40 321-40 328c0 9 65 12 290 12h290v-335zm913 298c-3-21-21-171-42-333l-36-295-253-3-252-2v670h588l-5-37zm907 22c0-13-110-463-151-617l-10-38h-499l5 23c2 12 21 157 41 322 19 165 37 306 39 313 3 9 70 12 290 12 249 0 285-2 285-15zM2121 2480c21-162 39-312 42-333l5-37-191 2-191 3-76 305c-42 168-80 317-83 333l-7 27 232-2 233-3 36-295zm749-35v-335l-187 2-188 3-36 295c-21 162-39 312-42 333l-5 37h458v-335zm790 323c0-7-18-155-40-328s-40-318-40-322c0-5-85-8-190-8h-190v670h230c177 0 230-3 230-12zm713-285c-41-164-78-315-84-335l-10-38h-184c-141 0-185 3-185 13 0 6 18 154 40 327s40 318 40 323c0 4 103 7 229 7h229l-75-297zM1876 956c91-42 142-141 123-241-15-82-90-159-170-173-103-19-195 26-241 117-45 88-33 169 34 246 65 74 162 94 254 51zm2240 0c92-43 144-147 123-246-41-190-296-236-400-72-59 93-47 195 30 272 73 72 157 88 247 46z"
        transform="matrix(.1 0 0 -.1 0 512)"
      />
    </svg>
  );
};

export default MarketplaceIcon;
