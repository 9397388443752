import styled from 'styled-components';
import cores from '../../styles/cores';

export const Background = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: ${cores.themeGradient_2};

  .loading {
    position: absolute;
    top: 0;
    width: 100%;
  }
`;

export const FormContainer = styled.div`
  background-color: white;
  display: flex;
  height: 420px;
  width: 400px;
  border-radius: 12px;
  opacity: 0.95;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // box-shadow: ${cores.shadow_2};

  .esqueciMinhaSenha {
    color: ${cores.purple_2};
    font-size: 14px;
    font-weight: bold;
    text-decoration: underline;

    &:hover {
      opacity: 0.9;
    }
  }

  .textoAux {
    font-size: 14px;
    text-align: center;
    margin: 0px 0 10px 0;
  }

  h2 {
    font-weight: bold;
    font-size: 28px;
    margin: 30px 0 10px 0;
  }
  h3 {
    margin-bottom: 20px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 80%;
  height: 100%;

  .manter-conectado {
    font-size: 14px;
  }
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 0px solid black;
  height: auto;
  margin-bottom: 10%;

  .input {
    width: 320px;
    label {
      font-size: 18px;
    }
  }

  .visibilidade {
    position: absolute;
    color: grey;
    cursor: pointer;
    margin-left: 270px;
    margin-top: 12px;
    font-size: 30px;
  }
`;

export const Icone = styled.div`
  display: flex;
  margin: 0 0 20px 0;
  align-items: center;
  justify-content: center;
  width: 220px;
  height: auto;

  svg {
    border: 0px solid black;
    width: 220px;
    height: auto;
  }
`;

export const IconeErro = styled.div`
  align-self: center;
  svg {
    font-size: 128px;
    color: red;
  }
`;

export const ModalContainer = styled.div`
  display: flex;
  width: fit-content;
  height: auto;
  border: 1px solid black;

  p {
    font-weigth: bold;
  }
`;

export const BotaoContainer = styled.div`
  display: flex;
  width: 100%;
  margin-left: 10px;
  margin-top: -10%;
`;
