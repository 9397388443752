/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable class-methods-use-this */
import AppError from '../errors/AppError';
import api from './api';
import { getToken, getUserData } from './auth';

class UsuariosService {
  private headers: any;

  constructor() {
    this.headers = {
      Authorization: `Bearer: ${getToken()}`,
    };
  }

  public async listAllUsers() {
    try {
      const { data } = await api.get(`/users/list`, {
        headers: {
          Authorization: `Bearer: ${getToken()}`,
        },
      });
      return data;
    } catch (err: any) {
      throw new AppError(err, err.statusCode);
    }
  }

  public async createUser(params: any) {
    try {
      const { data } = await api.post(
        `/users`,
        { ...params },
        {
          headers: {
            Authorization: `Bearer: ${getToken()}`,
          },
        },
      );
      return data;
    } catch (err: any) {
      throw new AppError(err.response.data.message, err.response.status);
    }
  }

  public async updateUser(params: any) {
    try {
      const { data } = await api.patch(
        `/users/`,
        { ...params },
        {
          headers: {
            Authorization: `Bearer: ${getToken()}`,
          },
        },
      );
      return data;
    } catch (err: any) {
      throw new AppError(err, err.statusCode);
    }
  }

  public async updateUserEnterprises(params: any) {
    try {
      const { data } = await api.patch(
        `/users/enterprises`,
        { ...params },
        {
          headers: {
            Authorization: `Bearer: ${getToken()}`,
          },
        },
      );
      return data;
    } catch (err: any) {
      throw new AppError(err, err.statusCode);
    }
  }

  public async updateUserAddress(params: any) {
    try {
      const { data } = await api.patch(
        `/users/address/admin`,
        { ...params },
        {
          headers: {
            Authorization: `Bearer: ${getToken()}`,
          },
        },
      );
      return data;
    } catch (err: any) {
      throw new AppError(err, err.statusCode);
    }
  }

  public async getUserData(id: string | number) {
    try {
      const { data } = await api.get(`/users/find/${id}`, {
        headers: {
          Authorization: `Bearer: ${getToken()}`,
        },
      });
      if (data) {
        return data;
      }
      return 'No Data Found';
    } catch (err: any) {
      throw new AppError(err, err.statusCode);
    }
  }

  public async deleteUser(id: string | number) {
    try {
      const { data } = await api.delete(`/users/${id}`, {
        headers: {
          Authorization: `Bearer: ${getToken()}`,
        },
      });
      if (data) {
        return data;
      }
      return 'No Data Found';
    } catch (err: any) {
      return err;
    }
  }

  public async getAvatarCorretor(id: string | number) {
    try {
      const { data } = await api.get(`/users/avatar/${id}`);
      if (data) {
        return data;
      }
      return 'No Data Found';
    } catch (err: any) {
      throw new AppError(err, err.statusCode);
    }
  }

  public async updateAvatarCorretor(id: string | number, params: any) {
    try {
      const { data } = await api.patch(`/users/avatar/${id}`, { ...params });
      if (data) {
        return data;
      }
      return 'No Data Found';
    } catch (err: any) {
      throw new AppError(err, err.statusCode);
    }
  }

  public async comprasCorretor(id: string | number) {
    try {
      const { data } = await api.get(`/users/compras/${id}`);
      if (data) {
        return data;
      }
      return 'No Data Found';
    } catch (err: any) {
      throw new AppError(err, err.statusCode);
    }
  }

  public async passwordResetWeb(id: string | number, params: any) {
    try {
      const { data } = await api.post(`/users/password-recovery/web/${id}`, {
        ...params,
      });
      if (data) {
        return data;
      }
      return 'No Data Found';
    } catch (err: any) {
      throw new AppError(err, err.statusCode);
    }
  }

  public async passwordResetApp(id: string | number, params: any) {
    try {
      const { data } = await api.post(`/users/password-recovery/app/${id}`, {
        ...params,
      });
      if (data) {
        return data;
      }
      return 'No Data Found';
    } catch (err: any) {
      throw new AppError(err, err.statusCode);
    }
  }

  public async listCorretoresImobiliaria() {
    try {
      const userData = getUserData();

      if (!userData) {
        throw new AppError('Usuário não está logado');
      }
      const idDoUsuario = userData.id;
      const buscaDadosCompletoDoUser = await this.getUserData(idDoUsuario);
      const idDaImob = buscaDadosCompletoDoUser.user.agency_id;

      const { data } = await api.get(`/users/agency/${idDaImob}`, {
        headers: {
          Authorization: `Bearer: ${getToken()}`,
        },
      });

      return data;
    } catch (err: any) {
      throw new AppError(err, err.statusCode);
    }
  }

  public async alteraDisponibilidade(
    idAlteracao: string,
    novoStatus: boolean,
    tipoAlteracao: string,
  ) {
    try {
      const response = await api.post(
        'https://webhooks.bairru.com.br/leads_internas/altera_disponibilidade',
        {
          id_alteracao: idAlteracao,
          novo_status: novoStatus,
          tipo_alteracao: tipoAlteracao,
        },
        {
          headers: this.headers,
        },
      );
      return response.data;
    } catch (err: any) {
      console.error('Erro na requisição:', err);
      throw new AppError(err, err.statusCode);
    }
  }

  public async recoverPassword(email: string) {
    try {
      const { data } = await api.post(`/change/recoverPassword`, {
        email: email,
      });
      return data;
    } catch (err: any) {
      throw new AppError(err, err.statusCode);
    }
  }

  public async changePassword(oldPassword: string, password: string) {
    try {
      const { data } = await api.post(
        `/change`,
        {
          oldPassword: oldPassword,
          newPassword: password,
        },
        {
          headers: {
            Authorization: `Bearer: ${getToken()}`,
          },
        },
      );
      return data;
    } catch (err: any) {
      throw new AppError(err, err.statusCode);
    }
  }

  public async listAlcada() {
    try {
      const userData = getUserData();
      const { data } = await api.get(`/alcadas/list`, {
        headers: {
          Authorization: `Bearer: ${getToken()}`,
        },
      });
      return { data, userData };
    } catch (err: any) {
      throw new AppError(err, err.statusCode);
    }
  }
}

export default new UsuariosService();
