import styled, { css } from 'styled-components';
import cores from '../../styles/cores';
import tema from '../../styles/tema';

interface IGridProps {
  width?: number;
  height?: number;
}

export const Container = styled.ul`
  width: 75vw;
  height: fit-content;
  box-shadow: 0 0 2px 2px rgba(12, 12, 14, 0.1);

  .editar:hover {
    transition: 0.5s;
    color: ${cores.yellow};
  }

  .excluir:hover {
    transition: 0.5s;
    color: ${cores.red};
  }

  .MuiTypography-h6 {
    font-weight: bold;
    font-size: 20px;
  }
`;

export const ModalContainer = styled.div`
  outline: 0;
  border: none;
  height: inherit;
`;

export const BotaoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: inherit;

  .btn {
    width: 45%;
  }
`;
