/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import CircularProgress, {
  CircularProgressProps,
} from '@material-ui/core/CircularProgress';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import Typography from '@material-ui/core/Typography';
import Tabela from '../../components/Tabelas/Tabela';
import Botao from '../../components/Botao/Botao';

import { Content, InputsContainer, OpcoesContainer, TabelaContainer } from './styles';
import EmpreendimentosService from '../../services/EmpreendimentosService';
import RankingService from '../../services/RankingService';


const Ranking = () => {
  const [userData, setUserData] = useState({});
  const [detalhes, setDetalhes] = useState(false);
  const [enterprises, setEnterprises] = useState<any[]>([{}]);
  const [enterpriseName, setEnterpriseName] = React.useState<string[]>([]);
  const [empSelected, setEmpSelected] = useState({ title: '', value: '' });
  const [rankingData, setRankingData] = useState();



  const fetchEnterprises = useCallback(async ()=>{
    await EmpreendimentosService.listAllEmpreendimentos().then((response)=>{
      
      setEnterprises(response.enterprise.map((enterprise: any)=>{
        return {
          title: enterprise.name,
          value: enterprise.id
        }
      }))
      
    })
  }, [])

  useEffect(()=>{
    fetchEnterprises()
  }, [])

  const rowSelected = (e: any, rowData: any) => {
    if (rowData) {
      setDetalhes(true);
      
    }
  };

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setEnterpriseName(event.target.value as string[]);
  };

  async function setEnterpriseValue(e: any, value: any) {
    setEmpSelected({
      title: value.title,
      value: value.value,
    });

    const {ranking} = await RankingService.getRankingByEnterprise(value.value)
    
    let posicao = 0;
    setRankingData(ranking.map((userRanking:any)=> {
      posicao +=1
      return {
        empreendimento: value.title,
        posicaovalor: posicao,
        nome:userRanking.name,
        score: Number(userRanking.sum).toFixed(2),
      }
    }))
  }

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };


  function CircularProgressWithLabel(
    props: CircularProgressProps & { value: number },
  ) {
    return (
      <Box position="relative" display="inline-flex">
        <CircularProgress variant="determinate" {...props} style={{}} />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography
            variant="caption"
            component="div"
            color="textSecondary"
            style={{ fontWeight: 'bold' }}
            // eslint-disable-next-line react/destructuring-assignment
          >{`${Math.round(props.value)}%`}</Typography>
        </Box>
      </Box>
    );
  }

  const columns = [
    {
      title: 'Empreendimento',
      field: 'empreendimento',
      filtering: true,
      sortable: true,
    },
    {
      title: 'Posicão',
      field: 'posicao',
      filtering: true,
      export: false,
      align: 'center',
      customFilterAndSearch: (term: any, rowData: any) => {
        return term === rowData.posicaovalor.toString();
      },
      customSort: (a: any, b: any) => a.posicaovalor - b.posicaovalor,
      render: (rowData: any) => {
        switch (rowData.posicaovalor) {
          case 1:
            return (
              <div className="medal">
                <div className="gold-medal" />
              </div>
            );
          case 2:
            return (
              <div className="medal">
                <div className="silver-medal" />
              </div>
            );
          case 3:
            return (
              <div className="medal">
                <div className="bronze-medal" />
              </div>
            );
          default:
            return <>{`${rowData.posicaovalor}° Lugar`}</>;
        }
      },
    },
    {
      title: 'Nome',
      field: 'nome',
      filtering: true,
      sortable: true,
    },
    {
      title: 'Score',
      field: 'score',
      sortable: true,
      filtering: false,
      align: 'center',
    }
  ];

  const data = [
    {
      posicaovalor: 1,
      nome: 'Eric Christian Costa',
      score: 1000,
      variacao: 4,
      empreendimento: 'Bairru das Cerejeiras',
      taxaconv: 88,
    },
    {
      posicaovalor: 2,
      nome: 'Eric Christian Costa',
      score: 1000,
      variacao: 2,
      empreendimento: 'Bairru das Cerejeiras',
      taxaconv: 80,
    },
    {
      posicaovalor: 3,
      nome: 'Eric Christian Costa',
      score: 1000,
      variacao: 0,
      empreendimento: 'Bairru das Cerejeiras',
      taxaconv: 78,
    },
    {
      posicaovalor: 4,
      nome: 'Eric Christian Costa',
      score: 1000,
      variacao: -3,
      empreendimento: 'Bairru das Cerejeiras',
      taxaconv: 77,
    },
    {
      posicaovalor: 5,
      nome: 'Eric Christian Costa',
      score: 1000,
      variacao: -2,
      empreendimento: 'Bairru das Cerejeiras',
      taxaconv: 76,
    },

    {
      posicaovalor: 6,
      nome: 'Eric Christian Costa',
      score: 1000,
      variacao: -1,
      empreendimento: 'Bairru das Cerejeiras',
      taxaconv: 54,
    },
  ];

  //  create array of numbers 

  return (
    <>
    <Content>
      <OpcoesContainer>
        <Botao tipo="adicionar" tamanho="medio" onClick = {() => fetchEnterprises()}>
          Atualizar tabela
        </Botao>

        <InputsContainer>
          <Autocomplete
            id="combo-box-demo"
            options={enterprises}
            getOptionLabel={(option: any) => option.title}
            style={{ width: 200 }}
            value={empSelected}
            onChange={(e: any, value: any)=>setEnterpriseValue(e, value)}
            disableClearable
            renderInput={params => (
              <TextField
                label="Empreendimento"
                variant="outlined"
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                size="small"
                error={false}
                helperText=""
              />
            )}
          />
        </InputsContainer>
      </OpcoesContainer>
      <TabelaContainer>
        <Tabela
          title="Ranking"
          columns={columns}
          data={data}
          onRowClick={(e: any, rowData: any): any => {
            setUserData(rowData);
            rowSelected(e, rowData);
          }}
          pagination
          paginationPageSize={10}
          haveActions="none"
          editarClick={() => console.log}
          excluirClick={() => console.log}
        />
      </TabelaContainer>
      </Content>
    </>
  );
};

export default Ranking;
