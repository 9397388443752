import { TextField } from '@material-ui/core';
import React, { useState } from 'react';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import Botao from '../Botao/Botao';
import Modal from '../Modal/Modal';
import { BotaoContainer, ModalContainerDelete } from './styles';
import colors from '../../styles/cores';


interface disapprovedModal {
  isModalOpen: boolean;
  onDisapprovedClick: any;
  onCloseModal: any;
}

const DisapprovedModal = (props: disapprovedModal): any => {
  const {
    isModalOpen,
    onDisapprovedClick,
    onCloseModal,
  } = props;

  return (
    <Modal
      isOpen={isModalOpen}
      width={420}
      onClose={() => {
        onCloseModal();
      }}
    >
      <>
        <ModalContainerDelete>
          <h1> Você deseja retornar essa proposta?</h1>

          <BotaoContainer>
            <Botao
              tipo="voltar"
              tamanho="medio"
              className="btn"
              disabled={false}
              onClick={() => {
                console.log('salvar');
                onDisapprovedClick();
              }}
            >
              Retornar
            </Botao>
            <Botao
              tamanho="medio"
              className="btn"
              onClick={() => {
                onCloseModal();
              }}
            >
              Cancelar
            </Botao>
          </BotaoContainer>
        </ModalContainerDelete>
      </>
    </Modal>
  );
};

export default DisapprovedModal;
