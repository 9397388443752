import CheckIcon from '@mui/icons-material/Check';
import { useHistory } from 'react-router-dom';
import Botao from '../Botao/Botao';
import Modal from '../Modal/Modal';
import {
  BotaoContainer,
  ModalContainerDelete,
  ModalTitle,
  Paragraph,
  SpinnerConatainer,
} from './styles';

interface updateModalProps {
  isModalOpen: boolean;
  onCloseModal: any;
}

const ModalFinancialSerie = (props: updateModalProps): any => {
  const { isModalOpen, onCloseModal } = props;
  const history = useHistory();
  return (
    <Modal isOpen={isModalOpen} width={420} onClose={onCloseModal}>
      <>
        <ModalContainerDelete>
          <ModalTitle>Sucesso!</ModalTitle>
          <SpinnerConatainer>
            <CheckIcon htmlColor="green" style={{ fontSize: 50 }} />
          </SpinnerConatainer>
          <Paragraph>Proposta personalizada criada com sucesso.</Paragraph>
          <BotaoContainer>
            <Botao
              tamanho="medio"
              tipo="salvar"
              className="btn"
              onClick={() =>
                setTimeout(() => {
                  history.push('/painel/propostas-admin/');
                }, 500)
              }
            >
              Continuar
            </Botao>
          </BotaoContainer>
        </ModalContainerDelete>
      </>
    </Modal>
  );
};

export default ModalFinancialSerie;
