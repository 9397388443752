import { TextField } from '@material-ui/core';
import React, { useState } from 'react';
import { FaSpinner } from 'react-icons/fa';

import Snackbars from '../Snackbars';
import { BotaoContainer, Container } from './styles';
import Botao from '../Botao/Botao';
import Modal from '../Modal/Modal';
import apiAutomacao from '../../services/api-automacao';

interface Props {
  isModalOpen: boolean;
  onDeleteClick: any;
  onCloseModal: any;
  title: string;
  value: Array<any>;
  dadosBonificacao: { [key: string]: any };
  bonificacaoIds: number[];
}

const ModalBonificacaoNF = (props: Props): any => {
  const { isModalOpen, onDeleteClick, onCloseModal, value, dadosBonificacao, bonificacaoIds } = props;
  const [areYouSure, setAreYouSure] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [enviando, setEnviando] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [openSnackbarProps, setOpenSnackbarProps] = useState({ message: 'Atualizado com Sucesso', type: 'success' });
  // Constantes de informações do Modal
  const [numeroNota, setNumeroNota] = useState("");
  const [pdfFileNF, setPdfFileNF] = useState<File | null>(null);

  const bonificacao = {
    valor_bonificacao: value[0] ?? 0,
    cod_empreendimento: value[1] ?? 0,
    nome_empreendimento: value[2] ?? 0,
    cnpj_empreendimento: value[3] ?? 0
  }

  const handleSnackbarClose = (
    event?: React.SyntheticEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const enviarNotaFiscal = () => {
    setOpenSnackbarProps({ message: `Estamos analisando a Nota, por favor aguarde.`, type: 'info' });

    if (numeroNota === "" || pdfFileNF === null) {
      // Caso algum campo obrigatório esteja faltando, exiba uma mensagem de erro ou aviso ao usuário
      setEnviando(false)
      setOpenSnackbarProps({ message: `Por favor preencha todos os dados`, type: 'warning' });
      setSnackbarOpen(true);
      return;
    }

    const dadosForm = {
      nome_empreendimento: bonificacao.nome_empreendimento,
      email_imob: dadosBonificacao.user_email,
      cnpj_imob: dadosBonificacao.agency_cnpj,
      cnpj_empreendimento: bonificacao.cnpj_empreendimento,
      id_imobiliaria: dadosBonificacao.id_imobiliaria,
      cod_empreendimento: bonificacao.cod_empreendimento,
      nome_imobiliaria: dadosBonificacao.agency_name,
      bonificacao_ids: bonificacaoIds,
    };

    const formData = new FormData();
    formData.append("numero_nf", numeroNota);
    formData.append("valor_bonificacao", bonificacao.valor_bonificacao);
    formData.append("dados_bonificacao", JSON.stringify(dadosForm));
    formData.append("token", "w5wac68Ho79ML577qRFDxjCiqaH6409d7b7cc31f");

    if (pdfFileNF) {
      formData.append("pdf_file", pdfFileNF)
    }

    formData.append("numero_nf", numeroNota);
    formData.append("valor_bonificacao", bonificacao.valor_bonificacao);
    formData.append("dados_bonificacao", JSON.stringify(dadosForm))
    formData.append("token", "w5wac68Ho79ML577qRFDxjCiqaH6409d7b7cc31f");

    setEnviando(true)

    apiAutomacao.post(`/bonificacao_v2/PostNFBonificacao`, formData)
      .then(response => {
        const data = response.data;
        if (data.status === '200') {
          setOpenSnackbarProps({ message: data.message, type: 'success' });
          setSnackbarOpen(true);
          setEnviando(false);
        } else if (data.status === '500') {
          setOpenSnackbarProps({ message: data.message, type: 'error' });
          setSnackbarOpen(true);
          setEnviando(false);
        }
      })
      .catch(error => {
        console.error('erro', error);
        setOpenSnackbarProps({ message: 'Erro interno', type: 'error' });
        setSnackbarOpen(true);
        setEnviando(false);
      });
  }

  function handleCloseModal() {
    setAreYouSure(true);
    onCloseModal();
    setSnackbarOpen(false);
    setNumeroNota("");
    setPdfFileNF(null);
  }

  return (
    <Modal
      isOpen={isModalOpen}
      width={640}
      onClose={handleCloseModal}
    >
      <>
        <Container>
          <h2 style={{marginBottom: 20}}> {props.title}</h2>

          <div id="input-row" style={{marginBottom: 15}}>
            <h3> Número NF </h3>
            <input id="input" type="number" value={numeroNota} onChange={(e) => setNumeroNota(e.target.value)} />
          </div>


          <div id="input-row" style={{marginBottom: 15}}>
            <h3>Arquivo PDF</h3>
            <div className="input-file">
              <input id="input" type="file" accept="application/pdf" onChange={(e) => setPdfFileNF(e.target.files ? e.target.files[0] : null)} />
            </div>
          </div>

          <div id="input-row" style={{marginBottom: 20}}>
            <h3>Valor Esperado</h3>
            <input id="input" type="text" value={`R$ ${bonificacao.valor_bonificacao.toLocaleString('pt-br')}`} readOnly />
          </div>

          <BotaoContainer>
            <Botao
              tipo='adicionar'
              tamanho="medio"
              className="btn"
              onClick={enviarNotaFiscal}
              disabled={enviando}
            >
              {enviando ? (
                <>
                  <FaSpinner className="spinner" />
                </>
              ) : ('Enviar Nota Fiscal')}
            </Botao>
          </BotaoContainer>

        </Container>

        <Snackbars
          type={openSnackbarProps.type}
          handleClose={handleSnackbarClose}
          open={snackbarOpen}
        >
          {openSnackbarProps.message}
        </Snackbars>
      </>
    </Modal>
  );
};

export default ModalBonificacaoNF;
