import {
  JSXElementConstructor,
  ReactElement,
} from 'react';

import { Close } from '@material-ui/icons';
import { Container, ModalWrapper, CloseModal, Content } from './styles';
import IconeClicavel from '../IconeClicavel/IconeClicavel';

interface IModal {
  isOpen: boolean;
  children: ReactElement<any, string | JSXElementConstructor<any>>;
  width: number;
  onClose: () => void;
  loading?: boolean;
}

const Modal = (props: IModal) => {
  const { children, width, isOpen, onClose, loading, ...otherProps } = props;

  return (
    <Container open={isOpen} disableAutoFocus>
      <ModalWrapper width={width}  >
        {/* <ModalContent> */}
        {loading ? <></> : (
          <CloseModal>
            <IconeClicavel action={() => onClose()}>
              <Close className="closeIcone" />
            </IconeClicavel>
          </CloseModal>
        )}
        <Content>{children}</Content>

        {/* </ModalContent> */}
      </ModalWrapper>
    </Container>
  );
};

Modal.defaultProps = {
  loading: false,
};

export default Modal;
