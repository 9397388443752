interface UserLoginPayload {
  token: string;
  id: any;
  userType: any;
  keepLogged?: boolean;
}

export enum UserActionTypes {
  LOGIN = '@user/AUTHENTICATE',
  LOGOUT = '@user/LOGOUT',
}

export function userLogin(payload: UserLoginPayload) {
  return { type: '@user/AUTHENTICATE', payload };
}
export function userLogout() {
  return { type: '@user/LOGOUT' };
}
