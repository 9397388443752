import React, { useCallback, useEffect, useState } from 'react';
import Switch from "react-switch";
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
  Content,
  MenuLateralContainer,
  TabelaContainer,
} from './styles';

import HeaderProposta from '../../components/HeaderProposta';
import MenuLateralImob from '../../components/MenuLateralImob/MenuLateralImob';
import Tabela from '../../components/Tabelas/Tabela';
import { isSignedIn } from '../../services/auth';
import { RootState } from '../../storage/rootReducer';
import LeadsService from '../../services/LeadsService';
import UserService from '../../services/UserService';
import Snackbars from '../../components/Snackbars';


const CorretoresImob: React.FC = ({currentPage, data}: any) => {
  // const id_user = currentPage.data.user ? currentPage.data.user.id : 'sem id user'
  // console.log('id user', id_user)

  const [userData, setUserData] = useState({});
  const [hotLead, setHotLead] = useState();
  const [listarLeads, setListarLeads] = useState<any>();
  const [listarLead, setListarLead] = useState<any>();

  const [warningModal, setWarningModal] = useState(false);

  const [leadModal, setLeadModal] = useState(false);

  const history = useHistory();
  const [isDataLoading, setIsDataLoading] = useState(false);

  const [newLead, setNewLead] = useState(false);
  const [infoModal, setInfoModal] = useState(false);

  // const [openModal, setOpenModal] = useState(false);
  const [leadsInfo, setLeadsInfo] = useState<any>()
  const [leadContact, setLeadContact] = useState<any>([])

  const [listarCorretores, setListarCorretores] = useState<any>();

  const { state } = useLocation();
  const { token } = useSelector((state: RootState) => state.userReducer);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarProps, setSnackbarProps] = useState({
    message: '',
    type: 'success',
  });
  const [switchStates, setSwitchStates] = useState({});

  const list = useCallback(async () => {
    setIsDataLoading(true);
    try {
      const corretor = await UserService.listCorretoresImobiliaria();
      console.log('corretor', corretor);

      const formattedData = corretor.users.map((corre: any) => {
        let obj = {
          id: corre.id,
          nome: corre.name,
          telefone: corre.phone,
          email: corre.email,
          elegivel: corre.elegivel,
          score_lead: corre.score
        };

        return obj;
      })
      console.log('formattedData 1',formattedData)

      setListarCorretores(formattedData)

      console.log('formattedData ', formattedData)

      setListarCorretores(formattedData)
      setIsDataLoading(false);
    } catch (err) {
      setIsDataLoading(false);
      console.log('deu ruim')
    }

  }, []);

  useEffect(() => {
    if (!isSignedIn()) {
      history.push('/');
    }
    list();
  }, []);


  const handleSnackbarClose = (
    event?: React.SyntheticEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };


  const { pathname } = history.location;



  // const handleSwitchChange = (rowData: { leads?: boolean | undefined; id?: any; }) => {
  //   const updatedListarCorretores = listarCorretores.map((corre: { id: any; leads: any; }) => {
  //     if (corre.id === rowData.id) {
  //       return {
  //         ...corre,
  //         leads: !corre.leads,
  //       };
  //     }
  //     return corre;
  //   });

  //   setListarCorretores(updatedListarCorretores);
  // };

  // const handleSwitchChange = async (rowData: { habilitado?: boolean | undefined; id?: any }) => {

  //   const { id, habilitado } = rowData;

  //   try {
  //     const response = await LeadsService.alteraDisponibilidade(id, !habilitado, 'corretor');

  //     const responseData = response.data;

  //     console.log('Dados enviados na resposta bem-sucedida:', responseData);

  //     const updatedData = listarCorretores.map((item: { id: any; }) =>
  //       item.id === id ? { ...item, habilitado: !habilitado } : item
  //     );

  //     setListarCorretores(updatedData);

  //     setSnackbarProps({
  //       message: 'Disponibilidade alterada com sucesso',
  //       type: 'success',
  //     });

  //     setSnackbarOpen(true);
  //   } catch (error) {
  //     console.error('Erro ao alterar a disponibilidade:', error);

  //     setSnackbarProps({
  //       message: 'Erro ao alterar a disponibilidade',
  //       type: 'error',
  //     });

  //     setSnackbarOpen(true);
  //   }
  // };

  const handleSwitchChange = async (rowData: { habilitado?: boolean | undefined; id?: any }) => {
      
      const { id, habilitado } = rowData;

      const verificaEstadoDoCorretor = listarCorretores.filter((corretor: { id: any; }) => corretor.id === id);

      const verificaEstadoElegivel = verificaEstadoDoCorretor.map((corretor: { elegivel: any; }) => {
        return corretor.elegivel
      });

      if (verificaEstadoElegivel[0] === false) {
        try {
          const response = await LeadsService.alteraDisponibilidade(id, true, 'corretor');
          const responseData = response.data;
    
          console.log('Dados enviados na resposta bem-sucedida:', responseData);
    
          const updatedData = listarCorretores.map((item: { id: any; }) =>
            item.id === id ? { ...item, habilitado: !habilitado } : item
          );
    
          setListarCorretores(updatedData);
    
          setSnackbarProps({
            message: 'Disponibilidade alterada com sucesso',
            type: 'success',
          });
    
          setSnackbarOpen(true); 
          list();

        } catch (error) {
          console.error('Erro ao alterar a disponibilidade:', error);
    
          setSnackbarProps({
            message: 'Erro ao alterar a disponibilidade',
            type: 'error',
          });
    
          setSnackbarOpen(true); 
        }
      } else {
        try {
          const response = await LeadsService.alteraDisponibilidade(id, false, 'corretor');
          const responseData = response.data;
    
          console.log('Dados enviados na resposta bem-sucedida:', responseData);
    
          const updatedData = listarCorretores.map((item: { id: any; }) =>
            item.id === id ? { ...item, habilitado: !habilitado } : item
          );
    
          setListarCorretores(updatedData);
    
          setSnackbarProps({
            message: 'Disponibilidade alterada com sucesso',
            type: 'success',
          });
    
          setSnackbarOpen(true); 
          list();
    
        } catch (error) {
          console.error('Erro ao alterar a disponibilidade:', error);
    
          setSnackbarProps({
            message: 'Erro ao alterar a disponibilidade',
            type: 'error',
          });
          setSnackbarOpen(true); 
        }
      }
    }


  const columns = [
    {
      title: 'Nome',
      field: 'nome',
    },
    {
      title: 'Telefone',
      field: 'telefone',
    },
    {
      title: 'Emai',
      field: 'email',
    },
    {
      title: 'Score',
      field: 'score',
    },
    {
      title: 'Receber Leads?',
      field: 'elegivel',
      render: (rowData: any) => (
        <Switch onChange={() => handleSwitchChange(rowData)} checked={rowData.elegivel} />
      )
    },
  ];






  console.log('listarCorretores', listarCorretores)

  const [isModalOpen, setModalOpen] = useState(false);

  function rowSelected(e: any, rowData: any) {
    // setInfoModal(true);
    // console.log('isso vai para o editar ', listarLeads);
    // console.log('info modal ', infoModal);

  }

  const [open, setOpen] = React.useState(false);

  // const handleClick = () => {
  //   setModalOpen(false);
  //   setOpen(true);
  // };

  // function deleteTicket(telefone: string) {

  //   const req = async () => {
  //     try {
  //       const response = await fetch('https://msging.net/commands', {
  //         method: 'POST',
  //         headers: {
  //           'Authorization': 'Key dGVzdGVzaXRldml6aWRpZ2l0YWw6YlM2bHF3eFdudmZsek9lWENGR0k=',
  //           'Content-Type': 'application/json',
  //         },
  //         body: JSON.stringify({
  //           "id": "765b1d9b-3db2-4212-bdc3-f375af5974c6",
  //           "to": "postmaster@desk.msging.net",
  //           "method": "get",
  //           "uri": "/monitoring/waiting-tickets"
  //         })
  //       })
  //       const json = await response.json()
  //       // console.log('json aq',json)

  //       setLeadsInfo(json.resource.items.map((resp: any) => {
  //         return {
  //           CID: resp.customerIdentity,
  //           costumer_name: resp.customerName,
  //           ticket_id: resp.id,
  //           telefone: telefone
  //         }
  //       }))
  //     } catch (error) {
  //       console.log(error)
  //     }
  //   }
  //   req()
  // }

  // useEffect(() => {
  //   if (leadsInfo) {
  //     console.log('leadsInfo', leadsInfo)
  //     const fetchData = async () => {
  //       const result = await Promise.all(leadsInfo.map(async (resp: any) => {
  //         try {
  //           const response = await fetch('https://msging.net/commands', {
  //             method: 'POST',
  //             headers: {
  //               'Authorization': 'Key dGVzdGVzaXRldml6aWRpZ2l0YWw6YlM2bHF3eFdudmZsek9lWENGR0k=',
  //               'Content-Type': 'application/json',
  //             },
  //             body: JSON.stringify({
  //               "id": "6649627d-16ee-42a6-a7ee-d3eaa0fcfc01",
  //               "to": "postmaster@crm.msging.net",
  //               "method": "get",
  //               "uri": `/contacts/${resp.CID}`
  //             })
  //           })
  //           const json = await response.json()
  //           return json
  //         } catch (error) {
  //           console.log(error)
  //           return null;
  //         }
  //       }))
  //       setLeadContact(result.filter(item => item !== null))
  //     }

  //     fetchData()
  //   } else {
  //     console.log('caiu no else')
  //   }
  // }, [leadsInfo])

  // useEffect(() => {
  //   console.log('leadcontact', leadContact)
  // }, [leadContact])

  // useEffect(() => {
  //   if (leadsInfo && leadContact) {
  //     console.log('aqqqqq', leadsInfo)
  //     console.log('aqwdsads', leadContact)

  //     const idLeadsInfo = leadsInfo.map((resp: any) => {
  //       return resp.telefone
  //     })
  //     console.log(idLeadsInfo)
  //     const resultadoFiltro = leadContact.filter((lead: any) => idLeadsInfo.includes(lead.resource.phoneNumber))
  //     console.log('vai brasil', resultadoFiltro)

  //     if (resultadoFiltro) {
  //       const deleteTicketID = leadsInfo.filter((lead: any) => resultadoFiltro[0].resource.phoneNumber.includes(lead.telefone))
  //       console.log('deu certo', deleteTicketID)
  //       const req = async () => {
  //         try {
  //           const response = await fetch('https://msging.net/commands', {
  //             method: 'POST',
  //             headers: {
  //               'Authorization': 'Key dGVzdGVzaXRldml6aWRpZ2l0YWw6YlM2bHF3eFdudmZsek9lWENGR0k=',
  //               'Content-Type': 'application/json',
  //             },
  //             body: JSON.stringify({
  //               "id": "43ff390c-f349-11ed-a05b-0242ac120003",
  //               "to": "postmaster@desk.msging.net",
  //               "method": "set",
  //               "uri": "/tickets/change-status",
  //               "type": "application/vnd.iris.ticket+json",
  //               "resource": {
  //                 "id": `${deleteTicketID[0].ticket_id}`,
  //                 "status": "ClosedClient"
  //               }
  //             })
  //           })
  //           const json = await response.json()
  //           console.log('json aq', json)

  //         } catch (error) {
  //           console.log(error)
  //         }

  //       }
  //       req()
  //     }
  //   }
  // }, [leadContact]);


  // const WarningModal: any = () => {
  //   if (warningModal) {
  //     return (
  //       <Modal
  //         isOpen={warningModal}
  //         width={500}
  //         onClose={() => setWarningModal(false)}
  //       >
  //         <>
  //           <h1>Sem permissão!</h1>
  //           <>
  //             <div className="formWrapper" style={{ height: 200 }}>
  //               <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 20 }}>
  //                 <AiOutlineWarning size={140} color="red" />
  //               </div>
  //               <p style={{ fontSize: 20 }}>
  //                 Você não pode alterar esse status!
  //               </p>
  //             </div>
  //           </>
  //         </>
  //       </Modal>
  //     );
  //   }

  //   return <></>;
  // };

  // const InfoModal: any = () => {
  //   if (infoModal && listarLead) {
  //     return (
  //       <Modal
  //         isOpen={infoModal}
  //         width={500}
  //         onClose={() => setInfoModal(false)}
  //       >
  //         <>
  //           <h1>Lead</h1>
  //           <>
  //             <LeadTicketInfo >
  //               <p>
  //                 <span>Nome: </span> {listarLead ? listarLead.nome : ''}
  //               </p>
  //               <p>
  //                 <span>Tempo de espera: </span> {listarLead ? timeAwaiting(listarLead.data) : ''}
  //               </p>
  //               <p>
  //                 <span>Entrada simulada:</span> {listarLead ? listarLead.entrada_simulada : ''}
  //               </p>
  //               <p>
  //                 <span>Parcelamento simulado:</span> {listarLead ? listarLead.parcelamento_simulado : ''}
  //               </p>
  //               <p>
  //                 <span>Empreendimento: </span> {listarLead ? listarLead.empreendimento : ''}
  //               </p>
  //               <p>
  //                 <span>Quadra/Lote: </span> {listarLead ? listarLead.quadra_lote : ''}
  //               </p>
  //               <p>
  //                 <span>Telefone: </span> {listarLead ? listarLead.telefone

  //                   : ''}
  //               </p>
  //               <p>
  //                 <span>Observacoes: </span> {listarLead ? listarLead.observacoes : ''}
  //               </p>
  //             </LeadTicketInfo>
  //             {/* <div className="btn-modalfull">
  //                   <Botao
  //                     tipo="salvar"
  //                     tamanho="fullwidth"
  //                     onClick={() => {setInfoModal(false) }}
  //                   >
  //                     OK
  //                   </Botao>
  //                 </div> */}
  //             <ContatctButton>
  //               <Botao
  //                 tipo="salvar"
  //                 tamanho="fullwidth"
  //                 onClick={() => deleteTicket(listarLead.telefone)}
  //               >
  //                 <a href={`https://api.whatsapp.com/send?phone=${listarLead.telefone}`}
  //                   target="_blank" rel="noreferrer">
  //                   Whatsapp <BiChat size={28} style={{ marginLeft: 5 }} />
  //                 </a>
  //               </Botao>
  //             </ContatctButton>
  //           </>
  //         </>
  //       </Modal>
  //     );
  //   }

  //   return <></>;
  // };

  return (
    <>
      <HeaderProposta />


      <Content>

        <MenuLateralContainer>
          <MenuLateralImob
            className="menuLateral"
            currentPage={{ pathname, data: userData }}
            data={userData}
            style={{ marginTop: '100px' }}
          // notification={newLead}
          />
        </MenuLateralContainer>
        <TabelaContainer>
          <Tabela
            title="Corretores"
            haveActions="none"
            columns={columns}
            isLoading={isDataLoading}
            data={listarCorretores}
            onRowClick={(e: any, rowData: any): any => {
              // setListarCorretores(rowData);
              // rowSelected(e, rowData);
            }}
            updateCellCallbackFunction={
              async (newValue, oldValue, rowData) => {
                // console.log('newValue', newValue)

                // if (newValue !== oldValue) {
                //   const params = {
                //     "id_lead": rowData.id_lead,
                //     "id_user": 'fb95ded4-d440-4701-86c0-998d1e8b6acb',
                //     "acao": newValue,
                //     "land_id": 0
                //   }

                //   console.log('rowData ', rowData)

                //   const savedNewAction = await LeadsService.createLeadAction(params)

                //   if (savedNewAction) {
                //     listarLeads.forEach((e: {
                //       status: any; id: string;
                //     }) => {
                //       if (e.id === rowData.id) {
                //         e.status = newValue
                //       }
                //     })
                //   }
                //   setListarLeads(listarLeads);
                //   console.log('listarLeads dps de atualziar', listarLeads)
                // }

              }
            }
            excluirClick={() => {
              // console.log('');
            }}
            editarClick={(e: any, rowData: any): any => {
              // setListarLead(rowData);
              // rowSelected(e, rowData);
            }}
            pagination
            paginationPageSize={10}
          />
        </TabelaContainer>
        {/* <LeadModal /> */}
        {/* <InfoModal /> */}
        {/* <WarningModal /> */}
        <Snackbars
          type={snackbarProps.type}
          handleClose={handleSnackbarClose}
          open={snackbarOpen}
        >
          {snackbarProps.message}
        </Snackbars>
      </Content>

    </>
  );
};

export default CorretoresImob;