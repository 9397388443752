import styled from 'styled-components';
import cores from '../../styles/cores';

export const Background = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: ${cores.themeGradient_2};

  .testando {
    margin-top: -50px;
    width: 100%;
  }
`;

export const FormContainer = styled.div`
  background-color: white;
  display: flex;
  height: 500px;
  width: 400px;
  border-radius: 12px;
  opacity: 0.95;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &.digite-email {
    height: 360px;
  }

  .input {
    width: 100%;
  }

  .esqueciMinhaSenha {
    color: ${cores.purple_2};
    font-size: 14px;
    font-weight: bold;
    text-decoration: underline;

    &:hover {
      opacity: 0.9;
    }
  }

  .textoAux {
    font-size: 14px;
    text-align: center;
    margin: 0px 0 10px 0;
  }

  h2 {
    font-weight: bold;
    font-size: 28px;
    margin: 30px 0 10px 0;
  }
  h3 {
    margin-bottom: 20px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 80%;
  height: 100%;

  .manter-conectado {
    font-size: 14px;
  }
`;

export const SenhaContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  border: 0px solid black;
  height: auto;
  width: 100%;
  .visibilidade {
    position: absolute;
    color: grey;
    cursor: pointer;
    margin-left: 270px;
    margin-top: 12px;
    font-size: 30px;
  }
`;

export const Icone = styled.div`
  display: flex;
  margin: 0 0 20px 0;
  align-items: center;
  justify-content: center;
  width: 220px;
  height: auto;

  svg {
    border: 0px solid black;
    width: 220px;
    height: auto;
  }
`;

export const IconeErro = styled.div`
  align-self: center;
  svg {
    font-size: 85px;
    color: green;
  }
`;

export const ModalContainer = styled.div`
  display: flex;
  width: fit-content;
  height: auto;
  border: 1px solid black;
  
  p {
    font-weigth: bold;
  }
`;

export const Paragraph = styled.div`
  text-align: center;
  font-weigth: bold;
  margin-bottom: 5px;
`;

export const Title = styled.div`
  text-align: center;
  font-size: 20px;
  font-weigth: bold;
  margin-bottom: 15px;
`;

export const BotaoContainer = styled.div`
  display: flex;
  width: 100%;
  margin-left: 10px;
  height: fit-content;
`;
