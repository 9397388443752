import React from "react";
import { CenteredText, OrderedList, StyledH1, StyledH2, UnderlinePart, UnorderedList } from "./styles";

const PoliticaDePrivacidade: React.FC = () => {
    return (
        <CenteredText>
            <br />
            <br />
            <StyledH1>Termos de Uso e Política de Privacidade</StyledH1>
            <br />
            <br />
            <StyledH2>Termos e Condições de Uso</StyledH2>
            <br />
            <p>Os presentes Termos e Condições de Uso visam regular a utilização por você, usuários de nosso aplicativo de reservas de lotes e análise de crédito (denominado como “Aplicativo”).
            </p>
            <br />
            <br />
            <StyledH2>Como Funciona?</StyledH2>
            <br />
            <p>
                <UnderlinePart>Termo de Aceitação</UnderlinePart> – Ao utilizar os nossos Serviços pelo Aplicativo, o usuário aceita e concorda com todos os termos e condições expostas que se encontram vigentes nesta data. Alertamos que estes Termos e Condições de Uso poderão ser modificados a qualquer momento pela BAIRRU, em virtude de alterações na legislação ou nos Serviços, em decorrência da utilização de novas ferramentas tecnológicas ou, ainda, sempre que, a exclusivo critério da BAIRRU, tais alterações se façam necessárias. A utilização do Aplicativo por qualquer usuário implicará em expressa aceitação destes Termos e Condições de Uso.
            </p>
            <br />
            <p>
                <UnderlinePart>Atualização das Informações</UnderlinePart> A BAIRRU se reserva o direito de aprimorar as funcionalidades dos Serviços dispostos no Aplicativo e de implementar novas tecnologias. Assim, os Termos de Uso poderão ser alterados, a qualquer tempo, exceto em caso de vedação legal, para incluir as modificações implementadas. Ao continuar a utilizar os nossos Serviços após alterados os Termos de Uso, você concorda com as alterações realizas e vigentes à época do acesso.
            </p>
            <br />
            <p>
                <UnderlinePart>Uso Adequado dos Serviços</UnderlinePart> Ao utilizar o Aplicativo, você se compromete a respeitar a legislação brasileira vigente e o disposto nestes Termos e Condições de Uso, não devendo produzir, disponibilizar ou compartilhar qualquer conteúdo que:
            </p>
            <br />
            <div>
                <OrderedList>
                    <li>Implique a prática de ato ilícito e viole a legislação brasileira vigente;</li>
                    <li>Viole direitos de terceiro ou direitos da BAIRRU ou da Companhia BAIRRU;</li>
                    <li>Seja falso, incorreto, impreciso, extemporâneo e que possa induzir outrem a erro;</li>
                    <li>Disponibilize ou permita o acesso a conteúdo ilegal, violento, pornográfico ou qualquer outro ato contrário à lei e à ordem pública;</li>
                    <li>Induza a discriminação ou incite o ódio contra pessoas e/ou grupos de pessoas em razão de nacionalidade, raça, religião, orientação sexual, gênero, condição física, nacionalidade, dentre outros atos que contrariem a ordem pública e a legislação brasileira vigente;</li>
                    <li>Seja resguardado por direito de propriedade intelectual de terceiro e você não tenha autorização prévia para utilizá-lo;</li>
                    <li>Contenha vírus ou outro elemento que seja capaz, em razão de suas características (como forma, extensão, etc.), de causar danos ou impedir o funcionamento regular da rede do sistema e/ou de equipamentos informáticos (&quot;hardware&quot; e &quot;software&quot;) da BAIRRU ou de terceiros.</li>
                </OrderedList>
            </div>
            <br />
            <p><UnderlinePart>Responsabilidades</UnderlinePart> – Enquanto você usufruir das funcionalidades do Aplicativo, você é responsável:</p>
            <br />
            <div>
                <OrderedList>
                    <li>Por todo ato, por ação ou omissão, realizado em nosso Aplicativo, responsabilizando-se pela prática de todo e qualquer ato ilícito feita por você;</li>
                    <li>Pelo conteúdo gerado por você;</li>
                    <li>Pela reparação de danos causados a terceiros, à BAIRRU ou a toda Companhia BAIRRU, a partir do uso dos Serviços disponibilizados no Aplicativo;</li>
                    <li>Pelo seu próprio acesso à internet e ao pagamento pelos serviços de acesso à rede;</li>
                    <li>Pelo equipamento necessário para realizar a conexão à internet, como celular e modem, garantindo a segurança adequada do ambiente, mediante a utilização de ferramentas como antivírus e firewall;</li>
                    <li>Por não utilizar os Serviços do Aplicativo para outras finalidades além da análise de crédito e reservas dos lotes de propriedade da BAIRRU.</li>
                </OrderedList>
            </div>
            <br />
            <p>
                Sem prejuízo das demais isenções de responsabilidade indicadas no presente documento, você se declara ciente de que a BAIRRU não será responsável:
            </p>
            <br />
            <div>
                <OrderedList>
                    <li>Por qualquer defraudação de utilidade que você possa atribuir ao Aplicativo, pela falibilidade destes, nem por qualquer dificuldade de acesso a este, decorrentes de condutas atribuíveis a terceiros, como, por exemplo, prestadores de serviços de conexão à internet;</li>
                    <li>Pela presença de vírus ou demais elementos nocivos nos Serviços, capazes de causar alterações em seus sistemas informáticos (software e hardware), documentos eletrônicos, eximindo-se o Santander de qualquer responsabilidade por eventuais danos e prejuízos decorrentes de quaisquer elementos nocivos inseridos por terceiros;</li>
                    <li>Pelos danos e prejuízos de toda natureza decorrentes do conhecimento que terceiros não autorizados possam ter de quaisquer dados fornecidos nos Serviços, em decorrência de falha exclusivamente relacionada ao usuário ou a terceiros que fujam a qualquer controle razoável do Santander.</li>
                </OrderedList>
            </div>
            <br />
            <p>
                <UnderlinePart>Propriedade Intelectual</UnderlinePart> – A BAIRRU assegura que as informações (textos, imagens, sons) contidas no aplicativo estão devidamente de acordo com a legislação e normativos que regulam os direitos de propriedade intelectual (autoral e industrial), bem como pertencem à BAIRRU ou a terceiro que licitamente cedeu o seu direito de uso, não sendo permitidas modificações, cópias, reproduções ou quaisquer outras formas de utilização para fins comerciais sem o consentimento prévio e expresso da BAIRRU.
            </p>
            <br />
            <p>
                Você declara que irá respeitar todos os direitos de propriedade intelectual contidos nos nossos Serviços, ciente de que o direito de acesso ao Serviço do aplicativo não concede qualquer autorização para uso de direitos de propriedade intelectual constantes de tal Serviço.
            </p>
            <br />
            <p>
                <UnderlinePart>Lei aplicável e resolução de conflitos </UnderlinePart>– Toda e qualquer controvérsia oriunda dos termos expostos nestes Termos e Condições de Uso serão solucionados de acordo com a lei brasileira, sendo competente o foro da cidade de Curitiba, Estado do Paraná, com exclusão de qualquer outro por mais privilegiado que seja.
            </p>
            <br />
            <br />
            <StyledH2>Conheça Nossa Política</StyledH2>
            <br />
            <UnderlinePart>Definições:</UnderlinePart>
            <br />
            <div>
                <UnorderedList>
                    <li>Agentes de tratamento: o controlador e o operador. Controlador: pessoa natural ou jurídica, de direito público ou privado, a quem competem as decisões referentes ao tratamento de dados pessoais.</li>
                    <li>Dado Pessoal: toda e qualquer informação relacionada a pessoa natural identificada ou identificável.</li>
                    <li>Dado Pessoal Sensível: dado pessoal sobre origem racial ou étnica, convicção religiosa, opinião política, filiação a sindicato ou a organização de caráter religioso, filosófico ou político, dado referente à saúde ou à vida sexual, dado genético ou biométrico, quando vinculado a uma pessoa natural.</li>
                    <li>Operador: pessoa natural ou jurídica, de direito público ou privado, que realiza o tratamento de dados pessoais em nome do controlador.</li>
                    <li>Titular: pessoa natural a quem se referem os dados pessoais que são objeto de tratamento ou a pessoa jurídica a quem se referem os dados, inclusive financeiros e transacionais objeto do tratamento</li>
                    <li>Tratamento: toda operação realizada com dados pessoais, como as que se referem a coleta, produção, recepção, classificação, utilização, acesso, reprodução, transmissão, distribuição, processamento, arquivamento, armazenamento, eliminação, avaliação ou controle da informação, modificação, comunicação, transferência, difusão ou extração.</li>
                </UnorderedList>
            </div>
            <br />
            <p>
                <UnderlinePart>Quem é o responsável pelo tratamento de dados pessoais (Controlador)?</UnderlinePart>– É a Bairru Holding Empreendimentos Imobiliários LTDA, inscrita no CNPJ/MF n° 03.625.376/0001-80, com sede na Rua General Mario Tourinho, 1805, Sala 1801, Campina do Siqueira, Curitiba/PR, CEP 80740-000 e ou demais empresas do Grupo Bairru. (&quot;BAIRRU&quot;)
            </p>
            <br />
            <p>
                <UnderlinePart>Para quais finalidades os dados pessoais são tratados?</UnderlinePart> - Nos termos da Lei Geral de Proteção de Dados (Lei nº 13.709/18), a BAIRRU realiza o tratamento de seus dados pessoais com finalidades específicas e de acordo com as bases legais previstas na respectiva Lei, tais como: para o devido cumprimento das obrigações legais e regulatórias, para o exercício regular de direitos e para a proteção do crédito, bem como sempre que necessário para a execução dos contratos firmados com seus clientes ou para atender aos interesses legítimos da BAIRRU, de seus clientes ou de terceiros. Para qualquer outra finalidade, para a qual o consentimento do titular deve ser coletado, o tratamento estará condicionado à manifestação livre, informada e inequívoca do titular.
            </p>
            <br />
            <UnderlinePart>Quais informações são coletadas para o uso do Aplicativo?</UnderlinePart>
            <br />
            <br />
            <div>
                <UnorderedList>
                    <li>Informações Fornecidas pelo Corretor: Nome Completo, CPF, Data de Nascimento, Número do CRECI (Conselho Regional de Corretores), Foto do Corretor, Logs de Erro</li>
                    <li>Informações Fornecidas pelo Cliente que pretende adquirir os lotes e realizar a análise de crédito: Nome Completo, CPF, RG, Data de Nascimento, Profissão, Estado Civil, Renda Familiar, Assinatura, Foto do Cliente.</li>

                </UnorderedList>
            </div>
            <br />
            <p>
                <UnderlinePart>Como as suas informações serão utilizadas?</UnderlinePart>– A BAIRRU utilizará as informações fornecidas pelo corretor e pelo cliente para os seguintes propósitos:
            </p>
            <br />
            <div>
                <UnorderedList>
                    <li>Análise de Crédito: As informações do cliente são usadas para realizar análises de crédito para processar as reservas de lotes.</li>
                    <li>Compartilhamento com Terceiros: Compartilhamos as informações do cliente com terceiros, conforme necessário, para realizar a análise de crédito e concluir as reservas de lotes.</li>
                    <li>Comunicação: Usamos as informações de contato fornecidas para nos comunicar com o corretor e o cliente sobre o status das reservas e análises de crédito.</li>
                    <li>Melhorias no Aplicativo: Utilizamos dados de logs de erro para melhorar a funcionalidade e estabilidade do nosso aplicativo.</li>

                </UnorderedList>
            </div>
            <br />
            <p>
                <UnderlinePart>Como tratamos as suas informações de forma segura?</UnderlinePart> – O acesso às informações pessoais coletadas, armazenadas ou de outra forma tratadas pela BAIRRU é restrito aos profissionais autorizados ao uso direto dessas informações, e necessário à prestação de seus Serviços, sendo limitado o uso para outras tarefas.
            </p>
            <br />
            <p>
                A BAIRRU poderá revelar as informações pessoais que tenha recebido nas seguintes hipóteses:
            </p>
            <br />
            <div>
                <UnorderedList>
                    <li>sempre que estiver obrigado a revelá-las, seja em virtude de dispositivo legal, ato de autoridade competente, ordem ou mandado judicial;</li>
                    <li>aos seus parceiros comerciais e/ou prestadores de serviço, a fim de atender à solicitação de serviços efetuada no Aplicativo;</li>
                    <li>aos órgãos de proteção e defesa de crédito e prestadores de serviços autorizadas pela BAIRRU a defender seus direitos e créditos;</li>
                    <li>aos órgãos que administrem cadastros de consumidores;</li>
                    <li>aos seus controladores, às empresas por ele controladas, as empresas a ele coligadas ou por qualquer forma associadas, no Brasil ou no exterior;</li>
                </UnorderedList>
            </div>
            <br />
            <p>
                A BAIRRU trabalha exaustivamente para assegurar que as informações divulgadas para os clientes sejam verdadeiras e íntegras, contando com controles apurados de monitoramento das informações fornecidas. Sua participação no processo é revisar as informações, valores e informativos e enviar para o usuário, um comunicado de qualquer discrepância nas informações fornecidas.
            </p>
            <br />
            <p>
            <UnderlinePart>Solicitações e Reclamações</UnderlinePart> - Caso você tenha alguma solicitação ou reclamação, pedimos que entre em contato pelo nosso SAC (contato@bairru.com.br). Ao entrar em contato, pediremos alguns dados pessoais para confirmarmos a sua identidade. Eventualmente, outras informações podem ser solicitadas para confirmar a sua identidade com o objetivo de melhor atender à sua solicitação ou reclamação, havendo dúvida sobre a veracidade ou legitimidade das informações informadas.
            </p>
            <br />
            <p>
            <UnderlinePart>Disposições Gerais</UnderlinePart> – Eventuais omissões ou meras tolerâncias das partes no exigir o estrito e pleno cumprimento desta Política de Privacidade e/ou de prerrogativas decorrentes dele ou da lei, não constituirão novação ou renúncia, nem afetarão o exercício de quaisquer direitos aqui previstos, que poderão ser plena e integralmente exercidos, a qualquer tempo.
            </p>
            <br />
            <p>
                Caso se perceba que uma disposição é nula, as disposições restantes desta Política de Privacidade permanecerão em pleno vigor e um termo válido substituirá o termo nulo, refletindo nossa intenção, tanto quanto possível.
            </p>
            <br />
            <p>
            <UnderlinePart>Seus Direitos</UnderlinePart> – Você tem o direito de acessar as informações pessoais que possuímos sobre você e solicitar que suas informações sejam corrigidas, atualizadas ou excluídas. Se você quiser exercer esse direito, entre em contato conosco por meio do e-mail contato@bairru.com.br.
            </p>
            <br />
            <p>
            <UnderlinePart>Alterações nesta Política de Privacidade</UnderlinePart> – Reservamo-nos o direito de modificar esta política de privacidade a qualquer momento, portanto, revise-a com frequência. As alterações e esclarecimentos terão efeito imediato após serem publicados no aplicativo. Se fizermos alterações materiais nesta política, iremos notificá-lo aqui que foi atualizada, para que você esteja ciente de quais informações coletamos, como as usamos e sob que circunstâncias, se houver, usamos e/ou divulgamos.
            </p>
            <br />
            <p>
            <UnderlinePart>Lei aplicável e resolução de conflitos</UnderlinePart> – Toda e qualquer controvérsia oriunda dos termos expostos nestes Termos e Condições de Uso serão solucionados de acordo com a lei brasileira, sendo competente o foro da cidade de Curitiba, Estado do Paraná, com exclusão de qualquer outro por mais privilegiado que seja.
            </p>


        </CenteredText >
    );
};

export default PoliticaDePrivacidade;
