import { useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import { useLocation } from 'react-router';
import { InputsContainer, InputsContent } from '../EditarProposta/styles';
import PreviewFile from '../../../components/PreviewFile/PreviewFile';


const PessoaFisica = (props: any) => {
  const { filesUrl} = props;
  const checkedAnexos = (arr: any,tag:string) => {
    let arrayFiltered = arr.filter((el:any) => {
      if(el.tag === tag){
        return true
      }
      return false
    })
    if (arrayFiltered.length >= 1 && arrayFiltered.length <= 15) {
      return <DoneIcon className="checked" />;
    }
    if (arrayFiltered.length > 15) {
      return (
        <>
          <ClearIcon className="wrong" />
          <p style={{fontSize: 11}}>Muitos arquivos anexados!</p>
        </>
      );
    }
    return (
      <>
        <ClearIcon className="wrong" />
        <p style={{fontSize: 11}}>Nenhum documento anexado!</p>
      </>
    );
  };

  return (
    <>
      <InputsContent className="acordion-1">
        <Accordion className="acordion">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="acordion-summary"
          >
            <Typography className="anexar-documentos">
              <AttachFileIcon />
              Carta Proposta (CP) e Documentos Pessoais (RG ou CNH não vencida e CPF, frente e verso)
              {checkedAnexos(filesUrl,'personal')}
            </Typography>

          </AccordionSummary>
          <AccordionDetails>
            <InputsContainer className="upload-fotos">
              {filesUrl.map((el:any)=>{
                  const urlSplited = el.document.split('.');
                  const nome = el.document.split('/');
                  let isImage = false;
                  if(urlSplited[urlSplited.length-1] === 'png' || 
                      urlSplited[urlSplited.length-1] === 'jpg' || 
                      urlSplited[urlSplited.length-1] === 'jpeg' ||
                      urlSplited[urlSplited.length-1] === 'svg'){
                          isImage = true;
                      }
                  if(el.tag === 'personal' || el.tag === 'signed_cp' || el.tag === 'signed-cp'){
                    return(
                      <PreviewFile url={el.document} nome={nome[nome.length - 1]} isImage = {isImage}/>
                    )
                  }
                  return<></>;
              })}
            </InputsContainer>
          </AccordionDetails>

        </Accordion>
      </InputsContent>
      <InputsContent className="acordion-1">
        <Accordion className="acordion">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="acordion-summary"
          >
            <Typography className="anexar-documentos">
              <AttachFileIcon />
              Comprovante de estado civil{' '}
              {checkedAnexos(filesUrl,'estado_civil')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <InputsContainer className="upload-fotos">
            {filesUrl.map((el:any)=>{
                  const urlSplited = el.document.split('.');
                  const nome = el.document.split('/');
                  let isImage = false;
                  if(urlSplited[urlSplited.length-1] === 'png' || 
                      urlSplited[urlSplited.length-1] === 'jpg' || 
                      urlSplited[urlSplited.length-1] === 'jpeg' ||
                      urlSplited[urlSplited.length-1] === 'svg'){
                          isImage = true;
                      }
                  if(el.tag === 'estado_civil'){
                    return(
                      <PreviewFile url={el.document} nome={nome[nome.length - 1]} isImage = {isImage}/>
                    )
                  }
                  return<></>;
              })}
            </InputsContainer>
          </AccordionDetails>
        </Accordion>
      </InputsContent>
      <InputsContent className="acordion-1">
        <Accordion className="acordion">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="acordion-summary"
          >
            <Typography className="anexar-documentos">
              <AttachFileIcon />
              Comprovante de residência (Até 90 dias)
              {checkedAnexos(filesUrl,'comp_residencial')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <InputsContainer className="upload-fotos">
            {filesUrl.map((el:any)=>{
                  const urlSplited = el.document.split('.');
                  const nome = el.document.split('/');
                  let isImage = false;
                  if(urlSplited[urlSplited.length-1] === 'png' || 
                      urlSplited[urlSplited.length-1] === 'jpg' || 
                      urlSplited[urlSplited.length-1] === 'jpeg' ||
                      urlSplited[urlSplited.length-1] === 'svg'){
                          isImage = true;
                      }
                  if(el.tag === 'comp_residencial'){
                    return(
                      <PreviewFile url={el.document} nome={nome[nome.length - 1]} isImage = {isImage}/>
                    )
                  }
                  return<></>;
              })}

            </InputsContainer>
          </AccordionDetails>
        </Accordion>

      </InputsContent>

      <InputsContent className="acordion-1">
        <Accordion className="acordion">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="acordion-summary"
          >
            <Typography className="anexar-documentos">
              <AttachFileIcon />
                Comprovante de renda
              {checkedAnexos(filesUrl,'comp_renda')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <InputsContainer className="upload-fotos">
            {filesUrl.map((el:any)=>{
                  const urlSplited = el.document.split('.');
                  const nome = el.document.split('/');
                  let isImage = false;
                  if(urlSplited[urlSplited.length-1] === 'png' || 
                      urlSplited[urlSplited.length-1] === 'jpg' || 
                      urlSplited[urlSplited.length-1] === 'jpeg' ||
                      urlSplited[urlSplited.length-1] === 'svg'){
                          isImage = true;
                      }
                  if(el.tag === 'comp_renda'){
                    return(
                      <PreviewFile url={el.document} nome={nome[nome.length - 1]} isImage = {isImage}/>
                    )
                  }
                  return<></>;
              })}

            </InputsContainer>
          </AccordionDetails>
        </Accordion>

      </InputsContent>
    </>
  );
};

export default PessoaFisica;
