import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
// import Switch from '@mui/material/Switch';
import Switch from "react-switch";

import {
    Content,
    TabelaContainer,
    OpcoesContainer,
} from './styles';
import Tabela from '../../components/Tabelas/Tabela';
import Botao from '../../components/Botao/Botao';
// import DetalhesCorretores from './DetalhesCorretores';
// import EditarCorretores from './EditarCorretores';
// import NovoCorretor from './NovoCorretor';
import DeleteModal from '../../components/DeleteModal';
import Snackbars from '../../components/Snackbars';
import ImobiliariasService from '../../services/ImobiliariasService';

const LeadsAdmin: React.FC = () => {
    const [agencyData, setAgencyData] = useState();
    const history = useHistory()
    const location = useLocation();
    const [pageMenu, setPageMenu] = useState('principal');
    const [allAgencyData, SetAllAgencyData] = useState<any[]>([]);
    const [snackbarProps, setSnackbarProps] = useState({
        message: '',
        type: 'success',
    });
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [isDataLoading, setIsDataLoading] = useState(false);

    const { pathname } = history.location;
    console.log('pathname', pathname)

    const fetchData = useCallback(async () => {
        setIsDataLoading(true);
        try {
            const response = await ImobiliariasService.listAllImobiliarias();
            const agencies = response.agencies || []; 
            SetAllAgencyData(agencies);
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setIsDataLoading(false);
        }
    }, []);


    useEffect(() => {
        fetchData();
    }, []);

    
    console.log('allAgencyData', allAgencyData)

    
    // const handleSwitchChange = (rowData: { habilitado?: boolean | undefined; id?: any }) => {

    //     const novo_status = !rowData.habilitado;

    //     // Chama alteraDisponibilidade com os valores corretos
    //     alteraDisponibilidade(rowData.id, novo_status, 'imobiliaria');

    //     // Restante do seu código para atualizar a interface do usuário
    //     const updatedAllAgencyData = allAgencyData.map((agency: any) => {
    //         if (agency.id === rowData.id) {
    //             console.log(`Imobiliária ID: ${agency.id}, Novo Status: ${novo_status}`);
    //             return {
    //                 ...agency,
    //                 habilitado: novo_status,
    //             };
    //         }
    //         return agency;
    //     });

    //     SetAllAgencyData(updatedAllAgencyData);
    // };
    

    const handleSwitchChange = async (rowData: { habilitado?: boolean | undefined; id?: any }) => {
        const { id, habilitado } = rowData;

        const verificaEstadoDaImobiliaria = allAgencyData.filter((imobiliaria: { id: any; }) => imobiliaria.id === id);

        console.log('verificaEstadoDaImobiliaria', verificaEstadoDaImobiliaria)
        
        const verificaEstadoElegivel = verificaEstadoDaImobiliaria.map((imobiliaria: { elegivel: any; }) => {
            return imobiliaria.elegivel
        });
        console.log('verificaEstadoElegivel', verificaEstadoElegivel)
        
        if (verificaEstadoElegivel[0] === false ) {
            try {
                const response = await ImobiliariasService.alteraDisponibilidade(id, true, 'imobiliaria');
        
                const responseData = response.data; 
        
                console.log('Dados enviados na resposta bem-sucedida:', responseData);
        
                const updatedData = allAgencyData.map(item =>
                    item.id === id ? { ...item, habilitado: !habilitado } : item
                );
        
                SetAllAgencyData(updatedData);
        
                setSnackbarProps({
                    message: 'Disponibilidade alterada com sucesso',
                    type: 'success',
                });
        
                setSnackbarOpen(true);
                fetchData();
            } catch (error) {
                console.error('Erro ao alterar a disponibilidade:', error);
        
                setSnackbarProps({
                    message: 'Erro ao alterar a disponibilidade',
                    type: 'error',
                });
        
                setSnackbarOpen(true);
            }
        } else {
            try {
                const response = await ImobiliariasService.alteraDisponibilidade(id, false, 'imobiliaria');
        
                const responseData = response.data; 
        
                console.log('Dados enviados na resposta bem-sucedida:', responseData);
        
                const updatedData = allAgencyData.map(item =>
                    item.id === id ? { ...item, habilitado: !habilitado } : item
                );
        
                SetAllAgencyData(updatedData);
        
                setSnackbarProps({
                    message: 'Disponibilidade alterada com sucesso',
                    type: 'success',
                });
        
                setSnackbarOpen(true);
                fetchData();
            } catch (error) {
                console.error('Erro ao alterar a disponibilidade:', error);
        
                setSnackbarProps({
                    message: 'Erro ao alterar a disponibilidade',
                    type: 'error',
                });
        
                setSnackbarOpen(true);
            }
        }
    };

    const columns = [
        // {
        //   title: 'Logo',
        //   field: 'imageUrl',
        //   filtering: false,
        //   export: false,

        //   render: (rowData: any) => (
        //     <img
        //       src={rowData.imageUrl}
        //       style={{ width: 64, borderRadius: '50%' }}
        //       alt="avatar"
        //     />
        //   ),
        // },
        {
            title: 'Nome',
            field: 'nome',
        },
        {
            title: 'Telefone',
            field: 'telefone',
        },
        {
            title: 'Email',
            field: 'email',
        },
        {
            title: 'Habilitar/Desabilitar',
            field: 'habilitado',
            render: (rowData: any) => (
                <Switch onChange={() => handleSwitchChange(rowData)} checked={rowData.elegivel} />
              )
        },


    ];


    function rowSelected(e: any, rowData: any) {
        // history.push({ pathname: '/painel/imobiliarias/detalhes', state: { rowData } })
    }

    function editSelected(e: any, rowData: any) {

        history.push({ pathname: '/painel/imobiliarias/editar', state: rowData })
    }

    function adicionarImobiliaria() {
        // setPageMenu('adicionar');
        history.push('/painel/imobiliarias/adicionar')
    }

    const handleSnackbarClose = (
        event?: React.SyntheticEvent,
        reason?: string,
    ) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };

    async function deletarRegistro(agencyData: any) {
        setDeleteModalOpen(false);
        await ImobiliariasService.deleteImobiliaria(agencyData.id)
        setSnackbarProps({
            message: 'Imobiliária deletada com sucesso',
            type: 'success',
        });

        setSnackbarOpen(true);
        history.go(0);
    }
    return (
        <>
            <Content>
                <OpcoesContainer>
                    {/* <Botao tipo="adicionar" tamanho="medio" onClick={adicionarImobiliaria}>
              Adicionar Imobiliária
            </Botao> */}
                </OpcoesContainer>

                <TabelaContainer>
                    <Tabela
                        title="Imobiliárias"
                        haveActions="none"
                        isLoading={isDataLoading}
                        columns={columns}
                        data={allAgencyData || []} 
                        onRowClick={(e: any, rowData: any): any => {
                            setAgencyData(rowData);
                            rowSelected(e, rowData);
                        }}
                        excluirClick={(e: any, rowData: any): any => {
                            setAgencyData(rowData);
                            setDeleteModalOpen(true);
                        }}
                        editarClick={(e: any, rowData: any): any => {
                            setAgencyData(rowData);
                            editSelected(e, rowData);
                        }}
                        pagination
                        paginationPageSize={10}
                    />
                </TabelaContainer>

                <DeleteModal
                    isModalOpen={deleteModalOpen}
                    onCloseModal={() => {
                        setDeleteModalOpen(false);
                    }}
                    onDeleteClick={() => deletarRegistro(agencyData)}
                />
                <Snackbars
                    type={snackbarProps.type}
                    handleClose={handleSnackbarClose}
                    open={snackbarOpen}
                >
                    {snackbarProps.message}
                </Snackbars>
            </Content>
        </>
    );
};

export default LeadsAdmin;


