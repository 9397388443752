import {
  IUserData,
  // IParcela,
  IContrato,
  IContractTier,
  UserTierTypes,
} from '../../../interfaces';
import { UserActionTypes } from './userAction';
import { onSignIn, onSignOut } from '../../../services/auth';

const initialState: IAdminState = {
  token: undefined,
};

interface IAdminState {
  token?: string;
}
interface UserAction {
  type: UserActionTypes;
  payload?: any;
}

export default function user(
  state: IAdminState = initialState,
  action: UserAction,
) {
  const { type, payload } = action;

  switch (type) {
    case UserActionTypes.LOGIN: {
      const { token, id,userType} = payload;
      onSignIn(token,id,userType);

      return {
        ...state,
        token,
        id,
        userType
      };
    }

    case UserActionTypes.LOGOUT: {
      onSignOut();

      return { ...state, token: undefined };
    }

    default:
      return { ...state };
  }
}
