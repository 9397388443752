import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { InputsContainer, InputsContent } from '../EditarProposta/styles';
import PreviewFile from '../../../components/PreviewFile/PreviewFile';


const DocumentDevolution = (props: any) => {
    const { filesUrl } = props;
    return (
        <>
            <InputsContent className="acordion-1">
                <Accordion className="acordion">
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className="acordion-summary-devolution"
                    >
                        <Typography className="anexar-documentos">
                            <AttachFileIcon />
                            Devoluções de Documentos
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <InputsContainer className="upload-fotos">
                            {filesUrl.map((el: any) => {
                                const urlSplited = el.document.split('.');
                                const nome = el.document.split('/');

                                let isImage = false;
                                if (urlSplited[urlSplited.length - 1] === 'png' ||
                                    urlSplited[urlSplited.length - 1] === 'jpg' ||
                                    urlSplited[urlSplited.length - 1] === 'jpeg' ||
                                    urlSplited[urlSplited.length - 1] === 'svg') {
                                    isImage = true;
                                }
                                return el.tag === 'devolution' ? <PreviewFile url={el.document} nome={nome[nome.length - 1]} isImage={isImage} /> :
                                <></>
                            }
                            )
                            }
                        </InputsContainer>
                    </AccordionDetails>
                </Accordion>
            </InputsContent>
        </>
    );
};

export default DocumentDevolution;
