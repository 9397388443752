import styled from 'styled-components';
import theme from '../../styles/tema';
import cores from '../../styles/cores';
import Imagem from '../../assets/svg/LogoEscudoVermelho.svg';

export const Container = styled.nav`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
`;

export const LogoContainer = styled.div`
  display: flex;
  width: 180px;
  height: 50px;
  margin: 0 30px;
  margin-top: 10px;
`;


export const Logo = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  background-image: url(${Imagem});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  transform: scale(1.15);
`;

export const UpperBar = styled.div`
  display: flex;
  width: 100%;
  height: 80px;
  background: ${cores.purple_2};
  align-items: center;
  justify-content: space-between;
  padding: 24px;
`;

export const Sair = styled.div`
  color: white;
  display: flex;
  align-items: center;
  width: fit-content;
  height: auto;

  h3 {
    padding-right: 6px;
    font-size: 16px;

    strong {
      font-weight: bold;
    }
  }

  .icone {
    display: flex;
    width: 32px;
    height: 32px;
  }

  &:hover {
    transition: 0.5s ease;
    cursor: pointer;
    color: ${cores.red};
    opacity: 0.7;
  }
`;

export const LowerBar = styled.div`
  width: 82%;
  height: 40px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 24px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  align-self: flex-end;

  .voltar-pagina-principal {
    cursor: pointer;
    font-size: 32px;
    font-weight: bold;
    margin-right: 10px;

    &:hover {
      transition: 0.3s linear;
      fill: ${cores.purple_1};
      transform: scale(1.1);
    }
  }

  h2 {
    margin: 0;
    font-weight: bold;
    font-size: 20px;
  }
`;

export const SidePart = styled.div`
  width: fit-content;
  height: inherit;
`;

export const ModalContainer = styled.div`
  outline: 0;
  border: none;
  height: inherit;
`;

export const BotaoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: inherit;

  .btn {
    width: 45%;
  }
`;
