import { TextField } from '@material-ui/core';
import React, { useState } from 'react';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import Botao from '../Botao/Botao';
import Modal from '../Modal/Modal';
import { BotaoContainer, ModalContainerDelete } from './styles';

interface deleteModalProps {
  isModalOpen: boolean;
  onDeleteClick: any;
  onCloseModal: any;
}

const DeleteModal = (props: deleteModalProps): any => {
  const { isModalOpen, onDeleteClick, onCloseModal } = props;

  const [areYouSure, setAreYouSure] = useState(true);
  // const [modalOpen, setModalOpen] = useState(!!isModalOpen);

  

  return (
    <Modal
      isOpen={isModalOpen}
      width={420}
      onClose={() => {
        setAreYouSure(true);
        onCloseModal();
      }}
    >
      <>
        <ModalContainerDelete>
          <h1> Você deseja excluir mesmo?</h1>
          <div className="aviso">
            <ErrorOutlineIcon />
            <p>Esse registro será excluído permanentemente</p>
            {/* <p>Essa operação pode impactar no funcionamento do aplicativo</p> */}
            <p>
              Para confirmar a exclusão digite{' '}
              <span style={{ color: 'red', fontWeight: 'bold' }}>excluir</span>{' '}
              no campo abaixo!
            </p>
          </div>

          <BotaoContainer>
            <TextField
              label="Excluir"
              variant="outlined"
              size="small"
              fullWidth
              helperText=""
              onChange={e => {
                if (e.target.value.toLowerCase() === 'excluir') {
                  setAreYouSure(false);
                }
              }}
            />
          </BotaoContainer>

          <BotaoContainer>
            <Botao
              tipo="excluir"
              tamanho="medio"
              className="btn"
              disabled={areYouSure}
              onClick={() => {
                setAreYouSure(true);
                onDeleteClick();
              }}
            >
              Excluir
            </Botao>
            <Botao
              tamanho="medio"
              className="btn"
              onClick={() => {
                setAreYouSure(true);
                onCloseModal();
              }}
            >
              Cancelar
            </Botao>
          </BotaoContainer>
        </ModalContainerDelete>
      </>
    </Modal>
  );
};

export default DeleteModal;
